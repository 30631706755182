/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./close.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "angular-feather";
import * as i4 from "./close.component";
var styles_CloseComponent = [i0.styles];
var RenderType_CloseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CloseComponent, data: {} });
export { RenderType_CloseComponent as RenderType_CloseComponent };
export function View_CloseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i-x", [["class", "close--icon"]], null, null, null, i2.View_ɵkk_0, i2.RenderType_ɵkk)), i1.ɵdid(2, 49152, null, 0, i3.ɵkk, [], null, null)], null, null); }
export function View_CloseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-close", [], null, null, null, View_CloseComponent_0, RenderType_CloseComponent)), i1.ɵdid(1, 114688, null, 0, i4.CloseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CloseComponentNgFactory = i1.ɵccf("shared-close", i4.CloseComponent, View_CloseComponent_Host_0, {}, {}, []);
export { CloseComponentNgFactory as CloseComponentNgFactory };
