var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TransactionService as ConvercusTransactionService, Configuration, TransactionData, } from "shared/lib/v2/apis/transactions";
import { HttpClient } from "@angular/common/http";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/config/config.service";
import * as i2 from "../../../common/services/utils/utils.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../apis/transactions/variables";
import * as i5 from "../../apis/transactions/configuration";
import * as i6 from "../../../common/services/environment/environment.service";
var TransactionService = /** @class */ (function (_super) {
    __extends(TransactionService, _super);
    function TransactionService(config, utils, httpClient, basePath, configuration, environmentService) {
        var _this = _super.call(this, httpClient, environmentService.getVariable("API_V2"), configuration) || this;
        _this.config = config;
        _this.utils = utils;
        _this.httpClient = httpClient;
        _this.environmentService = environmentService;
        return _this;
    }
    TransactionService.prototype.createTransaction = function (interactionId, body) {
        var store = this.config.getMountingConfig().store;
        return _super.prototype.createTransaction.call(this, interactionId, __assign({ externalId: Number(body.amount).toFixed(2) + "_" + (store ? store.vatId : store) + "_" + this.utils.getReceiptFormatDate(new Date()) }, body));
    };
    TransactionService.ngInjectableDef = i0.defineInjectable({ factory: function TransactionService_Factory() { return new TransactionService(i0.inject(i1.ConfigService), i0.inject(i2.UtilsService), i0.inject(i3.HttpClient), i0.inject(i4.BASE_PATH, 8), i0.inject(i5.Configuration, 8), i0.inject(i6.EnvironmentService)); }, token: TransactionService, providedIn: "root" });
    return TransactionService;
}(ConvercusTransactionService));
export { TransactionService };
