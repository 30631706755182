import { Injectable } from "@angular/core";
import { IResponse, IArtsResponse, IIdentifierType } from "shared/lib/common/interfaces";
import {
  ActionType,
  IClientComplet,
  IDisplayAd,
  IGetPermissionUserResponse,
  IMount,
  IMountDeviceResponse,
  IPartner,
  IPromotions,
  IRegister,
  IRegistrationCode,
  IStore,
  IStoreAPI2,
  IUnMountDeviceResponse,
} from "shared/lib/common/interfaces";
import {
  AdminService as AdminServiceV1,
  P4mService,
  SentryService,
  UtilsService,
  ConfigService,
  TranslateService,
} from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services/device-mounting/device-mounting.service";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { TransactionService } from "shared/lib/v2/services/transaction/transaction.service";
import { AccountService } from "../account/account.service";

type trueType = true;
type falseType = false;

@Injectable({
  providedIn: "root",
})
export class AdminService extends AdminServiceV1 {
  constructor(
    public translate: TranslateService,
    public deviceMountingService: DeviceMountingService,
    public p4m: P4mService,
    public configService: ConfigService,
    public sentry: SentryService,
    public utils: UtilsService,
    private transaction: TransactionService,
    private account: AccountService,
  ) {
    super(translate, p4m, utils, configService, sentry);
  }
  public getRegistrationType(): Promise<IResponse<IRegistrationCode>> {
    return Promise.resolve({ ok: true as trueType, response: this.deviceMountingService.profile.registrationType });
  }
  public async arts(transactionTime: string, amount: number, keyCode: string, idType: IIdentifierType): Promise<IResponse<IArtsResponse>> {
    try {
      const store = this.configService.getMountingConfig().store;
      await this.account
        .createTransaction(keyCode, this.configService.getMountingConfig().workstationId, idType, {
          amount,
          transactionType: "EARNTRANSACTION",
          transactionTime,
          externalId: `${amount.toFixed(2)}_${store ? store.vatId : store}_${this.utils.getReceiptFormatDate(new Date(transactionTime))}`,
        })
        .toPromise();
      return { ok: true, response: null };
    } catch (error) {
      const message = error.error && error.error.message ? error.error.message : error.message || error;
      return { ok: false, error: { message } };
    }
  }
  public async extendedRevenuePointsManually(
    keyCode: string,
    amount: number,
    extendedRevenueFactsId: number,
    idType: IIdentifierType,
    externalId: string,
  ): Promise<IResponse<any>> {
    try {
      await this.account
        .createTransaction(keyCode, this.configService.getMountingConfig().workstationId, idType, {
          amount,
          transactionType: "EARNTRANSACTION",
          externalId,
        })
        .toPromise();
      return { ok: true, response: null };
    } catch (error) {
      const message = error.error && error.error.message ? error.error.message : error.message || error;
      return { ok: false, error: { message } };
    }
  }
  public readProgramPartners(): Promise<IResponse<IPartner[], { message: string; code?: number }>> {
    return new Promise(resolve => resolve({ ok: false, error: { message: "Method not implemented." } }));
  }
  public readPartnerStores(partnerId: string): Promise<IResponse<IStoreAPI2[], { message: string; code?: number }>> {
    return new Promise(resolve => resolve({ ok: false, error: { message: "Method not implemented." } }));
  }
  public getAllClients(): Promise<IResponse<IClientComplet[], { message: string; code?: number }>> {
    return Promise.resolve({
      ok: true as trueType,
      response: [
        {
          clientId: Number(this.configService.getMountingConfig().clientId),
          showRegistrationButton: false,
          pointsRoundingCode: this.deviceMountingService.profile.pointsRoundingCode,
          revenueRoundingCode: this.deviceMountingService.profile.revenueRoundingCode,
        } as IClientComplet,
      ],
    });
  }
  public getClientStore(): IResponse<IStore, { message: string; code?: number }> {
    return {
      ok: true,
      response: {
        storeId: 1234,
        revenueModuleMode: this.deviceMountingService.profile.decimalRevenue ? "FLOAT" : "INT",
        usesPin: this.deviceMountingService.profile.pinRequired,
        pins: [{ pin: this.deviceMountingService.profile.pin }],
      } as IStore,
    };
  }
  public fetchStoresOfClient(clientId: string): Promise<IResponse<IStore[], { message: string; code?: number }>> {
    return null;
  }
  public getDisplayAds(deviceKey: string): Promise<IResponse<IDisplayAd[], { message: string; code?: number }>> {
    try {
      return Promise.resolve({
        ok: true as trueType,
        response: this.deviceMountingService.images
          .sort((a, b) => {
            if (a.order < b.order) {
              return -1;
            }
            if (a.order > b.order) {
              return 1;
            }
            return 0;
          })
          .map(i => ({
            displayAdId: i.id,
            displayAdUsageCode: "DEFAULT",
            pictureFilePath: i.filePath,
            width: null,
            height: null,
            displayDurationMsec: i.displayDurationMiliseconds,
            displayAdType: null,
            lastUpdate: null,
            status: null,
          })),
      });
    } catch (e) {
      return Promise.resolve({
        ok: false as falseType,
        error: { message: e.message },
      });
    }
  }
  public mountDevice(params: IMount): Promise<IResponse<IMountDeviceResponse, { message: string; code?: number }>> {
    return new Promise(resolve => resolve({ ok: false, error: { message: "Method not implemented." } }));
  }
  public async unMountDevice(): Promise<IResponse<IUnMountDeviceResponse, { message: string; code?: number }>> {
    try {
      await this.deviceMountingService.unmount();
      return { ok: true, response: {} as IUnMountDeviceResponse };
    } catch (error) {
      return { ok: false, error };
    }
  }
  public isValidPin(pin: string): boolean {
    throw new Error("Method not implemented.");
  }
  public async adminLogin(
    email: string,
    organization: string,
    password: string,
  ): Promise<IResponse<{ mountingApiKey: string; token: string }, { message: string; code?: number }>> {
    const loginResponse = await this.p4m.login({
      email,
      organization,
      password,
    });
    if (loginResponse.ok === true) {
      this.p4m.setUser(email);
      this.p4m.setPassword(password);
      this.p4m.setToken(loginResponse.response);
      return {
        ok: true,
        response: {
          mountingApiKey: null,
          token: loginResponse.response,
        },
      };
    } else {
      return loginResponse;
    }
  }
  public async reLogin(): Promise<IResponse<string>> {
    try {
      return await this.p4m.login({
        email: this.p4m.getUser(),
        organization: this.configService.getOrganization(),
        password: this.p4m.getPassword(),
      });
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.reLogin" });
      return { ok: false, error: { message: error.message || error } };
    }
  }
  public getPromotions(): Promise<IPromotions> {
    return Promise.resolve({
      rmc1: {
        factor: 3,
        name: "Massage 3x",
        points: 0,
        revenue: 0,
        type: ActionType.RMC1,
      },
      rmc2: {
        factor: 5,
        name: "Shop 5x",
        points: 0,
        revenue: 0,
        type: ActionType.RMC2,
      },
      rmc3: {
        factor: 1,
        name: "Sonstiges",
        points: 0,
        revenue: 0,
        type: ActionType.RMC3,
      },
      rmt: {
        factor: 1,
        name: "TOTAL",
        points: 0,
        revenue: 0,
        type: ActionType.RMT,
      },
      rmb: {
        factor: 1,
        name: "BURN",
        points: 0,
        revenue: 0,
        type: ActionType.RMB,
      },
    });
  }
  public logout(): void {
    localStorage.clear();
  }

  public isAuthenticated(): boolean {
    if (this.p4m.getUser() && this.p4m.getPassword()) {
      return true;
    } else {
      return false;
    }
  }

  public getPinCode(): string {
    return this.deviceMountingService.profile.pin;
  }

  public getMountingApiKey(): string {
    throw new Error("Method not implemented.");
  }
  public getApiKey(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.APIKEY));
  }
  public validateSaveNewPinParams(pin: string): IResponse<{ pin: string }, { message: string; code?: number }> {
    throw new Error("Method not implemented.");
  }
  public parseGetPermissionUser(response: IGetPermissionUserResponse): IResponse<string, { message: string; code?: number }> {
    throw new Error("Method not implemented.");
  }
  public register(params: IRegister): Promise<IResponse<any, { message: string; code?: number }>> {
    throw new Error("Method not implemented.");
  }
}
