import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, RoutingService, TranslateService, UtilsService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

@Injectable({
  providedIn: "root",
})
export class IsRegisteredAdminGuard implements CanActivate {
  constructor(
    private utils: UtilsService,
    private admin: AdminService,
    private translate: TranslateService,
    private config: ConfigService,
    private routing: RoutingService,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const deviceMounting = this.config.getMountingConfig()
      .deviceKey
      ? this.config.getMountingConfig()
      : JSON.parse(this.utils.decrypt(localStorage.getItem("mountingConfig")));
    const deviceKey = deviceMounting
      ? deviceMounting.deviceKey
      : null;
    const language = this.translate.getLanguage();

    if (this.admin.isAuthenticated() && deviceKey && deviceKey !== "" && language) {
      if (state.url === `/${SharedRoutes.login}`) this.routing.goForward([SharedRoutes.screenSaver]);
      return true;
    } else {
      localStorage.clear();
      if (state.url === `/${SharedRoutes.login}`) {
        return true;
      } else {
        this.routing.goForward([SharedRoutes.login]);
        return false;
      }
    }
  }
}
