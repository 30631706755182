import { Component, OnInit } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "generic-revenue-date",
  templateUrl: "./revenue-date.page.html",
  styleUrls: ["./revenue-date.page.scss"],
})
export class RevenueDatePage implements OnInit {
  public day: string;
  public hour: string;

  constructor(public translate: TranslateService, private modal: ModalController) {}

  public ngOnInit(): void {
    const date = new Date();
    this.day = date.toISOString();
    this.hour = date.toISOString();
  }

  public dismiss(): void {
    const [day, month, year] = new Date(this.day).toLocaleDateString("DE").split(".");
    const [hour, minutes, seconds] = new Date(this.hour).toLocaleTimeString("DE").split(":");
    this.modal.dismiss(
      `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T${hour.padStart(2, "0")}:${minutes.padStart(2, "0")}:${seconds.padStart(
        2,
        "0",
      )}`,
    );
  }

  public cancelation(): void {
    this.modal.dismiss();
  }
}
