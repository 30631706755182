/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RatingConfigData { 
    billNoUniqueness?: boolean;
    burnCurrencyToPointsFactor: number;
    currency: string;
    earnCurrencyToPointsFactor: number;
    pwpAllowed: RatingConfigData.PwpAllowedEnum;
    rounding: RatingConfigData.RoundingEnum;
}
export namespace RatingConfigData {
    export type PwpAllowedEnum = 'NO' | 'ALLUSERS' | 'REGISTEREDUSERS';
    export const PwpAllowedEnum = {
        NO: 'NO' as PwpAllowedEnum,
        ALLUSERS: 'ALLUSERS' as PwpAllowedEnum,
        REGISTEREDUSERS: 'REGISTEREDUSERS' as PwpAllowedEnum
    };
    export type RoundingEnum = 'UP' | 'DOWN' | 'CEILING' | 'FLOOR' | 'HALF_UP' | 'HALF_DOWN' | 'HALF_EVEN';
    export const RoundingEnum = {
        UP: 'UP' as RoundingEnum,
        DOWN: 'DOWN' as RoundingEnum,
        CEILING: 'CEILING' as RoundingEnum,
        FLOOR: 'FLOOR' as RoundingEnum,
        HALFUP: 'HALF_UP' as RoundingEnum,
        HALFDOWN: 'HALF_DOWN' as RoundingEnum,
        HALFEVEN: 'HALF_EVEN' as RoundingEnum
    };
}


