<ion-content>
  <div class="success-redeem">
    <shared-close class="success-redeem__shared-close" (tap)="dismiss()"></shared-close>
    <h2 class="success-redeem__title">
      {{ title }}
    </h2>
    <i-check class="success-redeem__check"></i-check>
    <span class="success-redeem__points">{{ points | number: "":translate.getSessionLanguage() }} {{ translate._("P") }}</span>
    <span *ngIf="!loading" class="success-redeem__total-points">
      {{ translate._("SUCCESS_REDEEM_MODAL_TOTAL_POINTS") }}
      <strong>{{ totalPoints | number: "":translate.getSessionLanguage() }} {{ translate._("P") }}</strong></span
    >
    <ion-spinner *ngIf="loading" class="success-redeem__spinner"></ion-spinner>
    <ion-button (tap)="dismiss()" class="success-redeem__button">{{ translate._("SCHLIESSEN") }}</ion-button>
  </div>
</ion-content>
