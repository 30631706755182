/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon-card.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i6 from "../../components/coupon-card/coupon-card.component.ngfactory";
import * as i7 from "../../components/coupon-card/coupon-card.component";
import * as i8 from "../../../../../../shared/lib/common/services/utils/utils.service";
import * as i9 from "../../../../../../shared/lib/common/services/translate/translate.service";
import * as i10 from "./coupon-card.page";
import * as i11 from "../../../../../../shared/lib/common/services/config/config.service";
var styles_CouponCardPage = [i0.styles];
var RenderType_CouponCardPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponCardPage, data: {} });
export { RenderType_CouponCardPage as RenderType_CouponCardPage };
export function View_CouponCardPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-header", [["class", "coupon-card-modal__header"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "img", [["alt", "company logo"], ["class", "coupon-card-modal__header__icon"], ["src", "assets/app-logo/ocm.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "shared-close", [["class", "coupon-card-modal__header__close"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.dismiss(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(4, 114688, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-content", [["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(6, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "ocm-coupon-card", [], null, [[null, "RedeemEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("RedeemEvent" === en)) {
        var pd_0 = (_co.dismiss($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CouponCardComponent_0, i6.RenderType_CouponCardComponent)), i1.ɵdid(8, 49152, null, 0, i7.CouponCardComponent, [i8.UtilsService, i9.TranslateService], { ticketSelected: [0, "ticketSelected"] }, { RedeemEvent: "RedeemEvent" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = _co.ticketSelected; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_CouponCardPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-coupon-card-modal", [], null, null, null, View_CouponCardPage_0, RenderType_CouponCardPage)), i1.ɵdid(1, 114688, null, 0, i10.CouponCardPage, [i3.ModalController, i11.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CouponCardPageNgFactory = i1.ɵccf("ocm-coupon-card-modal", i10.CouponCardPage, View_CouponCardPage_Host_0, { ticketSelected: "ticketSelected" }, {}, []);
export { CouponCardPageNgFactory as CouponCardPageNgFactory };
