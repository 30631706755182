import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { AppRoutingModule } from "ocm/src/app/app-routing.module";
import { AppComponent } from "ocm/src/app/app.component";
import {
  BurnComponent,
  CouponCardComponent,
  CouponComponent,
  HeaderComponent,
  HistoryComponent,
  DatetimeComponent,
} from "ocm/src/app/components";
import { DevicesComponent } from "ocm/src/app/icons";
import {
  AdminPage,
  BurnPage,
  ConfirmationPage,
  CouponCardPage,
  LanguagePage,
  MountingSettingsPage,
  QrScannerPage,
  ScreenSaverPage,
  SuccessPage,
  SuccessRedeemPage,
  RevenueDatePage,
  LoginPage,
  ConfigurationsPage,
} from "ocm/src/app/pages";
import { SharedModule } from "shared/shared.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { InterceptorService, DeviceMountingService, ProgramService } from "shared/lib/v2/services";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { P4mService, UtilsService, ConfigService, ThirdPartyScriptsService } from "shared/lib/common/services";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { CONFIGURATION } from "shared/lib/common/constants";

export function setCredentials(p4m: P4mService, utils: UtilsService): () => void {
  return () => {
    const oldUser = utils.decrypt(localStorage.getItem("email"));
    const oldPassword = utils.decrypt(localStorage.getItem("password"));
    if (oldUser && oldPassword) {
      p4m.setUser(oldUser);
      p4m.setPassword(oldPassword);
    }
  };
}

export function initializeApp(
  deviceMounting: DeviceMountingService,
  config: ConfigService,
  thirdParty: ThirdPartyScriptsService,
  utils: UtilsService,
  program: ProgramService,
): () => void {
  return async () => {
    await thirdParty.addThirdPartyScripts();
    config.setOrganization(CONFIGURATION.organization);
    await config.setConfig(program.getProgramConfig(CONFIGURATION.organization));
    const res = await deviceMounting.getMounting();
    if (res.ok === true) config.setMountingConfig(await deviceMounting.parseMountingConfigV1ToV2(res.response));
    else {
      const mountingConfig = JSON.parse(utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG)));
      if (mountingConfig) localStorage.clear();
    }
  };
}

const icons = [DevicesComponent];
const modals = [RevenueDatePage, LanguagePage, CouponCardPage, SuccessPage, ConfirmationPage, SuccessRedeemPage];
const components = [CouponComponent, HeaderComponent, BurnComponent, HistoryComponent, CouponCardComponent, DatetimeComponent];
const pages = [
  RevenueDatePage,
  MountingSettingsPage,
  AdminPage,
  ScreenSaverPage,
  SuccessPage,
  BurnPage,
  QrScannerPage,
  LoginPage,
  ConfigurationsPage,
];

@NgModule({
  entryComponents: [modals],
  declarations: [AppComponent, pages, components, icons, modals],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    IonicModule.forRoot({
      mode: "md",
      keyboardHeight: 0,
    }),
    AppRoutingModule,
    SharedModule,
    ZXingScannerModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: false }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: APP_INITIALIZER, useFactory: setCredentials, deps: [P4mService, UtilsService], multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DeviceMountingService, ConfigService, ThirdPartyScriptsService, UtilsService, ProgramService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
