var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NgZone } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { InformationModalPage } from "shared/lib/v1/pages";
import { IAudioFile } from "shared/lib/common/pages";
import { QrScannerPage as QrScannerPageShared } from "shared/lib/common/pages";
import { ConfigService, ToastService, TranslateService, UserService, InactivityService, RoutingService } from "shared/lib/common/services";
import { SuccessRedeemPage } from "../success-redeem/success-redeem.page";
import { SharedRoutes } from "shared/lib/common/enums";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { AccountService, AdminService } from "shared/lib/v2/services";
var QrScannerPage = /** @class */ (function (_super) {
    __extends(QrScannerPage, _super);
    function QrScannerPage(_translate, _zone, _userService, _toast, _config, _modal, _admin, _inactivity, _platform, _qrScanner, _routing, _account) {
        var _this = _super.call(this, _zone, _translate, _userService, _toast, _config, _modal, _admin, _inactivity, _platform, _qrScanner, _routing) || this;
        _this._translate = _translate;
        _this._zone = _zone;
        _this._userService = _userService;
        _this._toast = _toast;
        _this._config = _config;
        _this._modal = _modal;
        _this._admin = _admin;
        _this._inactivity = _inactivity;
        _this._platform = _platform;
        _this._qrScanner = _qrScanner;
        _this._routing = _routing;
        _this._account = _account;
        return _this;
    }
    QrScannerPage.prototype.scannerCode = function (qrResult, error) {
        return __awaiter(this, void 0, void 0, function () {
            var actionSuccess, resp, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.playSound(IAudioFile.scanned);
                        this.loading = true;
                        return [4 /*yield*/, this.disableAppQRScanner()];
                    case 1:
                        _b.sent();
                        actionSuccess = false;
                        this._inactivity.resetTimer();
                        if (!!error) return [3 /*break*/, 15];
                        resp = this.validateQr(qrResult);
                        if (!(resp.ok === true)) return [3 /*break*/, 12];
                        _a = resp.response.type;
                        switch (_a) {
                            case "user": return [3 /*break*/, 2];
                            case "earn": return [3 /*break*/, 4];
                            case "reward": return [3 /*break*/, 6];
                            case "coupon": return [3 /*break*/, 8];
                            case "acumulate": return [3 /*break*/, 10];
                        }
                        return [3 /*break*/, 11];
                    case 2: return [4 /*yield*/, this.user(resp.response.keyCode, resp.response.idType)];
                    case 3:
                        actionSuccess = _b.sent();
                        this.loading = false;
                        return [3 /*break*/, 11];
                    case 4: return [4 /*yield*/, this.earn(resp.response.keyCode, resp.response.idType)];
                    case 5:
                        actionSuccess = _b.sent();
                        return [3 /*break*/, 11];
                    case 6: return [4 /*yield*/, this.burnReward(resp.response.qrObject)];
                    case 7:
                        actionSuccess = _b.sent();
                        this.loading = false;
                        return [3 /*break*/, 11];
                    case 8: return [4 /*yield*/, this.burnCoupon(resp.response.qrObject)];
                    case 9:
                        actionSuccess = _b.sent();
                        this.loading = false;
                        return [3 /*break*/, 11];
                    case 10:
                        this._toast.show(this.translate._("INVALID_QR"), "error");
                        return [3 /*break*/, 11];
                    case 11: return [3 /*break*/, 14];
                    case 12: return [4 /*yield*/, this._toast.show(this.translate._(resp.error.message), "error")];
                    case 13:
                        _b.sent();
                        _b.label = 14;
                    case 14: return [3 /*break*/, 17];
                    case 15: return [4 /*yield*/, this._toast.show(this.translate._(qrResult), "error")];
                    case 16:
                        _b.sent();
                        _b.label = 17;
                    case 17:
                        if (!!actionSuccess) return [3 /*break*/, 19];
                        return [4 /*yield*/, this.enableAppQRScanner()];
                    case 18:
                        _b.sent();
                        _b.label = 19;
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.openSuccessModal = function (keyCode, points, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, successModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            points: points,
                            idType: idType,
                            keyCode: keyCode,
                            title: points > 0 ? this.translate._("SUCCESS_REDEEM_MODAL_TITLE_POSITIVE") : this.translate._("SUCCESS_REDEEM_MODAL_TITLE_NEGATIVE"),
                        };
                        return [4 /*yield*/, this._modal.create({
                                component: SuccessRedeemPage,
                                componentProps: componentProps,
                            })];
                    case 1:
                        successModal = _a.sent();
                        return [4 /*yield*/, successModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, successModal.onDidDismiss()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.earn = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(keyCode && this.params.type === "earn")) return [3 /*break*/, 7];
                        if (!this.params.manualPoints) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.bookPoints(keyCode, idType)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        if (!this.params.date) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._admin.arts(this.params.date, Number(this.params.totalRevenue), keyCode, idType)];
                    case 3:
                        resp = _a.sent();
                        if (resp.ok === true) {
                            this.timeout = setTimeout(function () {
                                _this._toast.show(_this.translate._("ARTS_ERROR"), "error");
                                _this._inactivity.resetTimer();
                                clearTimeout(_this.timeout);
                                _this.loading = false;
                            }, 30000);
                            return [2 /*return*/, true];
                        }
                        else {
                            this._toast.show(this.translate._(resp.error.message), "error");
                            this.loading = false;
                        }
                        return [3 /*break*/, 7];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this._account
                                .incentivateTransaction(keyCode, this._config.getMountingConfig().workstationId, this.params.transactionId, idType)
                                .toPromise()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 6:
                        error_1 = _a.sent();
                        this._toast.show(this._translate._(error_1), "error");
                        return [2 /*return*/, false];
                    case 7: return [2 /*return*/, false];
                }
            });
        });
    };
    QrScannerPage.prototype.user = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var details, queryParams, saveUserResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!keyCode) return [3 /*break*/, 10];
                        return [4 /*yield*/, this._userService.getDetails(keyCode, idType)];
                    case 1:
                        details = _a.sent();
                        if (!(details.ok === true)) return [3 /*break*/, 8];
                        queryParams = { idType: idType, keyCode: keyCode };
                        this._userService.setUser(details.response);
                        if (!(this._userService.getUser().userStatusCode === "LOCKED")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.showLockedModal()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, this._routing.goForward([SharedRoutes.screenSaver])];
                    case 3:
                        if (!(!this._userService.getUser().isRegistered && this.registrationType !== "NO")) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.showRegisterModal(keyCode, this._userService.getUser())];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, this._routing.goForward([SharedRoutes.screenSaver])];
                    case 5: return [4 /*yield*/, this.saveUser(details.response, idType)];
                    case 6:
                        saveUserResponse = _a.sent();
                        if (saveUserResponse.ok === true) {
                            this._routing.goForward([SharedRoutes.burn], { queryParams: queryParams });
                        }
                        else {
                            this._toast.show(this.translate._(saveUserResponse.error.message), "error");
                        }
                        return [2 /*return*/, saveUserResponse.ok];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this._toast.show(this.translate._(details.error.message), "error");
                        _a.label = 9;
                    case 9: return [2 /*return*/, details.ok];
                    case 10: return [2 /*return*/, false];
                }
            });
        });
    };
    QrScannerPage.prototype.showRegisterModal = function (keyCode, user) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, informationModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            title: this.translate._("PLEASE_REGISTER"),
                            description: "",
                            buttonText: this.translate._("UNDERSTOOD"),
                        };
                        return [4 /*yield*/, this._modal.create({
                                component: InformationModalPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 1:
                        informationModal = _a.sent();
                        return [4 /*yield*/, informationModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, informationModal.onDidDismiss()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    QrScannerPage.prototype.showLockedModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, informationModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            title: this.translate._("LOCKED_USER"),
                            description: "",
                            buttonText: this.translate._("UNDERSTOOD"),
                        };
                        return [4 /*yield*/, this._modal.create({
                                component: InformationModalPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 1:
                        informationModal = _a.sent();
                        return [4 /*yield*/, informationModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, informationModal.onDidDismiss()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    QrScannerPage.prototype.bookPoints = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.params.type === "earn")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._userService.bookPoints(keyCode, this.params.manualPoints.toString(), idType)];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            this.openSuccessModal(keyCode, this.params.manualPoints, idType);
                        }
                        else {
                            this._toast.show(this.translate._(response.error.message), "error");
                        }
                        return [2 /*return*/, response.ok];
                    case 2: return [2 /*return*/, false];
                }
            });
        });
    };
    return QrScannerPage;
}(QrScannerPageShared));
export { QrScannerPage };
