/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./header.component";
import * as i6 from "../../../common/services/routing/routing.service";
import * as i7 from "../../../common/services/config/config.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-img", [["class", "header--logo"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.press() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(1, 49152, null, 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.config.logo, ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-header", [["class", "header"], ["no-border", ""]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isScreenSaver ? !((_co.config == null) ? null : ((_co.config.screenSaver == null) ? null : _co.config.screenSaver.hideLogo)) : true); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isNotFound; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i5.HeaderComponent, [i6.RoutingService, i7.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("shared-header", i5.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
