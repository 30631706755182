var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
var ScreenSaverPage = /** @class */ (function () {
    function ScreenSaverPage(user, translate, config, admin, utils, routing) {
        this.user = user;
        this.translate = translate;
        this.config = config;
        this.admin = admin;
        this.utils = utils;
        this.routing = routing;
    }
    ScreenSaverPage.prototype.ngOnInit = function () {
        this.images = [];
        this.initSlide();
        this.background = "assets/screen-saver/default.jpg";
        this.actualImage = "assets/screen-saver/default.jpg";
    };
    ScreenSaverPage.prototype.ionViewDidEnter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var images;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.admin.fetchStoresOfClient(this.config.getMountingConfig().clientId);
                        return [4 /*yield*/, this.getImages()];
                    case 1:
                        images = _a.sent();
                        this.images = __spread(this.images, images.filter(function (_a) {
                            var url = _a.url;
                            return _this.images.findIndex(function (img) { return img.url === url; }) < 0;
                        }));
                        this.translate.setSessionLanguage();
                        this.user.removeUser();
                        this.languageOptions = { header: "Select language" };
                        this.showButtons = !this.config.screenSaver || (this.config.screenSaver && this.config.screenSaver.message.show);
                        return [2 /*return*/];
                }
            });
        });
    };
    ScreenSaverPage.prototype.goQrScanner = function () {
        var queryParams = { type: "burn" };
        this.routing.goForward([SharedRoutes.qrScanner], { queryParams: queryParams });
    };
    ScreenSaverPage.prototype.onImageError = function (erroredUrl) {
        this.images = this.images.filter(function (_a) {
            var url = _a.url;
            return url !== erroredUrl;
        });
    };
    ScreenSaverPage.prototype.initSlide = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.slideActive) {
                    this.slideActive = true;
                    this.backgroundSlide();
                }
                return [2 /*return*/];
            });
        });
    };
    ScreenSaverPage.prototype.backgroundSlide = function () {
        return __awaiter(this, void 0, void 0, function () {
            var i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.images.length > 0)) return [3 /*break*/, 5];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.images.length)) return [3 /*break*/, 4];
                        this.actualImage = this.images[i].url;
                        return [4 /*yield*/, this.utils.waiting(this.images[i].duration)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        this.actualImage = this.background;
                        return [4 /*yield*/, this.utils.waiting(5000)];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        this.backgroundSlide();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScreenSaverPage.prototype.getImages = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        resp = [];
                        return [4 /*yield*/, this.admin.getDisplayAds(this.config.getMountingConfig().deviceKey)];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            response.response.forEach(function (displayAd) {
                                if (displayAd.displayAdUsageCode === "PRINTER_LOGO") {
                                    _this.config.setPrinterLogo(displayAd.pictureFilePath);
                                }
                                else {
                                    resp.push({
                                        url: displayAd.pictureFilePath,
                                        duration: displayAd.displayDurationMsec,
                                    });
                                }
                            });
                        }
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    return ScreenSaverPage;
}());
export { ScreenSaverPage };
