var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { LANGUAGE } from "shared/lib/common/i18n/translate";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import * as i0 from "@angular/core";
import * as i1 from "../utils/utils.service";
var TranslateService = /** @class */ (function () {
    function TranslateService(utils) {
        this.utils = utils;
        this.translations = LANGUAGE;
    }
    TranslateService.prototype.setLanguage = function (language) {
        localStorage.setItem(LOCAL_STORAGE.LANGUAGE, this.utils.encrypt(language));
    };
    TranslateService.prototype.getLanguage = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.LANGUAGE));
    };
    TranslateService.prototype.setSessionLanguage = function (sessionLanguage) {
        if (sessionLanguage === void 0) { sessionLanguage = this.getLanguage(); }
        this.sessionLanguage = sessionLanguage;
    };
    TranslateService.prototype.getSessionLanguage = function () {
        return this.sessionLanguage || this.getLanguage() || "en";
    };
    TranslateService.prototype.setTranslations = function (remoteTraslations) {
        if (remoteTraslations === void 0) { remoteTraslations = { en: {}, de: {} }; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (remoteTraslations) {
                    this.translations = {
                        en: __assign({}, LANGUAGE.en, remoteTraslations.en),
                        de: __assign({}, LANGUAGE.de, remoteTraslations.de),
                    };
                }
                else {
                    this.translations = {
                        en: LANGUAGE.en,
                        de: LANGUAGE.de,
                    };
                }
                return [2 /*return*/];
            });
        });
    };
    TranslateService.prototype._ = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var translate = this.translations[this.getSessionLanguage()][key] || key;
        if (args && args.length > 0 && translate !== "") {
            args.forEach(function (argument, index) {
                translate = translate.replace("$" + index, argument);
            });
        }
        return translate;
    };
    TranslateService.ngInjectableDef = i0.defineInjectable({ factory: function TranslateService_Factory() { return new TranslateService(i0.inject(i1.UtilsService)); }, token: TranslateService, providedIn: "root" });
    return TranslateService;
}());
export { TranslateService };
