var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AccountService as ConvercusAccountService, Configuration, TransactionData, LineItemData, } from "shared/lib/v2/apis/accounts";
import { HttpClient } from "@angular/common/http";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/config/config.service";
import * as i2 from "../../../common/services/utils/utils.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../apis/accounts/variables";
import * as i5 from "../../apis/accounts/configuration";
import * as i6 from "../../../common/services/environment/environment.service";
var AccountService = /** @class */ (function (_super) {
    __extends(AccountService, _super);
    function AccountService(config, utils, httpClient, basePath, configuration, environmentService) {
        var _this = _super.call(this, httpClient, environmentService.getVariable("API_V2"), configuration) || this;
        _this.config = config;
        _this.utils = utils;
        _this.httpClient = httpClient;
        _this.environmentService = environmentService;
        return _this;
    }
    AccountService.prototype.incentivateTransaction = function (accountId, interactionId, transactionId, idType) {
        return _super.prototype.incentivateTransaction.call(this, accountId, interactionId, transactionId, idType);
    };
    AccountService.prototype.createTransaction = function (accountId, interactionId, idType, body) {
        var store = this.config.getMountingConfig().store;
        return _super.prototype.addTransaction.call(this, accountId, interactionId, idType, __assign({ externalId: Number(body.amount).toFixed(2) + "_" + (store ? store.vatId : store) + "_" + this.utils.getReceiptFormatDate(new Date()) }, body));
    };
    AccountService.prototype.createTransactionPrev = function (accountId, interactionId, idType, body) {
        return _super.prototype.createTransactionPreview.call(this, accountId, interactionId, idType, body);
    };
    AccountService.prototype.getTransactionData = function (revenues, type) {
        return {
            amount: this.get(revenues, "money"),
            transactionType: type,
            lineItems: revenues.map(function (r, i) {
                return {
                    extendedAmount: r.money,
                    itemID: r.id,
                    merchandiseGroupCode: r.groupCode ? r.groupCode : undefined,
                    merchandiseSubGroupCode: r.subGroupCode ? r.subGroupCode : undefined,
                    description: r.name,
                    sequenceNumber: i + 1,
                    type: r.money > 0 ? LineItemData.TypeEnum.SALE : LineItemData.TypeEnum.RETURN,
                };
            }),
        };
    };
    AccountService.prototype.filterRevenues = function (revenues, type) {
        return revenues.filter(function (r) { return (type === "EARNTRANSACTION" ? !r.unitType : r.unitType === type); });
    };
    AccountService.prototype.get = function (revenues, type) {
        var total = 0;
        (revenues || []).forEach(function (r) {
            total += r[type];
        });
        return total;
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.ConfigService), i0.inject(i2.UtilsService), i0.inject(i3.HttpClient), i0.inject(i4.BASE_PATH, 8), i0.inject(i5.Configuration, 8), i0.inject(i6.EnvironmentService)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}(ConvercusAccountService));
export { AccountService };
