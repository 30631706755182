<ion-spinner *ngIf="loading"></ion-spinner>
<ion-list *ngIf="tickets?.length > 0" class="burn-component__ticket-list" [class.--is-mobile]="mobileVersion">
  <ion-item
    *ngFor="let ticket of tickets"
    class="burn-component__ticket-list__ticket"
    [class.--active]="ticketSelected?.voucherId === ticket.voucherId"
    [class.--disable]="!ticket.unlocked"
    [disabled]="!ticket.unlocked"
    (click)="selectTicketHandler(ticket)"
  >
    <ocm-coupon [ticket]="ticket"></ocm-coupon>
  </ion-item>
</ion-list>
<div *ngIf="!mobileVersion && tickets?.length > 0 && !loading" class="burn-component__redeem">
  <ocm-coupon-card [ticketSelected]="ticketSelected" (RedeemEvent)="askToRedeemHandler($event)"></ocm-coupon-card>
</div>

<div *ngIf="tickets?.length === 0 && !loading" class="burn-component__empty">
  <h4>{{ translate._("BURN_PAGE_EMPTY_MESSAGE") }}</h4>
</div>
