var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModalController, AlertController } from "@ionic/angular";
import { ConfigService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { AccountService, DeviceMountingService } from "shared/lib/v2/services";
var SummaryPage = /** @class */ (function () {
    function SummaryPage(deviceMounting, account, modal, t, alert, userService, config, toast) {
        this.deviceMounting = deviceMounting;
        this.account = account;
        this.modal = modal;
        this.t = t;
        this.alert = alert;
        this.userService = userService;
        this.config = config;
        this.toast = toast;
    }
    SummaryPage.prototype.showAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var alert, role;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alert.create({
                            header: this.t._("REVENUE_ALERT_TITLE"),
                            message: this.t._("REVENUE_ALERT_MESSAGE"),
                            buttons: [
                                {
                                    role: "cancel",
                                    text: this.t._("CANCEL"),
                                },
                                {
                                    text: this.t._("CONFIRM"),
                                },
                            ],
                            backdropDismiss: false,
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, alert.onWillDismiss()];
                    case 3:
                        role = (_a.sent()).role;
                        if (!role || (role && role.toLowerCase() !== "cancel"))
                            this.modal.dismiss(this.revenues);
                        return [2 /*return*/];
                }
            });
        });
    };
    SummaryPage.prototype.updatePreview = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, resp, error_1, e;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        user = this.userService.getUser();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.account
                                .createTransactionPrev(user.keyCode, this.config.getMountingConfig().workstationId, user.idType, this.account.getTransactionData(this.revenues, this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION"))
                                .toPromise()];
                    case 2:
                        resp = _a.sent();
                        this.revenues = resp.lineItems.map(function (l) {
                            return {
                                name: l.description,
                                points: l.points,
                                money: l.extendedAmount,
                                id: l.itemID,
                                partnerId: _this.config.getMountingConfig().partner.id,
                                program: _this.config.getOrganization(),
                            };
                        });
                        this.amount = resp.amount;
                        this.points = resp.points;
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        e = error_1.error && error_1.error.message ? error_1.error.message : error_1.message || error_1;
                        this.toast.show(this.t._(e), "error");
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return SummaryPage;
}());
export { SummaryPage };
