import { Component } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminPage as SharedAdminPage } from "shared/lib/v1/pages";
import {
  ConfigService,
  PosConnectionService,
  ToastService,
  TranslateService,
  UserService,
  InactivityService,
  RoutingService,
  EnvironmentService,
} from "shared/lib/common/services";
import { RevenueDatePage } from "../revenue-date/revenue-date.page";
import { AdminService } from "shared/lib/v2/services";
import { IQrScannerPageParams } from "shared/lib/common/pages";

@Component({
  selector: "ocm-admin",
  templateUrl: "./admin.page.html",
  styleUrls: ["./admin.page.scss"],
})
export class AdminPage extends SharedAdminPage {
  public manualAllowed: boolean;
  public revenueModule: boolean;
  public bookPointModule: boolean;
  public loading: boolean;

  constructor(
    public _translate: TranslateService,
    public _platform: Platform,
    protected _admin: AdminService,
    protected _user: UserService,
    protected _toast: ToastService,
    protected _posConnectionService: PosConnectionService,
    protected _alert: AlertController,
    private _config: ConfigService,
    private modal: ModalController,
    private inactivity: InactivityService,
    private _routing: RoutingService,
    private _environment: EnvironmentService,
  ) {
    super(_translate, _platform, _admin, _user, _config, _toast, _routing, _environment);
  }

  public ionViewWillEnter(): void {
    this.manualAllowed = this._config.getAllowedManualPoints();
    this.revenueModule = this._config.getMountingConfig().posMode !== "SER";
    this.bookPointModule = this._config.getMountingConfig().posMode === "SER";
  }

  public ionViewWillLeave(): void {
    this.loading = false;
    this.inactivity.resetTimer();
  }

  public async arts(queryParams: IQrScannerPageParams): Promise<void> {
    const date = await this.dateModal();
    if (date && queryParams.type === "earn") {
      queryParams = { type: "earn", totalRevenue: queryParams.totalRevenue, totalPoints: queryParams.totalPoints, date };
      this._routing.goForward([SharedRoutes.qrScanner], {
        queryParams,
      });
    }
  }

  public async addPoints(manualPoints: number): Promise<void> {
    const queryParams: IQrScannerPageParams = { type: "earn", manualPoints };
    this._routing.goForward([SharedRoutes.qrScanner], {
      queryParams,
    });
  }

  private async dateModal(): Promise<string> {
    const modal = await this.modal.create({
      component: RevenueDatePage,
      backdropDismiss: false,
      cssClass: "modal",
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
