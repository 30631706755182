/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./coupon.component";
import * as i4 from "../../../../../../shared/lib/common/services/translate/translate.service";
var styles_CouponComponent = [i0.styles];
var RenderType_CouponComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponComponent, data: {} });
export { RenderType_CouponComponent as RenderType_CouponComponent };
function View_CouponComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "ticket__price"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", ""])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.ticket["points"], "", _co.translate.getSessionLanguage())); var currVal_1 = _co.translate._("P"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CouponComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ticket__price"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("FREE"); _ck(_v, 1, 0, currVal_0); }); }
export function View_CouponComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ticket__image"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "ticket__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.ticket == null) ? null : _co.ticket.points); _ck(_v, 5, 0, currVal_2); var currVal_3 = !((_co.ticket == null) ? null : _co.ticket.points); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.ticket == null) ? null : ((_co.ticket.teaserPictureFilePath == null) ? null : _co.ticket.teaserPictureFilePath.file)) ? (("url(" + ((_co.ticket == null) ? null : ((_co.ticket.teaserPictureFilePath == null) ? null : _co.ticket.teaserPictureFilePath.file))) + ")") : ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.ticket == null) ? null : _co.ticket.title); _ck(_v, 3, 0, currVal_1); }); }
export function View_CouponComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-coupon", [], null, null, null, View_CouponComponent_0, RenderType_CouponComponent)), i1.ɵdid(1, 49152, null, 0, i3.CouponComponent, [i4.TranslateService], null, null)], null, null); }
var CouponComponentNgFactory = i1.ɵccf("ocm-coupon", i3.CouponComponent, View_CouponComponent_Host_0, { ticket: "ticket" }, {}, []);
export { CouponComponentNgFactory as CouponComponentNgFactory };
