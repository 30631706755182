export const COUNTRIES = [
  {
    name: "GERMANY",
    code: "DE",
  },
  {
    name: "RUSSIA",
    code: "RU",
  },
  {
    name: "DENMARK",
    code: "DK",
  },
  {
    name: "FRANCE",
    code: "FR",
  },
  {
    name: "SPAIN",
    code: "ES",
  },
];
