/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Tag { 
    description?: string;
    domain: Tag.DomainEnum;
    id?: string;
    name: string;
}
export namespace Tag {
    export type DomainEnum = 'COUPON';
    export const DomainEnum = {
        COUPON: 'COUPON' as DomainEnum
    };
}


