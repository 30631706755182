var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as js2xmlparser from "js2xmlparser";
import { LOCAL_STORAGE, P4M } from "shared/lib/common/enums";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EnvironmentService } from "shared/lib/common/services/environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../sentry/sentry.service";
import * as i3 from "../../config/config.service";
import * as i4 from "../../utils/utils.service";
import * as i5 from "../../environment/environment.service";
var P4mV1Service = /** @class */ (function () {
    function P4mV1Service(http, sentry, config, utils, environment) {
        this.http = http;
        this.sentry = sentry;
        this.config = config;
        this.utils = utils;
        this.environment = environment;
    }
    P4mV1Service.prototype.checkIn = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.CHECK_IN + "?ac=" + params.organization + "&ak=" + params.apiKey + "&p=" + JSON.stringify(__assign({}, params, { channelCode: "P", _transactionDateTime: new Date().getTime(), fromOfflineQueue: false, secondsSinceAction: 0 }))), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_1 = _b.sent();
                        return [2 /*return*/, this.parseError(error_1)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.arts = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.environment.getVariable("API_V0") + "/arts", this.getArtsXml(params), {
                                headers: new HttpHeaders({
                                    "Content-Type": "application/xml",
                                    "API-Key": params.apiKey,
                                }),
                            })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_2 = _b.sent();
                        return [2 /*return*/, this.parseError(error_2)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getUserDetails = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.GET_USER_DETAILS + "?ak=" + params.apiKey + "&p={clientId:" + params.clientId + ";keyCode:" + params.keyCode + "}", "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_3 = _b.sent();
                        return [2 /*return*/, this.parseError(error_3)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getUserHistory = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.GET_USER_HISTORY + "?ak=" + params.apiKey + "&p={clientId:" + params.clientId + ";keyCode:" + params.keyCode + "}", "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_4 = _b.sent();
                        return [2 /*return*/, this.parseError(error_4)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.login = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http
                                .post(this.environment.getVariable("API_V1") + "/api/" + P4M.LOGIN, {
                                email: params.email,
                                organization: params.organization,
                                password: params.password,
                            }, {
                                responseType: "text",
                                headers: { "Content-Type": "application/json; charset=utf-8" },
                            })
                                .toPromise()];
                    case 1:
                        response = _a.sent();
                        this.setToken(response);
                        return [2 /*return*/, { ok: true, response: response }];
                    case 2:
                        error_5 = _a.sent();
                        return [2 /*return*/, this.parseError(error_5)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.redeemCoupon = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_6;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.REDEEM_COUPON + "?ak=" + params.apiKey + "&p=" + JSON.stringify(params), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_6 = _b.sent();
                        return [2 /*return*/, this.parseError(error_6)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.redeemReward = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_7;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.REDEEM_REWARD + "?ak=" + params.apiKey + "&p={keyCode:" + params.keyCode + ";rewardId:" + params.rewardId + ";clientId:" + params.clientId + "}", "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_7 = _b.sent();
                        return [2 /*return*/, this.parseError(error_7)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.bookPoints = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userDetailsResponse, _a, error_8;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.getUserDetails({ keyCode: params.keyCode, apiKey: params.apiKey, clientId: params.clientId })];
                    case 1:
                        userDetailsResponse = _b.sent();
                        if (!(userDetailsResponse.ok === true)) return [3 /*break*/, 3];
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.BOOK_POINTS + "?ak=" + params.apiKey + "&p={userReferenceCode:" + userDetailsResponse.response.userReferenceCode + ";totalRevenue:" + params.points + ";clientId:" + params.clientId + "}", "")
                                .toPromise()];
                    case 2: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 3: return [2 /*return*/, userDetailsResponse];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_8 = _b.sent();
                        return [2 /*return*/, this.parseError(error_8)];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.assignCoupon = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_9;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.environment.getVariable("API_V1") + "/api/" + P4M.ASSIGN_COUPONS, { couponId: params.couponId, keyCode: params.keyCode, clientId: params.clientId }, { headers: new HttpHeaders({ Authorization: params.token }) })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_9 = _b.sent();
                        return [2 /*return*/, this.parseError(error_9)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getCoupons = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_10;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.GET_COUPONS + "?ak=" + params.apiKey + "&p={clientId:" + params.clientId + "}", "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_10 = _b.sent();
                        return [2 /*return*/, this.parseError(error_10)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getRewards = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_11;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(this.getApiV1Url() + "/" + P4M.GET_REWARDS + "?ak=" + params.apiKey + "&p={clientId:" + params.clientId + "}", "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_11 = _b.sent();
                        return [2 /*return*/, this.parseError(error_11)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.deviceManagementLogin = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_12;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.DEVICE_MANAGEMENT + "?p=" + JSON.stringify({
                                userName: params.userName,
                                password: params.password,
                            }) + "&ac=" + params.organization), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_12 = _b.sent();
                        return [2 /*return*/, this.parseError(error_12)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getAllClients = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_13;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.GET_ALL_CLIENTS + "?ac=" + params.organization + "&ak=" + params.apiKey), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_13 = _b.sent();
                        return [2 /*return*/, this.parseError(error_13)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getStoresOfClient = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_14;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.GET_STORES + "?ac=" + params.organization + "&ak=" + params.apiKey + "&p=" + JSON.stringify({
                                clientId: params.clientId,
                            })), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_14 = _b.sent();
                        return [2 /*return*/, this.parseError(error_14)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.downloadDisplayAds = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_15;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.DOWNLOAD_DISPLAY_ADS + "?ac=" + params.organization + "&ak=" + params.apiKey + "&p=" + JSON.stringify({
                                deviceKey: params.deviceKey,
                                withoutBase64: true,
                                displayAdIds: [],
                            })), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_15 = _b.sent();
                        return [2 /*return*/, this.parseError(error_15)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.mountDevice = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a, error_16;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.MOUNT_DEVICE + "?ac=" + params.organization + "&ak=" + params.apiKey + "&p=" + JSON.stringify(params)), "")
                                .toPromise()];
                    case 1:
                        response = _a.apply(this, [_b.sent()]);
                        if (response.ok === true)
                            this.sentry.addTag("device-id", String(response.response.deviceId));
                        return [2 /*return*/, response];
                    case 2:
                        error_16 = _b.sent();
                        return [2 /*return*/, this.parseError(error_16)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.unMountDevice = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_17;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.sentry.removeTag("device-id");
                        this.sentry.removeTag("email");
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.UN_MOUNT_DEVICE + "?ac=" + params.organization + "&ak=" + params.apiKey + "&p=" + JSON.stringify(params)), "")
                                .toPromise()];
                    case 2: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 3:
                        error_17 = _b.sent();
                        return [2 /*return*/, this.parseError(error_17)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.loginToGetApiKey = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_18;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.LOGIN_APIKEY + "?p=" + JSON.stringify({
                                userName: params.userName,
                                password: params.password,
                                credentialTypeCode: "PM",
                                channelCode: "W",
                            }) + "&ac=" + params.organization), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_18 = _b.sent();
                        return [2 /*return*/, this.parseError(error_18)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getPermissionUser = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_19;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.GET_PERMISSION_USER + "?p=" + JSON.stringify({
                                clientId: params.clientId,
                            }) + "&ac=" + params.organization), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_19 = _b.sent();
                        return [2 /*return*/, this.parseError(error_19)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.extendedRevenuePointsManually = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_20;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.EXTENDED_REVENUE_POINTS_MANUALLY + "?p=" + JSON.stringify({
                                language: params.language,
                                deviceKey: params.deviceKey,
                                keyCode: params.keyCode,
                                extendedRevenueFactsId: params.extendedRevenueFactsId,
                                channelCode: "P",
                                loaderText: "Punkte buchen",
                            }) + "&ac=" + params.organization), "")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_20 = _b.sent();
                        return [2 /*return*/, this.parseError(error_20)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getActionDefinitions = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_21;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.GET_ACTION_DEFINITIONS), null, {
                                params: {
                                    ac: params.organization,
                                    p: JSON.stringify({ deviceKey: params.deviceKey }),
                                },
                            })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_21 = _b.sent();
                        return [2 /*return*/, this.parseError(error_21)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.revenuePoints = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_22;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.REVENUE_POINTS), null, {
                                params: {
                                    ac: params.organization,
                                    p: JSON.stringify(params),
                                },
                            })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_22 = _b.sent();
                        return [2 /*return*/, this.parseError(error_22)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.register = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_23;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.REGISTER), null, {
                                params: {
                                    ac: params.organization,
                                    p: JSON.stringify(params).replace("+", "%2B"),
                                },
                            })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_23 = _b.sent();
                        return [2 /*return*/, this.parseError(error_23)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.updateProfileExtendedRegistration = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, error_24;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this.parseResponseV1;
                        return [4 /*yield*/, this.http
                                .post(encodeURI(this.environment.getVariable("API_MOUNTING") + "/api/device-management/pmm/" + P4M.UPDATE_PROFILE_EXTENDED_REGISTRATION), null, {
                                params: {
                                    ac: params.organization,
                                    p: JSON.stringify(params),
                                },
                            })
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 2:
                        error_24 = _b.sent();
                        return [2 /*return*/, this.parseError(error_24)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.readAccountBalance = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, error_25;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getUserDetails({ apiKey: params.apiKey, clientId: params.clientId, keyCode: params.keyCode })];
                    case 1:
                        resp = _a.sent();
                        if (resp.ok === true)
                            return [2 /*return*/, this.parseBalance(this.parseResponseV1(resp.response))];
                        else
                            return [2 /*return*/, resp];
                        return [3 /*break*/, 3];
                    case 2:
                        error_25 = _a.sent();
                        return [2 /*return*/, this.parseError(error_25)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    P4mV1Service.prototype.getPassword = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.PASSWORD));
    };
    P4mV1Service.prototype.setPassword = function (password) {
        localStorage.setItem(LOCAL_STORAGE.PASSWORD, this.utils.encrypt(password));
    };
    P4mV1Service.prototype.getUser = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.EMAIL));
    };
    P4mV1Service.prototype.setUser = function (email) {
        localStorage.setItem(LOCAL_STORAGE.EMAIL, this.utils.encrypt(email));
    };
    P4mV1Service.prototype.getToken = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
    };
    P4mV1Service.prototype.setToken = function (token) {
        localStorage.setItem(LOCAL_STORAGE.TOKEN, this.utils.encrypt(token));
    };
    P4mV1Service.prototype.getArtsXml = function (params) {
        try {
            return js2xmlparser.parse("DigitalReceipt", {
                Transaction: {
                    "@": { MajorVersion: 6, MinorVersion: 0, FixVersion: 0, TypeCode: "SaleTransaction" },
                    ReceiptDateTime: params.date,
                    WorkstationID: params.workstationId,
                    ReceiptNumber: params.amount.toFixed(2) + "_" + params.vatId + "_" + this.utils.getReceiptFormatDate(new Date(params.date)),
                    RetailTransaction: {
                        Total: {
                            "@": {
                                TotalType: "TransactionGrandAmount",
                            },
                            "#": params.amount.toFixed(2),
                        },
                        LoyaltyAccount: { LoyaltyCard: { PrimaryAccountNumber: params.keyCode } },
                    },
                },
            });
        }
        catch (err) {
            this.sentry.handleError({ err: err, method: "getArtsXml" });
            return "";
        }
    };
    P4mV1Service.prototype.parseError = function (error) {
        if (error) {
            return {
                ok: false,
                error: {
                    message: error.message || error.error || error,
                    code: error.status || 0,
                },
            };
        }
        else {
            return {
                ok: false,
                error: {
                    message: "APP_UNHANDLED_ERROR",
                    code: 0,
                },
            };
        }
    };
    P4mV1Service.prototype.parseResponseV1 = function (response) {
        if (response["errorInfo"]) {
            return {
                ok: false,
                error: { message: response["errorInfo"].errorCode },
            };
        }
        else {
            return { ok: true, response: this.ensureValidTimestamp(response) };
        }
    };
    P4mV1Service.prototype.ensureValidTimestamp = function (obj) {
        var _this = this;
        Object.keys(obj).forEach(function (k) {
            if (obj[k] && typeof obj[k] === "object")
                return _this.ensureValidTimestamp(obj[k]);
            if (k === "unixTimeStamp" && String(obj[k]).length === 10)
                obj[k] *= 1000;
        });
        return obj;
    };
    P4mV1Service.prototype.parseBalance = function (response) {
        if (response.ok === true) {
            return { ok: true, response: { points: response.response.totalPoints, lockedPoints: response.response.totalPointsCollected } };
        }
        else {
            return response;
        }
    };
    P4mV1Service.prototype.readUser = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, { ok: true, response: {} }];
            });
        });
    };
    P4mV1Service.prototype.getApiV1Url = function () {
        var resp = this.environment.getVariable("API_V0") + "/api";
        if (this.config.getMountingConfig().workstationId) {
            resp += "/workstationId=" + this.config.getMountingConfig().workstationId;
        }
        return resp;
    };
    P4mV1Service.ngInjectableDef = i0.defineInjectable({ factory: function P4mV1Service_Factory() { return new P4mV1Service(i0.inject(i1.HttpClient), i0.inject(i2.SentryService), i0.inject(i3.ConfigService), i0.inject(i4.UtilsService), i0.inject(i5.EnvironmentService)); }, token: P4mV1Service, providedIn: "root" });
    return P4mV1Service;
}());
export { P4mV1Service };
