import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  ConfigService,
  EnvironmentService,
  P4mService,
  PosConnectionService,
  RoutingService,
  SentryService,
  ToastService,
  TranslateService,
} from "shared/lib/common/services";
import { LoginPage as SharedLoginPage } from "shared/lib/common/pages";
import { Platform } from "@ionic/angular";
import { AdminService, DeviceMountingService } from "shared/lib/v2/services";
import { SharedRoutes } from "shared/lib/common/enums";

interface LoginParams {
  email: string;
  password: string;
  interactionId: string;
}

@Component({
  selector: "ocm-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage extends SharedLoginPage {
  private params: LoginParams;

  constructor(
    public _translate: TranslateService,
    public _fb: FormBuilder,
    public _admin: AdminService,
    public _config: ConfigService,
    public _toast: ToastService,
    public _sentry: SentryService,
    private _p4m: P4mService,
    private _platform: Platform,
    private _routing: RoutingService,
    private _environment: EnvironmentService,
    private _deviceMountingService: DeviceMountingService,
    private _posConnectionService: PosConnectionService,
  ) {
    super(_translate, _fb, _admin, _config, _toast, _sentry, _p4m, _platform, _routing, _environment);
  }

  public async ionViewWillEnter(): Promise<void> {
    this.params = this._routing.getUrlParams<LoginParams>();
    if (this.params.email && this.params.password && this.params.interactionId) {
      try {
        await this.login(this.params.email, this.params.password);
        await this.mountDevice(this.params.interactionId);
      } catch (error) {
        super.ionViewWillEnter();
      }
    } else {
      super.ionViewWillEnter();
    }
  }

  public async mountDevice(appInteractionId: string): Promise<void> {
    this.loading = true;
    const res = await this._deviceMountingService.mount(appInteractionId);
    if (res.ok === true) {
      this._config.setMountingConfig(await this._deviceMountingService.parseMountingConfigV1ToV2(res.response));
      this._posConnectionService.connectToSocket(res.response.deviceKey);
      this.translate.setLanguage(res.response.profile.language === "de" ? "de" : "en");
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
      } catch (error) {
        this.toast.show("Camera access denied", "error");
      }
    }
    this._routing.goForward([SharedRoutes.screenSaver]);
    this.loading = false;
  }
}
