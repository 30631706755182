import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Configuration } from '../configuration';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
var AccountService = /** @class */ (function () {
    function AccountService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://staging.convercus.io';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }
    AccountService.prototype.addToHttpParams = function (httpParams, value, key) {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        }
        else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    };
    AccountService.prototype.addToHttpParamsRecursive = function (httpParams, value, key) {
        var _this = this;
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                value.forEach(function (elem) { return httpParams = _this.addToHttpParamsRecursive(httpParams, elem, key); });
            }
            else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, value.toISOString().substr(0, 10));
                }
                else {
                    throw Error("key may not be null if value is Date");
                }
            }
            else {
                Object.keys(value).forEach(function (k) { return httpParams = _this.addToHttpParamsRecursive(httpParams, value[k], key != null ? key + "." + k : k); });
            }
        }
        else if (key != null) {
            httpParams = httpParams.append(key, value);
        }
        else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    };
    AccountService.prototype.addAccount = function (interactionId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addAccount.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts", null, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addAccountIdentifier = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addAccountIdentifier.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addAccountIdentifier.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/identifiers", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addBooking = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addBooking.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addBooking.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/bookings", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addFraud = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addFraud.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addFraud.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/fraud", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addLevelBooking = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addLevelBooking.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addLevelBooking.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/level/bookings", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addMembership = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addMembership.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addMembership.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/memberships", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addPushToken = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addPushToken.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addPushToken.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/pushtoken", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.addTransaction = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling addTransaction.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addTransaction.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/transactions", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.createTransactionPreview = function (accountId, interactionId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling createTransactionPreview.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling createTransactionPreview.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/transactions/preview", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.deleteAccount = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deleteAccount.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteAccount.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.deleteFraud = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deleteFraud.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteFraud.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/fraud", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.deletePushToken = function (accountId, deviceId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deletePushToken.');
        }
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deletePushToken.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deletePushToken.');
        }
        var queryParameters = new HttpParams({ encoder: this.encoder });
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = this.addToHttpParams(queryParameters, deviceId, 'deviceId');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/pushtoken", {
            params: queryParameters,
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getAccountBalance = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountBalance.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountBalance.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/balance", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getAccountById = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountById.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountById.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getAccountCoupons = function (accountid, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountid === null || accountid === undefined) {
            throw new Error('Required parameter accountid was null or undefined when calling getAccountCoupons.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountCoupons.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountid)) + "/accountcoupons", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getAccountCurrencyBalance = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountCurrencyBalance.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountCurrencyBalance.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/balance/EUR", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getAccountIdentifiers = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountIdentifiers.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountIdentifiers.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/identifiers", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getAccountLevel = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getAccountLevel.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAccountLevel.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/level", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getBookings = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getBookings.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getBookings.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/bookings", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getFraudInfo = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getFraudInfo.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getFraudInfo.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/fraud", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getLevelBookings = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getLevelBookings.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getLevelBookings.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/level/bookings", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getMembership = function (accountId, interactionId, membershipId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMembership.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getMembership.');
        }
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling getMembership.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/memberships/" + encodeURIComponent(String(membershipId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getMemberships = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMemberships.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getMemberships.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/memberships", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getPushTokens = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getPushTokens.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getPushTokens.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/pushtoken", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.getTransactions = function (accountId, interactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getTransactions.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getTransactions.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/transactions", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.incentivateTransaction = function (accountId, interactionId, transactionId, idType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling incentivateTransaction.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling incentivateTransaction.');
        }
        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling incentivateTransaction.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.patch(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/transactions/" + encodeURIComponent(String(transactionId)), null, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.mergeAccount = function (interactionId, primaryAccountId, secondaryAccountId, primaryIdType, secondaryIdType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling mergeAccount.');
        }
        if (primaryAccountId === null || primaryAccountId === undefined) {
            throw new Error('Required parameter primaryAccountId was null or undefined when calling mergeAccount.');
        }
        if (secondaryAccountId === null || secondaryAccountId === undefined) {
            throw new Error('Required parameter secondaryAccountId was null or undefined when calling mergeAccount.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        if (primaryIdType !== undefined && primaryIdType !== null) {
            headers = headers.set('primary-id-type', String(primaryIdType));
        }
        if (secondaryIdType !== undefined && secondaryIdType !== null) {
            headers = headers.set('secondary-id-type', String(secondaryIdType));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(primaryAccountId)) + "/merge/" + encodeURIComponent(String(secondaryAccountId)), null, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.prototype.updateMembershipOptins = function (accountId, interactionId, membershipId, idType, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling updateMembershipOptins.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling updateMembershipOptins.');
        }
        if (membershipId === null || membershipId === undefined) {
            throw new Error('Required parameter membershipId was null or undefined when calling updateMembershipOptins.');
        }
        var headers = this.defaultHeaders;
        if (idType !== undefined && idType !== null) {
            headers = headers.set('id-type', String(idType));
        }
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.patch(this.configuration.basePath + "/accounts/" + encodeURIComponent(String(accountId)) + "/memberships/" + encodeURIComponent(String(membershipId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
