import { VERSION } from "../enums";
import { ProgramConfig } from "../interfaces";

export const programConfig: { [key: string]: ProgramConfig } = {
  oek: {
    language: "de",
    availableLanguages: ["en", "de"],
    logo:
      "https://firebasestorage.googleapis.com/v0/b/point4more-generic-app.appspot.com/o/oek.svg" +
      "?alt=media&token=10278f35-df52-436b-b2e6-a27892624008",
    posapp: {
      enabled: true,
      inactivityTime: 900,
      p4mApi: { default: VERSION.v2 },
      screenSaver: {
        backgroundCover: true,
        message: { show: true, horizontal: "right", vertical: "bottom" },
        hideLogo: true,
        showSelectLanguage: false,
      },
      showSelfServiceButton: true,
      translations: {
        en: {},
        de: {
          KEYCODE_NOT_FOUND: "oekobonus-Nummer nicht gefunden",
          INSERT_SOMETHING: "Markieren Sie einen Artikel oder geben Sie manuell Bees ein",
          EMPTY_USER_REF: "Kein userReferenceCode vorhanden - Buchung von Bees nicht möglich",
          EMPTY_USER_TOT: "Keine Angaben zum Bees-Konto vorhanden",
          REDEEM_WARNING: "Fehler im Buchungsprozess (EARN).",
          ADD_MANUAL_POINTS: "Fügen Sie manuell Bees hinzu",
          LIFETIME_EARNING: "Akkumulierte Bees",
          LOYALTY_CARD: "Jetzt oekobonus-Nummer scannen",
          ACCUMULATE_POINTS: "Bees buchen",
          NEW_BALANCE: "Neuer Kontostand",
          YOUR_BALANCE: "BEES-Guthaben",
          POINTS_EXCHANGED: "Bees eingelöst",
          POINTS_EARNED: "Bees erhalten",
          POINTS: "BEES",
          POINTS_TO_EXCHANGE: "Bees",
          MANUAL_POINT_BOOKING: "Manuelle Buchung von Bees",
          BOOK_POINTS: "Bees buchen",
          USER_KEY_DEACTIVATED: "oekobonus-Nummer deaktiviert",
          USER_KEY_DOES_NOT_EXIST: "oekobonus-Nummer existiert nicht oder wurde gelöscht",
          SHOULD_REGISTER_INFO: "Bitte registrieren Sie sich für unser Programm in unsere App oder einem Service Point.",
          INVALID_QR: "Leider können wir Ihre oekobonus-Nummer nicht finden.",
          TICKET_PRINT2: "Jetzt mit gratis oekobonus App statt",
          TICKET_PRINT4: "Nach 'oekobonus' im App Store",
          CUSTOMER_CARD: "oekobonus-Nummer",
          GRANT_CAMERA_INSTRUCTIONS: "Gehen Sie zu Einstellungen> Datenschutz> Kamera und aktivieren Sie sie für die oekobonus-App",
          PUNKTEHISTORIE: "MEINE BEES",
          REGISTRATION_NOT_COMPLETE:
            "Sie erhalten im Anschluss eine Email von oekobonus. Bitte bestätigen Sie Ihre Registrierung über den darin enthaltenen Link.",
          BOOK_POINTS_REASON: "Kulanzbuchung von Bees",
          MEMBER_REGISTER_DECLARATION_OF_CONSENT: "MEMBER_REGISTER_DECLARATION_OF_CONSENT",
          MEMBER_REGISTER_ACCEPT_CONSENT: "MEMBER_REGISTER_ACCEPT_CONSENT",
          MEMBER_REGISTER_TERMS_1: "MEMBER_REGISTER_TERMS_1",
          MEMBER_REGISTER_TERMS_2: "MEMBER_REGISTER_TERMS_2",
          FORM_OPTIN_ADVERTISING: "FORM_OPTIN_ADVERTISING",
          POINT_REDEMPTION: "Bees Einlösung",
          POINTS_SPENT: "Bees ausgegeben",
          MANUAL_POINTS_ENABLED_QUESTION: "Manuelle Einnahmen/Buchung von Bees erlaubt",
          NEED_MORE_POINTS: "Sie benötigen mehr Bees, um diesen Vorteil einzulösen",
          LOCKED_USER:
            "Leider ist Ihr Konto für das Einlösen von Bees und Coupons derzeit gesperrt. " +
            "Für nähere Informationen wenden Sie sich bitte an unseren Kundenservice.",
          USER_IS_NOT_REGISTERED_TO_BURN_FOR_REVENUE: "Bezahlen mit Bees ist nur nach Registrierung möglich",
          USER_DOES_NOT_HAVE_ENOUGH_POINTS_FOR_REVENUE_BURN: "Der Nutzer hat nicht genug Bees für diese Buchung",
          HISTORY: "Bees-konto",
          CUSTOMER_HISTORY: "Bees-konto",
          CONFIRM_TRANSACTION_TITLE: "Möchten Sie diese Bees wirklich hinzufügen?",
          PAY_WITH_POINT_NAME: "Bezahlen mit Bees",
          PAY_WITH_POINTS: "Bezahlen mit Bees",
          CONFIRM_EARN_TRANSACTION_TITLE: "Möchten Sie diese Bees wirklich hinzufügen?",
          CONFIRM_BURN_TRANSACTION_TITLE: "Sind Sie sicher, dass Sie Ihre Bees zum Bezahlen verwenden möchten?",
          MULTIPLIER: "Fache Bees",
          EXTRAPOINT: "Extra-Bees",
          REVENUE_STEP1_BURN_1: "Zahlen mit Bees",
        },
      },
    },
    userapp: { enabled: true },
    selfservice: { enabled: true },
    theme: {
      colors: {
        primary: "#FDC400",
        secondary: "#4A5762",
        tertiary: null,
        success: "#319888",
        warning: null,
        danger: "#E30613",
        dark: "#212121",
        medium: null,
        light: "#FAFAFA",
        background: "#F0EFF0",
        primaryText: "#3C3C3A",
        secondaryText: null,
        banner: null,
        border: "#EEEEEE",
      },
    },
  },
  p14: {
    language: "en",
    availableLanguages: ["en", "de"],
    logo:
      "https://firebasestorage.googleapis.com/v0/b/point4more-generic-app.appspot.com/o/p14.svg" +
      "?alt=media&token=90246eb1-5644-487e-a9ad-8595ec1a5d1c",
    posapp: {
      enabled: true,
      inactivityTime: 600000,
      p4mApi: { default: VERSION.v2 },
      screenSaver: {
        backgroundCover: true,
        message: { show: true, horizontal: "right", vertical: "bottom" },
        hideLogo: false,
        showSelectLanguage: false,
      },
      showSelfServiceButton: true,
      translations: null,
    },
    userapp: { enabled: true },
    selfservice: { enabled: true },
    theme: {
      colors: {
        primary: "#001a39",
        secondary: "#ffffff",
        tertiary: null,
        success: "#2dd36f",
        warning: null,
        danger: "#b1311b",
        dark: "#000000",
        medium: null,
        light: "#ffffff",
        background: "#f5ecd3",
        primaryText: "#3C3C3A",
        secondaryText: null,
        banner: null,
        border: "#EEEEEE",
      },
    },
  },
  pfm: {
    language: "de",
    availableLanguages: ["en", "de"],
    logo:
      "https://firebasestorage.googleapis.com/v0/b/point4more-generic-app.appspot.com/o/pfm.svg" +
      "?alt=media&token=f3cdbfc5-570f-4163-951d-2e99600140bf",
    posapp: {
      enabled: true,
      inactivityTime: 60,
      p4mApi: { default: VERSION.v2 },
      screenSaver: {
        backgroundCover: true,
        message: { show: true, horizontal: "center", vertical: "bottom" },
        hideLogo: false,
        showSelectLanguage: true,
      },
      showSelfServiceButton: true,
      translations: null,
    },
    userapp: { enabled: true },
    selfservice: { enabled: true },
    theme: {
      colors: {
        primary: "#C0262C",
        secondary: "#474747",
        tertiary: null,
        success: "#319888",
        warning: null,
        danger: "#EEBA12",
        dark: "#212121",
        medium: null,
        light: "#FAFAFA",
        background: "#F5F5F5",
        primaryText: "#474747",
        secondaryText: null,
        banner: null,
        border: "#EEEEEE",
      },
    },
  },
  ocm: {
    language: "de",
    availableLanguages: ["en", "de"],
    logo:
      "https://firebasestorage.googleapis.com/v0/b/point4more-generic-app.appspot.com/o/ocm.png" +
      "?alt=media&token=7036c8c4-ed58-4243-8d5c-889e80e9a68d",
    posapp: {
      enabled: true,
      inactivityTime: 30,
      p4mApi: { default: VERSION.v2 },
      screenSaver: {
        backgroundCover: true,
        message: { show: true, horizontal: "center", vertical: "bottom" },
        hideLogo: false,
        showSelectLanguage: true,
      },
      showSelfServiceButton: false,
      translations: {
        en: {
          SCREEN_SAVER_TITLE: "Outletcity Club",
          SCREEN_SAVER_SUBTITLE: "Check your total points or redeem your points",
          SCREEN_SAVER_ACTION_TEXT: "Touch the screen to start application",
          QRSCANNER_BURN_TITLE: "PLEASE SCAN YOUR OUTLETCITY CLUB CODE",
          QRSCANNER_BURN_SUBTITLE: "You will find your CLUB code in the OUTLETCITY app",
          STATUS: "Status",
          SUCCESS_MODAL_TITLE: "Redeemed",
          SUCCESS_MODAL_TEXT: "Show this information to the staff to receive your reward.",
          SUCCESS_REDEEM_MODAL_TITLE_POSITIVE: "POINTS COLLECTED",
          SUCCESS_REDEEM_MODAL_TITLE_NEGATIVE: "POINTS SPENT",
          SUCCESS_REDEEM_MODAL_TOTAL_POINTS: "Your current total points:",
          SCHLIESSEN: "Close",
          BURN_PAGE_EMPTY_MESSAGE: "Currently, there are no coupons or rewards available.",
          SELECT_VOUCHER: "Choose a coupon or reward",
          REVENUE_DATE: "Check date and time of ticket",
          DAY: "Date",
          HOUR: "Time",
          SET_DATE: "Save and book",
          default: "silver",
          silber: "silver",
          gold: "gold",
        },
        de: {
          SCREEN_SAVER_TITLE: "Outletcity Club",
          SCREEN_SAVER_SUBTITLE: "Prüfen Sie Ihren Punktestand oder lösen Sie Ihre Punkte ein",
          SCREEN_SAVER_ACTION_TEXT: "Bildschirm berühren und starten",
          QRSCANNER_BURN_TITLE: "BITTE SCANNEN SIE IHREN OUTLETCITY CLUB CODE",
          QRSCANNER_BURN_SUBTITLE: "Sie finden Ihren Club Code in der OUTLETCITY App.",
          STATUS: "Status",
          SUCCESS_MODAL_TITLE: "Eingelöst",
          SUCCESS_MODAL_TEXT: "Zeigen Sie diese Information dem Mitarbeiter, um Ihren Vorteil zu erhalten.",
          SUCCESS_REDEEM_MODAL_TITLE_POSITIVE: "PUNKTE GESAMMELT",
          SUCCESS_REDEEM_MODAL_TITLE_NEGATIVE: "PUNKTE AUSGEGEBEN",
          SUCCESS_REDEEM_MODAL_TOTAL_POINTS: "Ihr aktueller Punktestand:",
          SCHLIESSEN: "Schliessen",
          BURN_PAGE_EMPTY_MESSAGE: "Zur Zeit werden keine Coupons oder Prämien angeboten.",
          SELECT_VOUCHER: "Wählen Sie einen Coupon oder eine Prämie aus",
          REVENUE_DATE: "Datum und Uhrzeit des Kassenbons prüfen",
          DAY: "Datum",
          HOUR: "Uhrzeit",
          SET_DATE: "Übernehmen & verbuchen",
          default: "silber",
          silber: "silber",
          gold: "gold",
        },
      },
    },
    userapp: { enabled: true },
    selfservice: { enabled: true },
    theme: {
      colors: {
        primary: "#000000",
        secondary: "#474747",
        tertiary: null,
        success: "green",
        warning: null,
        danger: "#e64d69",
        dark: "#212121",
        medium: null,
        light: "#fafafa",
        background: "#f2f2f2",
        primaryText: "#474747",
        secondaryText: null,
        banner: null,
        border: "#ebebeb",
      },
    },
  },
};
