var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { formatDate } from "@angular/common";
import { Platform } from "@ionic/angular";
import * as Sentry from "@sentry/browser";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { Align, Line } from "shared/lib/common/interfaces/epson.interface";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { UserService } from "shared/lib/common/services/user/user.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EVENT_LISTENERS } from "shared/lib/common/services/third-party-scripts/third-party-scripts.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../utils/utils.service";
import * as i3 from "../user/user.service";
import * as i4 from "../translate/translate.service";
import * as i5 from "@ionic/angular";
var PrintService = /** @class */ (function () {
    function PrintService(config, utils, user, translate, platform) {
        this.config = config;
        this.utils = utils;
        this.user = user;
        this.translate = translate;
        this.platform = platform;
        if (window.epson) {
            this.epsonPOSDevice = new window.epson.ePOSDevice();
        }
        else {
            document.addEventListener(EVENT_LISTENERS.ON_LOAD_EPSON_SCRIPT, function (event) {
                this.epsonPOSDevice = new window.epson.ePOSDevice();
            });
        }
    }
    PrintService.prototype.setIp = function (ip) {
        localStorage.setItem(LOCAL_STORAGE.IP, this.utils.encrypt(ip));
    };
    PrintService.prototype.getIp = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.IP));
    };
    PrintService.prototype.removeIp = function () {
        localStorage.removeItem(LOCAL_STORAGE.IP);
    };
    PrintService.prototype.printTicket = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var connected, created, externalCodeResponse, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.connect()];
                    case 1:
                        connected = _b.sent();
                        if (!(connected.ok === true)) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.createDevice()];
                    case 2:
                        created = _b.sent();
                        if (!(created.ok === true)) return [3 /*break*/, 8];
                        externalCodeResponse = this.user.getExternalCode(voucher);
                        if (!(externalCodeResponse.ok === true)) return [3 /*break*/, 6];
                        this.printer = created.response;
                        this.printer.addTextLang("de");
                        this.print(this.config.getMountingConfig().storeName, Align.center, Line.title);
                        this.addBlankLine();
                        if (!this.platform.is("capacitor")) return [3 /*break*/, 3];
                        _a = this.print(this.config.getMountingConfig().clientName, Align.center, Line.title);
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.addLogo()];
                    case 4:
                        _a = _b.sent();
                        _b.label = 5;
                    case 5:
                        _a;
                        this.addBlankLine();
                        this.print("Coupon", Align.left, Line.paragraph);
                        this.print(voucher.description, Align.left, Line.subtitle);
                        this.addBlankLine();
                        this.print(this.translate._("VALID_AT"), Align.left, Line.paragraph);
                        this.print(this.config.getMountingConfig().clientName, Align.left, Line.paragraph);
                        this.print(this.config.getMountingConfig().storeName, Align.left, Line.paragraph);
                        this.addBlankLine();
                        if (externalCodeResponse.response.code) {
                            if (["CUSTOM", "P4M"].includes(externalCodeResponse.response.type)) {
                                this.print(externalCodeResponse.response.code, Align.center, Line.paragraph);
                            }
                            else {
                                this.printCode(externalCodeResponse.response.type, externalCodeResponse.response.code);
                                this.addBlankLine();
                            }
                        }
                        else {
                            this.print(voucher.title, Align.center, Line.paragraph);
                        }
                        this.addBlankLine();
                        this.print(this.translate._("TICKET_PRINT1"), Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.print(this.translate._("TICKET_PRINT2"), Align.center, Line.paragraph);
                        this.print(this.translate._("TICKET_PRINT3"), Align.center, Line.paragraph);
                        this.print(this.translate._("TICKET_PRINT4"), Align.center, Line.paragraph);
                        this.print(this.translate._("TICKET_PRINT5"), Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.print(this.translate._("ISSUED_ON"), Align.center, Line.paragraph);
                        this.print(formatDate(new Date(), "dd.MM.yyyy hh:mm", this.translate.getSessionLanguage()), Align.center, Line.paragraph);
                        this.print(this.translate._("CUSTOMER_CARD") + ": " + (this.user.getUser() || {}).keyCode, Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.print(this.translate._("REDEEMABLE_UNTIL") + formatDate(new Date(), "dd.MM.yyyy", this.translate.getSessionLanguage()), Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.printer.addCut();
                        this.printer.send();
                        this.epsonPOSDevice.deleteDevice(this.printer, function () { return _this.epsonPOSDevice.disconnect(); });
                        return [2 /*return*/, { ok: true, response: null }];
                    case 6:
                        this.epsonPOSDevice.disconnect();
                        return [2 /*return*/, {
                                ok: false,
                                error: { message: externalCodeResponse.error.message },
                            }];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this.epsonPOSDevice.disconnect();
                        return [2 /*return*/, {
                                ok: false,
                                error: { message: created.error.message },
                            }];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        this.epsonPOSDevice.disconnect();
                        return [2 /*return*/, {
                                ok: false,
                                error: { message: connected.error.message },
                            }];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    PrintService.prototype.testConnection = function (ip) {
        return __awaiter(this, void 0, void 0, function () {
            var connected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.connect(ip)];
                    case 1:
                        connected = _a.sent();
                        if (connected.ok === true) {
                            return [2 /*return*/, { ok: true, response: null }];
                        }
                        else {
                            return [2 /*return*/, { ok: false, error: { message: connected.error.message } }];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PrintService.prototype.addLogo = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var c = document.createElement("canvas");
            c.id = "canvas";
            document.body.appendChild(c);
            var canvas = document.getElementsByTagName("canvas")[0];
            var img = new Image();
            img.src = _this.config.getPrinterLogo();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
                c.width = img.width;
                c.height = img.height;
                canvas.getContext("2d").drawImage(img, 0, 0);
                _this.printer.brightness = 1.5;
                _this.printer.halftone = 0;
                _this.printer.addImage(canvas.getContext("2d"), 0, 0, c.width, c.height, "color_1", "gray16");
                resolve();
            };
            img.onerror = function () {
                resolve();
            };
        });
    };
    PrintService.prototype.print = function (text, align, type) {
        var isBold = type === Line.title || type === Line.subtitle;
        this.printer.addTextAlign(align);
        this.printer.addTextStyle(false, false, isBold);
        this.printer.addTextSize(type === Line.title ? 2 : 1, type === Line.title ? 2 : 1);
        this.printer.addText(this.utils.decodeHTMLEntity(text) + "\n");
    };
    PrintService.prototype.printCode = function (type, code) {
        this.printer.addTextAlign(Align.center);
        var externalCode = code;
        switch (type) {
            case "QRCODE":
                this.printer.addSymbol(code, "qrcode_micro", "level_0", 10);
                break;
            case "CODE128":
                this.printer.addBarcode(code, "code128", "none", "font_a", 3, 162);
                if (code.match("{A") || code.match("{B") || code.match("{C")) {
                    externalCode = code.substr(2);
                }
                break;
            case "EAN13":
                this.printer.addBarcode(code, "ean13", "none", "font_a", 3, 162);
                break;
            case "EAN8":
                this.printer.addBarcode(code, "ean8", "none", "font_a", 3, 162);
                break;
            default:
                break;
        }
        this.printer.addText("\n");
        this.print(externalCode, Align.center, Line.paragraph);
    };
    PrintService.prototype.addBlankLine = function () {
        this.printer.addText("\n\n");
    };
    PrintService.prototype.connect = function (ip) {
        var _this = this;
        var port = this.platform.is("capacitor") ? 8008 : 8043;
        return new Promise(function (resolve) {
            var auxIp;
            if (!ip || ip === "") {
                var savedIp = _this.getIp();
                if (!savedIp || savedIp === "") {
                    resolve({ ok: false, error: { message: "NOT_CONNECTED" } });
                }
                else {
                    auxIp = savedIp;
                }
            }
            else {
                auxIp = ip;
            }
            _this.epsonPOSDevice.connect(auxIp, port, function (result) {
                if (result === "OK" || result === "SSL_CONNECT_OK") {
                    resolve({ ok: true, response: null });
                }
                Sentry.captureMessage(result, Sentry.Severity.Debug);
                resolve({
                    ok: false,
                    error: { message: "NOT_CONNECTED" },
                });
            });
        });
    };
    PrintService.prototype.createDevice = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.epsonPOSDevice.createDevice("local_printer", "type_printer", { buffer: false, crypto: !_this.platform.is("capacitor") }, function (device, result) {
                if (result && result !== "OK") {
                    Sentry.captureMessage(result, Sentry.Severity.Debug);
                    resolve({ ok: false, error: { message: "NO_PAPER" } });
                }
                else {
                    resolve({ ok: true, response: device });
                }
            });
        });
    };
    PrintService.ngInjectableDef = i0.defineInjectable({ factory: function PrintService_Factory() { return new PrintService(i0.inject(i1.ConfigService), i0.inject(i2.UtilsService), i0.inject(i3.UserService), i0.inject(i4.TranslateService), i0.inject(i5.Platform)); }, token: PrintService, providedIn: "root" });
    return PrintService;
}());
export { PrintService };
