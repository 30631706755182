import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ConfirmationPage as SharedConfirmationPage } from "shared/lib/common/pages";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "ocm-confirmation",
  templateUrl: "./confirmation.page.html",
  styleUrls: ["./confirmation.page.scss"],
})
export class ConfirmationPage extends SharedConfirmationPage {
  constructor(_navParams: NavParams, _modal: ModalController, _translate: TranslateService) {
    super(_navParams, _modal, _translate);
  }
}
