/**
 * Device Mounting
 * Mounting devices API
 *
 * The version of the OpenAPI document: 0.0.5503
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Mounting } from './mounting';
import { Image } from './image';


export interface Profile { 
    image?: Image;
    mountings?: Array<Mounting>;
    images?: Array<Image>;
    images2?: Array<Image>;
    id: string;
    oldId?: number;
    organization: string;
    name: string;
    cameraPosition: Profile.CameraPositionEnum;
    language: Profile.LanguageEnum;
    mode: Profile.ModeEnum;
    revenueRoundingCode: Profile.RevenueRoundingCodeEnum;
    pointsRoundingCode: Profile.PointsRoundingCodeEnum;
    pinRequired: boolean;
    decimalRevenue: boolean;
    anonymousExchange: Profile.AnonymousExchangeEnum;
    pin: string;
    customProperties?: object;
    registrationType?: Profile.RegistrationTypeEnum;
    createdAt?: Date;
    updatedAt?: Date;
}
export namespace Profile {
    export type CameraPositionEnum = 'BC' | 'RC' | 'TR';
    export const CameraPositionEnum = {
        BC: 'BC' as CameraPositionEnum,
        RC: 'RC' as CameraPositionEnum,
        TR: 'TR' as CameraPositionEnum
    };
    export type LanguageEnum = 'de' | 'en' | 'es' | 'it' | 'ru';
    export const LanguageEnum = {
        De: 'de' as LanguageEnum,
        En: 'en' as LanguageEnum,
        Es: 'es' as LanguageEnum,
        It: 'it' as LanguageEnum,
        Ru: 'ru' as LanguageEnum
    };
    export type ModeEnum = 'CAS' | 'INF' | 'REG' | 'SER' | 'STD_REG' | 'STD';
    export const ModeEnum = {
        CAS: 'CAS' as ModeEnum,
        INF: 'INF' as ModeEnum,
        REG: 'REG' as ModeEnum,
        SER: 'SER' as ModeEnum,
        STDREG: 'STD_REG' as ModeEnum,
        STD: 'STD' as ModeEnum
    };
    export type RevenueRoundingCodeEnum = 'UP' | 'DOWN' | 'NO' | 'COM';
    export const RevenueRoundingCodeEnum = {
        UP: 'UP' as RevenueRoundingCodeEnum,
        DOWN: 'DOWN' as RevenueRoundingCodeEnum,
        NO: 'NO' as RevenueRoundingCodeEnum,
        COM: 'COM' as RevenueRoundingCodeEnum
    };
    export type PointsRoundingCodeEnum = 'UP' | 'DOWN' | 'COM';
    export const PointsRoundingCodeEnum = {
        UP: 'UP' as PointsRoundingCodeEnum,
        DOWN: 'DOWN' as PointsRoundingCodeEnum,
        COM: 'COM' as PointsRoundingCodeEnum
    };
    export type AnonymousExchangeEnum = 'NO' | 'YES' | 'BURN' | 'EARN';
    export const AnonymousExchangeEnum = {
        NO: 'NO' as AnonymousExchangeEnum,
        YES: 'YES' as AnonymousExchangeEnum,
        BURN: 'BURN' as AnonymousExchangeEnum,
        EARN: 'EARN' as AnonymousExchangeEnum
    };
    export type RegistrationTypeEnum = 'SIM' | 'EXT' | 'NO';
    export const RegistrationTypeEnum = {
        SIM: 'SIM' as RegistrationTypeEnum,
        EXT: 'EXT' as RegistrationTypeEnum,
        NO: 'NO' as RegistrationTypeEnum
    };
}


