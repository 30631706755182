<ion-content class="screen-saver" (tap)="_config.haveBurnPage() ? goQrScanner() : null">
  <div class="screen-saver__background" [class.--show]="actualImage === background"
    [class.--hide]="actualImage !== background" [style.backgroundImage]="'url(' + background + ')'"></div>
  <div *ngFor="let image of images" class="screen-saver__background" [class.--show]="actualImage === image.url"
    [class.--hide]="actualImage !== image.url" [style.backgroundImage]="'url(' + image.url + ')'"></div>
  <div class="screen-saver__welcome-message" *ngIf="showButtons && _config.haveBurnPage()">
    <div class="screen-saver__welcome-message__text">
      <h1>{{ translate._("SCREEN_SAVER_TITLE") }}</h1>
      <p>
        {{ translate._("SCREEN_SAVER_SUBTITLE") }}
      </p>
    </div>
    <div class="screen-saver__welcome-message__button">
      <shared-tap></shared-tap>
      {{ translate._("SCREEN_SAVER_ACTION_TEXT") }}
    </div>
  </div>
  <img *ngFor="let image of images" [src]="image.url" alt="image" class="screen-saver--bg-image"
    (error)="onImageError(image.url)" [hidden]="true" />
</ion-content>