import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { P4M, LOCAL_STORAGE } from "shared/lib/common/enums";
import {
  IAccount,
  IAccountBooking,
  IAccountCoupon,
  IAccountDetails,
  IBalance,
  IBookPointsParams,
  IError,
  IGetCouponsResponse,
  IGetCouponsParams,
  IGetRewardsResponse,
  IGetRewardsParams,
  IGetUserDetailsResponse,
  IGetUserDetailsParams,
  IGetUserHistoryParams,
  IGetUserHistoryResponse,
  II18nFields,
  IIdentifier,
  IIdentifierType,
  IImage,
  ILoginParams,
  IMembership,
  IPartner,
  IRedeemCouponParams,
  IRedeemRewardParams,
  IResponseP4M,
  IRevenuePointsParams,
  IRevenuePointsResponse,
  IStoreAPI2,
  ITransaction,
  IFile,
  ILevel,
  IStatusDefinitions,
  IReward,
  ICoupon,
  IStatus,
  IUserStatus,
  IReadBalanceParams,
  ICheckInParams,
  ICheckInResponse,
  IUserV2,
  IReadUserParams,
} from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { IResponse } from "shared/lib/common/interfaces";
import { AccountCouponV2, AccountCouponV2I18nFields, Image } from "shared/lib/v2/apis/accounts";
import { EnvironmentService } from "../../environment/environment.service";

type trueType = true;

@Injectable({
  providedIn: "root",
})
export class P4mV2Service {
  constructor(
    private http: HttpClient,
    private sentry: SentryService,
    private config: ConfigService,
    private utils: UtilsService,
    private translate: TranslateService,
    private environment: EnvironmentService,
  ) {}

  public async getUserDetails({ keyCode, idType }: IGetUserDetailsParams): Promise<IResponse<IGetUserDetailsResponse>> {
    const [
      accountResponse,
      accountMembershipsResponse,
      accountBalanceResponse,
      accountCouponsResponse,
      accountIdentifiersResponse,
      accountLevelResponse,
    ] = await Promise.all([
      this.readAccount(keyCode, idType),
      this.readAccountMemberships(keyCode, idType),
      this.readAccountBalance({ keyCode, idType }),
      this.readAccountCoupons(keyCode, idType),
      this.readAccountIdentifiers(keyCode, idType),
      this.readAccountLevel(keyCode, idType),
    ]);
    if (
      accountResponse.ok === true &&
      accountMembershipsResponse.ok === true &&
      accountBalanceResponse.ok === true &&
      accountCouponsResponse.ok === true &&
      accountIdentifiersResponse.ok === true &&
      accountLevelResponse.ok === true
    ) {
      const membership = this.getOwner(accountMembershipsResponse.response);
      return {
        ok: true,
        response: {
          userId: membership ? membership.userId : null,
          userStatusCode: this.getUserStatus(accountMembershipsResponse.response, accountResponse.response.status),
          totalPoints: accountBalanceResponse.response.points,
          coupons: this.parseCoupons(
            accountCouponsResponse.response.filter(c => c.usageType !== "REWARD"),
            accountBalanceResponse.response.points,
          ),
          rewards: this.parseRewards(
            accountCouponsResponse.response.filter(c => c.usageType === "REWARD"),
            accountBalanceResponse.response.points,
          ),
          isAppUser: this.isAppUser(accountIdentifiersResponse.response),
          statusDefinitions: accountLevelResponse.response ? this.parseLevel(accountLevelResponse.response) : null,
        },
      };
    } else {
      let message: string;
      [
        accountResponse,
        accountMembershipsResponse,
        accountBalanceResponse,
        accountCouponsResponse,
        accountIdentifiersResponse,
        accountLevelResponse,
      ].forEach(response => {
        if (response.ok === false) message = response.error.message;
      });
      return { ok: false, error: { message } };
    }
  }

  public async getUserHistory(params: IGetUserHistoryParams): Promise<IResponse<IGetUserHistoryResponse>> {
    const response = await this.readAccountBookings(params.keyCode, params.idType);
    if (response.ok === true) {
      return { ok: true, response: { transactions: this.parseTransactions(response.response) } };
    } else {
      return response;
    }
  }

  public async bookPoints(params: IBookPointsParams): Promise<IResponse<any>> {
    return await this.createAccountBookings(
      params.keyCode,
      "EARNBOOKING",
      params.points,
      this.translate._("BOOK_POINTS_REASON"),
      params.idType,
    );
  }

  public async revenuePoints(params: IRevenuePointsParams): Promise<IResponse<IRevenuePointsResponse>> {
    return await this.createAccountBookings(
      params.keyCode,
      "EARNBOOKING",
      params.totalPoints.toString(),
      this.translate._("REVENUE_POINTS_REASON"),
      params.idType,
    );
  }

  public async redeemCoupon(params: IRedeemCouponParams): Promise<IResponse<IResponseP4M>> {
    return this.redeemAccountCoupon(params.accountVoucherId);
  }

  public async redeemReward(params: IRedeemRewardParams): Promise<IResponse<IResponseP4M>> {
    return this.redeemAccountCoupon(params.accountVoucherId);
  }

  public async getCoupons(params: IGetCouponsParams): Promise<IResponse<IGetCouponsResponse>> {
    return { ok: true, response: { coupons: [] } };
  }

  public async getRewards(params: IGetRewardsParams): Promise<IResponse<IGetRewardsResponse>> {
    return { ok: true, response: { rewards: [] } };
  }

  public async login(params: ILoginParams): Promise<IResponse<string>> {
    return new Promise(resolve => {
      this.http
        .post(
          `${this.environment.getVariable("API_V2")}/${P4M.LOGIN_V2}`,
          {
            userName: params.email,
            org: params.organization,
            password: params.password,
          },
          {
            responseType: "text",
            headers: { "Content-Type": "application/json; charset=utf-8", "interaction-id": params.organization !== "oek" ? "posapp" : "" },
          },
        )
        .subscribe(
          (response: string) => {
            this.setToken(response);
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.login",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  public async readProgramPartners(workstationId?: string): Promise<IResponse<Array<IPartner>>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.PROGRAMS}/${this.config.getOrganization()}/${P4M.PARTNERS}`, {
          headers: { authorization: this.getToken(), "interaction-id": workstationId || "pos-app" },
        })
        .subscribe(
          (response: IPartner | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readProgramPartners",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  public async readPartnerStores(partnerId: string, workstationId?: string): Promise<IResponse<Array<IStoreAPI2>>> {
    return new Promise(resolve => {
      this.http
        .get(
          `${this.environment.getVariable("API_V2")}/${P4M.PROGRAMS}/${this.config.getOrganization()}/${P4M.PARTNERS}/${partnerId}/${
            P4M.STORES
          }`,
          {
            headers: { authorization: this.getToken(), "interaction-id": workstationId || "pos-app" },
          },
        )
        .subscribe(
          (response: IStoreAPI2 | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readPartnerStores",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  public async readAccountBalance(params: IReadBalanceParams): Promise<IResponse<IBalance>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${params.keyCode}/${P4M.BALANCE}`, {
          headers: {
            authorization: this.getToken(),
            "interaction-id": this.config.getMountingConfig().workstationId,
            "id-type": params.idType,
          },
        })
        .subscribe(
          (response: IBalance | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccountBalance",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  public async readUser(params: IReadUserParams): Promise<IResponse<IUserV2>> {
    return new Promise(resolve => {
      if (params.userId) {
        this.http
          .get(`${this.environment.getVariable("API_V2")}/${P4M.USERS}/${params.userId}`, {
            headers: {
              authorization: this.getToken(),
              "interaction-id": this.config.getMountingConfig().workstationId,
              "id-type": params.idType,
            },
          })
          .subscribe(
            (response: IUserV2 | IError) => {
              resolve(this.parseResponseV2(response));
            },
            error => {
              const err = this.parseError(error);
              this.sentry.info({
                status: err.status,
                message: err.message,
                method: "p4m.readUser",
              });
              resolve({ ok: false, error: { message: err.message } });
            },
          );
      } else {
        resolve({ ok: true, response: { emailAddress: this.translate._("ANONYMOUS_USER") } });
      }
    });
  }

  public parseResponseV2(response: any): IResponse<any> {
    if (response && response.errorType && response.message) {
      return { ok: false, error: { message: response.message } };
    } else {
      return { ok: true, response };
    }
  }

  public async checkIn(params: ICheckInParams): Promise<IResponse<ICheckInResponse>> {
    return Promise.resolve({ ok: true as trueType, response: null });
  }

  public parseCoupons(coupons: AccountCouponV2[], totalPoints: number): ICoupon[] {
    return (coupons || []).map(coupon => ({
      couponId: coupon.couponId,
      accountCouponId: coupon.accountCouponId,
      title: this.getCouponi18nField(coupon.i18nFields, "title"),
      subtitle: this.getCouponi18nField(coupon.i18nFields, "subTitle"),
      description: this.getCouponi18nField(coupon.i18nFields, "description"),
      points: coupon.couponValue,
      couponFiles: this.parseFiles(coupon.images),
      redeemDependencyCode: null,
      externalCodeBarcodeTypeCode: null,
      validToDate: { unixTimeStamp: this.utils.parseDate(coupon.validTo) },
      unlocked: this.isUnlocked(coupon, totalPoints),
      externalCode: coupon.externalReference,
      couponTypeCode: coupon.usageType,
      pictureFilePath: "",
      customProperties: coupon.customProperties,
      validFromDate: { unixTimeStamp: this.utils.parseDate(coupon.validFrom) },
      activation: coupon.activation,
    }));
  }

  public getCouponi18nField(field: { [key: string]: AccountCouponV2I18nFields }, param: "description" | "subTitle" | "title"): string {
    if (field) {
      if (this.translate.getSessionLanguage() === "de") {
        return field.de ? field.de[param] : "";
      } else {
        return field.en ? field.en[param] : "";
      }
    } else {
      return "";
    }
  }

  public parseFiles(images: Image[]): IFile[] {
    const c: IFile[] = [];
    if (images) {
      images.forEach(image => {
        if (image.tags) {
          image.tags.forEach(tag => {
            c.push({
              pictureFilePath: image.path,
              tag,
            });
          });
        }
      });
    }
    return c;
  }

  public isUnlocked(coupon: AccountCouponV2, totalPoints: number): boolean {
    return (
      (coupon.validTo
        ? this.utils.isBetweenDates(new Date(coupon.validFrom).getTime(), new Date(coupon.validTo).getTime())
        : this.utils.isBeforeNow(new Date(coupon.validFrom).getTime())) &&
      (coupon.usageType !== "REWARD" || coupon.couponValue <= totalPoints)
    );
  }

  private getUserStatus(memberShips: IMembership[], status: IStatus): IUserStatus {
    if (status === "LOCKED") return status;
    if (memberShips && memberShips.length > 0) return "RGU";
    return "";
  }

  private async readAccountLevel(accountId: string, idType: IIdentifierType): Promise<IResponse<ILevel>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.LEVEL}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
        })
        .subscribe(
          (response: IBalance | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            if (err.status >= 400 && err.status < 500) {
              resolve({ ok: true, response: null });
            } else {
              this.sentry.info({
                status: err.status,
                message: err.message,
                method: "p4m.readAccountLevel",
              });
              resolve({ ok: false, error: { message: err.message } });
            }
          },
        );
    });
  }

  private async readAccount(accountId: string, idType: IIdentifierType): Promise<IResponse<IAccount>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
        })
        .subscribe(
          (response: IAccount | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccount",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async readAccountMemberships(accountId: string, idType: IIdentifierType): Promise<IResponse<Array<IMembership>>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.MEMBERSHIPS}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
        })
        .subscribe(
          (response: Array<IMembership> | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccountMemberships",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async readAccountCoupons(accountId: string, idType: IIdentifierType): Promise<IResponse<Array<AccountCouponV2>>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/v2/${P4M.ACCOUNTS}/${accountId}/${P4M.ACCOUNT_COUPONS}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
        })
        .subscribe(
          (response: Array<AccountCouponV2> | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccountCoupons",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async readAccountIdentifiers(accountId: string, idType: IIdentifierType): Promise<IResponse<Array<IIdentifier>>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.IDENTIFIERS}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
        })
        .subscribe(
          (response: Array<IIdentifier> | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccountIdentifiers",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async readAccountDetails(accountId: string): Promise<IResponse<IAccountDetails>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNT_DETAILS}/${accountId}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId },
        })
        .subscribe(
          (response: IAccountDetails | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccountDetails",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async createAccountBookings(
    accountId: string,
    bookingType: "BURNBOOKING" | "EARNBOOKING",
    points: string,
    reason: string,
    idType: IIdentifierType,
  ): Promise<IResponse<any>> {
    return new Promise(resolve => {
      this.http
        .post(
          `${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.BOOKINGS}`,
          { bookingType, bookingTypeCode: "MANUALREVENUE", points, reason },
          {
            headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
          },
        )
        .subscribe(
          (response: any | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.createAccountBookings",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async readAccountBookings(accountId: string, idType: IIdentifierType): Promise<IResponse<Array<IAccountBooking>>> {
    return new Promise(resolve => {
      this.http
        .get(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNTS}/${accountId}/${P4M.BOOKINGS}`, {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": idType },
        })
        .subscribe(
          (response: Array<IAccountBooking> | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.readAccountBookings",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private async redeemAccountCoupon(accountCouponId: string): Promise<IResponse<any>> {
    return new Promise(resolve => {
      this.http
        .patch(`${this.environment.getVariable("API_V2")}/${P4M.ACCOUNT_COUPONS}/${accountCouponId}`, "", {
          headers: { authorization: this.getToken(), "interaction-id": this.config.getMountingConfig().workstationId, "id-type": "ID" },
        })
        .subscribe(
          (response: any | IError) => {
            resolve(this.parseResponseV2(response));
          },
          error => {
            const err = this.parseError(error);
            this.sentry.info({
              status: err.status,
              message: err.message,
              method: "p4m.redeemAccountCoupon",
            });
            resolve({ ok: false, error: { message: err.message } });
          },
        );
    });
  }

  private parseRewards(coupons: AccountCouponV2[], totalPoints: number): IReward[] {
    return (coupons || []).map(coupon => ({
      rewardId: coupon.couponId,
      accountCouponId: coupon.accountCouponId,
      title: this.getCouponi18nField(coupon.i18nFields, "title"),
      subtitle: this.getCouponi18nField(coupon.i18nFields, "subTitle"),
      description: this.getCouponi18nField(coupon.i18nFields, "description"),
      points: coupon.couponValue,
      unlocked: this.isUnlocked(coupon, totalPoints),
      rewardFiles: this.parseFiles(coupon.images),
      redeemDependencyCode: null,
      decreasePoints: true,
      createDateTime: null,
      lastRedeemDateTime: null,
      customProperties: coupon.customProperties,
      validFromDate: { unixTimeStamp: this.utils.parseDate(coupon.validFrom) },
      validToDate: { unixTimeStamp: this.utils.parseDate(coupon.validTo) },
      activation: coupon.activation,
    }));
  }

  private parseTransactions(bookings: IAccountBooking[]): ITransaction[] {
    const c: ITransaction[] = [];
    if (bookings) {
      bookings.forEach(booking => {
        c.push({
          description: this.getBookingDescription(booking),
          points: booking.bookingType === "EARNBOOKING" ? booking.points : booking.points * -1,
          transactionDateTime: {
            unixTimeStamp: this.utils.parseDate(
              booking.bookingTime[booking.bookingTime.length - 1] === "Z" ? booking.bookingTime : booking.bookingTime + "Z",
            ),
          },
        });
      });
    }
    return c;
  }

  private getBookingDescription(booking: IAccountBooking): string {
    switch (booking.bookingTypeCode) {
      case "COU":
        if (booking.points === 0) {
          return `${this.translate._("COUPON_REDEMPTION")}${booking.reason ? `: ${booking.reason}` : ``}`;
        } else {
          return `${this.translate._("POINT_REDEMPTION")}${booking.reason ? `: ${booking.reason}` : ``}`;
        }
      case "REV":
        switch (booking.bookingType) {
          case "BURNBOOKING":
            return `${this.translate._("PAY_WITH_POINTS")}${booking.reason ? `: ${booking.reason}` : ``}`;
          case "EARNBOOKING":
            return `${this.translate._("PURCHASE")}${booking.reason ? `: ${booking.reason}` : ``}`;
        }
        break;
      default:
        return booking.reason;
    }
  }

  private getOwner(memberships: Array<IMembership>): IMembership {
    return memberships.filter(membership => membership.memberRole === "OWNER")[0];
  }

  private isAppUser(identifiers: Array<IIdentifier>): boolean {
    return identifiers.filter(identifier => identifier.type === "APPCODE").length > 0;
  }

  private getToken(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
  }

  private setToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE.TOKEN, this.utils.encrypt(token));
  }

  private parseError(error: HttpErrorResponse): { message: string; status: number } {
    if (error.message && error.status) {
      return error;
    } else if (error.error instanceof ErrorEvent) {
      return {
        message: error.message,
        status: error.status,
      };
    } else {
      return {
        message: error.error ? error.error.message : "An error has occurred " + error.status,
        status: error.status,
      };
    }
  }

  private parseLevel(response: ILevel): IStatusDefinitions[] {
    return [
      {
        unlocked: true,
        pictureFilePath: "",
        points: response.currentStatePoints,
        statusDefinitionId: 0,
        statusName: response.name,
        pointsDisplay: String(response.currentStatePoints),
      },
    ];
  }
}
