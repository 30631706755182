/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scan-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./scan-frame.component";
var styles_ScanFrameComponent = [i0.styles];
var RenderType_ScanFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScanFrameComponent, data: {} });
export { RenderType_ScanFrameComponent as RenderType_ScanFrameComponent };
export function View_ScanFrameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, ":svg:svg", [[":xml:space", "preserve"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["class", "scan-frame"], ["enable-background", "new 0 0 210 210"], ["version", "1.1"], ["viewBox", "0 0 210 210"], ["xmlns", "http://www.w3.org/2000/svg"]], [[2, "--show", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["class", "scan-frame--fill"], ["d", "M10,47V10h37V0H7C3.14,0,0,3.14,0,7v40H10z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:path", [["class", "scan-frame--fill"], ["d", "M10,163H0v40c0,3.859,3.14,7,7,7h40v-10H10V163z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["class", "scan-frame--fill"], ["d", "M200,163v37h-37v10h40c3.859,0,7-3.141,7-7v-40H200z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["class", "scan-frame--fill"], ["d", "M200,47h10V7c0-3.86-3.141-7-7-7h-40v10h37V47z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ion-spinner", [["class", "scan-frame__loading"]], [[2, "--show", null]], null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(7, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 6, 0, currVal_1); }); }
export function View_ScanFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-scan-frame", [], null, null, null, View_ScanFrameComponent_0, RenderType_ScanFrameComponent)), i1.ɵdid(1, 114688, null, 0, i4.ScanFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScanFrameComponentNgFactory = i1.ɵccf("shared-scan-frame", i4.ScanFrameComponent, View_ScanFrameComponent_Host_0, { loading: "loading" }, {}, []);
export { ScanFrameComponentNgFactory as ScanFrameComponentNgFactory };
