import { Component, Input } from "@angular/core";
import { ITransaction } from "shared/lib/common/interfaces";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "ocm-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent {
  @Input() public transactions: ITransaction[];
  @Input() public totalEarn: number;
  @Input() public totalRedeem: number;
  @Input() public loading: boolean;

  constructor(public translate: TranslateService) {}
}
