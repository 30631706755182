import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import {
  IIdentifierType,
  IStatusDefinitions,
  IVoucher,
  RedeemDependencyCode,
  VoucherType,
  IRegistrationCode,
} from "shared/lib/common/interfaces";
import {
  ConfirmationPage,
  IConfirmationPageInputs,
  IConfirmationPageOutputs,
} from "shared/lib/common/pages/confirmation/confirmation.page";
import { CouponCardPage, ICouponCardInput, ICouponCardOutput } from "shared/lib/v1/pages/coupon-card/coupon-card.page";
import { IPrintPageInputs, IPrintPageOutputs, PrintPage } from "shared/lib/v1/pages/print/print.page";
import {
  AdminService,
  PrintService,
  RoutingService,
  ToastService,
  TranslateService,
  UserService,
  UtilsService,
} from "shared/lib/common/services";
import { IRegisterModalPageInputs, IRegisterModalPageOutputs, RegisterPage } from "shared/lib/common/pages/register/register.page";
import { IResponse } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services";
import { ISuccessComponentParams, SuccessPage } from "shared/lib/common/pages/success/success.page";

export interface IBurnPageParams {
  keyCode: string;
  couponId?: string;
  rewardId?: string;
  idType?: IIdentifierType;
}

@Component({
  selector: "burn",
  templateUrl: "./burn.page.html",
  styleUrls: ["./burn.page.scss"],
})
export class BurnPage implements OnInit {
  private params: IBurnPageParams;
  public loading: boolean;
  public redeemLoading: boolean;
  public tickets: IVoucher[];
  public ticketSelected: IVoucher;
  public totalPoints: number;
  public status: IStatusDefinitions;
  public mobileVersion: boolean;
  public registerLoading: boolean;
  public showRegisterButton: boolean;

  constructor(
    public translate: TranslateService,
    public user: UserService,
    public utils: UtilsService,
    private modal: ModalController,
    private toast: ToastService,
    private config: ConfigService,
    private print: PrintService,
    private admin: AdminService,
    private routing: RoutingService,
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.params = this.routing.getUrlParams<IBurnPageParams>();
    const width = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--max-width-mobile")
      .split("px")[0];
    this.mobileVersion = window.innerWidth < +width;
  }

  public ionViewDidEnter(): void {
    this.status = this.getStatus();
    this.totalPoints = this.user.getUser() ? this.user.getUser().totalPoints : 0;
    this.tickets = this.sortTickets(this.user.getVouchers());
    this.loading = false;
    this.redeemLoading = false;
    if (this.params && (this.params.couponId || this.params.rewardId)) {
      const ticket = this.findTicket(this.tickets);
      if (ticket) {
        if (ticket.unlocked) {
          if (!this.mobileVersion) this.selectTicket(ticket);
          this.askToRedeem(ticket);
        } else this.toast.show(this.showUnlockedReason(ticket, this.totalPoints), "info");
      } else {
        this.logOutUser();
        this.toast.show(this.translate._("VOUCHER_CODE_NOT_FOUND"), "error");
      }
    }
    this.isRegisterButtonRequired().then(isRequired => (this.showRegisterButton = isRequired));
  }

  public async isRegisterButtonRequired(): Promise<boolean> {
    const resp = await this.admin.getAllClients();
    if (resp.ok === true) {
      const client = resp.response.find(c => String(c.clientId) === this.config.getMountingConfig().clientId);
      const user = this.user.getUser();
      return client.showRegistrationButton && !!user && !user.isRegistered;
    } else {
      this.toast.show(this.translate._(resp.error.message), "error");
      return false;
    }
  }

  public async askToRedeem(voucher: IVoucher): Promise<any> {
    const componentProps: IConfirmationPageInputs = {
      voucher,
      title: this.translate._("SHOW_THIS_COUPON_TO_A_SALES_REP"),
      confirmText: this.translate._("REDEEM_NOW"),
      dismissText: this.translate._("CANCEL"),
    };
    const confirmationModal = await this.modal.create({
      component: ConfirmationPage,
      cssClass: "modal",
      componentProps,
    });
    await confirmationModal.present();
    const { data } = (await confirmationModal.onDidDismiss()) as IConfirmationPageOutputs;
    if (data) this.redeem(voucher);
  }

  public async goHistoryPage(): Promise<void> {
    this.routing.goForward([SharedRoutes.history]);
  }

  public async selectTicket(ticket: IVoucher): Promise<void> {
    this.ticketSelected = ticket;
    if (this.mobileVersion) {
      const componentProps: ICouponCardInput = {
        ticketSelected: this.ticketSelected,
        showRegisterButton: this.showRegisterButton,
        registerMandatory: this.needToShowRegisterModal(this.ticketSelected),
      };
      const couponModal = await this.modal.create({
        component: CouponCardPage,
        cssClass: "modal",
        componentProps,
      });
      await couponModal.present();
      const { data } = (await couponModal.onDidDismiss()) as ICouponCardOutput;
      if (data) this.askToRedeem(data);
    }
  }

  public logOutUser(): void {
    this.user.removeUser();
    this.routing.goForward([SharedRoutes.screenSaver]);
  }

  public async showPrintModal(voucher: IVoucher): Promise<boolean> {
    const componentProps: IPrintPageInputs = { voucher };
    const printModal = await this.modal.create({
      component: PrintPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await printModal.present();
    const { data } = (await printModal.onDidDismiss()) as IPrintPageOutputs;
    return data;
  }

  public async redeem(voucher: IVoucher): Promise<void> {
    this.redeemLoading = true;
    let resp: IResponse<any>;

    try {
      if (
        this.config.havePrinter() &&
        this.print.getIp() &&
        ((voucher.voucherType === VoucherType.coupon && voucher.couponTypeCode !== "EARN") || voucher.voucherType !== VoucherType.coupon)
      ) {
        const ok = await this.showPrintModal(voucher);
        if (ok) {
          if (voucher.voucherType === VoucherType.reward) resp = await this.user.redeemReward(voucher);
          else resp = await this.user.redeemCoupon(voucher);
        }
      } else {
        if (voucher.voucherType === VoucherType.reward) resp = await this.user.redeemReward(voucher);
        else resp = await this.user.redeemCoupon(voucher);
      }

      if (resp && resp.ok === true) {
        await this.success(voucher);
      } else if (resp && resp.ok === false) {
        this.toast.show(this.translate._(resp.error.message as any), "error");
      }
    } catch (e) {
      this.toast.show(this.translate._(e.message as any), "error");
    } finally {
      this.redeemLoading = false;
    }
  }

  public async goToRegister(): Promise<void> {
    this.registerLoading = true;
    const user = this.user.getUser();
    const resp = await this.admin.getRegistrationType();
    if (resp.ok === true) {
      await this.showRegisterModal(user.keyCode, user.userReferenceCode, resp.response);
    } else {
      this.toast.show(this.translate._(resp.error.message), "error");
    }
    this.registerLoading = false;
  }

  public sortTickets(tickets: IVoucher[]): IVoucher[] {
    const rewards = tickets.filter(t => t.voucherType === VoucherType.reward);
    const coupons = tickets.filter(t => t.voucherType === VoucherType.coupon);
    return [...this.orderByPoints(coupons), ...this.orderByPoints(rewards)];
  }

  public orderByPoints(tickets: IVoucher[]): IVoucher[] {
    return tickets.sort((t1, t2) => {
      if (t1.points < t2.points) return -1;
      if (t1.points > t2.points) return 1;
      return 0;
    });
  }

  public needToShowRegisterModal(voucher: IVoucher): boolean {
    const user = this.user.getUser();
    if (!voucher) return true;
    return (
      (voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_EASY ||
        voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_EXTENDED ||
        voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_SIMPLE) &&
      (!!user && !user.isRegistered)
    );
  }

  protected async success(voucher: IVoucher): Promise<any> {
    let points: number;
    if (voucher.voucherType === VoucherType.coupon && voucher.couponTypeCode === "EARN") points = voucher.points;
    else points = voucher.points * -1;
    const componentProps: ISuccessComponentParams = {
      idType: this.params.idType,
      keyCode: (this.user.getUser() || {}).keyCode,
      points,
      title: voucher.voucherType === VoucherType.reward ? this.translate._("REWARD_REDEEMED") : this.translate._("COUPON_REDEEMED"),
    };
    const successModal = await this.modal.create({
      component: SuccessPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await successModal.present();
    this.redeemLoading = false;
  }

  protected async showRegisterModal(
    keyCode: string,
    userReferenceCode: string,
    registrationType: IRegistrationCode,
  ): Promise<IRegisterModalPageOutputs> {
    const componentProps: IRegisterModalPageInputs = {
      registrationType,
      keyCode,
      userReferenceCode,
    };
    const registerModal = await this.modal.create({
      component: RegisterPage,
      cssClass: "modal modal--full-screen",
      componentProps,
    });
    await registerModal.present();

    return (await registerModal.onDidDismiss()) as IRegisterModalPageOutputs;
  }

  private getStatus(): IStatusDefinitions {
    let resp: IStatusDefinitions;
    const user = this.user.getUser();
    if (user && user.statusDefinitions) {
      user.statusDefinitions.forEach(status => {
        if (!resp || (resp.points < status.points && status.unlocked)) {
          resp = status;
        }
      });
    }
    return resp;
  }

  private showUnlockedReason(ticket: IVoucher, neededPoints: number): string {
    if (ticket.points > neededPoints) return this.translate._("NEED_MORE_POINTS");
    const now = new Date().getTime();
    if (ticket.voucherType === VoucherType.coupon) {
      if (ticket.validFromDate && now < new Date(ticket.validFromDate.unixTimeStamp).getTime()) {
        return this.translate._("VALID_FROM_ERROR", new Date(ticket.validFromDate.unixTimeStamp).toLocaleDateString());
      }
      if (ticket.validToDate && now > new Date(ticket.validToDate.unixTimeStamp).getTime()) {
        return this.translate._("VALID_TO_ERROR", new Date(ticket.validToDate.unixTimeStamp).toLocaleDateString());
      }
    }
    return this.translate._("LOCKED_VOUCHER");
  }

  private findTicket(tickets: IVoucher[]): IVoucher {
    return tickets.find(({ voucherId, accountVoucherId }) => {
      if (voucherId && accountVoucherId) {
        return (
          String(voucherId) === String(this.params.couponId) ||
          String(voucherId) === String(this.params.rewardId) ||
          accountVoucherId === String(this.params.couponId) ||
          accountVoucherId === String(this.params.rewardId)
        );
      }
      if (accountVoucherId) return accountVoucherId === String(this.params.couponId) || accountVoucherId === String(this.params.rewardId);
      if (voucherId) return String(voucherId) === String(this.params.couponId) || String(voucherId) === String(this.params.rewardId);
    });
  }
}
