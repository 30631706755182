var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { ActionType, } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "../translate/translate.service";
import * as i0 from "@angular/core";
import * as i1 from "../translate/translate.service";
import * as i2 from "../p4m/p4m.service";
import * as i3 from "../utils/utils.service";
import * as i4 from "../config/config.service";
import * as i5 from "../sentry/sentry.service";
var AdminService = /** @class */ (function () {
    function AdminService(translate, p4m, utils, configService, sentry) {
        this.translate = translate;
        this.p4m = p4m;
        this.utils = utils;
        this.configService = configService;
        this.sentry = sentry;
    }
    AdminService.prototype.getRegistrationType = function () {
        return __awaiter(this, void 0, void 0, function () {
            var registrationType, resp, client;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        registrationType = "NO";
                        return [4 /*yield*/, this.getAllClients()];
                    case 1:
                        resp = _a.sent();
                        if (resp.ok === true) {
                            client = resp.response.find(function (c) { return String(c.clientId) === _this.configService.getMountingConfig().clientId; });
                            if (client && client.alwaysShowRegistration) {
                                switch (this.configService.getMountingConfig().posMode) {
                                    case "STD":
                                        registrationType = client.showRegistrationCode;
                                        break;
                                    case "SER":
                                        registrationType = client.registrationModePosRegistrationTypeCode;
                                        break;
                                }
                            }
                            return [2 /*return*/, { ok: true, response: registrationType }];
                        }
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    AdminService.prototype.extendedRevenuePointsManually = function (keyCode, amount, extendedRevenueFactsId, idType, externalId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.extendedRevenuePointsManually({
                            organization: this.configService.getOrganization(),
                            language: this.translate.getSessionLanguage(),
                            deviceKey: this.configService.getMountingConfig().deviceKey,
                            keyCode: keyCode,
                            extendedRevenueFactsId: extendedRevenueFactsId,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AdminService.prototype.arts = function (date, amount, keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, workstationId, store, resp, response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.configService.getMountingConfig(), workstationId = _a.workstationId, store = _a.store;
                        return [4 /*yield*/, this.p4m.arts({ date: date, amount: amount, apiKey: this.getApiKey(), workstationId: workstationId, vatId: store && store.vatId, keyCode: keyCode })];
                    case 1:
                        resp = _b.sent();
                        response = this.parseResponse(resp);
                        if (response.ok === true && response.response.successInfo && response.response.successInfo.successCode === "BON_ID_ALREADY_EXISTS") {
                            return [2 /*return*/, { ok: false, error: { message: "BON_ID_ALREADY_EXISTS" } }];
                        }
                        return [2 /*return*/, response];
                }
            });
        });
    };
    AdminService.prototype.readProgramPartners = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.readProgramPartners()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AdminService.prototype.readPartnerStores = function (partnerId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.readPartnerStores(partnerId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AdminService.prototype.getAllClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var refresh, response, resp, _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, this.p4m.deviceManagementLogin({
                                userName: this.p4m.getUser(),
                                organization: this.configService.getOrganization(),
                                password: this.p4m.getPassword(),
                            })];
                    case 1:
                        refresh = _b.sent();
                        if (!(refresh.ok === true)) return [3 /*break*/, 5];
                        response = this.parseDeviceManagementLogin(refresh.response);
                        if (!(response.ok === true)) return [3 /*break*/, 3];
                        _a = this.parseResponse;
                        return [4 /*yield*/, this.p4m.getAllClients({
                                apiKey: this.getMountingApiKey(),
                                organization: this.configService.getOrganization(),
                            })];
                    case 2:
                        resp = _a.apply(this, [_b.sent()]);
                        if (resp.ok === true) {
                            return [2 /*return*/, { ok: true, response: resp.response.clients }];
                        }
                        else {
                            return [2 /*return*/, resp];
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, response];
                    case 4: return [3 /*break*/, 6];
                    case 5: return [2 /*return*/, refresh];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _b.sent();
                        this.sentry.handleError({ err: error_1, method: "admin.getAllClients" });
                        return [2 /*return*/, { ok: false, error: error_1 }];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.getClientStore = function () {
        try {
            return { ok: true, response: JSON.parse(this.getStore()) };
        }
        catch (error) {
            this.sentry.handleError({
                err: error,
                method: "admin.getClientStore",
            });
            return { ok: false, error: error };
        }
    };
    AdminService.prototype.fetchStoresOfClient = function (clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var refresh, refreshParsed, resp, _a, error_2;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, this.p4m.loginToGetApiKey({
                                organization: this.configService.getOrganization(),
                                userName: this.p4m.getUser(),
                                password: this.p4m.getPassword(),
                            })];
                    case 1:
                        refresh = _b.sent();
                        if (!(refresh.ok === true)) return [3 /*break*/, 5];
                        refreshParsed = this.parseResponse(refresh);
                        if (!(refreshParsed.ok === true)) return [3 /*break*/, 3];
                        _a = this.parseResponse;
                        return [4 /*yield*/, this.p4m.getStoresOfClient({
                                organization: this.configService.getOrganization(),
                                apiKey: this.getMountingApiKey(),
                                clientId: clientId,
                            })];
                    case 2:
                        resp = _a.apply(this, [_b.sent()]);
                        if (resp.ok === true) {
                            if (this.configService.getMountingConfig().storeName) {
                                this.setStore(resp.response.stores.find(function (s) { return s.storeName === _this.configService.getMountingConfig().storeName; }));
                            }
                            return [2 /*return*/, { ok: true, response: resp.response.stores }];
                        }
                        else {
                            return [2 /*return*/, resp];
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, refreshParsed];
                    case 4: return [3 /*break*/, 6];
                    case 5: return [2 /*return*/, refresh];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_2 = _b.sent();
                        this.sentry.handleError({
                            err: error_2,
                            method: "admin.fetchStoresOfClient",
                        });
                        return [2 /*return*/, { ok: false, error: error_2 }];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.getDisplayAds = function (deviceKey) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.p4m.downloadDisplayAds({
                                organization: this.configService.getOrganization(),
                                apiKey: this.getMountingApiKey(),
                                deviceKey: deviceKey,
                            })];
                    case 1:
                        resp = _a.sent();
                        if (resp.ok === true) {
                            return [2 /*return*/, { ok: true, response: this.parseDisplayAds(resp.response) }];
                        }
                        else {
                            return [2 /*return*/, resp];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        this.sentry.handleError({ err: error_3, method: "admin.getDisplayAds" });
                        return [2 /*return*/, { ok: false, error: error_3 }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.mountDevice = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var apiKey, _a, error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.getApiKeyProcess(this.configService.getOrganization(), params.clientId)];
                    case 1:
                        apiKey = _b.sent();
                        if (apiKey.ok === true) {
                            this.setApiKey(apiKey.response);
                        }
                        else {
                            return [2 /*return*/, apiKey];
                        }
                        _a = this.parseResponse;
                        return [4 /*yield*/, this.p4m.mountDevice({
                                organization: this.configService.getOrganization(),
                                apiKey: this.getMountingApiKey(),
                                clientId: params.clientId,
                                authenticationKey: params.authenticationKey,
                                cameraPositionCode: params.cameraPositionCode,
                                deviceInformation: params.deviceInformation,
                                deviceModel: params.deviceModel,
                                language: params.language,
                                osCode: params.osCode,
                                posModeCode: params.posModeCode,
                                storeId: params.storeId,
                                workstationId: params.workstationId,
                            })];
                    case 2: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 3:
                        error_4 = _b.sent();
                        this.sentry.handleError({ err: error_4, method: "admin.mountDevice" });
                        return [2 /*return*/, { ok: false, error: error_4 }];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.unMountDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var refresh, response, _a, error_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, this.p4m.loginToGetApiKey({
                                userName: this.p4m.getUser(),
                                password: this.p4m.getPassword(),
                                organization: this.configService.getOrganization(),
                            })];
                    case 1:
                        refresh = _b.sent();
                        if (!(refresh.ok === true)) return [3 /*break*/, 5];
                        response = this.parseResponse(refresh);
                        if (!(response.ok === true)) return [3 /*break*/, 3];
                        _a = this.parseResponse;
                        return [4 /*yield*/, this.p4m.unMountDevice({
                                organization: this.configService.getOrganization(),
                                apiKey: this.getMountingApiKey(),
                                deviceId: this.configService.getMountingConfig().deviceId.toFixed(),
                                fromDevice: true,
                            })];
                    case 2: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 3: return [2 /*return*/, response];
                    case 4: return [3 /*break*/, 6];
                    case 5: return [2 /*return*/, refresh];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_5 = _b.sent();
                        this.sentry.handleError({ err: error_5, method: "admin.unMountDevice" });
                        return [2 /*return*/, { ok: false, error: error_5 }];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.isValidPin = function (pin) {
        var pinCode = this.getPinCode();
        return pinCode && pinCode === pin;
    };
    AdminService.prototype.adminLogin = function (email, organization, password) {
        return __awaiter(this, void 0, void 0, function () {
            var loginResponse, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.AdminMultiLogin(email, organization, password)];
                    case 1:
                        loginResponse = _a.sent();
                        if (loginResponse.ok === true) {
                            this.p4m.setUser(email);
                            this.p4m.setPassword(password);
                            this.p4m.setToken(loginResponse.response.token);
                            this.setMountingApiKey(loginResponse.response.mountingApiKey);
                            return [2 /*return*/, {
                                    ok: true,
                                    response: {
                                        mountingApiKey: loginResponse.response.mountingApiKey,
                                        token: loginResponse.response.token,
                                    },
                                }];
                        }
                        else {
                            return [2 /*return*/, loginResponse];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        this.sentry.handleError({ err: error_6, method: "admin.adminLogin" });
                        return [2 /*return*/, { ok: false, error: { message: error_6.message || error_6 } }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.reLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.p4m.login({
                                email: this.p4m.getUser(),
                                organization: this.configService.getOrganization(),
                                password: this.p4m.getPassword(),
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_7 = _a.sent();
                        this.sentry.handleError({ err: error_7, method: "admin.reLogin" });
                        return [2 /*return*/, { ok: false, error: { message: error_7.message || error_7 } }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.getPromotions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, actionDefinitions, keyValue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.getActionDefinitions({
                            organization: this.configService.getOrganization(),
                            deviceKey: this.configService.getMountingConfig().deviceKey,
                        })];
                    case 1:
                        res = _a.sent();
                        actionDefinitions = [];
                        if (res.ok) {
                            actionDefinitions = res.response.actionDefinitions;
                        }
                        keyValue = {};
                        (actionDefinitions || []).forEach(function (a) {
                            keyValue[a.actionType] = {
                                factor: a.factor,
                                name: a.campaignName,
                                points: 0,
                                revenue: 0,
                                type: a.actionType,
                            };
                        });
                        return [2 /*return*/, {
                                rmc1: keyValue[ActionType.RMC1],
                                rmc2: keyValue[ActionType.RMC2],
                                rmc3: keyValue[ActionType.RMC3],
                                rmt: keyValue[ActionType.RMT],
                                rmb: keyValue[ActionType.RMB],
                            }];
                }
            });
        });
    };
    AdminService.prototype.logout = function () {
        localStorage.clear();
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    };
    AdminService.prototype.isAuthenticated = function () {
        if (this.p4m.getUser() && this.p4m.getPassword()) {
            return true;
        }
        else {
            return false;
        }
    };
    AdminService.prototype.getPinCode = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.PIN_CODE));
    };
    AdminService.prototype.getMountingApiKey = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_APIKEY));
    };
    AdminService.prototype.getApiKey = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.APIKEY));
    };
    AdminService.prototype.validateSaveNewPinParams = function (pin) {
        if (!pin)
            return { ok: false, error: { message: "EMPTY_PIN" } };
        if (pin.length <= 3) {
            return { ok: false, error: { message: "SHORT_PIN" } };
        }
        return { ok: true, response: { pin: pin } };
    };
    AdminService.prototype.parseGetPermissionUser = function (response) {
        if (!response.errorInfo) {
            return { ok: true, response: response.user.apiKey };
        }
        else {
            return { ok: false, error: { message: response.errorInfo.errorCode } };
        }
    };
    AdminService.prototype.parseResponse = function (response) {
        if (response.ok === true) {
            if (!response.response.errorInfo) {
                return response;
            }
            else {
                if (response.response.errorInfo.exceptionMessage &&
                    response.response.errorInfo.exceptionMessage.includes("workstation_id_unique")) {
                    return {
                        ok: false,
                        error: {
                            message: "Please verify your workstation ID (it has to be unique) and get in contact with convercus service",
                        },
                    };
                }
                return { ok: false, error: { message: response.response.errorInfo.errorCode } };
            }
        }
        else {
            return response;
        }
    };
    AdminService.prototype.register = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var simpleResponse, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.p4m.register({
                            organization: this.configService.getOrganization(),
                            channelCode: params.simple.channelCode,
                            emailAddress: params.simple.emailAddress,
                            keyCode: params.simple.keyCode,
                            language: params.simple.language,
                            clientOptins: params.simple.clientOptins,
                            clientId: params.simple.clientId,
                        })];
                    case 1:
                        simpleResponse = _b.sent();
                        if (!(simpleResponse.ok === true)) return [3 /*break*/, 4];
                        if (!(params.type !== "SIM")) return [3 /*break*/, 3];
                        _a = this.parseResponse;
                        return [4 /*yield*/, this.p4m.updateProfileExtendedRegistration({
                                organization: this.configService.getOrganization(),
                                birthdate: params.extended.birthdate,
                                city: params.extended.city,
                                countryCode: params.extended.countryCode,
                                deviceKey: params.extended.deviceKey,
                                emailAddress: params.extended.emailAddress,
                                familyName: params.extended.familyName,
                                genderCode: params.extended.genderCode || "NA",
                                givenName: params.extended.givenName,
                                phoneNumber: params.extended.phoneNumber,
                                streetHouseNo: params.extended.streetHouseNo,
                                userReferenceCode: params.extended.userReferenceCode,
                                zipCode: params.extended.zipCode,
                            })];
                    case 2: return [2 /*return*/, _a.apply(this, [_b.sent()])];
                    case 3: return [2 /*return*/, this.parseResponse(simpleResponse)];
                    case 4: return [2 /*return*/, simpleResponse];
                }
            });
        });
    };
    AdminService.prototype.setApiKey = function (apiKey) {
        localStorage.setItem(LOCAL_STORAGE.APIKEY, this.utils.encrypt(apiKey));
    };
    AdminService.prototype.setMountingApiKey = function (mountingApiKey) {
        localStorage.setItem(LOCAL_STORAGE.MOUNTING_APIKEY, this.utils.encrypt(mountingApiKey));
    };
    AdminService.prototype.getStore = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.STORE));
    };
    AdminService.prototype.setStore = function (store) {
        localStorage.setItem(LOCAL_STORAGE.STORE, this.utils.encrypt(JSON.stringify(store)));
    };
    AdminService.prototype.parseDisplayAds = function (displayAds) {
        var resp = [];
        if (displayAds && displayAds.displayAds) {
            displayAds.displayAds.forEach(function (d) {
                resp.push(d);
            });
        }
        return resp;
    };
    AdminService.prototype.AdminMultiLogin = function (email, organization, password) {
        return __awaiter(this, void 0, void 0, function () {
            var token, mountingApiKey, loginResp, resp, response, isValidLogin, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.loginToGetApiKey({ userName: email, organization: organization, password: password })];
                    case 1:
                        loginResp = _a.sent();
                        if (loginResp.ok === true) {
                            resp = this.parseResponse(loginResp);
                            if (resp.ok === false) {
                                return [2 /*return*/, resp];
                            }
                        }
                        else {
                            return [2 /*return*/, loginResp];
                        }
                        return [4 /*yield*/, Promise.all([
                                this.p4m.login({ email: email, organization: organization, password: password }),
                                this.p4m.deviceManagementLogin({ userName: email, organization: organization, password: password }),
                            ])];
                    case 2:
                        response = _a.sent();
                        isValidLogin = response.filter(function (_a) {
                            var ok = _a.ok;
                            return !ok;
                        });
                        if (isValidLogin.length === 0) {
                            if (response[0].ok === true) {
                                token = response[0].response;
                            }
                            else {
                                return [2 /*return*/, response[0]];
                            }
                            if (response[1].ok === true) {
                                resp = this.parseDeviceManagementLogin(response[1].response);
                                if (resp.ok === true) {
                                    mountingApiKey = resp.response;
                                }
                                else {
                                    return [2 /*return*/, resp];
                                }
                            }
                            else {
                                return [2 /*return*/, response[1]];
                            }
                            return [2 /*return*/, {
                                    ok: true,
                                    response: {
                                        mountingApiKey: mountingApiKey,
                                        token: token,
                                    },
                                }];
                        }
                        else {
                            return [2 /*return*/, isValidLogin[0]];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.parseDeviceManagementLogin = function (response) {
        if (!response.errorInfo) {
            return { ok: true, response: response.authenticationKey };
        }
        else {
            return { ok: false, error: { message: response.errorInfo.errorCode } };
        }
    };
    AdminService.prototype.getApiKeyProcess = function (organization, clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var userPermissionResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.getPermissionUser({ clientId: clientId, organization: organization })];
                    case 1:
                        userPermissionResponse = _a.sent();
                        if (userPermissionResponse.ok === true) {
                            return [2 /*return*/, this.parseGetPermissionUser(userPermissionResponse.response)];
                        }
                        else {
                            return [2 /*return*/, userPermissionResponse];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.TranslateService), i0.inject(i2.P4mService), i0.inject(i3.UtilsService), i0.inject(i4.ConfigService), i0.inject(i5.SentryService)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
