var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, EventEmitter, OnChanges, OnDestroy, SimpleChanges, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import jsQR from "jsqr";
import { UtilsService } from "shared/lib/common/services";
import { CONFIGURATIONS_BY_DEVICE } from "./configuations-by-device";
var QrscannerComponent = /** @class */ (function () {
    function QrscannerComponent(utils, platform) {
        this.utils = utils;
        this.platform = platform;
        this.scanSuccess = new EventEmitter();
        this.scanFailure = new EventEmitter();
        this.camerasFound = new EventEmitter();
        this.hasDevices = new EventEmitter();
        this.permissionResponse = new EventEmitter();
        this.tick = this.tick.bind(this);
    }
    QrscannerComponent.prototype.ngOnInit = function () {
        this.findDevices();
    };
    QrscannerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device && changes.device.currentValue)
            this.scannerOn();
    };
    QrscannerComponent.prototype.ngOnDestroy = function () {
        this.scannerOff();
    };
    QrscannerComponent.prototype.findDevices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var devices;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAnyVideoDevice()];
                    case 1:
                        devices = _a.sent();
                        if (!devices)
                            this.findDevices();
                        this.camerasFound.emit(devices);
                        return [2 /*return*/];
                }
            });
        });
    };
    QrscannerComponent.prototype.scannerOn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var mediaStream_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scanerEnable = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.canvas = this.canvasElement.nativeElement.getContext("2d");
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({
                                video: { deviceId: this.device.deviceId },
                            })];
                    case 2:
                        mediaStream_1 = _a.sent();
                        this.video.nativeElement.srcObject = mediaStream_1;
                        this.video.nativeElement.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
                        this.video.nativeElement.addEventListener("loadedmetadata", function () {
                            setTimeout(function () {
                                _this.onCapabilitiesReady(mediaStream_1.getTracks()[0].getCapabilities());
                            }, 500);
                        });
                        this.video.nativeElement
                            .play()
                            .then(this.tick)
                            .catch(function (e) { });
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QrscannerComponent.prototype.tick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var imageData, code, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.scannerEnabled && this.scanerEnable && this.video.nativeElement.readyState === this.video.nativeElement.HAVE_ENOUGH_DATA)) return [3 /*break*/, 2];
                        this.canvas.canvas.height = window.innerHeight;
                        this.canvas.canvas.width = window.innerWidth;
                        this.canvasElement.height = window.innerHeight;
                        this.canvasElement.width = window.innerWidth;
                        this.canvas.drawImage(this.video.nativeElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
                        imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);
                        code = jsQR(imageData.data, imageData.width, imageData.height, {
                            inversionAttempts: "dontInvert",
                        });
                        if (!code) return [3 /*break*/, 2];
                        this.code = code.data;
                        this.scanSuccess.emit(this.code);
                        return [4 /*yield*/, this.utils.waiting(1000)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this.scanerEnable)
                            this.requestAnimationFrameId = requestAnimationFrame(this.tick);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QrscannerComponent.prototype.scannerOff = function () {
        try {
            this.scanerEnable = false;
            cancelAnimationFrame(this.requestAnimationFrameId);
            this.video.nativeElement.pause();
            this.video.nativeElement.src = "";
            if (this.video.nativeElement.srcObject)
                this.video.nativeElement.srcObject.getTracks()[0].stop();
        }
        catch (error) {
            console.error(error);
        }
    };
    QrscannerComponent.prototype.getAnyVideoDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var devices, videoDevices, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                            this.hasDevices.emit(false);
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
                    case 1:
                        devices = _a.sent();
                        videoDevices = (devices || []).filter(function (d) { return d.kind === "videoinput"; });
                        if (videoDevices.length === 0) {
                            this.hasDevices.emit(false);
                            return [2 /*return*/, null];
                        }
                        this.hasDevices.emit(true);
                        return [2 /*return*/, videoDevices];
                    case 2:
                        error_3 = _a.sent();
                        console.error(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    QrscannerComponent.prototype.onCapabilitiesReady = function (capabilities) {
        if (capabilities.exposureCompensation) {
            var _a = this.getDeviceConfig(), exposureCompensation = _a.exposureCompensation, zoom = _a.zoom;
            this.video.nativeElement.srcObject.getTracks()[0].applyConstraints({
                advanced: [{ exposureCompensation: exposureCompensation, zoom: zoom }],
                height: 1080,
                width: 1920,
            });
        }
    };
    QrscannerComponent.prototype.getDeviceConfig = function () {
        var _this = this;
        return (CONFIGURATIONS_BY_DEVICE.find(function (d) { return _this.platform.testUserAgent(d.name); }) || { name: "default", exposureCompensation: -1, zoom: 4 });
    };
    return QrscannerComponent;
}());
export { QrscannerComponent };
