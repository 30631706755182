var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalController, NavParams } from "@ionic/angular";
import { ConfirmationPage as SharedConfirmationPage } from "shared/lib/common/pages";
import { TranslateService } from "shared/lib/common/services";
var ConfirmationPage = /** @class */ (function (_super) {
    __extends(ConfirmationPage, _super);
    function ConfirmationPage(_navParams, _modal, _translate) {
        return _super.call(this, _navParams, _modal, _translate) || this;
    }
    return ConfirmationPage;
}(SharedConfirmationPage));
export { ConfirmationPage };
