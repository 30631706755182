import * as de from "./translate.de.json";
import * as deOrganization from "./translate.de.organization.json";
import * as en from "./translate.en.json";
import * as enOrganization from "./translate.en.organization.json";

export type I18n =
  | string
  | "BOOK_NOW"
  | "SHORT_PIN"
  | "EMAIL_FORMAT"
  | "KEYCODE_NOT_FOUND"
  | "INSERT_SOMETHING"
  | "EMPTY_PIN"
  | "EMPTY_EMAIL"
  | "EMPTY_PASSWORD"
  | "EMPTY_USER"
  | "EMPTY_USER_ID"
  | "EMPTY_USER_REF"
  | "EMPTY_USER_TOT"
  | "EMPTY_HISTORY"
  | "HISTORY_NOT_FOUND"
  | "INVALID_SETUP"
  | "INVALID_VOUCHER"
  | "INVALID_USER"
  | "REDEEM_WARNING"
  | "INVALID_PIN"
  | "UNHANDLED_ERROR"
  | "VOUCHER_CODE_NOT_FOUND"
  | "ADD_MANUAL_POINTS"
  | "CUSTOMER_HISTORY"
  | "PARTNER_SINCE"
  | "LIFETIME_EARNING"
  | "LIFETIME_REDEMPTION"
  | "LOGIN"
  | "LOGIN_ERROR"
  | "EMAIL_ACCOUNT"
  | "ORGANIZATION"
  | "ORGANIZATION_REQUIRED"
  | "ORGANIZATION_NOT_FOUND"
  | "PASSWORD"
  | "PASSWORD_REQUIRED"
  | "LOYALTY_CARD"
  | "ACCUMULATE_POINTS"
  | "BENEFITS_WORLD"
  | "ENGLISH"
  | "GERMAN"
  | "PLEASE_INSERT_PIN"
  | "A_PIN"
  | "CONFIRM_PIN"
  | "NOT_EQUAL_PIN"
  | "COUPON_REDEEMED"
  | "NEW_BALANCE"
  | "YOUR_BALANCE"
  | "CLOSE"
  | "SEND_TO"
  | "POINTS_EXCHANGED"
  | "POINTS_EARNED"
  | "ACTIVITIES"
  | "AMOUNT"
  | "POINTS"
  | "SUCCESSFUL_PIN_SAVED"
  | "VALID_UNTIL"
  | "VALID_IN_DAYS"
  | "HISTORY"
  | "LOG_OUT"
  | "REDEEM_NOW"
  | "FREE"
  | "SHOW_THIS_COUPON_TO_A_SALES_REP"
  | "CANCEL"
  | "CONFIRM"
  | "REEDEM"
  | "POINTS_TO_EXCHANGE"
  | "TYPE_PIN"
  | "DEVICE_KEY_NOT_FOUND"
  | "EMPTY_FIELDS"
  | "USER_WITH_NO_CLIENTS"
  | "MANUAL_POINT_BOOKING"
  | "BOOK_POINTS"
  | "USER_KEY_DEACTIVATED"
  | "USER_KEY_DOES_NOT_EXIST"
  | "GENERAL_NO_ACCESS"
  | "REGISTER_TITLE"
  | "SHOULD_REGISTER_INFO"
  | "NO_CONNECTION"
  | "POINTS_ACUMULATED"
  | "FINISH_REGISTER"
  | "INVALID_PARAMS"
  | "INVALID_QR"
  | "UNMOUNT_DEVICE"
  | "CONFIRMATION"
  | "CONFIRMATION_MESSAGE"
  | "SETTINGS"
  | "PRINTER_CONFIG_TITLE"
  | "PRINTER_CONFIG_SUBTITLE"
  | "PRINTER_CONFIG_BUTTON"
  | "PRINTER_CONNECTED"
  | "NOT_CONNECTED"
  | "PRINT_PAGE_TITLE"
  | "NO_PAPER_TEXT1"
  | "NO_PAPER_TEXT2"
  | "NO_CONNECTION_TEXT1"
  | "NO_CONNECTION_TEXT2"
  | "SUCCESS_TEXT1"
  | "SUCCESS_TEXT2"
  | "OK"
  | "VALID_AT"
  | "TICKET_PRINT1"
  | "TICKET_PRINT2"
  | "TICKET_PRINT3"
  | "TICKET_PRINT4"
  | "TICKET_PRINT5"
  | "ISSUED_ON"
  | "CUSTOMER_CARD"
  | "REDEEMABLE_UNTIL"
  | "DEVICE_NOT_MOUNTED"
  | "GENERAL_INPUT_JSON_INVALID"
  | "LOGIN_USER_NOT_LOGGED_IN"
  | "COUPON_MAX_REDEEM_NUMBER_REACHED"
  | "GENERAL_NO_PERMISION"
  | "REMOVE_PRINTER"
  | "CAMERA_ACCESS_DENIED"
  | "GRANT_CAMERA_INSTRUCTIONS"
  | "REVENUE"
  | "TOTAL_POINTS"
  | "ASSIGN_POINTS"
  | "ADMIN_CONFIG_TITLE"
  | "GENERAL_SETTINGS"
  | "VORTEILE"
  | "PUNKTEHISTORIE"
  | "GENERAL_INPUT_PARAMETER_INVALID"
  | "BACKOFFICE"
  | "VOUCHER_DISABLED"
  | "EMAIL"
  | "GIVEN_NAME"
  | "FAMILY_NAME"
  | "STREET"
  | "POSTAL"
  | "CITY"
  | "COUNTRY"
  | "GENDER"
  | "BIRTHDATE"
  | "PHONE"
  | "SELECT_VOUCHER"
  | "UNDERSTOOD"
  | "REGISTRATION_NOT_COMPLETE"
  | "BOOK_POINTS_REASON"
  | "REVENUE_POINTS_REASON"
  | "MEMBER_REGISTER_DECLARATION_OF_CONSENT"
  | "MEMBER_REGISTER_ACCEPT_CONSENT"
  | "MEMBER_REGISTER_TERMS"
  | "FORM_OPTIN_ADVERTISING"
  | "RECONNECT"
  | "RECONNECTING"
  | "NO_SOCKET_CONNECTION"
  | "PURCHASE"
  | "POINT_REDEMPTION"
  | "COUPON_REDEMPTION"
  | "MALE"
  | "FEMALE"
  | "NO_ANSWER"
  | "PLEASE_REGISTER"
  | "REWARD_REDEEMED"
  | "POINTS_SPENT"
  | "NO_DESCRIPTION"
  | "P"
  | "EARN"
  | "VALID_BETWEEN"
  | "MOUNTING_PROCESS"
  | "CASH_CONFIGURATION"
  | "SELECT_YOUR_COMPANY"
  | "SELECT_SHOP"
  | "DEVICE_NAME"
  | "WORKSTATION_ID"
  | "DEVICE_CONFIGURATION"
  | "FRONT_CAMERA_POSITION_QUESTION"
  | "LEFT"
  | "TOP"
  | "RIGHT"
  | "MODE_QUESTION"
  | "DEFAULT_LANGUAGE_QUESTION"
  | "SAVE"
  | "SELECT_AN_OPTION"
  | "REVENUE_DATE"
  | "DAY"
  | "HOUR"
  | "SET_DATE"
  | "ARTS_ERROR"
  | "READ_MORE"
  | "SELECT_PARTNER"
  | "SELECT_PARTNER_SHOP"
  | "MANUAL_POINTS_ENABLED_QUESTION"
  | "DISMISS"
  | "REGISTER"
  | "default"
  | "silber"
  | "gold"
  | "REGISTER_NOW"
  | "REGISTER_NOW_DESCRIPTION"
  | "NEED_MORE_POINTS"
  | "VALID_FROM_ERROR"
  | "VALID_TO_ERROR"
  | "LOCKED_VOUCHER"
  | "LOCKED_USER"
  | "ONLY_REGISTERED"
  | "USER_IS_NOT_REGISTERED_TO_BURN_FOR_REVENUE"
  | "USER_DOES_NOT_HAVE_ENOUGH_POINTS_FOR_REVENUE_BURN"
  | "BON_ID_ALREADY_EXISTS"
  | "REVENUE_ALERT_TITLE"
  | "REVENUE_ALERT_MESSAGE"
  | "REVENUE_CHECK_OUT"
  | "STANDARD_PRODUCT_NAME"
  | "PAY_WITH_POINT_NAME"
  | "CONFIRM_EARN_TRANSACTION_TITLE"
  | "CONFIRM_BURN_TRANSACTION_TITLE"
  | "CALCULATOR_TITLE"
  | "REVENUES"
  | "SUMMARY"
  | "ITEMS"
  | "NO_COUPONS"
  | "DEACTIVE_COUPON"
  | "ACTIVE_COUPON"
  | "MULTIPLIER"
  | "EXTRAPOINT"
  | "DISCOUNT"
  | "CALCULATOR_TOTAL"
  | "CALCULATOR_ADD"
  | "ACTIVATED"
  | "DISABLED"
  | "NOT_ENOUGH_POINT"
  | "REVENUE_STEP1_EARN_1"
  | "REVENUE_STEP1_EARN_2"
  | "REVENUE_STEP1_BURN_1"
  | "REVENUE_STEP1_BURN_2"
  | "REVENUE_STEP2"
  | "MOUNTING_NOT_FOUND"
  | "MOUNTING_IN_USE"
  | "GROUPS"
  | "SUBGROUPS"
  | "ALL_GROUPS"
  | "EMPTY_GROUPS"
  | "EMPTY_SUBGROUPS"
  | "EMPTY_ITEMS"
  | "BACK"
  | "CHANGE"
  | "RESET_REVENUE_CART_TITLE"
  | "RESET_REVENUE_CART_MESSAGE"
  | "EARN_BURN"
  | "BURN"
  | "ANONYMOUS_USER"
  | "ANONYMOUS_USER_NOT_ALLOWED";

export type ILanguage = "de" | "en" | "es" | "it" | "ru";

export const LANGUAGE = {
  en: { ...(<any>en).default, ...(<any>enOrganization).default },
  de: { ...(<any>de).default, ...(<any>deOrganization).default },
};
