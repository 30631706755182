/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomPropertyData { 
    description?: string;
    domain: CustomPropertyData.DomainEnum;
    mandatory?: boolean;
    name: string;
    valueType: CustomPropertyData.ValueTypeEnum;
    values: Array<string>;
}
export namespace CustomPropertyData {
    export type DomainEnum = 'COUPON' | 'PROGRAM' | 'PARTNER' | 'STORE' | 'USER';
    export const DomainEnum = {
        COUPON: 'COUPON' as DomainEnum,
        PROGRAM: 'PROGRAM' as DomainEnum,
        PARTNER: 'PARTNER' as DomainEnum,
        STORE: 'STORE' as DomainEnum,
        USER: 'USER' as DomainEnum
    };
    export type ValueTypeEnum = 'LIST' | 'TEXT' | 'NUMBER' | 'LONGTEXT';
    export const ValueTypeEnum = {
        LIST: 'LIST' as ValueTypeEnum,
        TEXT: 'TEXT' as ValueTypeEnum,
        NUMBER: 'NUMBER' as ValueTypeEnum,
        LONGTEXT: 'LONGTEXT' as ValueTypeEnum
    };
}


