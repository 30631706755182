import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminService } from './api/admin.service';
import { CustomPropertyService } from './api/custom-property.service';
import { EndpointService } from './api/endpoint.service';
import { HealthControllerService } from './api/health-controller.service';
import { InteractionPointService } from './api/interaction-point.service';
import { PartnerService } from './api/partner.service';
import { ProductCatalogService } from './api/product-catalog.service';
import { ProgramService } from './api/program.service';
import { RatingConfigService } from './api/rating-config.service';
import { StatusLevelService } from './api/status-level.service';
import { StoreService } from './api/store.service';
import { SystemNotificationService } from './api/system-notification.service';
import { TagService } from './api/tag.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
