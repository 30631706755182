import { Injectable } from "@angular/core";
import { UrlTree, Router, NavigationEnd, Event } from "@angular/router";
import { NavController } from "@ionic/angular";
import { NavigationOptions } from "@ionic/angular/dist/providers/nav-controller";
import { SharedRoutes } from "shared/lib/common/enums";
import { IObjectLiteral, Merge } from "shared/lib/common/interfaces";
import * as qs from "querystring";
import { CONFIGURATION } from "shared/lib/common/constants";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";

@Injectable({ providedIn: "root" })
export class RoutingService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(private navCtrl: NavController, private router: Router, private config: ConfigService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public async goBack<QueryParams>(
    url: string[] | UrlTree | any[],
    options?: Merge<NavigationOptions, { queryParams?: QueryParams }>,
  ): Promise<void> {
    this.navCtrl.navigateBack(CONFIGURATION.is_custom_app ? url : [this.config.getOrganization(), ...url], options);
  }

  public async goForward<QueryParams>(
    url: SharedRoutes[] | string[],
    options: Merge<NavigationOptions, { relative?: "parent" | "current"; queryParams?: QueryParams }> = { queryParams: null },
  ): Promise<boolean> {
    const { relative, ...restOptions } = options;

    switch (relative) {
      case "parent":
        url = [...this.getUrlSegments().slice(0, -1), ...url];
        break;
      case "current":
        url = [...this.getUrlSegments(), ...url];
        break;
      default:
        if (!CONFIGURATION.is_custom_app) {
          url = [this.config.getOrganization(), ...url];
        }
    }

    return this.navCtrl.navigateForward(url, restOptions);
  }

  public async goRoot<QueryParams>(
    url: string[] | UrlTree | any[],
    options?: Merge<NavigationOptions, { queryParams?: QueryParams }>,
  ): Promise<void> {
    this.navCtrl.navigateRoot(CONFIGURATION.is_custom_app ? url : [this.config.getOrganization(), ...url], options);
  }

  public getUrlParams<T>(): T {
    return { ...this.router.routerState.snapshot.root.queryParams } as T;
  }

  public getUrlSegments(): string[] {
    const primary = this.router.parseUrl(this.router.url).root.children.primary;
    return primary ? primary.segments.map(({ path }) => path) : [];
  }

  public getParentHref(): string {
    const parts = this.getUrlSegments();
    return parts.slice(0, -1).join("/");
  }

  public isActiveUrl(url: string): boolean {
    const parts = this.getUrlSegments();
    return CONFIGURATION.is_custom_app ? parts.length === 1 && url === parts[0] : parts.length === 2 && url === parts[1];
  }

  public parseUrl(url: string): { segments: SharedRoutes[]; queryParams: { [key: string]: string } } {
    const [path, query] = url.split("?");
    const segments = path.split("/") as SharedRoutes[];
    let key: string = null;
    let value: string = null;

    if (query) [key, value] = query.split("=");
    return {
      segments,
      queryParams: query && { [key]: value },
    };
  }

  public createUrl(segments: SharedRoutes[], queryParams?: IObjectLiteral): string {
    return `${segments.join("/")}${queryParams ? "?" + qs.stringify(queryParams) : ""}`;
  }

  public resetConfig(): void {
    this.router.resetConfig(this.router.config);
  }

  public getRouterEvents(): Observable<Event> {
    return this.router.events;
  }
}
