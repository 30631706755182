import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IVoucher } from "shared/lib/common/interfaces";
import { TranslateService, UtilsService } from "shared/lib/common/services";

@Component({
  selector: "ocm-coupon-card",
  templateUrl: "./coupon-card.component.html",
  styleUrls: ["./coupon-card.component.scss"],
})
export class CouponCardComponent {
  @Input() public ticketSelected: IVoucher;
  @Input() public disabled: boolean;
  @Output() public RedeemEvent: EventEmitter<IVoucher> = new EventEmitter();

  constructor(public utils: UtilsService, public translate: TranslateService) {}

  public async redeem(): Promise<void> {
    this.RedeemEvent.emit(this.ticketSelected);
  }
}
