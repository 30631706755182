var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { CONFIGURATION } from "shared/lib/common/constants";
import { ConfigService } from "../config/config.service";
import { Platform } from "@ionic/angular";
import { EnvironmentService } from "../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "@ionic/angular";
import * as i3 from "../environment/environment.service";
var SENTRY_DSN = "https://6c69381642e9499cb7cb8aa23b1d5aa9@sentry.io/1364464";
var SentryService = /** @class */ (function (_super) {
    __extends(SentryService, _super);
    function SentryService(config, platform, environment) {
        var _this = _super.call(this) || this;
        _this.config = config;
        _this.platform = platform;
        _this.environment = environment;
        Sentry.init({
            release: (_this.platform.is("capacitor") ? "ios" : "web") + "-point4more-pos-app@" + _this.environment.getVariable("RELEASE"),
            dsn: SENTRY_DSN,
            environment: _this.environment.getVariable("BUILD_ENV"),
            attachStacktrace: true,
        });
        Sentry.configureScope(function (scope) {
            scope.setTag("app", _this.config.getOrganization());
            scope.setTag("api-version", CONFIGURATION.p4m_api.login ? CONFIGURATION.p4m_api.login : CONFIGURATION.p4m_api.default);
        });
        return _this;
    }
    SentryService.prototype.handleError = function (error) {
        _super.prototype.handleError.call(this, error);
        try {
            Sentry.captureException(error.originalError || error);
        }
        catch (e) {
            console.error(e);
        }
    };
    SentryService.prototype.info = function (data, level) {
        if (level === void 0) { level = Sentry.Severity.Info; }
        Sentry.captureMessage(JSON.stringify(data), level);
    };
    SentryService.prototype.breadcrumb = function (message, type, level, data) {
        if (message === void 0) { message = ""; }
        if (type === void 0) { type = "navigation"; }
        if (level === void 0) { level = Sentry.Severity.Log; }
        if (data === void 0) { data = {}; }
        Sentry.addBreadcrumb({ message: message, level: level, type: type, data: data });
    };
    SentryService.prototype.addTag = function (key, value) {
        Sentry.configureScope(function (scope) {
            scope.setTag(key, value);
        });
    };
    SentryService.prototype.removeTag = function (key) {
        Sentry.configureScope(function (scope) {
            scope.setTag(key, null);
        });
    };
    SentryService.ngInjectableDef = i0.defineInjectable({ factory: function SentryService_Factory() { return new SentryService(i0.inject(i1.ConfigService), i0.inject(i2.Platform), i0.inject(i3.EnvironmentService)); }, token: SentryService, providedIn: "root" });
    return SentryService;
}(ErrorHandler));
export { SentryService };
