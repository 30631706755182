var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as JSZip from "jszip";
import { CONFIGURATION } from "shared/lib/common/constants";
import { LOCAL_STORAGE, VERSION } from "shared/lib/common/enums";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../utils/utils.service";
import * as i3 from "../translate/translate.service";
var ConfigService = /** @class */ (function () {
    function ConfigService(document, utils, translate) {
        this.document = document;
        this.utils = utils;
        this.translate = translate;
        this.organization = "";
    }
    ConfigService.prototype.setOrganization = function (organization) {
        this.organization = organization;
    };
    ConfigService.prototype.getOrganization = function () {
        return this.organization;
    };
    ConfigService.prototype.setConfig = function (programConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var config, clientId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!programConfig) return [3 /*break*/, 1];
                        this.applyProgramConfig(programConfig);
                        return [3 /*break*/, 8];
                    case 1:
                        config = void 0;
                        clientId = this.getMountingConfig().clientId;
                        if (!(clientId && clientId !== "1234")) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.getZipConfig(clientId)];
                    case 2:
                        config = _a.sent();
                        if (!!config.ok) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getZipConfig(this.organization)];
                    case 3:
                        config = _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, this.getZipConfig(this.organization)];
                    case 6:
                        config = _a.sent();
                        _a.label = 7;
                    case 7:
                        if (config.ok === true)
                            this.applyConfig(config.response);
                        else
                            this.applyConfig(CONFIGURATION);
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ConfigService.prototype.setMountingConfig = function (config) {
        localStorage.setItem(LOCAL_STORAGE.MOUNTING_CONFIG, this.utils.encrypt(JSON.stringify(config)));
    };
    ConfigService.prototype.getMountingConfig = function () {
        var mountingConfig = {
            workstationId: null,
            deviceId: null,
            cameraPosition: null,
            clientId: null,
            clientName: null,
            deviceKey: null,
            posMode: null,
            storeId: null,
            storeName: null,
            deviceInformation: null,
            partner: null,
            store: null,
            legalName: null,
            appInteractionId: null,
            cashInteractionId: null,
        };
        try {
            return JSON.parse(this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG))) || mountingConfig;
        }
        catch (error) {
            return mountingConfig;
        }
    };
    ConfigService.prototype.havePrinter = function () {
        return this.getMountingConfig().posMode ? this.getMountingConfig().posMode === "SER" : false;
    };
    ConfigService.prototype.haveBurnPage = function () {
        return this.getMountingConfig().posMode ? this.getMountingConfig().posMode !== "CAS" : false;
    };
    ConfigService.prototype.setAllowedManualPoints = function (allowed) {
        localStorage.setItem(LOCAL_STORAGE.MANUAL_ALLOWED, this.utils.encrypt(JSON.stringify(allowed)));
    };
    ConfigService.prototype.getAllowedManualPoints = function () {
        try {
            return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MANUAL_ALLOWED)) === "true";
        }
        catch (error) {
            return false;
        }
    };
    ConfigService.prototype.setPrinterLogo = function (src) {
        this.printerLogo = src;
    };
    ConfigService.prototype.getPrinterLogo = function () {
        return this.printerLogo;
    };
    ConfigService.prototype.getVersion = function (request) {
        return this.version ? this.version[request] || this.version.default : VERSION.v1;
    };
    ConfigService.prototype.getZipConfig = function (configName) {
        var _this = this;
        return new Promise(function (resolve) {
            if (window.JSZipUtils) {
                window.JSZipUtils.getBinaryContent("/assets/" + configName + ".zip", function (err, response) { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!err) return [3 /*break*/, 1];
                                resolve({ ok: false, error: { message: err } });
                                return [3 /*break*/, 3];
                            case 1:
                                _a = resolve;
                                return [4 /*yield*/, this.parseZipData(response)];
                            case 2:
                                _a.apply(void 0, [_b.sent()]);
                                _b.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            }
            else {
                resolve({ ok: false, error: { message: "UNHANDLED_ERROR" } });
            }
        });
    };
    ConfigService.prototype.parseZipData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, config, zip, _b, _c, filename, _d, name_1, type, _e, _f, _g, _h, _j, _k, configData, _l, _m, e_1_1, error_1;
            return __generator(this, function (_o) {
                switch (_o.label) {
                    case 0:
                        _o.trys.push([0, 17, , 18]);
                        config = CONFIGURATION;
                        return [4 /*yield*/, JSZip.loadAsync(data)];
                    case 1:
                        zip = _o.sent();
                        _o.label = 2;
                    case 2:
                        _o.trys.push([2, 14, 15, 16]);
                        _b = __values(Object.keys(zip.files)), _c = _b.next();
                        _o.label = 3;
                    case 3:
                        if (!!_c.done) return [3 /*break*/, 13];
                        filename = _c.value;
                        _d = __read(filename.split("."), 2), name_1 = _d[0], type = _d[1];
                        _e = name_1;
                        switch (_e) {
                            case "logo": return [3 /*break*/, 4];
                            case this.organization + "/logo": return [3 /*break*/, 4];
                            case "config": return [3 /*break*/, 10];
                            case this.organization + "/config": return [3 /*break*/, 10];
                        }
                        return [3 /*break*/, 12];
                    case 4:
                        _f = type;
                        switch (_f) {
                            case "svg": return [3 /*break*/, 5];
                            case "png": return [3 /*break*/, 7];
                        }
                        return [3 /*break*/, 9];
                    case 5:
                        _g = config;
                        _h = "data:image/svg+xml;base64,";
                        return [4 /*yield*/, zip.files[filename].async("base64")];
                    case 6:
                        _g.logo_url = _h + (_o.sent());
                        return [3 /*break*/, 9];
                    case 7:
                        _j = config;
                        _k = "data:image/png;base64,";
                        return [4 /*yield*/, zip.files[filename].async("base64")];
                    case 8:
                        _j.logo_url = _k + (_o.sent());
                        return [3 /*break*/, 9];
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        _m = (_l = JSON).parse;
                        return [4 /*yield*/, zip.files[filename].async("text")];
                    case 11:
                        configData = _m.apply(_l, [_o.sent()]);
                        config = __assign({}, config, configData);
                        _o.label = 12;
                    case 12:
                        _c = _b.next();
                        return [3 /*break*/, 3];
                    case 13: return [3 /*break*/, 16];
                    case 14:
                        e_1_1 = _o.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 16];
                    case 15:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 16: return [2 /*return*/, { ok: true, response: config }];
                    case 17:
                        error_1 = _o.sent();
                        return [2 /*return*/, { ok: false, error: { message: error_1 } }];
                    case 18: return [2 /*return*/];
                }
            });
        });
    };
    ConfigService.prototype.applyConfig = function (config) {
        this.translate.setTranslations(config.translations);
        this.logo = config.logo_url;
        this.registerFormUrl = config.register_form;
        this.screenSaver = config.screen_saver;
        this.version = config.p4m_api;
        this.showSelfServiceButton = config.showSelfServiceButton;
        this.inactivityTime = config.inactivity_time;
        for (var key in config.theme) {
            if (config.theme.hasOwnProperty(key)) {
                this.document.documentElement.style.setProperty(key, config.theme[key]);
            }
        }
    };
    ConfigService.prototype.applyProgramConfig = function (programConfig) {
        this.translate.setTranslations(programConfig.posapp.translations);
        this.logo = programConfig.logo;
        this.screenSaver = programConfig.posapp.screenSaver;
        this.version = programConfig.posapp.p4mApi;
        this.showSelfServiceButton = programConfig.posapp.showSelfServiceButton;
        this.inactivityTime = programConfig.posapp.inactivityTime;
        if (programConfig.theme && programConfig.theme.colors)
            this.applyTheme(programConfig.theme.colors);
    };
    ConfigService.prototype.applyTheme = function (colors) {
        var _this = this;
        this.setVariables("dark", colors.dark);
        this.setVariables("light", colors.light);
        Object.entries(colors).forEach(function (_a) {
            var _b = __read(_a, 2), name = _b[0], value = _b[1];
            if (!value)
                return;
            switch (name) {
                case "primary":
                case "secondary":
                case "tertiary":
                case "success":
                case "warning":
                case "danger":
                case "medium":
                case "background":
                case "banner":
                case "border":
                case "dark":
                case "light":
                    _this.setVariables(name, value);
                    break;
                case "primaryText":
                    _this.setVariables("text", value);
                    break;
            }
        });
    };
    ConfigService.prototype.setVariables = function (name, color) {
        this.document.documentElement.style.setProperty("--color-" + name, color);
        var colorShade = this.getShadeColor(color, -20);
        this.document.documentElement.style.setProperty("--color-" + name + "-shade", colorShade);
        var colorTint = this.getShadeColor(color, 20);
        this.document.documentElement.style.setProperty("--color-" + name + "-tint", colorTint);
        var colorRgb = this.hexToRgb(color);
        this.document.documentElement.style.setProperty("--color-" + name + "-rgb", colorRgb);
        var colorShadeRgb = this.hexToRgb(colorShade);
        this.document.documentElement.style.setProperty("--color-" + name + "-shade-rgb", colorShadeRgb);
        var colorTintRgb = this.hexToRgb(colorTint);
        this.document.documentElement.style.setProperty("--color-" + name + "-tint-rgb", colorTintRgb);
        var colorDark = this.document.documentElement.style.getPropertyValue("--color-dark") || "#000000";
        var colorLight = this.document.documentElement.style.getPropertyValue("--color-light") || "#ffffff";
        var colorContrast;
        if (this.lightOrDark(color) === "dark")
            colorContrast = colorLight;
        else
            colorContrast = colorDark;
        this.document.documentElement.style.setProperty("--color-" + name + "-contrast", colorContrast);
        var colorContrastRgb = this.hexToRgb(colorContrast);
        this.document.documentElement.style.setProperty("--color-" + name + "-contrast-rgb", colorContrastRgb);
    };
    ConfigService.prototype.getShadeColor = function (color, percent) {
        var usePound = false;
        if (color[0] === "#") {
            color = color.slice(1);
            usePound = true;
        }
        var R = parseInt(color.substring(0, 2), 16);
        var G = parseInt(color.substring(2, 4), 16);
        var B = parseInt(color.substring(4, 6), 16);
        R = R + percent;
        G = G + percent;
        B = B + percent;
        if (R > 255)
            R = 255;
        else if (R < 0)
            R = 0;
        if (G > 255)
            G = 255;
        else if (G < 0)
            G = 0;
        if (B > 255)
            B = 255;
        else if (B < 0)
            B = 0;
        var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
        var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
        var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);
        return (usePound ? "#" : "") + RR + GG + BB;
    };
    ConfigService.prototype.hexToRgb = function (color) {
        if (color[0] === "#")
            color = color.slice(1);
        var R = parseInt(color.substring(0, 2), 16);
        var G = parseInt(color.substring(2, 4), 16);
        var B = parseInt(color.substring(4, 6), 16);
        return R + ", " + G + ", " + B;
    };
    ConfigService.prototype.lightOrDark = function (color) {
        if (color[0] === "#")
            color = color.slice(1);
        var R = parseInt(color.substring(0, 2), 16);
        var G = parseInt(color.substring(2, 4), 16);
        var B = parseInt(color.substring(4, 6), 16);
        // HSP (Highly Sensitive Poo)
        var hsp = Math.sqrt(0.299 * (R * R) + 0.587 * (G * G) + 0.114 * (B * B));
        if (hsp > 127.5)
            return "light";
        else
            return "dark";
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.DOCUMENT), i0.inject(i2.UtilsService), i0.inject(i3.TranslateService)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
