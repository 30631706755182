var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { ModalController } from "@ionic/angular";
import { COUNTRIES } from "shared/lib/common/constants";
import { AdminService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
var RegisterPage = /** @class */ (function () {
    function RegisterPage(t, modal, fb, admin, toast, config, user) {
        this.t = t;
        this.modal = modal;
        this.fb = fb;
        this.admin = admin;
        this.toast = toast;
        this.config = config;
        this.user = user;
        this.countries = [];
    }
    RegisterPage.prototype.ngOnInit = function () {
        this.isLoading = false;
        this.countries = COUNTRIES;
        this.client = this.config.getMountingConfig().legalName;
        var SimForm = {
            emailAddress: ["", [Validators.required, Validators.email]],
            declarationOfConsent: [false],
            acceptConsent: [false],
        };
        var ExtForm = __assign({}, SimForm, { givenName: ["", Validators.required], familyName: ["", Validators.required], streetHouseNo: ["", Validators.required], zipCode: ["", Validators.required], city: ["", Validators.required], countryCode: ["", Validators.required], genderCode: ["", Validators.required], birthdate: ["", Validators.required], phoneNumber: [""] });
        var SimExtForm = __assign({}, SimForm, { givenName: [""], familyName: [""], streetHouseNo: [""], zipCode: [""], city: [""], countryCode: [""], genderCode: [""], birthdate: [""], phoneNumber: [""] });
        switch (this.registrationType) {
            case "SIMEXT":
                this.form = this.fb.group(SimExtForm);
                break;
            case "EXT":
                this.form = this.fb.group(ExtForm);
                break;
            default:
                this.form = this.fb.group(SimForm);
        }
    };
    RegisterPage.prototype.register = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, resp, details;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        if (!this.form.valid) return [3 /*break*/, 4];
                        data = {
                            type: this.registrationType,
                            simple: __assign({}, this.form.value, { clientOptins: [
                                    {
                                        clientId: this.config.getMountingConfig().clientId,
                                        emailOptin: this.form.get("declarationOfConsent").value,
                                        dataOptin: this.form.get("acceptConsent").value,
                                    },
                                ], keyCode: this.keyCode, language: this.t.getSessionLanguage(), channelCode: "A", clientId: this.config.getMountingConfig().clientId }),
                            extended: __assign({}, this.form.value, { userReferenceCode: this.userReferenceCode, deviceKey: this.config.getMountingConfig().deviceKey }),
                        };
                        return [4 /*yield*/, this.admin.register(data)];
                    case 1:
                        resp = _a.sent();
                        if (!(resp.ok === true)) return [3 /*break*/, 3];
                        this.form.reset();
                        return [4 /*yield*/, this.user.getDetails(this.keyCode)];
                    case 2:
                        details = _a.sent();
                        if (details.ok === true)
                            this.user.setUser(details.response);
                        else
                            this.toast.show(this.t._(details.error.message), "error");
                        this.dismiss("SUCCESS");
                        return [3 /*break*/, 4];
                    case 3:
                        if (resp.error.message === "REGISTRATION_USER_EXISTS_NOT_ACTIVATED") {
                            this.form.reset();
                            this.dismiss("SUCCESS");
                        }
                        this.toast.show(resp.error.message, "error");
                        _a.label = 4;
                    case 4:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterPage.prototype.dismiss = function (state) {
        this.modal.dismiss(state);
    };
    RegisterPage.prototype.open = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var Browser;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Browser = Plugins.Browser;
                        return [4 /*yield*/, Browser.open({ url: url })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterPage.prototype.buildName = function (literal, isAlwaysRequired) {
        if (isAlwaysRequired === void 0) { isAlwaysRequired = false; }
        return "" + this.t._(literal) + (this.registrationType === "EXT" || isAlwaysRequired ? " *" : "");
    };
    RegisterPage.prototype.isEnable = function () {
        return this.registrationType === "EXT" || this.registrationType === "SIMEXT";
    };
    return RegisterPage;
}());
export { RegisterPage };
