import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { IIdentifierType } from "shared/lib/common/interfaces";
import { TranslateService, P4mService, ConfigService, RoutingService } from "shared/lib/common/services";
import { AdminService } from "shared/lib/v2/services";

export interface ISuccessReedemComponentParams {
  keyCode: string;
  idType: IIdentifierType;
  points: number;
  title: string;
}

@Component({
  selector: "ocm-success-redeem",
  templateUrl: "./success-redeem.page.html",
  styleUrls: ["./success-redeem.page.scss"],
})
export class SuccessRedeemPage implements OnInit {
  @Input() public keyCode: string;
  @Input() public idType: IIdentifierType;
  @Input() public points: number;
  @Input() public title: string;

  public totalPoints: number;
  public loading: boolean;

  constructor(
    private routing: RoutingService,
    private modal: ModalController,
    private p4m: P4mService,
    private admin: AdminService,
    private config: ConfigService,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.loading = true;
  }

  public async ionViewDidEnter(): Promise<void> {
    const resp = await this.p4m.readAccountBalance({
      keyCode: this.keyCode,
      idType: this.idType,
      apiKey: this.admin.getApiKey(),
      clientId: this.config.getMountingConfig().clientId,
    });
    if (resp.ok === true) this.totalPoints = resp.response.points;
    this.loading = false;
  }

  public async dismiss(): Promise<void> {
    await this.modal.dismiss();
    this.routing.goForward([SharedRoutes.screenSaver]);
  }
}
