<ion-list class="history-list">
  <ion-item class="history-list__item">
    <div class="history-list__item__activity">
      {{ translate._("ACTIVITIES") }}
    </div>
    <div class="history-list__item__points">{{ translate._("P") }}</div>
  </ion-item>
  <ion-item class="history-list__item" *ngFor="let transaction of transactions | orderBy: 'transactionDateTime unixTimeStamp'">
    <div class="history-list__item__activity">
      <span [innerHTML]="transaction?.description"></span>
      <span class="history-list__item__activity__date">{{ transaction?.transactionDateTime?.unixTimeStamp | date: "dd.MM.yy HH:mm" }}</span>
    </div>
    <div class="history-list__item__points">{{ transaction?.points | number: "":translate.getSessionLanguage() }}</div>
  </ion-item>
</ion-list>
