/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i6 from "angular-feather";
import * as i7 from "./success.page";
import * as i8 from "../../services/routing/routing.service";
import * as i9 from "../../services/p4m/p4m.service";
import * as i10 from "../../services/admin/admin.service";
import * as i11 from "../../services/config/config.service";
import * as i12 from "../../services/utils/utils.service";
import * as i13 from "../../services/translate/translate.service";
var styles_SuccessPage = [i0.styles];
var RenderType_SuccessPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessPage, data: {} });
export { RenderType_SuccessPage as RenderType_SuccessPage };
function View_SuccessPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "success__content__total-points"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 3), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "success__content__total-points__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.totalPoints, "", _co.translate.getSessionLanguage())); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.translate._("NEW_BALANCE"); _ck(_v, 4, 0, currVal_1); }); }
function View_SuccessPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [["class", "success__content__loading"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], null, null); }
export function View_SuccessPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 18, "ion-content", [["class", "modal--inner success page-wrapper"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 16, "div", [["class", "success__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "modal--title success__content__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i-check-circle", [["class", "success__content__check"]], null, null, null, i5.View_ɵbr_0, i5.RenderType_ɵbr)), i1.ɵdid(7, 49152, null, 0, i6.ɵbr, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "success__content__points"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 3), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "success__content__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuccessPage_1)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuccessPage_2)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "ion-button", [["class", "button button--primary success__content__button"], ["expand", "block"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.success() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(18, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(19, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.loading; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 16, 0, currVal_4); var currVal_5 = "block"; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.points, "", _co.translate.getSessionLanguage())); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.translate._("POINTS"); _ck(_v, 12, 0, currVal_2); var currVal_6 = _co.translate._("CLOSE"); _ck(_v, 19, 0, currVal_6); }); }
export function View_SuccessPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-success", [], null, null, null, View_SuccessPage_0, RenderType_SuccessPage)), i1.ɵdid(1, 245760, null, 0, i7.SuccessPage, [i8.RoutingService, i3.ModalController, i9.P4mService, i10.AdminService, i11.ConfigService, i12.UtilsService, i13.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuccessPageNgFactory = i1.ɵccf("shared-success", i7.SuccessPage, View_SuccessPage_Host_0, { keyCode: "keyCode", idType: "idType", points: "points", title: "title" }, {}, []);
export { SuccessPageNgFactory as SuccessPageNgFactory };
