var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { environment } from "shared/lib/common/environments/environment";
import { environmentVariables, org } from "shared/lib/common/environments/environment.constant";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
var EnvironmentService = /** @class */ (function () {
    function EnvironmentService(config) {
        this.config = config;
    }
    EnvironmentService.prototype.getVariable = function (variable) {
        var organization = this.config.getOrganization();
        var variables = __assign({}, environment, environmentVariables[environment.BUILD_ENV + "_" + organization]);
        return organization === "pfm" ? variables[variable].replace(org, "") : variables[variable].replace(org, organization + ".");
    };
    EnvironmentService.ngInjectableDef = i0.defineInjectable({ factory: function EnvironmentService_Factory() { return new EnvironmentService(i0.inject(i1.ConfigService)); }, token: EnvironmentService, providedIn: "root" });
    return EnvironmentService;
}());
export { EnvironmentService };
