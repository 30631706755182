var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Configuration } from "shared/lib/v2/apis/mounting-service";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "shared/lib/common/services";
import { MountingsService as SharedMountingsService } from "shared/lib/v2/apis/mounting-service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../apis/mounting-service/variables";
import * as i3 from "../../apis/mounting-service/configuration";
import * as i4 from "../../../common/services/environment/environment.service";
var MountingsService = /** @class */ (function (_super) {
    __extends(MountingsService, _super);
    function MountingsService(httpClient, basePath, configuration, environmentService) {
        var _this = _super.call(this, httpClient, environmentService.getVariable("API_V2"), configuration) || this;
        _this.httpClient = httpClient;
        _this.environmentService = environmentService;
        return _this;
    }
    MountingsService.ngInjectableDef = i0.defineInjectable({ factory: function MountingsService_Factory() { return new MountingsService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8), i0.inject(i4.EnvironmentService)); }, token: MountingsService, providedIn: "root" });
    return MountingsService;
}(SharedMountingsService));
export { MountingsService };
