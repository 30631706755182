<div class="burn__nav">
  <ion-button class="burn__nav__button" [class.--active]="burnActive" (tap)="goToBurn()">
    {{ translate._("VORTEILE") }}
  </ion-button>
  <ion-button class="burn__nav__button" [class.--active]="historyActive" (tap)="goToHistory()">
    {{ translate._("PUNKTEHISTORIE") }}
  </ion-button>
</div>

<div class="burn__status">
  <span class="burn__status__title">{{ translate._(status?.statusName) }} {{ translate._("STATUS") }} | </span>
  <span class="burn__status__points">{{ totalPoints | number: "":translate.getSessionLanguage() }} P</span>
</div>

<div class="burn__wrapper">
  <ion-slides class="burn__wrapper__slides" (ionSlideWillChange)="activeSlide($event)">
    <ion-slide class="burn__wrapper__slides__slide-burn">
      <ocm-burn
        [mobileVersion]="mobileVersion"
        [ticketSelected]="ticketSelected"
        [tickets]="tickets"
        [loading]="loading"
        (askToRedeemEvent)="askToRedeem($event)"
        (selectTicketEvent)="selectTicket($event)"
      >
      </ocm-burn>
    </ion-slide>
    <ion-slide class="burn__wrapper__slides__slide-history">
      <ocm-history [transactions]="transactions" [totalEarn]="totalEarn" [totalRedeem]="totalRedeem" [loading]="loading"></ocm-history>
    </ion-slide>
  </ion-slides>
</div>
<shared-loading *ngIf="redeemLoading"></shared-loading>
