var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { ScreenSaverPage as SharedScreenSaverPage } from "shared/lib/common/pages";
import { ConfigService, RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { AdminService } from "shared/lib/v2/services";
var ScreenSaverPage = /** @class */ (function (_super) {
    __extends(ScreenSaverPage, _super);
    function ScreenSaverPage(_user, _translate, _config, _admin, _utils, _routing) {
        var _this = _super.call(this, _user, _translate, _config, _admin, _utils, _routing) || this;
        _this._user = _user;
        _this._translate = _translate;
        _this._config = _config;
        _this._admin = _admin;
        _this._utils = _utils;
        _this._routing = _routing;
        return _this;
    }
    ScreenSaverPage.prototype.ngOnInit = function () {
        this.images = [];
        this.initSlide();
        this.background = "assets/screen-saver/screen-saver.jpg";
        this.actualImage = "assets/screen-saver/screen-saver.jpg";
    };
    return ScreenSaverPage;
}(SharedScreenSaverPage));
export { ScreenSaverPage };
