/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i6 from "../../components/coupon/coupon.component.ngfactory";
import * as i7 from "../../components/coupon/coupon.component";
import * as i8 from "../../../../../../shared/lib/common/services/translate/translate.service";
import * as i9 from "./confirmation.page";
var styles_ConfirmationPage = [i0.styles];
var RenderType_ConfirmationPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationPage, data: {} });
export { RenderType_ConfirmationPage as RenderType_ConfirmationPage };
export function View_ConfirmationPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 13, "div", [["class", "confirmation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.cancelation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(4, 114688, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "confirmation__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ocm-coupon", [["class", "confirmation__voucher"]], null, null, null, i6.View_CouponComponent_0, i6.RenderType_CouponComponent)), i1.ɵdid(8, 49152, null, 0, i7.CouponComponent, [i8.TranslateService], { ticket: [0, "ticket"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "confirmation__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ion-button", [["class", "confirmation__buttons__confirm"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.confirmation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(12, 0, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ion-button", [["class", "confirmation__buttons__dismiss"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.cancelation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(14, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(15, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_1 = ((_co.params == null) ? null : _co.params.voucher); _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params == null) ? null : _co.params.title); _ck(_v, 6, 0, currVal_0); var currVal_2 = ((_co.params == null) ? null : _co.params.confirmText); _ck(_v, 12, 0, currVal_2); var currVal_3 = ((_co.params == null) ? null : _co.params.dismissText); _ck(_v, 15, 0, currVal_3); }); }
export function View_ConfirmationPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-confirmation", [], null, null, null, View_ConfirmationPage_0, RenderType_ConfirmationPage)), i1.ɵdid(1, 114688, null, 0, i9.ConfirmationPage, [i3.NavParams, i3.ModalController, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationPageNgFactory = i1.ɵccf("ocm-confirmation", i9.ConfirmationPage, View_ConfirmationPage_Host_0, {}, {}, []);
export { ConfirmationPageNgFactory as ConfirmationPageNgFactory };
