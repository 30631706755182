/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i6 from "@angular/common";
import * as i7 from "./language.page";
var styles_LanguagePage = [i0.styles];
var RenderType_LanguagePage = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguagePage, data: {} });
export { RenderType_LanguagePage as RenderType_LanguagePage };
function View_LanguagePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-item", [["class", "language-modal-list__item"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, 0, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "language-modal-list__item__flag --", _v.context.$implicit.key, "-flag"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_LanguagePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(3, 114688, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-list", [["class", "language-modal-list"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(5, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LanguagePage_1)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.params.languages; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_LanguagePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-language", [], null, null, null, View_LanguagePage_0, RenderType_LanguagePage)), i1.ɵdid(1, 114688, null, 0, i7.LanguagePage, [i3.NavParams, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguagePageNgFactory = i1.ɵccf("ocm-language", i7.LanguagePage, View_LanguagePage_Host_0, {}, {}, []);
export { LanguagePageNgFactory as LanguagePageNgFactory };
