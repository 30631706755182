var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { AlertController, ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, P4mService, PosConnectionService, PrintService, ToastService, TranslateService, UtilsService, ConfigService, RoutingService, EnvironmentService, } from "shared/lib/common/services";
import { EnsureUnmountLoginModal } from "shared/lib/common/pages";
var ConfigurationsPage = /** @class */ (function () {
    function ConfigurationsPage(environment, modal, utils, p4m, fb, toast, routing, translate, print, admin, posConnectionService, alert, config) {
        this.environment = environment;
        this.modal = modal;
        this.utils = utils;
        this.p4m = p4m;
        this.fb = fb;
        this.toast = toast;
        this.routing = routing;
        this.translate = translate;
        this.print = print;
        this.admin = admin;
        this.posConnectionService = posConnectionService;
        this.alert = alert;
        this.config = config;
    }
    ConfigurationsPage.prototype.ngOnInit = function () {
        this.loading = false;
        this.form = this.fb.group({
            ip: [
                this.print.getIp(),
                [
                    Validators.required,
                    Validators.pattern(/\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/),
                ],
            ],
        });
    };
    ConfigurationsPage.prototype.back = function () {
        this.routing.goForward([SharedRoutes.screenSaver]);
    };
    ConfigurationsPage.prototype.testConnection = function (ip) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.print.testConnection(ip)];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            this.print.setIp(ip);
                            this.toast.show(this.translate._("PRINTER_CONNECTED"), "info");
                        }
                        else {
                            this.toast.show(this.translate._(response.error.message), "error");
                        }
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationsPage.prototype.removeIp = function () {
        this.form.reset();
        this.print.removeIp();
    };
    ConfigurationsPage.prototype.logOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.unmmountLoading = true;
                        return [4 /*yield*/, this.presentLogin()];
                    case 1:
                        data = (_a.sent()).data;
                        if (!data) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.admin.unMountDevice()];
                    case 2:
                        resp = _a.sent();
                        if (resp.ok === false)
                            this.toast.show(this.translate._(resp.error.message), "error");
                        this.admin.logout();
                        this.posConnectionService.closeSocketConnection();
                        this.routing.resetConfig();
                        this.p4m.setUser(data.email);
                        this.p4m.setPassword(data.password);
                        window.location.href = "/" + SharedRoutes.login;
                        _a.label = 3;
                    case 3:
                        this.unmmountLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationsPage.prototype.openBackOffice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var Browser;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Browser = Plugins.Browser;
                        return [4 /*yield*/, Browser.open({ url: this.environment.getVariable("SELFSERVICE") })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigurationsPage.prototype.presentLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modal.create({ component: EnsureUnmountLoginModal })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, modal.onDidDismiss()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return ConfigurationsPage;
}());
export { ConfigurationsPage };
