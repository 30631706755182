/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datetime.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i5 from "angular-feather";
import * as i6 from "@angular/forms";
import * as i7 from "./datetime.component";
var styles_DatetimeComponent = [i0.styles];
var RenderType_DatetimeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatetimeComponent, data: {} });
export { RenderType_DatetimeComponent as RenderType_DatetimeComponent };
export function View_DatetimeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "datetime"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ion-button", [["class", "datetime__decrement"], ["color", "light"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decrementTime() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "i-minus", [], null, null, null, i4.View_ɵgb_0, i4.RenderType_ɵgb)), i1.ɵdid(4, 49152, null, 0, i5.ɵgb, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-datetime", [["class", "datetime__date"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleChangeEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.updateTime($event.target.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonDatetime_0, i2.RenderType_IonDatetime)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(7, 49152, null, 0, i3.IonDatetime, [i1.ChangeDetectorRef, i1.ElementRef], { displayFormat: [0, "displayFormat"], value: [1, "value"] }, null), i1.ɵdid(8, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "ion-button", [["class", "datetime__increment"], ["color", "light"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementTime() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "i-plus", [], null, null, null, i4.View_ɵhe_0, i4.RenderType_ɵhe)), i1.ɵdid(12, 49152, null, 0, i5.ɵhe, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "light"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayFormat; var currVal_2 = _co.time; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = "light"; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_DatetimeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-datetime", [], null, null, null, View_DatetimeComponent_0, RenderType_DatetimeComponent)), i1.ɵdid(1, 49152, null, 0, i7.DatetimeComponent, [], null, null)], null, null); }
var DatetimeComponentNgFactory = i1.ɵccf("ocm-datetime", i7.DatetimeComponent, View_DatetimeComponent_Host_0, { time: "time", displayFormat: "displayFormat", millisecondsFactor: "millisecondsFactor" }, { newDate: "newDate" }, []);
export { DatetimeComponentNgFactory as DatetimeComponentNgFactory };
