<ion-app>
  <shared-connection-banner
    [isDeviceMounted]="isDeviceMounted"
    [connection]="connection"
    [socketConnection]="socketConnection"
    [loading]="loading"
    (reconnectSocket)="connectToSocket(true)"
  ></shared-connection-banner>
  <ocm-header></ocm-header>
  <div class="ocm-main">
    <ion-router-outlet></ion-router-outlet>
  </div>
</ion-app>
