<div class="coupon-card">
  <div *ngIf="!ticketSelected" class="coupon-card__empty">
    <span class="coupon-card__empty__text">
      {{ translate._("SELECT_VOUCHER") }}
    </span>
  </div>
  <div
    *ngIf="ticketSelected"
    class="coupon-card__image"
    [style.backgroundImage]="
      ticketSelected?.contentPictureFilePath?.file ? 'url(' + ticketSelected?.contentPictureFilePath?.file + ')' : ''
    "
  ></div>
  <div *ngIf="ticketSelected" class="coupon-card__content">
    <h6 class="coupon-card__content__title">
      {{ ticketSelected?.title }}
    </h6>
    <span class="coupon-card__content__price">
      {{ !ticketSelected?.points ? translate._("FREE") : ticketSelected?.points + " " + translate._("P") }}
    </span>
    <span class="coupon-card__content__date" *ngIf="ticketSelected?.validToDate && ticketSelected?.validFromDate">
      {{
        ticketSelected?.voucherType === "coupon" && ticketSelected?.validToDate
          ? translate._(
              "VALID_BETWEEN",
              ticketSelected?.validFromDate?.unixTimeStamp | date: "dd.MM.yy",
              ticketSelected?.validToDate?.unixTimeStamp | date: "dd.MM.yy"
            )
          : ""
      }}
    </span>
    <span class="coupon-card__content__date" *ngIf="ticketSelected?.validToDate && !ticketSelected?.validFromDate">
      {{
        ticketSelected?.voucherType === "coupon" && ticketSelected?.validToDate
          ? translate._("VALID_UNTIL", ticketSelected?.validToDate?.unixTimeStamp | date: "dd.MM.yy")
          : ""
      }}
    </span>
    <span class="coupon-card__content__date" *ngIf="ticketSelected?.validFromDate && !ticketSelected?.validToDate">
      {{ ticketSelected?.voucherType === "coupon" ? translate._("VALID_IN_DAYS", utils.getNumberOfDays(ticketSelected)) : "" }}
    </span>
    <p class="coupon-card__content__description">
      {{ ticketSelected?.description }}
    </p>
    <div class="coupon-card__content__button-wrapper">
      <p *ngIf="disabled" class="coupon-card__content__button-wrapper__text">
        {{ translate._("VOUCHER_DISABLED") }}
      </p>
      <ion-button class="button button--primary coupon-card__content__button-wrapper__button" (tap)="redeem()" [disabled]="disabled">
        {{ translate._("REDEEM_NOW") }}
      </ion-button>
    </div>
  </div>
</div>
