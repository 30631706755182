/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatusLevel } from './status-level';
import { StatusLevelSetDataMetricConfiguration } from './status-level-set-data-metric-configuration';


export interface StatusLevelSetChangeData { 
    metricConfiguration: StatusLevelSetDataMetricConfiguration;
    status: StatusLevelSetChangeData.StatusEnum;
    statusLevels: Array<StatusLevel>;
}
export namespace StatusLevelSetChangeData {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


