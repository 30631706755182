var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ProgramService as ConvercusProgramService, Configuration } from "shared/lib/v2/apis/programs";
import { HttpClient } from "@angular/common/http";
import { INTERACTION_ID, programConfig } from "shared/lib/common/constants";
import { EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../apis/programs/variables";
import * as i3 from "../../apis/programs/configuration";
import * as i4 from "../../../common/services/environment/environment.service";
var ProgramService = /** @class */ (function (_super) {
    __extends(ProgramService, _super);
    function ProgramService(httpClient, basePath, configuration, environmentService) {
        var _this = _super.call(this, httpClient, environmentService.getVariable("API_V2"), configuration) || this;
        _this.httpClient = httpClient;
        _this.environmentService = environmentService;
        return _this;
    }
    ProgramService.prototype.getInteractionPointsOfProgram = function (programId, interactionId, recursive) {
        if (interactionId === void 0) { interactionId = INTERACTION_ID; }
        return _super.prototype.getInteractionPointsOfProgram.call(this, interactionId, programId, recursive);
    };
    ProgramService.prototype.getStore = function (partnerId, programId, storeId, interactionId) {
        if (interactionId === void 0) { interactionId = INTERACTION_ID; }
        return _super.prototype.getStore.call(this, interactionId, partnerId, programId, storeId);
    };
    ProgramService.prototype.getProgramConfig = function (program) {
        return programConfig[program];
    };
    ProgramService.ngInjectableDef = i0.defineInjectable({ factory: function ProgramService_Factory() { return new ProgramService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8), i0.inject(i4.EnvironmentService)); }, token: ProgramService, providedIn: "root" });
    return ProgramService;
}(ConvercusProgramService));
export { ProgramService };
