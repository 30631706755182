import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedRoutes, LOCAL_STORAGE } from "shared/lib/common/enums";
import {
  AdminService,
  EnvironmentService,
  P4mService,
  RoutingService,
  SentryService,
  ToastService,
  TranslateService,
} from "shared/lib/common/services";
import { Platform } from "@ionic/angular";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  public showPassword: boolean;
  public loading: boolean;
  public form: FormGroup;
  public releaseVersion: string;
  public isKeyboardActive: boolean;
  public organizations: string[] = ["pfm", "mhe"];
  public isCapacitor: boolean;
  public invalidOrganization: boolean;

  constructor(
    public translate: TranslateService,
    public fb: FormBuilder,
    public admin: AdminService,
    public config: ConfigService,
    public toast: ToastService,
    public sentry: SentryService,
    private p4m: P4mService,
    private platform: Platform,
    private routing: RoutingService,
    private environment: EnvironmentService,
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
    this.isCapacitor = this.platform.is("capacitor");
  }

  public async ionViewWillEnter(): Promise<void> {
    this.releaseVersion = this.environment.getVariable("RELEASE");
    this.form.reset();
    this.loading = false;
    this.showPassword = false;
    const email = this.p4m.getUser();
    const password = this.p4m.getPassword();
    if (email && password) {
      this.form.setValue({ email, password });
      this.login(email, password);
    }
  }

  public changeShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  public updateKeyboardStatus(isActive: boolean): void {
    this.isKeyboardActive = isActive;
  }

  public async login(email: string, password: string): Promise<void> {
    this.loading = true;
    const resp = await this.admin.adminLogin(email, this.config.getOrganization(), password);
    if (resp.ok === true) {
      this.sentry.addTag("email", email);
      this.routing.goForward([SharedRoutes.mounting]);
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
    }
    this.loading = false;
  }

  public checkErrors(formControlName: string): boolean {
    const control = this.form.get(formControlName);
    return control.invalid && control.touched;
  }

  public onSelectOrganization(event: any): void {
    this.config.setOrganization(event.detail.value);
    localStorage.setItem(LOCAL_STORAGE.ORGANIZATION, event.detail.value);
  }
}
