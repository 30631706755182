var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Subject } from "rxjs";
import * as io from "socket.io-client";
import { AdminService } from "shared/lib/common/services/admin/admin.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { EnvironmentService } from "../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "../admin/admin.service";
import * as i2 from "../config/config.service";
import * as i3 from "../environment/environment.service";
var PosConnectionService = /** @class */ (function () {
    function PosConnectionService(admin, config, environmentService) {
        this.admin = admin;
        this.config = config;
        this.environmentService = environmentService;
        this.subject = new Subject();
    }
    PosConnectionService.prototype.connectToSocket = function (deviceKey) {
        return __awaiter(this, void 0, void 0, function () {
            var response, mountingConfig;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(deviceKey && !this.socket)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.admin.reLogin()];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            mountingConfig = this.config.getMountingConfig();
                            this.socket = io(this.environmentService.getVariable("API_SOCKET"), {
                                transports: ["websocket"],
                                query: {
                                    deviceKey: deviceKey,
                                    token: response.response,
                                    deviceInformation: mountingConfig.deviceInformation,
                                    workstationId: mountingConfig.workstationId,
                                    version: this.environmentService.getVariable("RELEASE"),
                                    ak: this.admin.getApiKey(),
                                    ac: this.config.getOrganization(),
                                    appInteractionId: mountingConfig.appInteractionId,
                                    cashInteractionId: mountingConfig.cashInteractionId,
                                },
                            });
                            this.socket.on("reconnect_attempt", function () { return (_this.socket.io.opts.transports = ["websocket", "polling"]); });
                            this.socket.on("event", function (data) { return _this.subject.next({ type: "REVENUE", data: data }); });
                            this.socket.on("revenue", function (data) { return _this.subject.next({ type: "REVENUE", data: data }); });
                            this.socket.on("unmount", function () { return _this.subject.next({ type: "UNMOUNT" }); });
                            this.socket.on("connect", function () { return _this.subject.next({ type: "CONNECT" }); });
                            this.socket.on("disconnect", function () { return _this.subject.next({ type: "DISCONNECT" }); });
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PosConnectionService.prototype.closeSocketConnection = function () {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    };
    PosConnectionService.prototype.getObservable = function () {
        return this.subject.asObservable();
    };
    PosConnectionService.ngInjectableDef = i0.defineInjectable({ factory: function PosConnectionService_Factory() { return new PosConnectionService(i0.inject(i1.AdminService), i0.inject(i2.ConfigService), i0.inject(i3.EnvironmentService)); }, token: PosConnectionService, providedIn: "root" });
    return PosConnectionService;
}());
export { PosConnectionService };
