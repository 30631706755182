/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i6 from "../../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i7 from "angular-feather";
import * as i8 from "../../components/coupon/coupon.component.ngfactory";
import * as i9 from "../../components/coupon/coupon.component";
import * as i10 from "../../../../../../shared/lib/common/services/translate/translate.service";
import * as i11 from "./success.page";
import * as i12 from "../../../../../../shared/lib/common/services/user/user.service";
import * as i13 from "../../../../../../shared/lib/common/services/routing/routing.service";
import * as i14 from "../../services/qr/qr.service";
var styles_SuccessPage = [i0.styles];
var RenderType_SuccessPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessPage, data: {} });
export { RenderType_SuccessPage as RenderType_SuccessPage };
export function View_SuccessPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 12, "div", [["class", "success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(4, 114688, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "success__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i-check", [["class", "success__check"]], null, null, null, i6.View_ɵbt_0, i6.RenderType_ɵbt)), i1.ɵdid(8, 49152, null, 0, i7.ɵbt, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "success__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "success__voucher-wrapper"]], [[2, "--active", null]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "canvas", [["class", "success__voucher-wrapper__canvas"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "ocm-coupon", [["class", "success__voucher-wrapper__voucher"]], null, null, null, i8.View_CouponComponent_0, i8.RenderType_CouponComponent)), i1.ɵdid(14, 49152, null, 0, i9.CouponComponent, [i10.TranslateService], { ticket: [0, "ticket"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_3 = _co.voucher; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("SUCCESS_MODAL_TITLE"); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.translate._("SUCCESS_MODAL_TEXT"); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.externalCode; _ck(_v, 11, 0, currVal_2); }); }
export function View_SuccessPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-success", [], null, null, null, View_SuccessPage_0, RenderType_SuccessPage)), i1.ɵdid(1, 114688, null, 0, i11.SuccessPage, [i3.ModalController, i12.UserService, i13.RoutingService, i14.QrService, i10.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuccessPageNgFactory = i1.ɵccf("ocm-success", i11.SuccessPage, View_SuccessPage_Host_0, { voucher: "voucher" }, {}, []);
export { SuccessPageNgFactory as SuccessPageNgFactory };
