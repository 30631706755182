import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(public toastController: ToastController) {}

  public async show(
    message: string,
    kind: "error" | "info",
    duration: number = 3000,
    position: "top" | "bottom" | "middle" = "top",
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      duration,
      color: kind === "error" ? "danger" : "primary",
      cssClass: `-is-${kind}-toast`,
      showCloseButton: false,
      translucent: true,
      position,
    });
    toast.present();
  }
}
