import { Component, NgZone } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { IIdentifierType, IUser } from "shared/lib/common/interfaces";
import { IInformationModalPageInputs, InformationModalPage } from "shared/lib/v1/pages";
import { IBurnPageParams, IAudioFile } from "shared/lib/common/pages";
import { QrScannerPage as QrScannerPageShared } from "shared/lib/common/pages";
import { ConfigService, ToastService, TranslateService, UserService, InactivityService, RoutingService } from "shared/lib/common/services";
import { ISuccessReedemComponentParams, SuccessRedeemPage } from "../success-redeem/success-redeem.page";
import { SharedRoutes } from "shared/lib/common/enums";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { AccountService, AdminService } from "shared/lib/v2/services";

@Component({
  selector: "ocm-qr-scanner",
  templateUrl: "./qr-scanner.page.html",
  styleUrls: ["./qr-scanner.page.scss"],
})
export class QrScannerPage extends QrScannerPageShared {
  constructor(
    public _translate: TranslateService,
    protected _zone: NgZone,
    protected _userService: UserService,
    protected _toast: ToastService,
    protected _config: ConfigService,
    protected _modal: ModalController,
    protected _admin: AdminService,
    protected _inactivity: InactivityService,
    protected _platform: Platform,
    protected _qrScanner: QRScanner,
    protected _routing: RoutingService,
    private _account: AccountService,
  ) {
    super(_zone, _translate, _userService, _toast, _config, _modal, _admin, _inactivity, _platform, _qrScanner, _routing);
  }

  public async scannerCode(qrResult: string, error: boolean): Promise<void> {
    this.playSound(IAudioFile.scanned);
    this.loading = true;
    await this.disableAppQRScanner();
    let actionSuccess = false;
    this._inactivity.resetTimer();
    if (!error) {
      const resp = this.validateQr(qrResult);
      if (resp.ok === true) {
        switch (resp.response.type) {
          case "user":
            actionSuccess = await this.user(resp.response.keyCode, resp.response.idType);
            this.loading = false;
            break;
          case "earn":
            actionSuccess = await this.earn(resp.response.keyCode, resp.response.idType);
            break;
          case "reward":
            actionSuccess = await this.burnReward(resp.response.qrObject);
            this.loading = false;
            break;
          case "coupon":
            actionSuccess = await this.burnCoupon(resp.response.qrObject);
            this.loading = false;
            break;
          case "acumulate":
            this._toast.show(this.translate._("INVALID_QR"), "error");
            break;
        }
      } else {
        await this._toast.show(this.translate._(resp.error.message), "error");
      }
    } else {
      await this._toast.show(this.translate._(qrResult), "error");
    }

    if (!actionSuccess) await this.enableAppQRScanner();
  }

  public async openSuccessModal(keyCode: string, points: number, idType: IIdentifierType): Promise<void> {
    const componentProps: ISuccessReedemComponentParams = {
      points,
      idType,
      keyCode,
      title: points > 0 ? this.translate._("SUCCESS_REDEEM_MODAL_TITLE_POSITIVE") : this.translate._("SUCCESS_REDEEM_MODAL_TITLE_NEGATIVE"),
    };
    const successModal = await this._modal.create({
      component: SuccessRedeemPage,
      componentProps,
    });
    await successModal.present();
    await successModal.onDidDismiss();
  }

  protected async earn(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (keyCode && this.params.type === "earn") {
      if (this.params.manualPoints) {
        return await this.bookPoints(keyCode, idType);
      } else {
        if (this.params.date) {
          const resp = await this._admin.arts(this.params.date, Number(this.params.totalRevenue), keyCode, idType);
          if (resp.ok === true) {
            this.timeout = setTimeout(() => {
              this._toast.show(this.translate._("ARTS_ERROR"), "error");
              this._inactivity.resetTimer();
              clearTimeout(this.timeout);
              this.loading = false;
            }, 30000);
            return true;
          } else {
            this._toast.show(this.translate._(resp.error.message), "error");
            this.loading = false;
          }
        } else {
          try {
            await this._account
              .incentivateTransaction(keyCode, this._config.getMountingConfig().workstationId, this.params.transactionId, idType)
              .toPromise();
            return true;
          } catch (error) {
            this._toast.show(this._translate._(error), "error");
            return false;
          }
        }
      }
    }
    return false;
  }

  protected async user(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (keyCode) {
      const details = await this._userService.getDetails(keyCode, idType);
      if (details.ok === true) {
        const queryParams: IBurnPageParams = { idType, keyCode };
        this._userService.setUser(details.response);
        if (this._userService.getUser().userStatusCode === "LOCKED") {
          await this.showLockedModal();
          return this._routing.goForward([SharedRoutes.screenSaver]);
        } else if (!this._userService.getUser().isRegistered && this.registrationType !== "NO") {
          await this.showRegisterModal(keyCode, this._userService.getUser());
          return this._routing.goForward([SharedRoutes.screenSaver]);
        } else {
          const saveUserResponse = await this.saveUser(details.response, idType);
          if (saveUserResponse.ok === true) {
            this._routing.goForward([SharedRoutes.burn], { queryParams });
          } else {
            this._toast.show(this.translate._(saveUserResponse.error.message), "error");
          }
          return saveUserResponse.ok;
        }
      } else {
        this._toast.show(this.translate._(details.error.message), "error");
      }
      return details.ok;
    }
    return false;
  }

  protected async showRegisterModal(keyCode: string, user: IUser): Promise<boolean> {
    const componentProps: IInformationModalPageInputs = {
      title: this.translate._("PLEASE_REGISTER"),
      description: "",
      buttonText: this.translate._("UNDERSTOOD"),
    };
    const informationModal = await this._modal.create({
      component: InformationModalPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await informationModal.present();
    await informationModal.onDidDismiss();
    return true;
  }

  private async showLockedModal(): Promise<boolean> {
    const componentProps: IInformationModalPageInputs = {
      title: this.translate._("LOCKED_USER"),
      description: "",
      buttonText: this.translate._("UNDERSTOOD"),
    };
    const informationModal = await this._modal.create({
      component: InformationModalPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await informationModal.present();
    await informationModal.onDidDismiss();
    return true;
  }

  private async bookPoints(keyCode: string, idType: IIdentifierType): Promise<boolean> {
    if (this.params.type === "earn") {
      const response = await this._userService.bookPoints(keyCode, this.params.manualPoints.toString(), idType);
      if (response.ok === true) {
        this.openSuccessModal(keyCode, this.params.manualPoints, idType);
      } else {
        this._toast.show(this.translate._(response.error.message as any), "error");
      }
      return response.ok;
    }
    return false;
  }
}
