var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Location } from "@angular/common";
import { MountingSettingsPage as SharedMountingSettingsPage } from "shared/lib/v2/pages";
import { ConfigService, PosConnectionService, RoutingService, ToastService, TranslateService } from "shared/lib/common/services";
import { DeviceMountingService, ProgramService } from "shared/lib/v2/services";
import { FormBuilder } from "@angular/forms";
var MountingSettingsPage = /** @class */ (function (_super) {
    __extends(MountingSettingsPage, _super);
    function MountingSettingsPage(_fb, _program, _routing, _toast, _location, _config, _posConnectionService, _deviceMountingService, _translate) {
        var _this = _super.call(this, _fb, _program, _routing, _toast, _location, _config, _posConnectionService, _deviceMountingService, _translate) || this;
        _this._fb = _fb;
        _this._program = _program;
        _this._routing = _routing;
        _this._toast = _toast;
        _this._location = _location;
        _this._config = _config;
        _this._posConnectionService = _posConnectionService;
        _this._deviceMountingService = _deviceMountingService;
        _this._translate = _translate;
        return _this;
    }
    return MountingSettingsPage;
}(SharedMountingSettingsPage));
export { MountingSettingsPage };
