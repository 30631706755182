/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProgramUnit { 
    unitType: ProgramUnit.UnitTypeEnum;
    value: string;
}
export namespace ProgramUnit {
    export type UnitTypeEnum = 'PARTNER' | 'STORE' | 'INTERACTIONPOINT';
    export const UnitTypeEnum = {
        PARTNER: 'PARTNER' as UnitTypeEnum,
        STORE: 'STORE' as UnitTypeEnum,
        INTERACTIONPOINT: 'INTERACTIONPOINT' as UnitTypeEnum
    };
}


