import { Component, OnInit } from "@angular/core";
import { ScreenSaverPage as SharedScreenSaverPage } from "shared/lib/common/pages";
import { ConfigService, RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { AdminService } from "shared/lib/v2/services";

@Component({
  selector: "ocm-screen-saver",
  templateUrl: "./screen-saver.page.html",
  styleUrls: ["./screen-saver.page.scss"],
})
export class ScreenSaverPage extends SharedScreenSaverPage implements OnInit {
  constructor(
    protected _user: UserService,
    protected _translate: TranslateService,
    public _config: ConfigService,
    protected _admin: AdminService,
    protected _utils: UtilsService,
    protected _routing: RoutingService,
  ) {
    super(_user, _translate, _config, _admin, _utils, _routing);
  }

  public ngOnInit(): void {
    this.images = [];
    this.initSlide();
    this.background = "assets/screen-saver/screen-saver.jpg";
    this.actualImage = "assets/screen-saver/screen-saver.jpg";
  }
}
