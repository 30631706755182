/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatusLevel { 
    collectionPeriod?: number;
    id: string;
    label: string;
    neededStatePoints: number;
    retainingPeriod: StatusLevel.RetainingPeriodEnum;
}
export namespace StatusLevel {
    export type RetainingPeriodEnum = 'UNLIMITED' | '12' | '24' | '36';
    export const RetainingPeriodEnum = {
        UNLIMITED: 'UNLIMITED' as RetainingPeriodEnum,
        _12: '12' as RetainingPeriodEnum,
        _24: '24' as RetainingPeriodEnum,
        _36: '36' as RetainingPeriodEnum
    };
}


