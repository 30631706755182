import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IVoucher } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services";

export interface ICouponCardInput {
  ticketSelected: IVoucher;
}

export interface ICouponCardOutput {
  data?: IVoucher;
}

@Component({
  selector: "ocm-coupon-card-modal",
  templateUrl: "./coupon-card.page.html",
  styleUrls: ["./coupon-card.page.scss"],
})
export class CouponCardPage implements OnInit {
  @Input() public ticketSelected: IVoucher;

  constructor(private modal: ModalController, public config: ConfigService) {}

  public ngOnInit(): void {}

  public dismiss(coupon: IVoucher): void {
    this.modal.dismiss(coupon);
  }
}
