export enum SharedRoutes {
  screenSaver = "screen-saver",
  login = "login",
  qrScanner = "qr-scanner",
  burn = "burn",
  admin = "admin",
  setPin = "set-session-pin",
  history = "history",
  mounting = "mounting-settings",
  configurations = "configurations",
  deviceTest = "device-test",
  revenue = "revenue",
}
