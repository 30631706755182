/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./device-test.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./device-test.page";
import * as i5 from "../../services/config/config.service";
import * as i6 from "../../services/p4m/p4m.service";
import * as i7 from "../../services/environment/environment.service";
var styles_DeviceTestPage = [i0.styles];
var RenderType_DeviceTestPage = i1.ɵcrt({ encapsulation: 0, styles: styles_DeviceTestPage, data: {} });
export { RenderType_DeviceTestPage as RenderType_DeviceTestPage };
export function View_DeviceTestPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-content", [["class", "devicetest"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["class", "devicedata"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Camera Permision State: ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" App-Version: ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" Organisation: ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" Mounting User: ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cameraPermissionState; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.releaseVersion; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.organisation; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.mountingUser; _ck(_v, 9, 0, currVal_3); }); }
export function View_DeviceTestPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "generic-device-test", [], null, null, null, View_DeviceTestPage_0, RenderType_DeviceTestPage)), i1.ɵdid(1, 49152, null, 0, i4.DeviceTestPage, [i5.ConfigService, i6.P4mService, i7.EnvironmentService], null, null)], null, null); }
var DeviceTestPageNgFactory = i1.ɵccf("generic-device-test", i4.DeviceTestPage, View_DeviceTestPage_Host_0, {}, {}, []);
export { DeviceTestPageNgFactory as DeviceTestPageNgFactory };
