/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationConfigurationTrigger } from './notification-configuration-trigger';


export interface NotificationConfigurationActions { 
    channels?: Array<NotificationConfigurationActions.ChannelsEnum>;
    deliveryContext?: { [key: string]: string; };
    landingPage?: string;
    trigger?: NotificationConfigurationTrigger;
}
export namespace NotificationConfigurationActions {
    export type ChannelsEnum = 'EMAIL';
    export const ChannelsEnum = {
        EMAIL: 'EMAIL' as ChannelsEnum
    };
}


