import { Component, OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { IQrScannerPageParams } from "../qr-scanner/qr-scanner.page";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-screen-saver",
  templateUrl: "./screen-saver.page.html",
  styleUrls: ["./screen-saver.page.scss"],
})
export class ScreenSaverPage implements OnInit {
  private slideActive: boolean;
  public languageOptions: object;
  public background: string;
  public actualImage: string;
  public images: { url: string; duration: number }[];
  public showButtons: boolean;

  constructor(
    public user: UserService,
    public translate: TranslateService,
    public config: ConfigService,
    private admin: AdminService,
    private utils: UtilsService,
    private routing: RoutingService,
  ) {}

  public ngOnInit(): void {
    this.images = [];
    this.initSlide();
    this.background = "assets/screen-saver/default.jpg";
    this.actualImage = "assets/screen-saver/default.jpg";
  }

  public async ionViewDidEnter(): Promise<void> {
    this.admin.fetchStoresOfClient(this.config.getMountingConfig().clientId);
    const images = await this.getImages();
    this.images = [...this.images, ...images.filter(({ url }) => this.images.findIndex(img => img.url === url) < 0)];
    this.translate.setSessionLanguage();
    this.user.removeUser();
    this.languageOptions = { header: "Select language" };
    this.showButtons = !this.config.screenSaver || (this.config.screenSaver && this.config.screenSaver.message.show);
  }

  public goQrScanner(): void {
    const queryParams: IQrScannerPageParams = { type: "burn" };
    this.routing.goForward([SharedRoutes.qrScanner], { queryParams });
  }

  public onImageError(erroredUrl: string): void {
    this.images = this.images.filter(({ url }) => url !== erroredUrl);
  }

  protected async initSlide(): Promise<void> {
    if (!this.slideActive) {
      this.slideActive = true;
      this.backgroundSlide();
    }
  }

  protected async backgroundSlide(): Promise<void> {
    if (this.images.length > 0) {
      for (let i = 0; i < this.images.length; i++) {
        this.actualImage = this.images[i].url;
        await this.utils.waiting(this.images[i].duration);
      }
    } else {
      this.actualImage = this.background;
      await this.utils.waiting(5000);
    }
    this.backgroundSlide();
  }

  private async getImages(): Promise<{ url: string; duration: number }[]> {
    const resp: { url: string; duration: number }[] = [];
    const response = await this.admin.getDisplayAds(this.config.getMountingConfig().deviceKey);
    if (response.ok === true) {
      response.response.forEach(displayAd => {
        if (displayAd.displayAdUsageCode === "PRINTER_LOGO") {
          this.config.setPrinterLogo(displayAd.pictureFilePath);
        } else {
          resp.push({
            url: displayAd.pictureFilePath,
            duration: displayAd.displayDurationMsec,
          });
        }
      });
    }
    return resp;
  }
}
