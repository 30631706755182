var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LOCAL_STORAGE, SharedRoutes } from "shared/lib/common/enums";
import { InactivityService, PosConnectionService, RoutingService, SentryService, ThirdPartyScriptsService, TranslateService, UserService, UtilsService, } from "shared/lib/common/services";
import { SuccessPage } from "shared/lib/common/pages";
import { ConfigService } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services";
var AppComponent = /** @class */ (function () {
    function AppComponent(inactivity, modalCtrl, user, routing, config, posConnectionService, thirdParty, utils, sentry, translate, deviceMountingService) {
        this.inactivity = inactivity;
        this.modalCtrl = modalCtrl;
        this.user = user;
        this.routing = routing;
        this.config = config;
        this.posConnectionService = posConnectionService;
        this.thirdParty = thirdParty;
        this.utils = utils;
        this.sentry = sentry;
        this.translate = translate;
        this.deviceMountingService = deviceMountingService;
    }
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.isDeviceMounted = !!this.config.getMountingConfig().clientId;
                this.setLanguage();
                this.checkConnection();
                this.connectToSocket();
                this.inactivityWatch();
                this.addSentryTags();
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.subscription)
            this.subscription.unsubscribe();
    };
    AppComponent.prototype.inactivityWatch = function () {
        var _this = this;
        this.inactivity.startWatching();
        this.inactivity.onTimeout().subscribe(function () { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.modalCtrl.dismiss()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3:
                        this.user.removeUser();
                        return [4 /*yield*/, this.routing.goForward([SharedRoutes.screenSaver])];
                    case 4:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AppComponent.prototype.checkConnection = function () {
        var _this = this;
        this.connection = navigator.onLine;
        window.addEventListener("offline", function () { return (_this.connection = false); });
        window.addEventListener("online", function () { return (_this.connection = true); });
    };
    AppComponent.prototype.connectToSocket = function (force) {
        if (force === void 0) { force = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.connection = true;
                        this.socketConnection = true;
                        if (force)
                            this.posConnectionService.closeSocketConnection();
                        if (this.subscription)
                            this.subscription.unsubscribe();
                        this.subscription = this.posConnectionService.getObservable().subscribe(this.manageSocket.bind(this));
                        return [4 /*yield*/, this.posConnectionService.connectToSocket(this.config.getMountingConfig().deviceKey)];
                    case 1:
                        _a.sent();
                        setTimeout(function () { return (_this.connection = navigator.onLine); }, 4000);
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.openSuccessModal = function (keyCode, points, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, successModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            idType: idType,
                            keyCode: keyCode,
                            points: points,
                            title: points > 0 ? this.translate._("POINTS_ACUMULATED") : this.translate._("POINTS_SPENT"),
                        };
                        return [4 /*yield*/, this.modalCtrl.create({
                                component: SuccessPage,
                                componentProps: componentProps,
                            })];
                    case 1:
                        successModal = _a.sent();
                        return [4 /*yield*/, successModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, successModal.onDidDismiss()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.setLanguage = function () {
        if (this.deviceMountingService.profile && this.deviceMountingService.profile.language) {
            this.translate.setLanguage(this.deviceMountingService.profile.language);
            this.translate.setSessionLanguage();
        }
    };
    AppComponent.prototype.onSocketRevenue = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var modal, queryParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.inactivity.resetTimer();
                        return [4 /*yield*/, this.modalCtrl.getTop()];
                    case 1:
                        modal = _a.sent();
                        if (modal)
                            modal.dismiss();
                        queryParams = {
                            type: "earn",
                            totalRevenue: data.totalRevenue,
                            totalPoints: data.totalPoints,
                            externalId: data.payload.billNo,
                            transactionId: data.payload.transactionId,
                        };
                        if (!data.keyCode) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.openSuccessModal(data.keyCode, data.totalPoints, data.idType || "APPCODE")];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, this.routing.goForward([SharedRoutes.screenSaver])];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.routing.goForward([SharedRoutes.qrScanner], { queryParams: queryParams })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.addSentryTags = function () {
        var mountingConfig = JSON.parse(this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG)));
        if (mountingConfig)
            this.sentry.addTag("device-id", String(mountingConfig.deviceId));
        var email = this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.EMAIL));
        if (email)
            this.sentry.addTag("email", String(email));
    };
    AppComponent.prototype.manageSocket = function (message) {
        this.isDeviceMounted = !!this.config.getMountingConfig().clientId;
        switch (message.type) {
            case "CONNECT":
                this.socketConnection = true;
                break;
            case "DISCONNECT":
                this.socketConnection = false;
                break;
            case "REVENUE":
                this.onSocketRevenue(message.data);
                break;
            case "UNMOUNT":
                localStorage.clear();
                window.location.href = this.config.getOrganization() + "/" + SharedRoutes.login;
                break;
        }
    };
    return AppComponent;
}());
export { AppComponent };
