<ion-content class="qr-scan page-wrapper" scroll-y="false">
  <div class="qr-scan__content --{{ cameraPosition }}">
    <div class="qr-scan__content__scanner-wrapper --{{ cameraPosition }}">
      <shared-qrscanner *ngIf="showCamera" [scannerEnabled]="scannerEnabled" [device]="cameraSelected"
        (torchCompatible)="logs('torchCompatible', $event)" (hasDevices)="logs('hasDevices', $event)"
        (scanError)="logs('scanError', $event)" (scanComplete)="logs('scanComplete', $event)"
        (permissionResponse)="logs('permissionResponse', $event)" (camerasFound)="cameraFounds($event)"
        (scanSuccess)="scannerCode($event, false)" (scanFailure)="scannerCode($event, true)"
        [cameraPosition]="cameraPosition" class="qr-scan__content__scanner-wrapper__scanner"></shared-qrscanner>
      <div class="qr-scan__content__scanner-wrapper__frame --{{ cameraPosition }}">
        <shared-scan-frame [loading]="loading"></shared-scan-frame>
      </div>
    </div>
    <div class="qr-scan__content__wrapper --{{ cameraPosition }}">
      <h1 class="qr-scan__content__wrapper__title">
        {{ _translate._("QRSCANNER_BURN_TITLE") }}
      </h1>
      <p class="qr-scan__content__wrapper__text">{{ _translate._("QRSCANNER_BURN_SUBTITLE") }}</p>
      <ocm-devices *ngIf="params?.type === 'burn'" class="qr-scan__content__wrapper__image --{{ cameraPosition }}">
      </ocm-devices>
      <div *ngIf="params?.type === 'earn'" class="qr-scan__content__wrapper__earn --{{ cameraPosition }}">
        <p class="qr-scan__content__wrapper__earn__points">
          {{ params["totalPoints"] || params["manualPoints"] || params["acumulateParams"] | number: "":_translate.getSessionLanguage() }}
        </p>
        <p class="qr-scan__content__wrapper__earn__text">
          {{ _translate._("ACCUMULATE_POINTS") }}
        </p>
      </div>
    </div>
  </div>
</ion-content>