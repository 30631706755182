import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { RegisterPage as SharedRegisterPage } from "shared/lib/common/pages";
import { AdminService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-register",
  templateUrl: "../../../../../shared/lib/common/pages/register/register.page.html",
  styleUrls: ["../../../../../shared/lib/common/pages/register/register.page.scss"],
})
export class RegisterPage extends SharedRegisterPage {
  constructor(
    public _t: TranslateService,
    private _modal: ModalController,
    private _fb: FormBuilder,
    private _admin: AdminService,
    private _toast: ToastService,
    private _config: ConfigService,
    private _user: UserService,
  ) {
    super(_t, _modal, _fb, _admin, _toast, _config, _user);
  }
}
