<ion-content class="page-wrapper">
  <div class="revenue-date">
    <shared-close (tap)="cancelation()"></shared-close>
    <h1 class="revenue-date__header">{{ translate._("REVENUE_DATE") }}</h1>
    <div class="revenue-date__body">
      <p class="revenue-date__body__text">{{ translate._("DAY") }}</p>
      <ocm-datetime
        [millisecondsFactor]="24 * 60 * 60 * 1000"
        [time]="day"
        [displayFormat]="'YYYY-MM-DD'"
        (newDate)="day = $event"
      ></ocm-datetime>
      <p class="revenue-date__body__text">{{ translate._("HOUR") }}</p>
      <ocm-datetime [time]="hour" [displayFormat]="'HH:mm'" (newDate)="hour = $event"></ocm-datetime>
      <ion-button class="revenue-date__body__button" (click)="dismiss()">{{ translate._("SET_DATE") }}</ion-button>
    </div>
  </div>
</ion-content>
