var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { NgZone } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { RegisterPage } from "shared/lib/common/pages/register/register.page";
import { SuccessPage } from "shared/lib/common/pages/success/success.page";
import { AdminService, InactivityService, RoutingService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { InformationModalPage } from "shared/lib/v1/pages/information-modal/information-modal.page";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ConfigService } from "shared/lib/common/services";
export var positionMap = {
    BC: "top",
    TR: "left",
    RC: "right",
};
export var IAudioFile;
(function (IAudioFile) {
    IAudioFile[IAudioFile["scanned"] = 0] = "scanned";
    IAudioFile[IAudioFile["socket"] = 1] = "socket";
})(IAudioFile || (IAudioFile = {}));
var COUPON_MASK = "AEHJPQSXBV";
var REWARD_MASK = "CDGKLPSVXZ";
var QrScannerPage = /** @class */ (function () {
    function QrScannerPage(zone, translate, userService, toast, config, modal, admin, inactivity, platform, qrScanner, routing) {
        this.zone = zone;
        this.translate = translate;
        this.userService = userService;
        this.toast = toast;
        this.config = config;
        this.modal = modal;
        this.admin = admin;
        this.inactivity = inactivity;
        this.platform = platform;
        this.qrScanner = qrScanner;
        this.routing = routing;
    }
    QrScannerPage.prototype.ionViewWillEnter = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.isOnCapacitor = this.platform.is("capacitor");
                this.params = this.routing.getUrlParams();
                if (!this.validParams()) {
                    this.toast.show(this.translate._("INVALID_PARAMS"), "error");
                    this.back();
                    return [2 /*return*/];
                }
                this.loadAudioFiles();
                this.cameraPosition = positionMap[this.config.getMountingConfig().cameraPosition];
                if (this.params.type === "earn" && this.params.totalRevenue) {
                    this.playSound(IAudioFile.socket);
                }
                this.enableAppQRScanner();
                this.loading = false;
                this.showCamera = true;
                return [2 /*return*/];
            });
        });
    };
    QrScannerPage.prototype.ionViewDidEnter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.admin.getRegistrationType()];
                    case 1:
                        resp = _a.sent();
                        if (resp.ok === true)
                            this.registrationType = resp.response;
                        else
                            this.toast.show(this.translate._(resp.error.message), "error");
                        return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.ionViewDidLeave = function () {
        clearTimeout(this.timeout);
        this.showCamera = false;
        this.disableAppQRScanner();
        if (this.isOnCapacitor)
            this.qrScanner.destroy();
    };
    QrScannerPage.prototype.logs = function (name, event) { };
    QrScannerPage.prototype.back = function () {
        this.routing.goForward([SharedRoutes.screenSaver]);
    };
    QrScannerPage.prototype.cameraFounds = function (devices) {
        var _this = this;
        var cameras = devices.filter(function (_a) {
            var kind = _a.kind;
            return kind === "videoinput";
        });
        cameras.forEach(function (camera) {
            if (camera.label.includes("front")) {
                _this.cameraSelected = camera;
            }
        });
        if (!this.cameraSelected && cameras.length > 1 && this.getMobileOperatingSystem() === "iOS") {
            this.cameraSelected = cameras[1];
        }
        else if (!this.cameraSelected && cameras.length > 0) {
            this.cameraSelected = cameras[0];
        }
    };
    QrScannerPage.prototype.scannerCode = function (qrResult, error) {
        return __awaiter(this, void 0, void 0, function () {
            var actionSuccess, resp, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.playSound(IAudioFile.scanned);
                        this.loading = true;
                        return [4 /*yield*/, this.disableAppQRScanner()];
                    case 1:
                        _b.sent();
                        actionSuccess = false;
                        this.inactivity.resetTimer();
                        if (!!error) return [3 /*break*/, 15];
                        resp = this.validateQr(qrResult);
                        if (!(resp.ok === true)) return [3 /*break*/, 12];
                        _a = resp.response.type;
                        switch (_a) {
                            case "user": return [3 /*break*/, 2];
                            case "earn": return [3 /*break*/, 4];
                            case "reward": return [3 /*break*/, 6];
                            case "coupon": return [3 /*break*/, 8];
                            case "acumulate": return [3 /*break*/, 10];
                        }
                        return [3 /*break*/, 11];
                    case 2: return [4 /*yield*/, this.user(resp.response.keyCode, resp.response.idType)];
                    case 3:
                        actionSuccess = _b.sent();
                        return [3 /*break*/, 11];
                    case 4: return [4 /*yield*/, this.earn(resp.response.keyCode, resp.response.idType)];
                    case 5:
                        actionSuccess = _b.sent();
                        return [3 /*break*/, 11];
                    case 6: return [4 /*yield*/, this.burnReward(resp.response.qrObject)];
                    case 7:
                        actionSuccess = _b.sent();
                        return [3 /*break*/, 11];
                    case 8: return [4 /*yield*/, this.burnCoupon(resp.response.qrObject)];
                    case 9:
                        actionSuccess = _b.sent();
                        return [3 /*break*/, 11];
                    case 10:
                        this.toast.show(this.translate._("INVALID_QR"), "error");
                        return [3 /*break*/, 11];
                    case 11: return [3 /*break*/, 14];
                    case 12: return [4 /*yield*/, this.toast.show(this.translate._(resp.error.message), "error")];
                    case 13:
                        _b.sent();
                        _b.label = 14;
                    case 14: return [3 /*break*/, 17];
                    case 15: return [4 /*yield*/, this.toast.show(this.translate._(qrResult), "error")];
                    case 16:
                        _b.sent();
                        _b.label = 17;
                    case 17:
                        this.loading = false;
                        if (!!actionSuccess) return [3 /*break*/, 19];
                        return [4 /*yield*/, this.enableAppQRScanner()];
                    case 18:
                        _b.sent();
                        _b.label = 19;
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.openSuccessModal = function (keyCode, points, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, successModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            idType: idType,
                            keyCode: keyCode,
                            points: points,
                            title: points > 0 ? this.translate._("POINTS_ACUMULATED") : this.translate._("POINTS_SPENT"),
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: SuccessPage,
                                componentProps: componentProps,
                            })];
                    case 1:
                        successModal = _a.sent();
                        return [4 /*yield*/, successModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, successModal.onDidDismiss()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.saveUser = function (user, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userService.getUserHistory(user.keyCode, idType)];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            this.userService.setUser(user);
                            this.userService.setHistory(response.response);
                        }
                        return [2 /*return*/, response];
                }
            });
        });
    };
    QrScannerPage.prototype.extendedRevenuePointsManually = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.params.type === "earn")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.admin.extendedRevenuePointsManually(keyCode, this.params.totalRevenue, this.params.extendedRevenueFactsId, idType, this.params.externalId)];
                    case 1:
                        response = _a.sent();
                        if (response.ok === false)
                            this.toast.show(this.translate._(response.error.message), "error");
                        return [2 /*return*/, response.ok];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.user = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, checkInResponse, details, queryParams, saveUserResponse;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!keyCode) return [3 /*break*/, 9];
                        return [4 /*yield*/, Promise.all([
                                this.userService.checkIn(keyCode),
                                this.userService.getDetails(keyCode, idType),
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), checkInResponse = _a[0], details = _a[1];
                        if (checkInResponse.ok === false)
                            this.toast.show(this.translate._(checkInResponse.error.message), "error");
                        if (!(details.ok === true)) return [3 /*break*/, 7];
                        queryParams = { idType: idType, keyCode: keyCode };
                        return [4 /*yield*/, this.saveUser(details.response, idType)];
                    case 2:
                        saveUserResponse = _b.sent();
                        if (!(saveUserResponse.ok === true)) return [3 /*break*/, 5];
                        if (!(!details.response.isRegistered && this.registrationType !== "NO")) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.showRegisterModal(keyCode, details.response)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        this.routing.goForward([SharedRoutes.burn], { queryParams: queryParams });
                        return [3 /*break*/, 6];
                    case 5:
                        this.toast.show(this.translate._(saveUserResponse.error.message), "error");
                        _b.label = 6;
                    case 6: return [2 /*return*/, saveUserResponse.ok];
                    case 7:
                        this.toast.show(this.translate._(details.error.message), "error");
                        _b.label = 8;
                    case 8: return [2 /*return*/, details.ok];
                    case 9: return [2 /*return*/, false];
                }
            });
        });
    };
    QrScannerPage.prototype.earn = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(keyCode && this.params.type === "earn")) return [3 /*break*/, 4];
                        if (!this.params.manualPoints) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.revenuePoints(keyCode, idType)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.extendedRevenuePointsManually(keyCode, idType)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [2 /*return*/, false];
                }
            });
        });
    };
    QrScannerPage.prototype.showRegisterModal = function (keyCode, user) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, registerModal, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            registrationType: this.registrationType,
                            keyCode: keyCode,
                            userReferenceCode: user.userReferenceCode,
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: RegisterPage,
                                cssClass: "modal modal--full-screen",
                                componentProps: componentProps,
                            })];
                    case 1:
                        registerModal = _a.sent();
                        return [4 /*yield*/, registerModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, registerModal.onDidDismiss()];
                    case 3:
                        data = (_a.sent()).data;
                        if (!(data === "SUCCESS")) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.showAttentionModal()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/, data === "SUCCESS"];
                }
            });
        });
    };
    QrScannerPage.prototype.playSound = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(this.audios && this.audios[file])) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.audios[file].play()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.enableAppQRScanner = function () {
        return __awaiter(this, void 0, void 0, function () {
            var authorized, componentProps, informationModal, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scannerEnabled = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 10, , 11]);
                        if (!this.isOnCapacitor) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.qrScanner.prepare()];
                    case 2:
                        authorized = (_a.sent()).authorized;
                        if (!authorized) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.qrScanner.useFrontCamera()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.qrScanner.show()];
                    case 4:
                        _a.sent();
                        this.qrScanner$ = this.qrScanner.scan().subscribe(function (text) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.qrScanner$.unsubscribe();
                                        this.scannerCode(text, false);
                                        return [4 /*yield*/, this.qrScanner.hide()];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); });
                        return [3 /*break*/, 9];
                    case 5:
                        componentProps = {
                            title: this.translate._("CAMERA_ACCESS_DENIED"),
                            description: this.translate._("GRANT_CAMERA_INSTRUCTIONS"),
                            buttonText: this.translate._("OK"),
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: InformationModalPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 6:
                        informationModal = _a.sent();
                        return [4 /*yield*/, informationModal.present()];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, informationModal.onDidDismiss()];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        error_2 = _a.sent();
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.disableAppQRScanner = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scannerEnabled = false;
                        if (!this.isOnCapacitor) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.qrScanner.hide()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.validateQr = function (qrResult) {
        var resp = this.getQrType(qrResult);
        if (resp.ok === true) {
            switch (resp.response.type) {
                case "uk":
                case "cc":
                case "pk":
                    return this.params.type === "burn"
                        ? { ok: true, response: { type: "user", keyCode: resp.response.keyCode, idType: resp.response.idType } }
                        : { ok: true, response: { type: "earn", keyCode: resp.response.keyCode, idType: resp.response.idType } };
                case "ruk":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "reward",
                                qrObject: { keyCode: resp.response.keyCode, rewardId: this.unmask(resp.response.hashedReward, REWARD_MASK) },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
                case "ck":
                    return this.params.type === "burn"
                        ? { ok: false, error: { message: this.translate._("INVALID_QR") } }
                        : { ok: true, response: { type: "acumulate", couponId: resp.response.couponId } };
                case "cuk":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "coupon",
                                qrObject: { keyCode: resp.response.keyCode, couponId: this.unmask(resp.response.hashedCoupon, COUPON_MASK) },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
                case "ckuk":
                    return this.params.type === "burn"
                        ? {
                            ok: true,
                            response: {
                                type: "coupon",
                                qrObject: { keyCode: resp.response.keyCode, couponId: resp.response.couponId, idType: resp.response.idType },
                            },
                        }
                        : { ok: false, error: { message: this.translate._("INVALID_QR") } };
            }
        }
        else {
            return resp;
        }
    };
    QrScannerPage.prototype.burnReward = function (_a) {
        var keyCode = _a.keyCode, rewardId = _a.rewardId;
        return __awaiter(this, void 0, void 0, function () {
            var details, saveUserResponse, queryParams;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.userService.getDetails(keyCode)];
                    case 1:
                        details = _b.sent();
                        if (!(details.ok === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.saveUser(details.response)];
                    case 2:
                        saveUserResponse = _b.sent();
                        if (saveUserResponse.ok === true) {
                            queryParams = { keyCode: keyCode, rewardId: rewardId };
                            this.routing.goForward([SharedRoutes.burn], { queryParams: queryParams });
                        }
                        else {
                            this.toast.show(this.translate._(saveUserResponse.error.message), "error");
                        }
                        return [2 /*return*/, saveUserResponse.ok];
                    case 3:
                        this.toast.show(this.translate._(details.error.message), "error");
                        return [2 /*return*/, details.ok];
                }
            });
        });
    };
    QrScannerPage.prototype.burnCoupon = function (_a) {
        var idType = _a.idType, keyCode = _a.keyCode, couponId = _a.couponId;
        return __awaiter(this, void 0, void 0, function () {
            var details, saveUserResponse, queryParams;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.userService.getDetails(keyCode, idType)];
                    case 1:
                        details = _b.sent();
                        if (!(details.ok === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.saveUser(details.response, idType)];
                    case 2:
                        saveUserResponse = _b.sent();
                        if (saveUserResponse.ok === true) {
                            queryParams = { idType: idType, keyCode: keyCode, couponId: couponId };
                            this.routing.goForward([SharedRoutes.burn], { queryParams: queryParams });
                        }
                        else {
                            this.toast.show(this.translate._(saveUserResponse.error.message), "error");
                        }
                        return [2 /*return*/, saveUserResponse.ok];
                    case 3:
                        this.toast.show(this.translate._(details.error.message), "error");
                        return [2 /*return*/, details.ok];
                }
            });
        });
    };
    QrScannerPage.prototype.showAttentionModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, informationModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            title: this.translate._("CONFIRM"),
                            description: this.translate._("REGISTRATION_NOT_COMPLETE"),
                            buttonText: this.translate._("UNDERSTOOD"),
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: InformationModalPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 1:
                        informationModal = _a.sent();
                        return [4 /*yield*/, informationModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, informationModal.onDidDismiss()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QrScannerPage.prototype.getQrType = function (qrResult) {
        try {
            var qrObject = JSON.parse(qrResult);
            if (qrObject.accountId) {
                if (qrObject.accountCouponId || qrObject.couponId) {
                    return {
                        ok: true,
                        response: {
                            type: "ckuk",
                            keyCode: qrObject.accountId,
                            couponId: qrObject.accountCouponId || qrObject.couponId,
                            idType: qrObject.idType ? qrObject.idType : "ID",
                        },
                    };
                }
                else {
                    return { ok: true, response: { type: "uk", keyCode: qrObject.accountId, idType: qrObject.idType ? qrObject.idType : "ID" } };
                }
            }
            else {
                return { ok: false, error: { message: this.translate._("INVALID_QR") } };
            }
        }
        catch (error) {
            var array = qrResult.split("/");
            if (array.length > 4) {
                var type = array[array.length - 2];
                var value = array[array.length - 1];
                switch (type) {
                    case "uk":
                    case "cc":
                    case "pk":
                        return { ok: true, response: { type: type, keyCode: value } };
                    case "ruk":
                        var hashedReward = value.split("-");
                        return { ok: true, response: { type: type, keyCode: hashedReward[0], hashedReward: hashedReward[1] } };
                    case "ck":
                        return { ok: true, response: { type: type, couponId: value } };
                    case "cuk":
                        var hashedCoupon = value.split("-");
                        return { ok: true, response: { type: type, keyCode: hashedCoupon[0], hashedCoupon: hashedCoupon[1] } };
                    case "ckuk":
                        var coupon = value.split("-");
                        return { ok: true, response: { type: type, keyCode: coupon[0], couponId: coupon[1] } };
                    default:
                        return { ok: false, error: { message: this.translate._("INVALID_QR") } };
                }
            }
            else if (array.length === 4 || array.length === 1) {
                return { ok: true, response: { type: "uk", keyCode: array[array.length - 1], idType: "ID" } };
            }
            else {
                return { ok: false, error: { message: this.translate._("INVALID_QR") } };
            }
        }
    };
    QrScannerPage.prototype.unmask = function (ticketId, mask) {
        var ticket = "";
        for (var i = 0; i < ticketId.length; i++) {
            var c = ticketId.substring(i, i + 1);
            var x = mask.indexOf(c);
            if (x < 0) {
                return null;
            }
            ticket += x;
        }
        return ticket;
    };
    QrScannerPage.prototype.revenuePoints = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.params.type === "earn")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.userService.revenuePoints({
                                deviceKey: this.config.getMountingConfig().deviceKey,
                                keyCode: keyCode,
                                idType: idType,
                                revenueRmb: this.params.revenueRmb,
                                revenueRmc1: this.params.revenueRmc1,
                                revenueRmc2: this.params.revenueRmc2,
                                revenueRmc3: this.params.revenueRmc3,
                                revenueRmt: this.params.revenueRmt,
                                totalPoints: this.params.manualPoints,
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            this.openSuccessModal(keyCode, this.params.manualPoints, idType);
                        }
                        else {
                            this.toast.show(this.translate._(response.error.message), "error");
                        }
                        return [2 /*return*/, response.ok];
                    case 2: return [2 /*return*/, false];
                }
            });
        });
    };
    QrScannerPage.prototype.validParams = function () {
        switch (this.params.type) {
            case "burn":
                return true;
            case "earn":
                return (!!this.params.manualPoints ||
                    (!!this.params.totalRevenue && !!this.params.date) ||
                    (!!this.params.totalRevenue && !!this.params.totalPoints && !!this.params.externalId));
            default:
                return false;
        }
    };
    QrScannerPage.prototype.getMobileOperatingSystem = function () {
        if (/windows phone/i.test(navigator.userAgent || navigator.vendor)) {
            return "Windows Phone";
        }
        if (/android/i.test(navigator.userAgent || navigator.vendor)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor)) {
            return "iOS";
        }
        return "unknown";
    };
    QrScannerPage.prototype.loadAudioFiles = function () {
        var _a;
        try {
            this.audios = (_a = {},
                _a[IAudioFile.scanned] = new Audio("assets/scanned.mp3"),
                _a[IAudioFile.socket] = new Audio("assets/socket.mp3"),
                _a);
            this.audios[IAudioFile.scanned].load();
            this.audios[IAudioFile.socket].load();
        }
        catch (error) {
            console.error(error);
        }
    };
    return QrScannerPage;
}());
export { QrScannerPage };
