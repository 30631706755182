<div
  class="ticket__image"
  [style.backgroundImage]="ticket?.teaserPictureFilePath?.file ? 'url(' + ticket?.teaserPictureFilePath?.file + ')' : ''"
></div>
<span class="ticket__title">
  {{ ticket?.title }}
</span>
<span *ngIf="ticket?.points" class="ticket__price">
  {{ ticket["points"] | number: "":translate.getSessionLanguage() }} {{ translate._("P") }}</span
>
<span *ngIf="!ticket?.points" class="ticket__price">
  {{ translate._("FREE") }}
</span>
