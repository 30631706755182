/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success-redeem.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i6 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i7 from "../../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i8 from "angular-feather";
import * as i9 from "./success-redeem.page";
import * as i10 from "../../../../../../shared/lib/common/services/routing/routing.service";
import * as i11 from "../../../../../../shared/lib/common/services/p4m/p4m.service";
import * as i12 from "../../../../../../shared/lib/v2/services/admin/admin.service";
import * as i13 from "../../../../../../shared/lib/common/services/config/config.service";
import * as i14 from "../../../../../../shared/lib/common/services/translate/translate.service";
var styles_SuccessRedeemPage = [i0.styles];
var RenderType_SuccessRedeemPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessRedeemPage, data: {} });
export { RenderType_SuccessRedeemPage as RenderType_SuccessRedeemPage };
function View_SuccessRedeemPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "success-redeem__total-points"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), i1.ɵppd(4, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("SUCCESS_REDEEM_MODAL_TOTAL_POINTS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.totalPoints, "", _co.translate.getSessionLanguage())); var currVal_2 = _co.translate._("P"); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_SuccessRedeemPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [["class", "success-redeem__spinner"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], null, null); }
export function View_SuccessRedeemPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 18, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 16, "div", [["class", "success-redeem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "shared-close", [["class", "success-redeem__shared-close"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CloseComponent_0, i5.RenderType_CloseComponent)), i1.ɵdid(5, 114688, null, 0, i6.CloseComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "success-redeem__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "i-check", [["class", "success-redeem__check"]], null, null, null, i7.View_ɵbt_0, i7.RenderType_ɵbt)), i1.ɵdid(9, 49152, null, 0, i8.ɵbt, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "success-redeem__points"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", " ", ""])), i1.ɵppd(12, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuccessRedeemPage_1)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuccessRedeemPage_2)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "ion-button", [["class", "success-redeem__button"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(18, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(19, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_3 = !_co.loading; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.points, "", _co.translate.getSessionLanguage())); var currVal_2 = _co.translate._("P"); _ck(_v, 11, 0, currVal_1, currVal_2); var currVal_5 = _co.translate._("SCHLIESSEN"); _ck(_v, 19, 0, currVal_5); }); }
export function View_SuccessRedeemPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-success-redeem", [], null, null, null, View_SuccessRedeemPage_0, RenderType_SuccessRedeemPage)), i1.ɵdid(1, 114688, null, 0, i9.SuccessRedeemPage, [i10.RoutingService, i3.ModalController, i11.P4mService, i12.AdminService, i13.ConfigService, i14.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuccessRedeemPageNgFactory = i1.ɵccf("ocm-success-redeem", i9.SuccessRedeemPage, View_SuccessRedeemPage_Host_0, { keyCode: "keyCode", idType: "idType", points: "points", title: "title" }, {}, []);
export { SuccessRedeemPageNgFactory as SuccessRedeemPageNgFactory };
