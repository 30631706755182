var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from "@angular/common/http";
import { SharedRoutes, LOCAL_STORAGE } from "shared/lib/common/enums";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";
import { UAParser } from "ua-parser-js";
import * as uuid from "uuid";
import { ProgramService } from "../program/program.service";
import { MountingsService } from "../mounting/mounting.service";
import { DevicesService } from "../devices/devices.service";
import * as i0 from "@angular/core";
import * as i1 from "../program/program.service";
import * as i2 from "../mounting/mounting.service";
import * as i3 from "../devices/devices.service";
import * as i4 from "../../../common/services/utils/utils.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../../../common/services/config/config.service";
import * as i7 from "../../../common/services/environment/environment.service";
var DeviceMountingService = /** @class */ (function () {
    function DeviceMountingService(programService, mountingService, deviceService, utils, httpClient, config, environment) {
        this.programService = programService;
        this.mountingService = mountingService;
        this.deviceService = deviceService;
        this.utils = utils;
        this.httpClient = httpClient;
        this.config = config;
        this.environment = environment;
    }
    Object.defineProperty(DeviceMountingService.prototype, "profile", {
        get: function () {
            if (!this.mounting)
                this.unmount();
            return this.mounting ? this.mounting.profile : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceMountingService.prototype, "images", {
        get: function () {
            return this.deviceImages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceMountingService.prototype, "mountingId", {
        get: function () {
            return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_ID));
        },
        set: function (mountingId) {
            localStorage.setItem(LOCAL_STORAGE.MOUNTING_ID, this.utils.encrypt(mountingId.toString()));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceMountingService.prototype, "deviceKey", {
        get: function () {
            return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.DEVICE_KEY));
        },
        set: function (deviceKey) {
            localStorage.setItem(LOCAL_STORAGE.DEVICE_KEY, this.utils.encrypt(deviceKey));
        },
        enumerable: true,
        configurable: true
    });
    DeviceMountingService.prototype.mountingControllerCreateOneBase = function (mounting) {
        return this.mountingService.mountingControllerCreateOneBase(mounting);
    };
    DeviceMountingService.prototype.unmount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.mountingService
                                .mountingControllerUpdateOne(this.mountingId, {
                                deviceKey: null,
                                organization: this.config.getOrganization(),
                            })
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.deviceService.deviceControllerDeleteOne(this.deviceKey).toPromise()];
                    case 2:
                        _a.sent();
                        localStorage.clear();
                        window.location.href = SharedRoutes.login;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DeviceMountingService.prototype.mount = function (appInteractionId, deviceInformation) {
        if (deviceInformation === void 0) { deviceInformation = ""; }
        return __awaiter(this, void 0, void 0, function () {
            var mounting, parser, deviceKey, resp, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, , 9]);
                        return [4 /*yield*/, this.getMountingFromApi(appInteractionId)];
                    case 1:
                        mounting = _a.sent();
                        if (!mounting) return [3 /*break*/, 6];
                        if (!!mounting.deviceKey) return [3 /*break*/, 4];
                        parser = new UAParser();
                        return [4 /*yield*/, this.deviceService
                                .deviceControllerCreateOneBase({
                                os: parser.getOS().name,
                                osVersion: parser.getOS().version,
                                browser: parser.getBrowser().name + " " + parser.getBrowser().version,
                                deviceInformation: deviceInformation,
                                connected: false,
                                version: this.environment.getVariable("RELEASE"),
                                deviceKey: uuid.v1(),
                                organization: this.config.getOrganization(),
                            })
                                .toPromise()];
                    case 2:
                        deviceKey = (_a.sent()).deviceKey;
                        return [4 /*yield*/, this.mountingService
                                .mountingControllerUpdateOne(mounting.id, { deviceKey: deviceKey, organization: this.config.getOrganization() })
                                .toPromise()];
                    case 3:
                        resp = _a.sent();
                        this.mountingId = mounting.id;
                        this.mounting = mounting;
                        this.deviceKey = deviceKey;
                        return [2 /*return*/, {
                                ok: true,
                                response: __assign({}, mounting, { deviceKey: deviceKey, appInteractionId: resp.appInteractionId, cashInteractionId: resp.cashInteractionId }),
                            }];
                    case 4: return [2 /*return*/, { ok: false, error: "MOUNTING_IN_USE" }];
                    case 5: return [3 /*break*/, 7];
                    case 6: return [2 /*return*/, { ok: false, error: "MOUNTING_NOT_FOUND" }];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_2 = _a.sent();
                        return [2 /*return*/, { ok: false, error: error_2 }];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    DeviceMountingService.prototype.getMounting = function () {
        return __awaiter(this, void 0, void 0, function () {
            var mounting, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getMountingFromApi()];
                    case 1:
                        mounting = _a.sent();
                        if (mounting) {
                            this.mountingId = mounting.id;
                            this.mounting = mounting;
                            this.deviceKey = mounting.deviceKey;
                            return [2 /*return*/, { ok: true, response: mounting }];
                        }
                        else
                            return [2 /*return*/, { ok: false, error: "MOUNTING_NOT_FOUND" }];
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        return [2 /*return*/, { ok: false, error: error_3.message || error_3 }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeviceMountingService.prototype.parseMountingConfigV1ToV2 = function (mounting) {
        return __awaiter(this, void 0, void 0, function () {
            var store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!mounting.store) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.programService.getStore(mounting.partnerId, mounting.organization, mounting.store).toPromise()];
                    case 1:
                        store = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, {
                            workstationId: mounting.appInteractionId,
                            deviceId: null,
                            deviceKey: mounting.deviceKey,
                            cameraPosition: mounting.profile.cameraPosition,
                            clientId: "1234",
                            clientName: null,
                            posMode: mounting.profile.mode,
                            storeId: Number(mounting.store),
                            storeName: null,
                            deviceInformation: null,
                            partner: { id: mounting.partnerId, name: "", status: "" },
                            store: store,
                            legalName: null,
                            appInteractionId: mounting.appInteractionId,
                            cashInteractionId: mounting.cashInteractionId,
                        }];
                }
            });
        });
    };
    DeviceMountingService.prototype.getMountingFromApi = function (appInteractionId) {
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, params, mounting, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        baseUrl = this.environment.getVariable("API_MOUNTING") + "/mounting-service/mountings";
                        params = null;
                        if (this.mountingId) {
                            baseUrl += "/" + this.mountingId;
                        }
                        if (appInteractionId) {
                            params = { filter: "appInteractionId||$eq||" + appInteractionId };
                        }
                        if (!appInteractionId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.httpClient.get(baseUrl, { params: params }).toPromise()];
                    case 1:
                        _a = (_d.sent())[0];
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.httpClient.get(baseUrl, { params: params }).toPromise()];
                    case 3:
                        _a = _d.sent();
                        _d.label = 4;
                    case 4:
                        mounting = _a;
                        if (!(mounting && mounting.profileId)) return [3 /*break*/, 6];
                        _b = mounting;
                        return [4 /*yield*/, this.getMountingProfile(mounting.profileId)];
                    case 5:
                        _b.profile = _d.sent();
                        _d.label = 6;
                    case 6:
                        if (!mounting) return [3 /*break*/, 8];
                        _c = this;
                        return [4 /*yield*/, this.getDeviceImages(mounting)];
                    case 7:
                        _c.deviceImages = _d.sent();
                        _d.label = 8;
                    case 8: return [2 /*return*/, mounting];
                }
            });
        });
    };
    DeviceMountingService.prototype.getMountingProfile = function (profileId) {
        return this.httpClient
            .get(this.environment.getVariable("API_MOUNTING") + "/mounting-service/profiles/" + profileId)
            .toPromise();
    };
    DeviceMountingService.prototype.getDeviceImages = function (mounting) {
        var or = [];
        if (mounting.organization)
            or.push("programs.id||$eq||" + mounting.organization);
        if (mounting.partnerId)
            or.push("partners.id||$eq||" + mounting.partnerId);
        if (mounting.store)
            or.push("stores.id||$eq||" + mounting.store);
        if (mounting.profileId)
            or.push("profiles.id||$eq||" + mounting.profileId);
        return this.httpClient
            .get(this.environment.getVariable("API_MOUNTING") + "/mounting-service/images", {
            params: { join: ["programs", "partners", "stores", "profiles"], or: or },
        })
            .toPromise();
    };
    DeviceMountingService.ngInjectableDef = i0.defineInjectable({ factory: function DeviceMountingService_Factory() { return new DeviceMountingService(i0.inject(i1.ProgramService), i0.inject(i2.MountingsService), i0.inject(i3.DevicesService), i0.inject(i4.UtilsService), i0.inject(i5.HttpClient), i0.inject(i6.ConfigService), i0.inject(i7.EnvironmentService)); }, token: DeviceMountingService, providedIn: "root" });
    return DeviceMountingService;
}());
export { DeviceMountingService };
