/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EndpointData { 
    label: string;
    type: EndpointData.TypeEnum;
    url: string;
}
export namespace EndpointData {
    export type TypeEnum = 'COUPONCODE' | 'COMMUNICATION';
    export const TypeEnum = {
        COUPONCODE: 'COUPONCODE' as TypeEnum,
        COMMUNICATION: 'COMMUNICATION' as TypeEnum
    };
}


