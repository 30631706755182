var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService, TranslateService, ConfigService } from "shared/lib/common/services";
import { AdminService } from "shared/lib/v2/services";
import { EnsureUnmountLoginModal as SharedEnsureUnmountLoginModal } from "shared/lib/common/pages";
var EnsureUnmountLoginModal = /** @class */ (function (_super) {
    __extends(EnsureUnmountLoginModal, _super);
    function EnsureUnmountLoginModal(_translate, _fb, _admin, _config, _toast, _modal) {
        var _this = _super.call(this, _translate, _fb, _admin, _config, _toast, _modal) || this;
        _this._translate = _translate;
        _this._fb = _fb;
        _this._admin = _admin;
        _this._config = _config;
        _this._toast = _toast;
        _this._modal = _modal;
        return _this;
    }
    return EnsureUnmountLoginModal;
}(SharedEnsureUnmountLoginModal));
export { EnsureUnmountLoginModal };
