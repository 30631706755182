import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Configuration } from '../configuration';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
var ProgramService = /** @class */ (function () {
    function ProgramService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://staging.convercus.io';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }
    ProgramService.prototype.addToHttpParams = function (httpParams, value, key) {
        if (typeof value === "object") {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        }
        else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    };
    ProgramService.prototype.addToHttpParamsRecursive = function (httpParams, value, key) {
        var _this = this;
        if (typeof value === "object") {
            if (Array.isArray(value)) {
                value.forEach(function (elem) { return httpParams = _this.addToHttpParamsRecursive(httpParams, elem, key); });
            }
            else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, value.toISOString().substr(0, 10));
                }
                else {
                    throw Error("key may not be null if value is Date");
                }
            }
            else {
                Object.keys(value).forEach(function (k) { return httpParams = _this.addToHttpParamsRecursive(httpParams, value[k], key != null ? key + "." + k : k); });
            }
        }
        else if (key != null) {
            httpParams = httpParams.append(key, value);
        }
        else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    };
    ProgramService.prototype.addAdminUser = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addAdminUser.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addAdminUser.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAdminUser.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/admins", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addCustomProperty = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addCustomProperty.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addCustomProperty.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCustomProperty.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/customproperties", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addEndpoint = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addEndpoint.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addEndpoint.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addEndpoint.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/endpoints", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addInteractionPointToPartner = function (interactionId, partnerId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addInteractionPointToPartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling addInteractionPointToPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addInteractionPointToPartner.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addInteractionPointToPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/interactionpoints", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addInteractionPointToProgram = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addInteractionPointToProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addInteractionPointToProgram.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addInteractionPointToProgram.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/interactionpoints", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addInteractionPointToStore = function (interactionId, partnerId, programId, storeId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addInteractionPointToStore.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling addInteractionPointToStore.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addInteractionPointToStore.');
        }
        if (storeId === null || storeId === undefined) {
            throw new Error('Required parameter storeId was null or undefined when calling addInteractionPointToStore.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addInteractionPointToStore.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores/" + encodeURIComponent(String(storeId)) + "/interactionpoints", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addPartner = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addPartner.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addProgram = function (interactionId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addProgram.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addProgram.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addStatusLevelSet = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addStatusLevelSet.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addStatusLevelSet.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addStatusLevelSet.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/statuslevelsets", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addStore = function (interactionId, partnerId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addStore.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling addStore.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addStore.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addStore.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.addTag = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling addTag.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling addTag.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addTag.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.post(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/tags", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changeCustomProperty = function (customPropertyId, interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (customPropertyId === null || customPropertyId === undefined) {
            throw new Error('Required parameter customPropertyId was null or undefined when calling changeCustomProperty.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changeCustomProperty.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changeCustomProperty.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeCustomProperty.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/customproperties/" + encodeURIComponent(String(customPropertyId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changeEndpoint = function (endpointId, interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling changeEndpoint.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changeEndpoint.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changeEndpoint.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeEndpoint.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/endpoints/" + encodeURIComponent(String(endpointId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changeInteractionPoint = function (interactionId, interactionPointId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changeInteractionPoint.');
        }
        if (interactionPointId === null || interactionPointId === undefined) {
            throw new Error('Required parameter interactionPointId was null or undefined when calling changeInteractionPoint.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changeInteractionPoint.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeInteractionPoint.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.patch(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/interactionpoints/" + encodeURIComponent(String(interactionPointId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changePartner = function (interactionId, partnerId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changePartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling changePartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changePartner.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changePartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changePassword = function (adminId, interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling changePassword.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changePassword.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changePassword.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changePassword.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.patch(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/admins/" + encodeURIComponent(String(adminId)) + "/password", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changeStatusLevelSet = function (interactionId, programId, statusLevelSetId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changeStatusLevelSet.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changeStatusLevelSet.');
        }
        if (statusLevelSetId === null || statusLevelSetId === undefined) {
            throw new Error('Required parameter statusLevelSetId was null or undefined when calling changeStatusLevelSet.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeStatusLevelSet.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/statuslevelsets/" + encodeURIComponent(String(statusLevelSetId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changeStore = function (interactionId, partnerId, programId, storeId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changeStore.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling changeStore.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changeStore.');
        }
        if (storeId === null || storeId === undefined) {
            throw new Error('Required parameter storeId was null or undefined when calling changeStore.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeStore.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores/" + encodeURIComponent(String(storeId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.changeTag = function (interactionId, programId, tagId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling changeTag.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling changeTag.');
        }
        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling changeTag.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeTag.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/tags/" + encodeURIComponent(String(tagId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.deactivatePartner = function (interactionId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deactivatePartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling deactivatePartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling deactivatePartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.deactivateStore = function (interactionId, partnerId, programId, storeId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deactivateStore.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling deactivateStore.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling deactivateStore.');
        }
        if (storeId === null || storeId === undefined) {
            throw new Error('Required parameter storeId was null or undefined when calling deactivateStore.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores/" + encodeURIComponent(String(storeId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.deleteEndpoint = function (endpointId, interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling deleteEndpoint.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteEndpoint.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling deleteEndpoint.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/endpoints/" + encodeURIComponent(String(endpointId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.deleteInteractionPoint = function (interactionId, interactionPointId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteInteractionPoint.');
        }
        if (interactionPointId === null || interactionPointId === undefined) {
            throw new Error('Required parameter interactionPointId was null or undefined when calling deleteInteractionPoint.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling deleteInteractionPoint.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/interactionpoints/" + encodeURIComponent(String(interactionPointId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.deleteStatusLevelSet = function (interactionId, programId, statusLevelSetId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling deleteStatusLevelSet.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling deleteStatusLevelSet.');
        }
        if (statusLevelSetId === null || statusLevelSetId === undefined) {
            throw new Error('Required parameter statusLevelSetId was null or undefined when calling deleteStatusLevelSet.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.delete(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/statuslevelsets/" + encodeURIComponent(String(statusLevelSetId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getAdminUser = function (adminId, interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling getAdminUser.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAdminUser.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getAdminUser.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/admins/" + encodeURIComponent(String(adminId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getAdminUsersOfProgram = function (interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAdminUsersOfProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getAdminUsersOfProgram.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/admins", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getAffiliatePartnersOfPartner = function (interactionId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAffiliatePartnersOfPartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getAffiliatePartnersOfPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getAffiliatePartnersOfPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/affiliates", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getAffiliateUnitsOfPartner = function (interactionId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAffiliateUnitsOfPartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getAffiliateUnitsOfPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getAffiliateUnitsOfPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/affiliateunits", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getAvailableNotificationConfigurationValues = function (interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getAvailableNotificationConfigurationValues.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getAvailableNotificationConfigurationValues.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/notificationconfiguration/availablevalues", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getCustomPropertiesOfProgram = function (interactionId, programId, domain, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getCustomPropertiesOfProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getCustomPropertiesOfProgram.');
        }
        var queryParameters = new HttpParams({ encoder: this.encoder });
        if (domain !== undefined && domain !== null) {
            queryParameters = this.addToHttpParams(queryParameters, domain, 'domain');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/customproperties", {
            params: queryParameters,
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getCustomProperty = function (customPropertyId, interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (customPropertyId === null || customPropertyId === undefined) {
            throw new Error('Required parameter customPropertyId was null or undefined when calling getCustomProperty.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getCustomProperty.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getCustomProperty.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/customproperties/" + encodeURIComponent(String(customPropertyId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getEndpoint = function (endpointId, interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (endpointId === null || endpointId === undefined) {
            throw new Error('Required parameter endpointId was null or undefined when calling getEndpoint.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getEndpoint.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getEndpoint.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/endpoints/" + encodeURIComponent(String(endpointId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getEndpoints = function (interactionId, programId, type, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getEndpoints.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getEndpoints.');
        }
        var queryParameters = new HttpParams({ encoder: this.encoder });
        if (type !== undefined && type !== null) {
            queryParameters = this.addToHttpParams(queryParameters, type, 'type');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/endpoints", {
            params: queryParameters,
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointDetailsOfProgramById = function (interactionId, interactionPointId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointDetailsOfProgramById.');
        }
        if (interactionPointId === null || interactionPointId === undefined) {
            throw new Error('Required parameter interactionPointId was null or undefined when calling getInteractionPointDetailsOfProgramById.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointDetailsOfProgramById.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/interactionpoints/" + encodeURIComponent(String(interactionPointId)) + "/details", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointOfPartnerById = function (interactionId, interactionPointId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointOfPartnerById.');
        }
        if (interactionPointId === null || interactionPointId === undefined) {
            throw new Error('Required parameter interactionPointId was null or undefined when calling getInteractionPointOfPartnerById.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getInteractionPointOfPartnerById.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointOfPartnerById.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/interactionpoints/" + encodeURIComponent(String(interactionPointId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointOfProgramById = function (interactionId, interactionPointId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointOfProgramById.');
        }
        if (interactionPointId === null || interactionPointId === undefined) {
            throw new Error('Required parameter interactionPointId was null or undefined when calling getInteractionPointOfProgramById.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointOfProgramById.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/interactionpoints/" + encodeURIComponent(String(interactionPointId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointOfStoreById = function (interactionId, interactionPointId, partnerId, programId, storeId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointOfStoreById.');
        }
        if (interactionPointId === null || interactionPointId === undefined) {
            throw new Error('Required parameter interactionPointId was null or undefined when calling getInteractionPointOfStoreById.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getInteractionPointOfStoreById.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointOfStoreById.');
        }
        if (storeId === null || storeId === undefined) {
            throw new Error('Required parameter storeId was null or undefined when calling getInteractionPointOfStoreById.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores/" + encodeURIComponent(String(storeId)) + "/interactionpoints/" + encodeURIComponent(String(interactionPointId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointsOfPartner = function (interactionId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointsOfPartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getInteractionPointsOfPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointsOfPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/interactionpoints", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointsOfProgram = function (interactionId, programId, recursive, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointsOfProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointsOfProgram.');
        }
        var queryParameters = new HttpParams({ encoder: this.encoder });
        if (recursive !== undefined && recursive !== null) {
            queryParameters = this.addToHttpParams(queryParameters, recursive, 'recursive');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/interactionpoints", {
            params: queryParameters,
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getInteractionPointsOfStore = function (interactionId, partnerId, programId, storeId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getInteractionPointsOfStore.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getInteractionPointsOfStore.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getInteractionPointsOfStore.');
        }
        if (storeId === null || storeId === undefined) {
            throw new Error('Required parameter storeId was null or undefined when calling getInteractionPointsOfStore.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores/" + encodeURIComponent(String(storeId)) + "/interactionpoints", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getNotificationConfiguration = function (interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getNotificationConfiguration.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getNotificationConfiguration.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/notificationconfiguration", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getPartner = function (interactionId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getPartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getPartnersOfProgram = function (interactionId, programId, partnerType, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getPartnersOfProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getPartnersOfProgram.');
        }
        var queryParameters = new HttpParams({ encoder: this.encoder });
        if (partnerType !== undefined && partnerType !== null) {
            queryParameters = this.addToHttpParams(queryParameters, partnerType, 'partnerType');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners", {
            params: queryParameters,
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getProgram = function (interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getProgram.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getStatusLevelSetById = function (interactionId, programId, statusLevelSetId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getStatusLevelSetById.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getStatusLevelSetById.');
        }
        if (statusLevelSetId === null || statusLevelSetId === undefined) {
            throw new Error('Required parameter statusLevelSetId was null or undefined when calling getStatusLevelSetById.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/statuslevelsets/" + encodeURIComponent(String(statusLevelSetId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getStatusLevelSets = function (interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getStatusLevelSets.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getStatusLevelSets.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/statuslevelsets", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getStore = function (interactionId, partnerId, programId, storeId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getStore.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getStore.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getStore.');
        }
        if (storeId === null || storeId === undefined) {
            throw new Error('Required parameter storeId was null or undefined when calling getStore.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores/" + encodeURIComponent(String(storeId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getStoresOfPartner = function (interactionId, partnerId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getStoresOfPartner.');
        }
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling getStoresOfPartner.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getStoresOfPartner.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/partners/" + encodeURIComponent(String(partnerId)) + "/stores", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getStoresOfProgram = function (interactionId, programId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getStoresOfProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getStoresOfProgram.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/stores", {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getTag = function (interactionId, programId, tagId, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getTag.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getTag.');
        }
        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling getTag.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/tags/" + encodeURIComponent(String(tagId)), {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.getTagsOfProgram = function (interactionId, programId, domain, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling getTagsOfProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling getTagsOfProgram.');
        }
        var queryParameters = new HttpParams({ encoder: this.encoder });
        if (domain !== undefined && domain !== null) {
            queryParameters = this.addToHttpParams(queryParameters, domain, 'domain');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.get(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/tags", {
            params: queryParameters,
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.patchAdminUser = function (adminId, interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling patchAdminUser.');
        }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling patchAdminUser.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling patchAdminUser.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchAdminUser.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.patch(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/admins/" + encodeURIComponent(String(adminId)), body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.prototype.updateNotificationConfiguration = function (interactionId, programId, body, observe, reportProgress, options) {
        if (observe === void 0) { observe = 'body'; }
        if (reportProgress === void 0) { reportProgress = false; }
        if (interactionId === null || interactionId === undefined) {
            throw new Error('Required parameter interactionId was null or undefined when calling updateNotificationConfiguration.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling updateNotificationConfiguration.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateNotificationConfiguration.');
        }
        var headers = this.defaultHeaders;
        if (interactionId !== undefined && interactionId !== null) {
            headers = headers.set('interaction-id', String(interactionId));
        }
        // authentication (jwt) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }
        var httpHeaderAcceptSelected = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            var httpHeaderAccepts = [];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        var consumes = [
            'application/json'
        ];
        var httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        var responseType = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }
        return this.httpClient.put(this.configuration.basePath + "/programs/" + encodeURIComponent(String(programId)) + "/notificationconfiguration", body, {
            responseType: responseType,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    };
    ProgramService.ngInjectableDef = i0.defineInjectable({ factory: function ProgramService_Factory() { return new ProgramService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: ProgramService, providedIn: "root" });
    return ProgramService;
}());
export { ProgramService };
