import { Injectable } from "@angular/core";
import { IResponse, IUserV2 } from "shared/lib/common/interfaces";
import {
  IBarcodeType,
  ICheckInResponse,
  ICoupon,
  IExtendedRevenuePointsManuallyResponse,
  IFile,
  IGetUserDetailsResponse,
  IGetUserHistoryResponse,
  IIdentifierType,
  IQrParams,
  IRevenuePoints,
  IRevenuePointsResponse,
  IReward,
  IUser,
  IVoucher,
  Picture,
  VoucherCoupon,
  VoucherReward,
  VoucherType,
} from "shared/lib/common/interfaces";
import { AdminService } from "shared/lib/common/services/admin/admin.service";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { AccountCouponV2 } from "shared/lib/v2/apis/coupons";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: IUser;
  public history: IGetUserHistoryResponse;

  constructor(
    public sentry: SentryService,
    public p4m: P4mService,
    public admin: AdminService,
    public config: ConfigService,
    public translate: TranslateService,
  ) {}

  public async checkIn(keyCode: string): Promise<IResponse<ICheckInResponse>> {
    return await this.p4m.checkIn({
      apiKey: this.admin.getApiKey(),
      deviceKey: this.config.getMountingConfig().deviceKey,
      language: this.translate.getSessionLanguage(),
      keyCode,
      organization: this.config.getOrganization(),
    });
  }

  public setUser(user: IUser): void {
    this.user = user;
  }

  public getUser(): IUser {
    return this.user;
  }

  public setHistory(history: IGetUserHistoryResponse): void {
    this.history = history;
  }

  public getHistory(): IGetUserHistoryResponse {
    return this.history || { transactions: [] };
  }

  public updateUserCoupon(coupon: AccountCouponV2): void {
    const i = this.user.coupons.findIndex(c => c.couponId === coupon.couponId);
    if (i >= 0) this.user.coupons[i] = this.p4m.parseCoupons([coupon], this.user.totalPoints)[0];
  }

  public getExternalCode(voucher: IVoucher): IResponse<{ type: IBarcodeType; code: string }, { message: "COUPON_NOT_FOUND" }> {
    let response: { type: IBarcodeType; code: string };
    if (this.user) {
      if (voucher.voucherType === VoucherType.coupon) {
        (this.user.coupons || []).forEach(c => {
          if (c.couponId === voucher.voucherId) {
            response = {
              type: c.externalCodeBarcodeTypeCode,
              code: c.externalCode,
            };
          }
        });
      } else {
        (this.user.rewards || []).forEach(r => {
          if (r.rewardId === voucher.voucherId) {
            response = {
              type: r.externalCodeBarcodeTypeCode,
              code: r.externalCode,
            };
          }
        });
      }
    }
    return response ? { ok: true, response } : { ok: false, error: { message: "COUPON_NOT_FOUND" } };
  }

  public async getDetails(keyCode: string, idType?: IIdentifierType): Promise<IResponse<IUser>> {
    try {
      const userDetailsResponse = await this.p4m.getUserDetails({
        keyCode,
        apiKey: this.admin.getApiKey(),
        clientId: this.config.getMountingConfig().clientId,
        idType,
      });
      if (userDetailsResponse.ok === true) {
        const [userResponse, couponsResponse, rewardsResponse] = await Promise.all([
          this.p4m.readUser({ idType: "ID", userId: userDetailsResponse.response.userId }),
          this.p4m.getCoupons({ apiKey: this.admin.getApiKey(), clientId: this.config.getMountingConfig().clientId }),
          this.p4m.getRewards({ apiKey: this.admin.getApiKey(), clientId: this.config.getMountingConfig().clientId }),
        ]);
        if (userResponse.ok === true && couponsResponse.ok === true && rewardsResponse.ok === true) {
          const coupons = this.parseCoupons(couponsResponse.response.coupons);
          const rewards = this.parseRewards(rewardsResponse.response.rewards);
          return this.parseUser(keyCode, userDetailsResponse.response, coupons, rewards, userResponse.response, idType);
        } else {
          let message: string;
          [userResponse, couponsResponse, rewardsResponse].forEach(response => {
            if (response.ok === false) message = response.error.message;
          });
          return { ok: false, error: { message } };
        }
      } else {
        return userDetailsResponse;
      }
    } catch (error) {
      this.sentry.handleError({ err: error, method: "user.getDetails" });
      return { ok: false, error: { message: error.message || error } };
    }
  }

  public async getUserHistory(keyCode: string, idType?: IIdentifierType): Promise<IResponse<IGetUserHistoryResponse>> {
    try {
      return await this.p4m.getUserHistory({
        keyCode,
        apiKey: this.admin.getApiKey(),
        clientId: this.config.getMountingConfig().clientId,
        idType,
      });
    } catch (error) {
      this.sentry.handleError({ err: error, method: "user.getUserHistory" });
      return { ok: false, error: { message: error.message || error } };
    }
  }

  public removeUser(): void {
    this.user = undefined;
    this.history = undefined;
  }

  // API1:
  // title: description
  // description: extended description (or empty)

  // API2:
  // title: title || subtitle
  // description: description
  public getVouchers(): IVoucher[] {
    const resp: IVoucher[] = [];
    if (this.user) {
      if (this.user.coupons) {
        this.user.coupons.forEach(c => {
          if (!c.storeId || (c.storeId && c.storeId === this.config.getMountingConfig().storeId)) {
            resp.push({
              title: c.title || c.subtitle || c.description,
              couponTypeCode: c.couponTypeCode,
              voucherType: VoucherType.coupon,
              points: c.points,
              description: c.extendedDescription || (c.title || c.subtitle ? c.description : ""),
              unlocked: c.unlocked,
              voucherId: c.couponId,
              accountVoucherId: c.accountCouponId,
              extendedDescription: c.extendedDescription,
              contentPictureFilePath: this.getPicture(c.couponFiles, "content", c.pictureFilePath),
              teaserPictureFilePath: this.getPicture(c.couponFiles, "teaser", c.pictureFilePath),
              userCouponKeyCodes: c.userCouponKeyCodes,
              redeemedUserCouponKeyCodes: c.redeemedUserCouponKeyCodes,
              validToDate: c.validToDate,
              validFromDate: c.validFromDate,
              redeemDependencyCode: c.redeemDependencyCode,
              customProperties: c.customProperties,
              activation: c.activation,
            });
          }
        });
      }
      if (this.user.rewards) {
        this.user.rewards.forEach(r => {
          resp.push({
            title: r.title || r.subtitle || r.description,
            voucherType: VoucherType.reward,
            decreasePoints: r.decreasePoints,
            description: r.extendedDescription || (r.title || r.subtitle ? r.description : ""),
            voucherId: r.rewardId,
            accountVoucherId: r.accountCouponId,
            unlocked: r.unlocked,
            points: r.points,
            contentPictureFilePath: this.getPicture(r.rewardFiles, "content", r.pictureFilePath),
            teaserPictureFilePath: this.getPicture(r.rewardFiles, "teaser", r.pictureFilePath),
            redeemDependencyCode: r.redeemDependencyCode,
            customProperties: r.customProperties,
            validFromDate: r.validFromDate,
            validToDate: r.validToDate,
            extendedDescription: r.extendedDescription,
            activation: r.activation,
          });
        });
      }
    }
    return resp;
  }

  public async redeemReward(voucher: VoucherReward): Promise<IResponse<any>> {
    try {
      return await this.p4m.redeemReward({
        keyCode: this.user.keyCode,
        rewardId: voucher.voucherId.toString(),
        accountVoucherId: voucher.accountVoucherId,
        apiKey: this.admin.getApiKey(),
        clientId: this.config.getMountingConfig().clientId,
      });
    } catch (error) {
      this.sentry.handleError({ err: error, method: "user.redeemReward" });
      return {
        ok: false,
        error: { message: error },
      };
    }
  }

  public async redeemCoupon(voucher: VoucherCoupon): Promise<IResponse<any>> {
    try {
      return await this.p4m.redeemCoupon({
        keyCode: this.user ? this.user.keyCode : "",
        apiKey: this.admin.getApiKey(),
        clientId: this.config.getMountingConfig().clientId,
        couponId: voucher.voucherId,
        accountVoucherId: voucher.accountVoucherId,
      });
    } catch (error) {
      this.sentry.handleError({ err: error, method: "user.redeemCoupon" });
      return {
        ok: false,
        error: { message: error },
      };
    }
  }

  public convertToPoints(euros: number): number {
    if (this.user && this.user.convertFactor) {
      return euros * this.user.convertFactor;
    } else {
      return euros;
    }
  }

  public async revenuePoints(params: IRevenuePoints): Promise<IResponse<IRevenuePointsResponse>> {
    return await this.p4m.revenuePoints({
      organization: this.config.getOrganization(),
      keyCode: params.keyCode,
      deviceKey: params.deviceKey,
      idType: params.idType,
      revenueRmb: params.revenueRmb,
      revenueRmc1: params.revenueRmc1,
      revenueRmc2: params.revenueRmc2,
      revenueRmc3: params.revenueRmc3,
      revenueRmt: params.revenueRmt,
      totalPoints: params.totalPoints,
    });
  }

  public async bookPoints(keyCode: string, customPoints: string, idType: IIdentifierType): Promise<IResponse<void>> {
    return await this.p4m.bookPoints({
      keyCode,
      points: customPoints,
      idType,
      apiKey: this.admin.getApiKey(),
      clientId: this.config.getMountingConfig().clientId,
    });
  }

  public getEarnCoupons(): ICoupon[] {
    if (this.user && this.user.coupons) {
      return this.user.coupons.filter(({ couponTypeCode }) => couponTypeCode === "EARN");
    } else {
      return [];
    }
  }

  public getEarnListFilters(): string[] {
    const filters: string[] = [];
    const coupons = this.getEarnCoupons();
    if (coupons) {
      coupons.forEach(c => {
        if (c.subtitle && !filters.includes(c.subtitle)) {
          filters.push(c.subtitle);
        }
      });
    }
    return filters;
  }

  public parseCoupons(
    coupons: Array<ICoupon>,
  ): {
    [key: number]: ICoupon;
  } {
    const resp: { [key: number]: ICoupon } = {};
    (coupons || []).forEach(coupon => {
      let storeId: number = null;
      if (coupon.sponsorStore && coupon.sponsorStore.storeId !== null) {
        storeId = coupon.sponsorStore.storeId;
      }
      if (coupon.store && coupon.store.storeId !== null) {
        storeId = coupon.store.storeId;
      }
      resp[coupon.couponId] = { ...coupon, storeId };
    });
    return resp;
  }

  public parseRewards(
    rewards: Array<IReward>,
  ): {
    [key: number]: IReward;
  } {
    const resp: { [key: number]: IReward } = {};
    if (rewards) {
      rewards.forEach(reward => {
        resp[reward.rewardId] = reward;
      });
    }
    return resp;
  }

  public parseUser(
    keyCode: string,
    user: IGetUserDetailsResponse,
    infoCoupons: { [key: number]: ICoupon },
    infoRewards: { [key: number]: IReward },
    userData: IUserV2,
    idType: IIdentifierType,
  ): IResponse<IUser> {
    if (user) {
      if (user.errorInfo) {
        return { ok: false, error: { message: user.errorInfo.errorCode } };
      }
      if (typeof user.totalPoints === undefined) {
        return {
          ok: false,
          error: { message: "EMPTY_USER_TOT" },
        };
      }
      if (typeof user.userId === undefined) {
        return {
          ok: false,
          error: { message: "EMPTY_USER_ID" },
        };
      }
    } else {
      return {
        ok: false,
        error: { message: "EMPTY_USER" },
      };
    }
    const coupons: ICoupon[] = [];
    const rewards: IReward[] = [];
    if (user.coupons) {
      user.coupons.forEach(coupon => {
        coupons.push({
          ...coupon,
          couponId: coupon.couponId,
          couponTypeCode: coupon.couponTypeCode,
          description: coupon.description,
          unlocked: coupon.unlocked,
          subtitle: coupon.subtitle,
          points: coupon.points,
          extendedDescription: coupon.extendedDescription,
          pictureFilePath: coupon.pictureFilePath,
          couponFiles: coupon.couponFiles,
          userCouponKeyCodes: coupon.userCouponKeyCodes,
          redeemedUserCouponKeyCodes: coupon.redeemedUserCouponKeyCodes,
          storeId: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].storeId : null,
          externalCode: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].externalCode : coupon.externalCode,
          externalCodeBarcodeTypeCode: infoCoupons[coupon.couponId]
            ? infoCoupons[coupon.couponId].externalCodeBarcodeTypeCode
            : coupon.externalCodeBarcodeTypeCode,
          validFromDate: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].validFromDate : coupon.validFromDate,
          validToDate: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].validToDate : coupon.validToDate,
        });
      });
    }
    if (user.rewards) {
      user.rewards.forEach(reward => {
        rewards.push({
          ...reward,
          accountCouponId: reward.accountCouponId,
          rewardId: reward.rewardId,
          points: reward.points,
          title: reward.title,
          subtitle: reward.subtitle,
          description: reward.description,
          extendedDescription: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].extendedDescription : reward.extendedDescription,
          decreasePoints: reward.decreasePoints,
          unlocked: reward.unlocked,
          pictureFilePath: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].pictureFilePath : null,
          externalCode: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].externalCode : null,
          externalCodeBarcodeTypeCode: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].externalCodeBarcodeTypeCode : null,
          validFromDate: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].validFromDate : reward.validFromDate,
          validToDate: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].validToDate : reward.validToDate,
        });
      });
    }
    return {
      ok: true,
      response: {
        userStatusCode: user.userStatusCode,
        userId: user.userId,
        userReferenceCode: user.userReferenceCode,
        totalPoints: user.totalPoints,
        coupons,
        rewards,
        keyCode,
        convertFactor: user.convertFactor || 1,
        errorInfo: user.errorInfo,
        emailAddress: userData.emailAddress,
        firstAction: user.firstAction,
        statusDefinitions: user.statusDefinitions,
        isRegistered: user.userStatusCode === "RGU",
        idType,
      },
    };
  }

  public validateRedeemParams(qrJson: IQrParams): IResponse<{ keyCode: string; isCoupon: boolean; code: string }> {
    if (qrJson.keyCode) {
      if (qrJson.couponCode) {
        return {
          ok: true,
          response: {
            keyCode: qrJson.keyCode,
            code: qrJson.couponCode,
            isCoupon: true,
          },
        };
      } else if (qrJson.rewardId) {
        return {
          ok: true,
          response: {
            keyCode: qrJson.keyCode,
            code: qrJson.rewardId.toString(),
            isCoupon: false,
          },
        };
      } else {
        return {
          ok: false,
          error: {
            message: "VOUCHER_CODE_NOT_FOUND",
          },
        };
      }
    } else {
      return {
        ok: false,
        error: { message: "KEYCODE_NOT_FOUND" },
      };
    }
  }

  public getPicture(files: IFile[], type: "teaser" | "content", defaultImage: string): Picture {
    let pictureFile: Picture;
    if (files) {
      files.forEach(file => {
        if (file.tag === `${type}Image` || file.tag === `${type}Picture`) {
          pictureFile = { file: file.pictureFilePath, isCorrectPicture: true };
        }
      });
      if (!pictureFile && files.length > 0) pictureFile = { file: files[0].pictureFilePath, isCorrectPicture: false };
    }
    return pictureFile || { file: defaultImage, isCorrectPicture: false };
  }
}
