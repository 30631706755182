var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ActionType, } from "shared/lib/common/interfaces";
import { AdminService as AdminServiceV1, P4mService, SentryService, UtilsService, ConfigService, TranslateService, } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services/device-mounting/device-mounting.service";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { TransactionService } from "shared/lib/v2/services/transaction/transaction.service";
import { AccountService } from "../account/account.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/translate/translate.service";
import * as i2 from "../device-mounting/device-mounting.service";
import * as i3 from "../../../common/services/p4m/p4m.service";
import * as i4 from "../../../common/services/config/config.service";
import * as i5 from "../../../common/services/sentry/sentry.service";
import * as i6 from "../../../common/services/utils/utils.service";
import * as i7 from "../transaction/transaction.service";
import * as i8 from "../account/account.service";
var AdminService = /** @class */ (function (_super) {
    __extends(AdminService, _super);
    function AdminService(translate, deviceMountingService, p4m, configService, sentry, utils, transaction, account) {
        var _this = _super.call(this, translate, p4m, utils, configService, sentry) || this;
        _this.translate = translate;
        _this.deviceMountingService = deviceMountingService;
        _this.p4m = p4m;
        _this.configService = configService;
        _this.sentry = sentry;
        _this.utils = utils;
        _this.transaction = transaction;
        _this.account = account;
        return _this;
    }
    AdminService.prototype.getRegistrationType = function () {
        return Promise.resolve({ ok: true, response: this.deviceMountingService.profile.registrationType });
    };
    AdminService.prototype.arts = function (transactionTime, amount, keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var store, error_1, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        store = this.configService.getMountingConfig().store;
                        return [4 /*yield*/, this.account
                                .createTransaction(keyCode, this.configService.getMountingConfig().workstationId, idType, {
                                amount: amount,
                                transactionType: "EARNTRANSACTION",
                                transactionTime: transactionTime,
                                externalId: amount.toFixed(2) + "_" + (store ? store.vatId : store) + "_" + this.utils.getReceiptFormatDate(new Date(transactionTime)),
                            })
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, { ok: true, response: null }];
                    case 2:
                        error_1 = _a.sent();
                        message = error_1.error && error_1.error.message ? error_1.error.message : error_1.message || error_1;
                        return [2 /*return*/, { ok: false, error: { message: message } }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.extendedRevenuePointsManually = function (keyCode, amount, extendedRevenueFactsId, idType, externalId) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.account
                                .createTransaction(keyCode, this.configService.getMountingConfig().workstationId, idType, {
                                amount: amount,
                                transactionType: "EARNTRANSACTION",
                                externalId: externalId,
                            })
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, { ok: true, response: null }];
                    case 2:
                        error_2 = _a.sent();
                        message = error_2.error && error_2.error.message ? error_2.error.message : error_2.message || error_2;
                        return [2 /*return*/, { ok: false, error: { message: message } }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.readProgramPartners = function () {
        return new Promise(function (resolve) { return resolve({ ok: false, error: { message: "Method not implemented." } }); });
    };
    AdminService.prototype.readPartnerStores = function (partnerId) {
        return new Promise(function (resolve) { return resolve({ ok: false, error: { message: "Method not implemented." } }); });
    };
    AdminService.prototype.getAllClients = function () {
        return Promise.resolve({
            ok: true,
            response: [
                {
                    clientId: Number(this.configService.getMountingConfig().clientId),
                    showRegistrationButton: false,
                    pointsRoundingCode: this.deviceMountingService.profile.pointsRoundingCode,
                    revenueRoundingCode: this.deviceMountingService.profile.revenueRoundingCode,
                },
            ],
        });
    };
    AdminService.prototype.getClientStore = function () {
        return {
            ok: true,
            response: {
                storeId: 1234,
                revenueModuleMode: this.deviceMountingService.profile.decimalRevenue ? "FLOAT" : "INT",
                usesPin: this.deviceMountingService.profile.pinRequired,
                pins: [{ pin: this.deviceMountingService.profile.pin }],
            },
        };
    };
    AdminService.prototype.fetchStoresOfClient = function (clientId) {
        return null;
    };
    AdminService.prototype.getDisplayAds = function (deviceKey) {
        try {
            return Promise.resolve({
                ok: true,
                response: this.deviceMountingService.images
                    .sort(function (a, b) {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    return 0;
                })
                    .map(function (i) { return ({
                    displayAdId: i.id,
                    displayAdUsageCode: "DEFAULT",
                    pictureFilePath: i.filePath,
                    width: null,
                    height: null,
                    displayDurationMsec: i.displayDurationMiliseconds,
                    displayAdType: null,
                    lastUpdate: null,
                    status: null,
                }); }),
            });
        }
        catch (e) {
            return Promise.resolve({
                ok: false,
                error: { message: e.message },
            });
        }
    };
    AdminService.prototype.mountDevice = function (params) {
        return new Promise(function (resolve) { return resolve({ ok: false, error: { message: "Method not implemented." } }); });
    };
    AdminService.prototype.unMountDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.deviceMountingService.unmount()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, { ok: true, response: {} }];
                    case 2:
                        error_3 = _a.sent();
                        return [2 /*return*/, { ok: false, error: error_3 }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.isValidPin = function (pin) {
        throw new Error("Method not implemented.");
    };
    AdminService.prototype.adminLogin = function (email, organization, password) {
        return __awaiter(this, void 0, void 0, function () {
            var loginResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.login({
                            email: email,
                            organization: organization,
                            password: password,
                        })];
                    case 1:
                        loginResponse = _a.sent();
                        if (loginResponse.ok === true) {
                            this.p4m.setUser(email);
                            this.p4m.setPassword(password);
                            this.p4m.setToken(loginResponse.response);
                            return [2 /*return*/, {
                                    ok: true,
                                    response: {
                                        mountingApiKey: null,
                                        token: loginResponse.response,
                                    },
                                }];
                        }
                        else {
                            return [2 /*return*/, loginResponse];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.reLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.p4m.login({
                                email: this.p4m.getUser(),
                                organization: this.configService.getOrganization(),
                                password: this.p4m.getPassword(),
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_4 = _a.sent();
                        this.sentry.handleError({ err: error_4, method: "admin.reLogin" });
                        return [2 /*return*/, { ok: false, error: { message: error_4.message || error_4 } }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.getPromotions = function () {
        return Promise.resolve({
            rmc1: {
                factor: 3,
                name: "Massage 3x",
                points: 0,
                revenue: 0,
                type: ActionType.RMC1,
            },
            rmc2: {
                factor: 5,
                name: "Shop 5x",
                points: 0,
                revenue: 0,
                type: ActionType.RMC2,
            },
            rmc3: {
                factor: 1,
                name: "Sonstiges",
                points: 0,
                revenue: 0,
                type: ActionType.RMC3,
            },
            rmt: {
                factor: 1,
                name: "TOTAL",
                points: 0,
                revenue: 0,
                type: ActionType.RMT,
            },
            rmb: {
                factor: 1,
                name: "BURN",
                points: 0,
                revenue: 0,
                type: ActionType.RMB,
            },
        });
    };
    AdminService.prototype.logout = function () {
        localStorage.clear();
    };
    AdminService.prototype.isAuthenticated = function () {
        if (this.p4m.getUser() && this.p4m.getPassword()) {
            return true;
        }
        else {
            return false;
        }
    };
    AdminService.prototype.getPinCode = function () {
        return this.deviceMountingService.profile.pin;
    };
    AdminService.prototype.getMountingApiKey = function () {
        throw new Error("Method not implemented.");
    };
    AdminService.prototype.getApiKey = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.APIKEY));
    };
    AdminService.prototype.validateSaveNewPinParams = function (pin) {
        throw new Error("Method not implemented.");
    };
    AdminService.prototype.parseGetPermissionUser = function (response) {
        throw new Error("Method not implemented.");
    };
    AdminService.prototype.register = function (params) {
        throw new Error("Method not implemented.");
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.TranslateService), i0.inject(i2.DeviceMountingService), i0.inject(i3.P4mService), i0.inject(i4.ConfigService), i0.inject(i5.SentryService), i0.inject(i6.UtilsService), i0.inject(i7.TransactionService), i0.inject(i8.AccountService)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}(AdminServiceV1));
export { AdminService };
