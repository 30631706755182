import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { MountingSettingsPage as SharedMountingSettingsPage } from "shared/lib/v2/pages";
import { ConfigService, PosConnectionService, RoutingService, ToastService, TranslateService } from "shared/lib/common/services";
import { DeviceMountingService, ProgramService } from "shared/lib/v2/services";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "ocm-mounting-settings",
  templateUrl: "./mounting-settings.page.html",
  styleUrls: ["./mounting-settings.page.scss"],
})
export class MountingSettingsPage extends SharedMountingSettingsPage {
  constructor(
    private _fb: FormBuilder,
    private _program: ProgramService,
    protected _routing: RoutingService,
    protected _toast: ToastService,
    protected _location: Location,
    protected _config: ConfigService,
    protected _posConnectionService: PosConnectionService,
    protected _deviceMountingService: DeviceMountingService,
    public _translate: TranslateService,
  ) {
    super(_fb, _program, _routing, _toast, _location, _config, _posConnectionService, _deviceMountingService, _translate);
  }
}
