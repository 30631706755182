/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revenue-date.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i6 from "../../components/datetime/datetime.component.ngfactory";
import * as i7 from "../../components/datetime/datetime.component";
import * as i8 from "./revenue-date.page";
import * as i9 from "../../../../../../shared/lib/common/services/translate/translate.service";
var styles_RevenueDatePage = [i0.styles];
var RenderType_RevenueDatePage = i1.ɵcrt({ encapsulation: 0, styles: styles_RevenueDatePage, data: {} });
export { RenderType_RevenueDatePage as RenderType_RevenueDatePage };
export function View_RevenueDatePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "ion-content", [["class", "page-wrapper"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 16, "div", [["class", "revenue-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.cancelation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(4, 114688, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "revenue-date__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "revenue-date__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "revenue-date__body__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ocm-datetime", [], null, [[null, "newDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("newDate" === en)) {
        var pd_0 = ((_co.day = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DatetimeComponent_0, i6.RenderType_DatetimeComponent)), i1.ɵdid(11, 49152, null, 0, i7.DatetimeComponent, [], { time: [0, "time"], displayFormat: [1, "displayFormat"], millisecondsFactor: [2, "millisecondsFactor"] }, { newDate: "newDate" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["class", "revenue-date__body__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "ocm-datetime", [], null, [[null, "newDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("newDate" === en)) {
        var pd_0 = ((_co.hour = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DatetimeComponent_0, i6.RenderType_DatetimeComponent)), i1.ɵdid(15, 49152, null, 0, i7.DatetimeComponent, [], { time: [0, "time"], displayFormat: [1, "displayFormat"] }, { newDate: "newDate" }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ion-button", [["class", "revenue-date__body__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(17, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵted(18, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_2 = _co.day; var currVal_3 = "YYYY-MM-DD"; var currVal_4 = (((24 * 60) * 60) * 1000); _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.hour; var currVal_7 = "HH:mm"; _ck(_v, 15, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("REVENUE_DATE"); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.translate._("DAY"); _ck(_v, 9, 0, currVal_1); var currVal_5 = _co.translate._("HOUR"); _ck(_v, 13, 0, currVal_5); var currVal_8 = _co.translate._("SET_DATE"); _ck(_v, 18, 0, currVal_8); }); }
export function View_RevenueDatePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "generic-revenue-date", [], null, null, null, View_RevenueDatePage_0, RenderType_RevenueDatePage)), i1.ɵdid(1, 114688, null, 0, i8.RevenueDatePage, [i9.TranslateService, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevenueDatePageNgFactory = i1.ɵccf("generic-revenue-date", i8.RevenueDatePage, View_RevenueDatePage_Host_0, {}, {}, []);
export { RevenueDatePageNgFactory as RevenueDatePageNgFactory };
