import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ILanguage } from "shared/lib/common/i18n/translate";

export interface LanguagePageInputs {
  selected: ILanguage;
  languages: Array<{ key: ILanguage; name: string }>;
}

export interface LanguagePageOutputs {
  data?: ILanguage;
}

@Component({
  selector: "ocm-language",
  templateUrl: "./language.page.html",
  styleUrls: ["./language.page.scss"],
})
export class LanguagePage implements OnInit {
  public params: LanguagePageInputs;
  constructor(private navParams: NavParams, private modal: ModalController) {}

  public ngOnInit(): void {
    this.params = this.navParams.data as LanguagePageInputs;
  }

  public select(language: ILanguage = null): void {
    this.modal.dismiss(language);
  }
}
