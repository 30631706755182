/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/common/pipes/order/order-by.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./history.component";
import * as i7 from "../../../../../../shared/lib/common/services/translate/translate.service";
var styles_HistoryComponent = [i0.styles];
var RenderType_HistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HistoryComponent, data: {} });
export { RenderType_HistoryComponent as RenderType_HistoryComponent };
function View_HistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-item", [["class", "history-list__item"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "history-list__item__activity"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "history-list__item__activity__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "div", [["class", "history-list__item__points"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 1), ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.transactionDateTime == null) ? null : _v.context.$implicit.transactionDateTime.unixTimeStamp)), "dd.MM.yy HH:mm")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 2), ((_v.context.$implicit == null) ? null : _v.context.$implicit.points), "", _co.translate.getSessionLanguage())); _ck(_v, 8, 0, currVal_2); }); }
export function View_HistoryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.OrderByPipe, []), i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(3, 0, null, null, 10, "ion-list", [["class", "history-list"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(4, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "ion-item", [["class", "history-list__item"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(6, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "div", [["class", "history-list__item__activity"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "div", [["class", "history-list__item__points"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_HistoryComponent_1)), i1.ɵdid(12, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(13, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.transactions, "transactionDateTime unixTimeStamp")); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("ACTIVITIES"); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.translate._("P"); _ck(_v, 10, 0, currVal_1); }); }
export function View_HistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-history", [], null, null, null, View_HistoryComponent_0, RenderType_HistoryComponent)), i1.ɵdid(1, 49152, null, 0, i6.HistoryComponent, [i7.TranslateService], null, null)], null, null); }
var HistoryComponentNgFactory = i1.ɵccf("ocm-history", i6.HistoryComponent, View_HistoryComponent_Host_0, { transactions: "transactions", totalEarn: "totalEarn", totalRedeem: "totalRedeem", loading: "loading" }, {}, []);
export { HistoryComponentNgFactory as HistoryComponentNgFactory };
