/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatusLevelSetDataMetricConfiguration { 
    metric?: StatusLevelSetDataMetricConfiguration.MetricEnum;
    metricFactor?: number;
}
export namespace StatusLevelSetDataMetricConfiguration {
    export type MetricEnum = 'BASISPOINTS' | 'TRANSACTIONS';
    export const MetricEnum = {
        BASISPOINTS: 'BASISPOINTS' as MetricEnum,
        TRANSACTIONS: 'TRANSACTIONS' as MetricEnum
    };
}


