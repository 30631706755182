/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./coupon-card.component";
import * as i6 from "../../../../../../shared/lib/common/services/utils/utils.service";
import * as i7 from "../../../../../../shared/lib/common/services/translate/translate.service";
var styles_CouponCardComponent = [i0.styles];
var RenderType_CouponCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponCardComponent, data: {} });
export { RenderType_CouponCardComponent as RenderType_CouponCardComponent };
function View_CouponCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "coupon-card__empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "coupon-card__empty__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("SELECT_VOUCHER"); _ck(_v, 2, 0, currVal_0); }); }
function View_CouponCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "coupon-card__image"]], [[4, "backgroundImage", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.ticketSelected == null) ? null : ((_co.ticketSelected.contentPictureFilePath == null) ? null : _co.ticketSelected.contentPictureFilePath.file)) ? (("url(" + ((_co.ticketSelected == null) ? null : ((_co.ticketSelected.contentPictureFilePath == null) ? null : _co.ticketSelected.contentPictureFilePath.file))) + ")") : ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CouponCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "coupon-card__content__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_co.ticketSelected == null) ? null : _co.ticketSelected.voucherType) === "coupon") && ((_co.ticketSelected == null) ? null : _co.ticketSelected.validToDate)) ? _co.translate._("VALID_BETWEEN", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), ((_co.ticketSelected == null) ? null : ((_co.ticketSelected.validFromDate == null) ? null : _co.ticketSelected.validFromDate.unixTimeStamp)), "dd.MM.yy")), i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), ((_co.ticketSelected == null) ? null : ((_co.ticketSelected.validToDate == null) ? null : _co.ticketSelected.validToDate.unixTimeStamp)), "dd.MM.yy"))) : ""); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "coupon-card__content__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_co.ticketSelected == null) ? null : _co.ticketSelected.voucherType) === "coupon") && ((_co.ticketSelected == null) ? null : _co.ticketSelected.validToDate)) ? _co.translate._("VALID_UNTIL", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), ((_co.ticketSelected == null) ? null : ((_co.ticketSelected.validToDate == null) ? null : _co.ticketSelected.validToDate.unixTimeStamp)), "dd.MM.yy"))) : ""); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponCardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "coupon-card__content__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.ticketSelected == null) ? null : _co.ticketSelected.voucherType) === "coupon") ? _co.translate._("VALID_IN_DAYS", _co.utils.getNumberOfDays(_co.ticketSelected)) : ""); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponCardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "coupon-card__content__button-wrapper__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("VOUCHER_DISABLED"); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "coupon-card__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h6", [["class", "coupon-card__content__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "coupon-card__content__price"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "coupon-card__content__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "coupon-card__content__button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_7)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ion-button", [["class", "button button--primary coupon-card__content__button-wrapper__button"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.redeem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(17, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(18, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.ticketSelected == null) ? null : _co.ticketSelected.validToDate) && ((_co.ticketSelected == null) ? null : _co.ticketSelected.validFromDate)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (((_co.ticketSelected == null) ? null : _co.ticketSelected.validToDate) && !((_co.ticketSelected == null) ? null : _co.ticketSelected.validFromDate)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (((_co.ticketSelected == null) ? null : _co.ticketSelected.validFromDate) && !((_co.ticketSelected == null) ? null : _co.ticketSelected.validToDate)); _ck(_v, 10, 0, currVal_4); var currVal_6 = _co.disabled; _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.disabled; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ticketSelected == null) ? null : _co.ticketSelected.title); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!((_co.ticketSelected == null) ? null : _co.ticketSelected.points) ? _co.translate._("FREE") : ((((_co.ticketSelected == null) ? null : _co.ticketSelected.points) + " ") + _co.translate._("P"))); _ck(_v, 4, 0, currVal_1); var currVal_5 = ((_co.ticketSelected == null) ? null : _co.ticketSelected.description); _ck(_v, 12, 0, currVal_5); var currVal_8 = _co.translate._("REDEEM_NOW"); _ck(_v, 18, 0, currVal_8); }); }
export function View_CouponCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "coupon-card"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponCardComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.ticketSelected; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.ticketSelected; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.ticketSelected; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_CouponCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-coupon-card", [], null, null, null, View_CouponCardComponent_0, RenderType_CouponCardComponent)), i1.ɵdid(1, 49152, null, 0, i5.CouponCardComponent, [i6.UtilsService, i7.TranslateService], null, null)], null, null); }
var CouponCardComponentNgFactory = i1.ɵccf("ocm-coupon-card", i5.CouponCardComponent, View_CouponCardComponent_Host_0, { ticketSelected: "ticketSelected", disabled: "disabled" }, { RedeemEvent: "RedeemEvent" }, []);
export { CouponCardComponentNgFactory as CouponCardComponentNgFactory };
