/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomPropertyAttribute } from './custom-property-attribute';


export interface Program { 
    code?: string;
    /**
     * Array of custom properties
     */
    customProperties?: Array<CustomPropertyAttribute>;
    id?: string;
    name?: string;
    status?: Program.StatusEnum;
}
export namespace Program {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


