import { Injectable } from "@angular/core";
import * as JsBarcode from "jsbarcode";
import * as QRcode from "qrcode";
import { IBarcodeType } from "shared/lib/common/interfaces";

@Injectable({
  providedIn: "root",
})
export class QrService {
  constructor() {}

  public draw(element: HTMLCanvasElement, data: string, format: IBarcodeType): void {
    if (format === "CODE128" || format === "EAN13" || format === "EAN8") JsBarcode(element, data, { format });
    if (format === "QRCODE") QRcode.toCanvas(element, data);
  }
}
