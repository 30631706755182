/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationConfigurationDataActions { 
    channels: Array<NotificationConfigurationDataActions.ChannelsEnum>;
    deliveryContext: { [key: string]: string; };
    landingPage?: string;
    trigger: NotificationConfigurationDataActions.TriggerEnum;
}
export namespace NotificationConfigurationDataActions {
    export type ChannelsEnum = 'EMAIL';
    export const ChannelsEnum = {
        EMAIL: 'EMAIL' as ChannelsEnum
    };
    export type TriggerEnum = 'REGISTRATION_DOUBLE_OPTIN' | 'REGISTRATION_WELCOME' | 'USER_PASSWORD_RESET';
    export const TriggerEnum = {
        REGISTRATIONDOUBLEOPTIN: 'REGISTRATION_DOUBLE_OPTIN' as TriggerEnum,
        REGISTRATIONWELCOME: 'REGISTRATION_WELCOME' as TriggerEnum,
        USERPASSWORDRESET: 'USER_PASSWORD_RESET' as TriggerEnum
    };
}


