import { Component, OnInit } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationStart } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { LanguagePage, LanguagePageInputs, LanguagePageOutputs } from "ocm/src/app/pages";
import { HeaderComponent as SharedHeaderComponent } from "shared/lib/v1/components";
import { SharedRoutes } from "shared/lib/common/enums";
import { ILanguage } from "shared/lib/common/i18n/translate";
import { RoutingService, TranslateService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

const HISTORY_DISABLED_ROUTES = [`/${SharedRoutes.admin}`, `/${SharedRoutes.configurations}`];

@Component({
  selector: "ocm-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends SharedHeaderComponent implements OnInit {
  private history: string[] = [];
  private readonly pagesWithOutCross: string[] = [
    SharedRoutes.screenSaver,
    SharedRoutes.burn,
    SharedRoutes.configurations,
    SharedRoutes.login,
  ];
  public sesionLanguage: ILanguage;
  public isScreenSaverPage: boolean;
  public showCross: boolean;
  public showLogo: boolean;
  public showLanguageSelect: boolean;
  public showLogOut: boolean;
  public readonly languages: { [key: string]: string } = {
    de: this.translate._("GERMAN"),
    en: this.translate._("ENGLISH"),
  };
  constructor(
    public translate: TranslateService,
    public _config: ConfigService,
    private _routing: RoutingService,
    private modal: ModalController,
  ) {
    super(_routing, _config);
  }

  public ngOnInit(): void {
    this._routing.getRouterEvents().subscribe(event => {
      let url: string;
      if (event instanceof NavigationEnd) {
        if (!HISTORY_DISABLED_ROUTES.includes(event.url)) {
          this.history.push(event.url);
          this.history = this.history.slice(-2);
        }
      }
      if (event instanceof NavigationEnd || event instanceof NavigationStart || event instanceof NavigationCancel) {
        if (event instanceof NavigationEnd || event instanceof NavigationStart) {
          url = event instanceof NavigationEnd ? event.urlAfterRedirects : event.url;
        } else {
          url = window.location.pathname;
        }
        this.showLanguageSelect = url.indexOf(`/${SharedRoutes.screenSaver}`) >= 0;
        this.showLogOut = url.indexOf(`/${SharedRoutes.burn}`) >= 0;
        this.showCross = !this.pagesWithOutCross.some(page => url.includes(`/${page}`));
      }
    });
  }

  public back(): void {
    this._routing.goForward([this.history.length > 1 ? this.history[0] : SharedRoutes.screenSaver]);
  }

  public async logOut(): Promise<void> {
    await this._routing.goForward([SharedRoutes.screenSaver]);
  }

  public async openLanguageModal(): Promise<void> {
    const languageModalInputs: LanguagePageInputs = {
      selected: this.translate.getSessionLanguage(),
      languages: [{ key: "de", name: this.translate._("GERMAN") }, { key: "en", name: this.translate._("ENGLISH") }],
    };
    const languageModal = await this.modal.create({
      component: LanguagePage,
      componentProps: languageModalInputs,
      backdropDismiss: false,
    });
    await languageModal.present();
    const languageModalData: LanguagePageOutputs = await languageModal.onDidDismiss();
    this.translate.setSessionLanguage(languageModalData.data);
  }

  public async goAdminPage(): Promise<void> {
    if (this.adminZoneEnabled) {
      this.adminZoneEnabled = false;
      await this._routing.goForward([SharedRoutes.admin]);
      this.timeout = setTimeout(() => {
        this.adminZoneEnabled = true;
      }, 500);
    }
  }
}
