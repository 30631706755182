var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { from, of, throwError } from "rxjs";
import { catchError, switchMap, retry } from "rxjs/operators";
import { UtilsService, P4mService, ConfigService, EnvironmentService } from "shared/lib/common/services";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/utils/utils.service";
import * as i2 from "../../../common/services/p4m/p4m.service";
import * as i3 from "../../../common/services/config/config.service";
import * as i4 from "../../../common/services/environment/environment.service";
var InterceptorService = /** @class */ (function () {
    function InterceptorService(utils, p4m, config, environmentService) {
        this.utils = utils;
        this.p4m = p4m;
        this.config = config;
        this.environmentService = environmentService;
    }
    InterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        if (req.url.includes(this.environmentService.getVariable("API_MOUNTING") + "/api/device-management/pmm")) {
            req = req.clone({ withCredentials: true });
        }
        var authorization = this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
        if (authorization)
            req = req.clone({ setHeaders: { authorization: authorization } });
        return next.handle(req).pipe(retry(2), catchError(function (error) {
            if (error && error.error) {
                try {
                    var err = _this.parseError(error.error, error.status);
                    return _this.handleError(req, next, err);
                }
                catch (error) { }
            }
            return throwError(error);
        }));
    };
    InterceptorService.prototype.reLogin = function (req, next, error) {
        var email = this.p4m.getUser();
        var password = this.p4m.getPassword();
        var login = this.p4m.login({ password: password, email: email, organization: this.config.getOrganization() });
        if (login && email && password) {
            return from(login).pipe(switchMap(function (response) {
                if (response.ok === true)
                    return next.handle(req.clone({ setHeaders: { authorization: response.response } }));
                else
                    return of(response.error);
            }), catchError(function (err) {
                localStorage.clear();
                return of(err);
            }));
        }
        else {
            localStorage.clear();
            return of(error);
        }
    };
    InterceptorService.prototype.handleError = function (req, next, error) {
        if (error.errorType === "Unauthorized" || error.status === 401 || error.status === 0)
            return this.reLogin(req, next, error);
        return throwError(error);
    };
    InterceptorService.prototype.parseError = function (error, status) {
        try {
            var err = JSON.parse(error);
            return __assign({}, err, { status: status });
        }
        catch (err) {
            return __assign({}, error, { status: status });
        }
    };
    InterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.inject(i1.UtilsService), i0.inject(i2.P4mService), i0.inject(i3.ConfigService), i0.inject(i4.EnvironmentService)); }, token: InterceptorService, providedIn: "root" });
    return InterceptorService;
}());
export { InterceptorService };
