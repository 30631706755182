/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../../shared/lib/v1/components/revenue-module/revenue-module.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/revenue-module/revenue-module.component";
import * as i6 from "../../../../../../shared/lib/common/services/translate/translate.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i9 from "angular-feather";
import * as i10 from "../../../../../../shared/lib/common/directives/double-tap/double-tap.directive";
import * as i11 from "./admin.page";
import * as i12 from "../../../../../../shared/lib/v2/services/admin/admin.service";
import * as i13 from "../../../../../../shared/lib/common/services/user/user.service";
import * as i14 from "../../../../../../shared/lib/common/services/toast/toast.service";
import * as i15 from "../../../../../../shared/lib/common/services/pos-connection/pos-connection.service";
import * as i16 from "../../../../../../shared/lib/common/services/config/config.service";
import * as i17 from "../../../../../../shared/lib/common/services/inactivity/inactivity.service";
import * as i18 from "../../../../../../shared/lib/common/services/routing/routing.service";
import * as i19 from "../../../../../../shared/lib/common/services/environment/environment.service";
var styles_AdminPage = [i0.styles];
var RenderType_AdminPage = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminPage, data: {} });
export { RenderType_AdminPage as RenderType_AdminPage };
function View_AdminPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "admin__content__loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-spinner", [["class", "admin__content__loading__spinner"], ["name", "crescent"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(2, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "crescent"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AdminPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "admin__content__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("ADD_MANUAL_POINTS"); _ck(_v, 2, 0, currVal_0); }); }
function View_AdminPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-revenue-module", [], null, [[null, "backEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backEvent" === en)) {
        var pd_0 = (_co.arts($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RevenueModuleComponent_0, i4.RenderType_RevenueModuleComponent)), i1.ɵdid(1, 114688, null, 0, i5.RevenueModuleComponent, [i6.TranslateService], { promotions: [0, "promotions"], revenueRounding: [1, "revenueRounding"], pointsRounding: [2, "pointsRounding"], revenueMode: [3, "revenueMode"], mode: [4, "mode"] }, { backEvent: "backEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.promotions; var currVal_1 = _co.revenueRounding; var currVal_2 = _co.pointsRounding; var currVal_3 = _co.revenueMode; var currVal_4 = "simple"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_AdminPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-revenue-module", [], null, [[null, "backEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backEvent" === en)) {
        var pd_0 = (_co.addPoints($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RevenueModuleComponent_0, i4.RenderType_RevenueModuleComponent)), i1.ɵdid(1, 114688, null, 0, i5.RevenueModuleComponent, [i6.TranslateService], { revenueMode: [0, "revenueMode"], mode: [1, "mode"] }, { backEvent: "backEvent" })], function (_ck, _v) { var currVal_0 = "INT"; var currVal_1 = "manual"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AdminPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "ion-content", [["scroll-y", "false"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 14, "div", [["class", "page-wrapper admin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "admin__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminPage_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminPage_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminPage_3)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminPage_4)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["class", "admin__content__version"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "i-settings", [["class", "admin__button-configurations"]], null, [[null, "appDoubleTap"], [null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("appDoubleTap" === en)) {
        var pd_1 = (_co.goToConfigurations() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_ɵhv_0, i8.RenderType_ɵhv)), i1.ɵdid(15, 49152, null, 0, i9.ɵhv, [], null, null), i1.ɵdid(16, 81920, null, 0, i10.DoubleTapDirective, [], null, { appDoubleTap: "appDoubleTap" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.bookPointModule; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.revenueModule; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.bookPointModule; _ck(_v, 11, 0, currVal_3); _ck(_v, 16, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.releaseVersion; _ck(_v, 13, 0, currVal_4); }); }
export function View_AdminPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-admin", [], null, null, null, View_AdminPage_0, RenderType_AdminPage)), i1.ɵdid(1, 114688, null, 0, i11.AdminPage, [i6.TranslateService, i3.Platform, i12.AdminService, i13.UserService, i14.ToastService, i15.PosConnectionService, i3.AlertController, i16.ConfigService, i3.ModalController, i17.InactivityService, i18.RoutingService, i19.EnvironmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminPageNgFactory = i1.ɵccf("ocm-admin", i11.AdminPage, View_AdminPage_Host_0, {}, {}, []);
export { AdminPageNgFactory as AdminPageNgFactory };
