<ion-content>
  <div class="confirmation">
    <shared-close (tap)="cancelation()"></shared-close>
    <h1 class="confirmation__title">{{ params?.title }}</h1>
    <ocm-coupon class="confirmation__voucher" [ticket]="params?.voucher"></ocm-coupon>
    <div class="confirmation__buttons">
      <ion-button class="confirmation__buttons__confirm" (tap)="confirmation()">{{ params?.confirmText }}</ion-button>
      <ion-button class="confirmation__buttons__dismiss" (tap)="cancelation()">{{ params?.dismissText }}</ion-button>
    </div>
  </div>
</ion-content>
