<ion-content>
  <div class="success">
    <shared-close (tap)="dismiss()"></shared-close>
    <h1 class="success__title">
      {{ translate._("SUCCESS_MODAL_TITLE") }}
    </h1>
    <i-check class="success__check"></i-check>
    <p class="success__text">
      {{ translate._("SUCCESS_MODAL_TEXT") }}
    </p>
    <div class="success__voucher-wrapper" [class.--active]="externalCode">
      <canvas class="success__voucher-wrapper__canvas"></canvas>
      <ocm-coupon class="success__voucher-wrapper__voucher" [ticket]="voucher"></ocm-coupon>
    </div>
  </div>
</ion-content>
