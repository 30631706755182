import { Subject } from "rxjs";
import { ConfigService } from "../config/config.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
var InactivityService = /** @class */ (function () {
    function InactivityService(config) {
        var _this = this;
        this.config = config;
        this.timeout = this.config.inactivityTime;
        this.timeout$ = new Subject();
        this.incrementTime = this.incrementTime.bind(this);
        window.addEventListener("keyup", function () { return _this.resetTimer(); });
        window.addEventListener("click", function () { return _this.resetTimer(); });
        window.addEventListener("wheel", function () { return _this.resetTimer(); });
    }
    InactivityService.prototype.startWatching = function () {
        this.resetTimer();
        if (this.idleInterval)
            this.stopWatch();
        this.idleTime = 0;
        this.incrementTime();
        this.idleInterval = setInterval(this.incrementTime, 1000);
    };
    InactivityService.prototype.resetTimer = function () {
        this.idleTime = 0;
    };
    InactivityService.prototype.stopWatch = function () {
        clearInterval(this.idleInterval);
    };
    InactivityService.prototype.onTimeout = function () {
        return this.timeout$;
    };
    InactivityService.prototype.incrementTime = function () {
        this.idleTime = this.idleTime + 1;
        if (this.idleTime >= this.timeout) {
            this.resetTimer();
            this.timeout$.next();
        }
    };
    InactivityService.ngInjectableDef = i0.defineInjectable({ factory: function InactivityService_Factory() { return new InactivityService(i0.inject(i1.ConfigService)); }, token: InactivityService, providedIn: "root" });
    return InactivityService;
}());
export { InactivityService };
