var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from "@angular/core";
import { ActionType, PointsRoundingCode, RevenueRoundingCode } from "shared/lib/common/interfaces";
import { TranslateService } from "shared/lib/common/services";
var RevenueModuleComponent = /** @class */ (function () {
    function RevenueModuleComponent(translate) {
        this.translate = translate;
        this.backEvent = new EventEmitter();
    }
    RevenueModuleComponent.prototype.ngOnInit = function () {
        this.revenue = 0;
        this.totalPoints = 0;
        this.negativeMode = false;
        switch (this.mode) {
            case "extended":
                this.modeButtons = ["plusMinus", "c", "ac", "7", "8", "9", "4", "5", "6", "1", "2", "3", "delete", "0", "00"];
                break;
            case "simple":
                this.modeButtons = ["plusMinus", "c", "delete", "7", "8", "9", "4", "5", "6", "1", "2", "3", "00", "0", "enter"];
                break;
            case "manual":
                this.modeButtons = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "delete", "0", "enter"];
                break;
        }
    };
    RevenueModuleComponent.prototype.press = function (value) {
        switch (value) {
            case "plusMinus":
                this.toggleNegativeMode();
                break;
            case "c":
                this.reset();
                break;
            case "ac":
                this.resetAll();
                break;
            case "enter":
                this.enter();
                break;
            case "delete":
                this.remove();
                break;
            default:
                this.add(value);
                break;
        }
    };
    RevenueModuleComponent.prototype.toggleNegativeMode = function () {
        this.negativeMode = !this.negativeMode;
        this.resetAll();
    };
    RevenueModuleComponent.prototype.getArray = function (n) {
        return Array(n);
    };
    RevenueModuleComponent.prototype.resetAll = function () {
        this.revenue = 0;
        this.totalPoints = 0;
        this.promotions = (this.promotions || []).map(function (p) {
            if (p)
                return __assign({}, p, { revenue: 0, points: 0 });
        });
    };
    RevenueModuleComponent.prototype.reset = function () {
        this.revenue = 0;
    };
    RevenueModuleComponent.prototype.remove = function () {
        if (this.revenue > 0) {
            var revenue = void 0;
            switch (this.revenueMode) {
                case "INT":
                    revenue = this.revenue.toFixed(0);
                    revenue = revenue.substring(0, revenue.length - 1) || "0";
                    this.revenue = parseInt(revenue, 10);
                    break;
                case "FLOAT":
                    revenue = (this.revenue * 100).toFixed(0);
                    revenue = revenue.substring(0, revenue.length - 1) || "0";
                    this.revenue = parseInt(revenue, 10) / 100;
                    break;
            }
        }
    };
    RevenueModuleComponent.prototype.add = function (number) {
        var revenue;
        switch (this.revenueMode) {
            case "INT":
                revenue = this.revenue.toFixed(0);
                revenue += number;
                this.revenue = parseInt(revenue, 10);
                break;
            case "FLOAT":
                revenue = (this.revenue * 100).toFixed(0);
                revenue += number;
                this.revenue = parseInt(revenue, 10) / 100;
                break;
        }
    };
    RevenueModuleComponent.prototype.applyFactor = function (_a) {
        var _this = this;
        var type = _a.type, revenue = _a.revenue, factor = _a.factor;
        var newRevenue = this.revenue;
        if (this.negativeMode || type === "RMB")
            newRevenue = Math.abs(newRevenue) * -1;
        this.promotions = this.promotions.map(function (actualPromotion) {
            if (actualPromotion && actualPromotion.type === type) {
                actualPromotion.revenue += newRevenue;
                actualPromotion.points = _this.roundPoints(_this.roundRevenue(actualPromotion.revenue) * factor);
            }
            return actualPromotion;
        });
        this.totalPoints = this.promotions.reduce(function (acumulate, promotion) { return (promotion ? promotion.points + acumulate : 0 + acumulate); }, 0);
        this.reset();
    };
    RevenueModuleComponent.prototype.assignPoints = function () {
        var params = {};
        this.promotions.forEach(function (p) {
            if (p && p.revenue)
                params[p.type] = p;
        });
        var queryParams = {
            type: "earn",
            manualPoints: this.totalPoints,
            revenueRmb: params[ActionType.RMB] ? params[ActionType.RMB].revenue * -1 : 0,
            revenueRmt: params[ActionType.RMT] ? params[ActionType.RMT].revenue : 0,
            revenueRmc1: params[ActionType.RMC1] ? params[ActionType.RMC1].revenue : 0,
            revenueRmc2: params[ActionType.RMC2] ? params[ActionType.RMC2].revenue : 0,
            revenueRmc3: params[ActionType.RMC3] ? params[ActionType.RMC3].revenue : 0,
        };
        this.backEvent.emit(queryParams);
        this.resetAll();
    };
    RevenueModuleComponent.prototype.enter = function () {
        if (this.mode === "manual") {
            this.backEvent.emit(this.roundPoints(this.revenue));
        }
        else {
            var newRevenue = this.revenue;
            if (this.negativeMode)
                newRevenue = Math.abs(newRevenue) * -1;
            var queryParams = {
                type: "earn",
                totalPoints: this.roundPoints(newRevenue),
                totalRevenue: this.roundRevenue(newRevenue),
            };
            this.backEvent.emit(queryParams);
        }
        this.reset();
    };
    RevenueModuleComponent.prototype.roundRevenue = function (revenue) {
        var absRevenue = Math.abs(revenue);
        var algebraicSign = revenue < 0 ? -1 : 1;
        switch (this.revenueRounding) {
            case "UP":
                return Math.ceil(absRevenue) * algebraicSign;
            case "DOWN":
                return Math.floor(absRevenue) * algebraicSign;
            case "NO":
                return revenue;
            default:
                return Math.round(absRevenue) * algebraicSign;
        }
    };
    RevenueModuleComponent.prototype.roundPoints = function (points) {
        var absPoints = Math.abs(points);
        var algebraicSign = points < 0 ? -1 : 1;
        switch (this.pointsRounding) {
            case "UP":
                return Math.ceil(absPoints) * algebraicSign;
            case "DOWN":
                return Math.floor(absPoints) * algebraicSign;
            default:
                return Math.round(absPoints) * algebraicSign;
        }
    };
    return RevenueModuleComponent;
}());
export { RevenueModuleComponent };
