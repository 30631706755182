import { Component, Input } from "@angular/core";
import { IVoucher } from "shared/lib/common/interfaces";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "ocm-coupon",
  templateUrl: "./coupon.component.html",
  styleUrls: ["./coupon.component.scss"],
})
export class CouponComponent {
  @Input() public ticket: IVoucher;

  constructor(public translate: TranslateService) {}
}
