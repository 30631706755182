var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from "@angular/common/http";
import { VERSION } from "shared/lib/common/enums";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { P4mV1Service } from "shared/lib/common/services/p4m/p4m-v1/p4m-v1.service";
import { P4mV2Service } from "shared/lib/common/services/p4m/p4m-v2/p4m-v2.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EnvironmentService } from "../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../sentry/sentry.service";
import * as i3 from "../config/config.service";
import * as i4 from "../utils/utils.service";
import * as i5 from "../translate/translate.service";
import * as i6 from "../environment/environment.service";
import * as i7 from "./p4m-v1/p4m-v1.service";
import * as i8 from "./p4m-v2/p4m-v2.service";
var P4mService = /** @class */ (function () {
    function P4mService(http, sentry, config, utils, translate, environment, v1, v2) {
        this.http = http;
        this.sentry = sentry;
        this.config = config;
        this.utils = utils;
        this.translate = translate;
        this.environment = environment;
        this.v1 = v1;
        this.v2 = v2;
        // tslint:disable: typedef
        this.assignCoupon = this.v1.assignCoupon;
        this.deviceManagementLogin = this.v1.deviceManagementLogin;
        this.getAllClients = this.v1.getAllClients;
        this.getStoresOfClient = this.v1.getStoresOfClient;
        this.downloadDisplayAds = this.v1.downloadDisplayAds;
        this.unMountDevice = this.v1.unMountDevice;
        this.loginToGetApiKey = this.v1.loginToGetApiKey;
        this.getPermissionUser = this.v1.getPermissionUser;
        this.extendedRevenuePointsManually = this.v1.extendedRevenuePointsManually;
        this.getActionDefinitions = this.v1.getActionDefinitions;
        this.register = this.v1.register;
        this.updateProfileExtendedRegistration = this.v1.updateProfileExtendedRegistration;
        this.readProgramPartners = this.v2.readProgramPartners;
        this.readPartnerStores = this.v2.readPartnerStores;
        this.parseResponseV2 = this.v2.parseResponseV2;
        this.mountDevice = this.v1.mountDevice;
        this.getPassword = this.v1.getPassword;
        this.setPassword = this.v1.setPassword;
        this.getUser = this.v1.getUser;
        this.setUser = this.v1.setUser;
        this.getToken = this.v1.getToken;
        this.setToken = this.v1.setToken;
        this.arts = this.v1.arts;
        this.getArtsXml = this.v1.getArtsXml;
        this.parseResponseV1 = this.v1.parseResponseV1;
        this.parseError = this.v1.parseError;
        this.parseBalance = this.v1.parseBalance;
        this.ensureValidTimestamp = this.v1.ensureValidTimestamp;
        this.parseCoupons = this.v2.parseCoupons;
        this.getCouponi18nField = this.v2.getCouponi18nField;
        this.parseFiles = this.v2.parseFiles;
        this.isUnlocked = this.v2.isUnlocked;
    }
    P4mService.prototype.getUserDetails = function (params) {
        return this.config.getVersion("getUserDetails") === VERSION.v1 ? this.v1.getUserDetails(params) : this.v2.getUserDetails(params);
    };
    P4mService.prototype.checkIn = function (params) {
        return this.config.getVersion("checkIn") === VERSION.v1 ? this.v1.checkIn(params) : this.v2.checkIn(params);
    };
    P4mService.prototype.getUserHistory = function (params) {
        return this.config.getVersion("getUserHistory") === VERSION.v1 ? this.v1.getUserHistory(params) : this.v2.getUserHistory(params);
    };
    P4mService.prototype.getCoupons = function (params) {
        return this.config.getVersion("getCoupons") === VERSION.v1 ? this.v1.getCoupons(params) : this.v2.getCoupons(params);
    };
    P4mService.prototype.getRewards = function (params) {
        return this.config.getVersion("getRewards") === VERSION.v1 ? this.v1.getRewards(params) : this.v2.getRewards(params);
    };
    P4mService.prototype.bookPoints = function (params) {
        return this.config.getVersion("bookPoints") === VERSION.v1 ? this.v1.bookPoints(params) : this.v2.bookPoints(params);
    };
    P4mService.prototype.revenuePoints = function (params) {
        return this.config.getVersion("revenuePoints") === VERSION.v1 ? this.v1.revenuePoints(params) : this.v2.revenuePoints(params);
    };
    P4mService.prototype.redeemCoupon = function (params) {
        return this.config.getVersion("redeemCoupon") === VERSION.v1 ? this.v1.redeemCoupon(params) : this.v2.redeemCoupon(params);
    };
    P4mService.prototype.redeemReward = function (params) {
        return this.config.getVersion("redeemReward") === VERSION.v1 ? this.v1.redeemReward(params) : this.v2.redeemReward(params);
    };
    P4mService.prototype.login = function (params) {
        return this.config.getVersion("login") === VERSION.v1 ? this.v1.login(params) : this.v2.login(params);
    };
    P4mService.prototype.readAccountBalance = function (params) {
        return this.config.getVersion("readAccountBalance") === VERSION.v1
            ? this.v1.readAccountBalance(params)
            : this.v2.readAccountBalance(params);
    };
    P4mService.prototype.readUser = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.config.getVersion("readUser") === VERSION.v1 ? this.v1.readUser(params) : this.v2.readUser(params)];
            });
        });
    };
    P4mService.ngInjectableDef = i0.defineInjectable({ factory: function P4mService_Factory() { return new P4mService(i0.inject(i1.HttpClient), i0.inject(i2.SentryService), i0.inject(i3.ConfigService), i0.inject(i4.UtilsService), i0.inject(i5.TranslateService), i0.inject(i6.EnvironmentService), i0.inject(i7.P4mV1Service), i0.inject(i8.P4mV2Service)); }, token: P4mService, providedIn: "root" });
    return P4mService;
}());
export { P4mService };
