import { Injectable, Inject, Optional } from "@angular/core";
import { ProgramService as ConvercusProgramService, Configuration, BASE_PATH } from "shared/lib/v2/apis/programs";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { INTERACTION_ID, programConfig } from "shared/lib/common/constants";
import { ProgramConfig } from "shared/lib/common/interfaces";
import { EnvironmentService } from "shared/lib/common/services";

@Injectable({ providedIn: "root" })
export class ProgramService extends ConvercusProgramService {
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
  }

  public getInteractionPointsOfProgram(programId: string, interactionId: string = INTERACTION_ID, recursive?: boolean): Observable<any> {
    return super.getInteractionPointsOfProgram(interactionId, programId, recursive);
  }

  public getStore(partnerId: string, programId: string, storeId: string, interactionId: string = INTERACTION_ID): Observable<any> {
    return super.getStore(interactionId, partnerId, programId, storeId);
  }

  public getProgramConfig(program: string): ProgramConfig {
    return programConfig[program];
  }
}
