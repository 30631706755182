var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as de from "./translate.de.json";
import * as deOrganization from "./translate.de.organization.json";
import * as en from "./translate.en.json";
import * as enOrganization from "./translate.en.organization.json";
export var LANGUAGE = {
    en: __assign({}, en.default, enOrganization.default),
    de: __assign({}, de.default, deOrganization.default),
};
