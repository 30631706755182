import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { IVoucher } from "shared/lib/common/interfaces";
import { RoutingService, TranslateService, UserService } from "shared/lib/common/services";
import { QrService } from "../../services";

export interface ISuccessComponentParams {
  voucher: IVoucher;
}

@Component({
  selector: "ocm-success",
  templateUrl: "./success.page.html",
  styleUrls: ["./success.page.scss"],
})
export class SuccessPage implements OnInit {
  @Input() public voucher: IVoucher;

  public externalCode: boolean;

  constructor(
    private modal: ModalController,
    private user: UserService,
    private routing: RoutingService,
    public qr: QrService,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    const resp = this.user.getExternalCode(this.voucher);
    if (resp.ok === true) {
      if (resp.response.code && resp.response.type) {
        this.externalCode = true;
        this.qr.draw(document.getElementsByTagName("canvas")[0], resp.response.code, resp.response.type);
      }
    }
  }

  public async dismiss(): Promise<void> {
    await this.modal.dismiss();
    this.routing.goForward([SharedRoutes.screenSaver]);
  }
}
