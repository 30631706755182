/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InteractionPoint { 
    businessUnitId?: string;
    businessUnitType?: InteractionPoint.BusinessUnitTypeEnum;
    id?: string;
    name?: string;
    type?: InteractionPoint.TypeEnum;
}
export namespace InteractionPoint {
    export type BusinessUnitTypeEnum = 'PARTNER' | 'STORE' | 'PROGRAM';
    export const BusinessUnitTypeEnum = {
        PARTNER: 'PARTNER' as BusinessUnitTypeEnum,
        STORE: 'STORE' as BusinessUnitTypeEnum,
        PROGRAM: 'PROGRAM' as BusinessUnitTypeEnum
    };
    export type TypeEnum = 'CASHIER' | 'APP' | 'ONLINE';
    export const TypeEnum = {
        CASHIER: 'CASHIER' as TypeEnum,
        APP: 'APP' as TypeEnum,
        ONLINE: 'ONLINE' as TypeEnum
    };
}


