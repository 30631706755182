import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppComponent as SharedAppComponent } from "shared/lib/v2/components";
import {
  InactivityService,
  PosConnectionService,
  SentryService,
  ThirdPartyScriptsService,
  TranslateService,
  UserService,
  UtilsService,
  RoutingService,
} from "shared/lib/common/services";
import { SuccessRedeemPage, ISuccessReedemComponentParams } from "./pages";
import { IIdentifierType } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent extends SharedAppComponent implements OnInit {
  constructor(
    private _inactivity: InactivityService,
    private readonly _modalCtrl: ModalController,
    private _user: UserService,
    private _routing: RoutingService,
    private _config: ConfigService,
    private _posConnectionService: PosConnectionService,
    private _thirdParty: ThirdPartyScriptsService,
    private _utils: UtilsService,
    private _sentry: SentryService,
    private _translate: TranslateService,
    private _deviceMountingService: DeviceMountingService,
  ) {
    super(
      _inactivity,
      _modalCtrl,
      _user,
      _routing,
      _config,
      _posConnectionService,
      _thirdParty,
      _utils,
      _sentry,
      _translate,
      _deviceMountingService,
    );
  }

  protected async openSuccessModal(keyCode: string, points: number, idType: IIdentifierType): Promise<void> {
    const componentProps: ISuccessReedemComponentParams = {
      idType,
      keyCode,
      points,
      title:
        points > 0 ? this._translate._("SUCCESS_REDEEM_MODAL_TITLE_POSITIVE") : this._translate._("SUCCESS_REDEEM_MODAL_TITLE_NEGATIVE"),
    };
    const successModal = await this._modalCtrl.create({
      component: SuccessRedeemPage,
      componentProps,
    });
    await successModal.present();
    await successModal.onDidDismiss();
  }
}
