import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ConfirmationPage } from "ocm/src/app/pages/confirmation/confirmation.page";
import { CouponCardPage, ICouponCardInput, ICouponCardOutput } from "ocm/src/app/pages/coupon-card/coupon-card.page";
import { ITransaction, IVoucher, VoucherType } from "shared/lib/common/interfaces";
import { IConfirmationPageInputs, IConfirmationPageOutputs } from "shared/lib/common/pages";
import { BurnPage as BurnPageShared } from "shared/lib/common/pages";
import {
  PrintService,
  ToastService,
  TranslateService,
  UserService,
  UtilsService,
  ConfigService,
  RoutingService,
} from "shared/lib/common/services";
import { ISuccessComponentParams, SuccessPage } from "../success/success.page";
import { AdminService } from "shared/lib/v2/services";

@Component({
  selector: "ocm-burn-page",
  templateUrl: "./burn.page.html",
  styleUrls: ["./burn.page.scss"],
})
export class BurnPage extends BurnPageShared implements OnInit {
  public historyActive: boolean;
  public burnActive: boolean;
  public slides: HTMLIonSlidesElement;
  public transactions: ITransaction[];
  public totalEarn: number;
  public totalRedeem: number;

  constructor(
    public _translate: TranslateService,
    public _user: UserService,
    public _utils: UtilsService,
    protected _modal: ModalController,
    protected _toast: ToastService,
    protected _config: ConfigService,
    protected _print: PrintService,
    protected _admin: AdminService,
    protected _routing: RoutingService,
  ) {
    super(_translate, _user, _utils, _modal, _toast, _config, _print, _admin, _routing);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.burnActive = true;
    this.historyActive = false;
    this.slides = document.querySelector("ion-slides");
  }

  public ionViewDidEnter(): void {
    super.ionViewDidEnter();
    this.transactions = this._user.getHistory().transactions;
    this.getTotals(this.transactions);
  }

  public goToBurn(): void {
    this.burnActive = true;
    this.historyActive = false;
    this.slides.slidePrev();
  }

  public async activeSlide(event: any): Promise<void> {
    const resp = await this.slides.getActiveIndex();
    switch (resp) {
      case 0:
        this.goToBurn();
        break;
      case 1:
        this.goToHistory();
        break;
    }
  }

  public goToHistory(): void {
    this.burnActive = false;
    this.historyActive = true;
    this.slides.slideNext();
    this.transactions = this._user.getHistory().transactions;
    this.getTotals(this.transactions);
  }

  public sortTickets(tickets: IVoucher[]): IVoucher[] {
    const rewards = tickets.filter(t => t.voucherType === VoucherType.reward);
    const coupons = tickets.filter(t => t.voucherType === VoucherType.coupon);
    return [
      ...this.orderByBoostFactor(coupons.filter(c => c.points === 0 || !c.points)),
      ...this.orderByPoints(coupons.filter(c => c.points && c.points !== 0)),
      ...this.orderByBoostFactor(rewards.filter(c => c.points === 0 || !c.points)),
      ...this.orderByPoints(rewards.filter(c => c.points && c.points !== 0)),
    ];
  }

  public async selectTicket(ticket: IVoucher): Promise<void> {
    this.ticketSelected = ticket;
    if (this.mobileVersion) {
      const componentProps: ICouponCardInput = {
        ticketSelected: this.ticketSelected,
      };
      const couponModal = await this._modal.create({
        component: CouponCardPage,
        cssClass: "modal",
        componentProps,
      });
      await couponModal.present();
      const { data } = (await couponModal.onDidDismiss()) as ICouponCardOutput;
      if (data) this.askToRedeem(data);
    }
  }

  public async askToRedeem(voucher: IVoucher): Promise<void> {
    const componentProps: IConfirmationPageInputs = {
      voucher,
      title: this.translate._("SHOW_THIS_COUPON_TO_A_SALES_REP"),
      confirmText: this.translate._("REDEEM_NOW"),
      dismissText: this.translate._("CANCEL"),
    };
    const confirmationModal = await this._modal.create({
      component: ConfirmationPage,
      cssClass: "modal",
      componentProps,
    });
    await confirmationModal.present();
    const { data } = (await confirmationModal.onDidDismiss()) as IConfirmationPageOutputs;
    if (data) super.redeem(voucher);
  }

  public orderByBoostFactor(tickets: IVoucher[]): IVoucher[] {
    return tickets.sort((t1, t2) => {
      const boostFactor1 = (t1.customProperties || []).find(p => p.name === "BOOST_FACTOR");
      const boostFactor2 = (t2.customProperties || []).find(p => p.name === "BOOST_FACTOR");
      if (boostFactor1 && boostFactor2) {
        if (boostFactor1.value < boostFactor2.value) return -1;
        if (boostFactor1.value > boostFactor2.value) return 1;
        return 0;
      } else {
        if (!boostFactor1) return 0;
        if (!boostFactor2) return -1;
      }
    });
  }

  protected async success(voucher: IVoucher): Promise<any> {
    const componentProps: ISuccessComponentParams = { voucher };
    const successModal = await this._modal.create({
      component: SuccessPage,
      cssClass: "modal",
      componentProps,
      backdropDismiss: false,
    });
    await successModal.present();
  }

  private getTotals(transactions: ITransaction[] = []): void {
    transactions.forEach(t => {
      if (t.points) {
        if (t.points >= 0) {
          this.totalEarn += t.points;
        } else {
          this.totalRedeem += t.points;
        }
      }
    });
  }
}
