var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ConfirmationPage } from "ocm/src/app/pages/confirmation/confirmation.page";
import { CouponCardPage } from "ocm/src/app/pages/coupon-card/coupon-card.page";
import { VoucherType } from "shared/lib/common/interfaces";
import { BurnPage as BurnPageShared } from "shared/lib/common/pages";
import { PrintService, ToastService, TranslateService, UserService, UtilsService, ConfigService, RoutingService, } from "shared/lib/common/services";
import { SuccessPage } from "../success/success.page";
import { AdminService } from "shared/lib/v2/services";
var BurnPage = /** @class */ (function (_super) {
    __extends(BurnPage, _super);
    function BurnPage(_translate, _user, _utils, _modal, _toast, _config, _print, _admin, _routing) {
        var _this = _super.call(this, _translate, _user, _utils, _modal, _toast, _config, _print, _admin, _routing) || this;
        _this._translate = _translate;
        _this._user = _user;
        _this._utils = _utils;
        _this._modal = _modal;
        _this._toast = _toast;
        _this._config = _config;
        _this._print = _print;
        _this._admin = _admin;
        _this._routing = _routing;
        return _this;
    }
    BurnPage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.burnActive = true;
        this.historyActive = false;
        this.slides = document.querySelector("ion-slides");
    };
    BurnPage.prototype.ionViewDidEnter = function () {
        _super.prototype.ionViewDidEnter.call(this);
        this.transactions = this._user.getHistory().transactions;
        this.getTotals(this.transactions);
    };
    BurnPage.prototype.goToBurn = function () {
        this.burnActive = true;
        this.historyActive = false;
        this.slides.slidePrev();
    };
    BurnPage.prototype.activeSlide = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.slides.getActiveIndex()];
                    case 1:
                        resp = _a.sent();
                        switch (resp) {
                            case 0:
                                this.goToBurn();
                                break;
                            case 1:
                                this.goToHistory();
                                break;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.goToHistory = function () {
        this.burnActive = false;
        this.historyActive = true;
        this.slides.slideNext();
        this.transactions = this._user.getHistory().transactions;
        this.getTotals(this.transactions);
    };
    BurnPage.prototype.sortTickets = function (tickets) {
        var rewards = tickets.filter(function (t) { return t.voucherType === VoucherType.reward; });
        var coupons = tickets.filter(function (t) { return t.voucherType === VoucherType.coupon; });
        return __spread(this.orderByBoostFactor(coupons.filter(function (c) { return c.points === 0 || !c.points; })), this.orderByPoints(coupons.filter(function (c) { return c.points && c.points !== 0; })), this.orderByBoostFactor(rewards.filter(function (c) { return c.points === 0 || !c.points; })), this.orderByPoints(rewards.filter(function (c) { return c.points && c.points !== 0; })));
    };
    BurnPage.prototype.selectTicket = function (ticket) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, couponModal, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ticketSelected = ticket;
                        if (!this.mobileVersion) return [3 /*break*/, 4];
                        componentProps = {
                            ticketSelected: this.ticketSelected,
                        };
                        return [4 /*yield*/, this._modal.create({
                                component: CouponCardPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                            })];
                    case 1:
                        couponModal = _a.sent();
                        return [4 /*yield*/, couponModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, couponModal.onDidDismiss()];
                    case 3:
                        data = (_a.sent()).data;
                        if (data)
                            this.askToRedeem(data);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.askToRedeem = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, confirmationModal, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            voucher: voucher,
                            title: this.translate._("SHOW_THIS_COUPON_TO_A_SALES_REP"),
                            confirmText: this.translate._("REDEEM_NOW"),
                            dismissText: this.translate._("CANCEL"),
                        };
                        return [4 /*yield*/, this._modal.create({
                                component: ConfirmationPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                            })];
                    case 1:
                        confirmationModal = _a.sent();
                        return [4 /*yield*/, confirmationModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, confirmationModal.onDidDismiss()];
                    case 3:
                        data = (_a.sent()).data;
                        if (data)
                            _super.prototype.redeem.call(this, voucher);
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.orderByBoostFactor = function (tickets) {
        return tickets.sort(function (t1, t2) {
            var boostFactor1 = (t1.customProperties || []).find(function (p) { return p.name === "BOOST_FACTOR"; });
            var boostFactor2 = (t2.customProperties || []).find(function (p) { return p.name === "BOOST_FACTOR"; });
            if (boostFactor1 && boostFactor2) {
                if (boostFactor1.value < boostFactor2.value)
                    return -1;
                if (boostFactor1.value > boostFactor2.value)
                    return 1;
                return 0;
            }
            else {
                if (!boostFactor1)
                    return 0;
                if (!boostFactor2)
                    return -1;
            }
        });
    };
    BurnPage.prototype.success = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, successModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = { voucher: voucher };
                        return [4 /*yield*/, this._modal.create({
                                component: SuccessPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 1:
                        successModal = _a.sent();
                        return [4 /*yield*/, successModal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.getTotals = function (transactions) {
        var _this = this;
        if (transactions === void 0) { transactions = []; }
        transactions.forEach(function (t) {
            if (t.points) {
                if (t.points >= 0) {
                    _this.totalEarn += t.points;
                }
                else {
                    _this.totalRedeem += t.points;
                }
            }
        });
    };
    return BurnPage;
}(BurnPageShared));
export { BurnPage };
