<ion-content scroll-y="false">
  <div class="page-wrapper admin">
    <div class="admin__content">
      <div class="admin__content__loading" *ngIf="loading">
        <ion-spinner class="admin__content__loading__spinner" name="crescent"></ion-spinner>
      </div>
      <div class="admin__content__title" *ngIf="bookPointModule">
        <h1>
          {{ translate._("ADD_MANUAL_POINTS") }}
        </h1>
      </div>
      <shared-revenue-module *ngIf="revenueModule" [revenueMode]="revenueMode" [pointsRounding]="pointsRounding"
        [revenueRounding]="revenueRounding" [promotions]="promotions" [mode]="'simple'" (backEvent)="arts($event)">
      </shared-revenue-module>
      <shared-revenue-module *ngIf="bookPointModule" [revenueMode]="'INT'" [mode]="'manual'"
        (backEvent)="addPoints($event)"></shared-revenue-module>
      <p class="admin__content__version">{{ releaseVersion }}</p>
    </div>
    <i-settings class="admin__button-configurations" (appDoubleTap)="goToConfigurations()"></i-settings>
  </div>
</ion-content>