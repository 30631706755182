/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "angular-feather";
import * as i4 from "../../../../../../shared/lib/v1/components/close/close.component.ngfactory";
import * as i5 from "../../../../../../shared/lib/v1/components/close/close.component";
import * as i6 from "@angular/common";
import * as i7 from "./header.component";
import * as i8 from "../../../../../../shared/lib/common/services/translate/translate.service";
import * as i9 from "../../../../../../shared/lib/common/services/config/config.service";
import * as i10 from "../../../../../../shared/lib/common/services/routing/routing.service";
import * as i11 from "@ionic/angular";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ocm-header__language-selector"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.openLanguageModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "ocm-header__language-selector__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i-chevron-down", [], null, null, null, i2.View_ɵbu_0, i2.RenderType_ɵbu)), i1.ɵdid(5, 49152, null, 0, i3.ɵbu, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "ocm-header__language-selector__flag --", _co.translate.getSessionLanguage(), "-flag"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.languages[_co.translate.getSessionLanguage()]; _ck(_v, 3, 0, currVal_1); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ocm-header__logout"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i-power", [["class", "ocm-header__logout__icon"]], null, null, null, i2.View_ɵhg_0, i2.RenderType_ɵhg)), i1.ɵdid(2, 49152, null, 0, i3.ɵhg, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "ocm-header__logout__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("LOG_OUT"); _ck(_v, 4, 0, currVal_0); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-close", [["class", "ocm-header__cross"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(1, 114688, null, 0, i5.CloseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "company logo"], ["class", "ocm-header__logo"]], [[8, "src", 4]], [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.goAdminPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showLanguageSelect && ((_co._config == null) ? null : ((_co._config.screenSaver == null) ? null : _co._config.screenSaver.showSelectLanguage))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showLogOut; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showCross; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "", (_co._config.logo || "assets/app-logo/ocm.png"), ""); _ck(_v, 6, 0, currVal_3); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i7.HeaderComponent, [i8.TranslateService, i9.ConfigService, i10.RoutingService, i11.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("ocm-header", i7.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
