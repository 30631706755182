/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminUser { 
    id?: string;
    partnerId?: string;
    roles?: Array<AdminUser.RolesEnum>;
    userName?: string;
}
export namespace AdminUser {
    export type RolesEnum = 'PROGRAM_MANAGER' | 'OPERATOR' | 'MARKETING_MANAGER' | 'CUSTOMER_CARE_AGENT';
    export const RolesEnum = {
        PROGRAMMANAGER: 'PROGRAM_MANAGER' as RolesEnum,
        OPERATOR: 'OPERATOR' as RolesEnum,
        MARKETINGMANAGER: 'MARKETING_MANAGER' as RolesEnum,
        CUSTOMERCAREAGENT: 'CUSTOMER_CARE_AGENT' as RolesEnum
    };
}


