var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { RegisterPage as SharedRegisterPage } from "shared/lib/common/pages";
import { AdminService } from "shared/lib/v2/services";
import { ConfigService } from "shared/lib/common/services";
var RegisterPage = /** @class */ (function (_super) {
    __extends(RegisterPage, _super);
    function RegisterPage(_t, _modal, _fb, _admin, _toast, _config, _user) {
        var _this = _super.call(this, _t, _modal, _fb, _admin, _toast, _config, _user) || this;
        _this._t = _t;
        _this._modal = _modal;
        _this._fb = _fb;
        _this._admin = _admin;
        _this._toast = _toast;
        _this._config = _config;
        _this._user = _user;
        return _this;
    }
    return RegisterPage;
}(SharedRegisterPage));
export { RegisterPage };
