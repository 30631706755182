import { EnvironmentVariables } from "shared/lib/common/interfaces";

export const org = "<ORGANIZATION>";

export const environmentVariables: EnvironmentVariables = {
  local_pfm: {
    API_MOUNTING: "https://dev.service.point4more.com",
    SELFSERVICE: `https://test.point4more.com/selfservice`,
  },
  local_mhe: {
    API_MOUNTING: "https://dev.service.point4more.com",
    SELFSERVICE: `https://mhe.test.point4more.com/selfservice`,
  },
  staging_pfm: {
    API_MOUNTING: "https://dev.service.point4more.com",
    SELFSERVICE: `https://test.point4more.com/selfservice`,
  },
  production_pfm: {
    API_MOUNTING: "https://service.point4more.com",
    SELFSERVICE: `https://point4more.com/selfservice`,
  },
};
