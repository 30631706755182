var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, EnvironmentService, RoutingService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { Platform } from "@ionic/angular";
import { ConfigService } from "shared/lib/common/services";
var AdminPage = /** @class */ (function () {
    function AdminPage(translate, platform, admin, user, config, toast, routing, environment) {
        this.translate = translate;
        this.platform = platform;
        this.admin = admin;
        this.user = user;
        this.config = config;
        this.toast = toast;
        this.routing = routing;
        this.environment = environment;
    }
    AdminPage.prototype.ngOnInit = function () {
        this.releaseVersion = this.environment.getVariable("RELEASE");
    };
    AdminPage.prototype.ionViewDidEnter = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.user.removeUser();
                        return [4 /*yield*/, this.getAndParseActionsDefinition()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getRoundingFactors()];
                    case 2:
                        _a.sent();
                        this.getRevenueMode();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminPage.prototype.goToConfigurations = function () {
        this.routing.goForward([SharedRoutes.configurations]);
    };
    AdminPage.prototype.goToScreenSaver = function () {
        this.routing.goForward([SharedRoutes.screenSaver]);
    };
    AdminPage.prototype.goToQrScanner = function (queryParams) {
        this.routing.goForward([SharedRoutes.qrScanner], {
            queryParams: queryParams,
        });
    };
    AdminPage.prototype.getAndParseActionsDefinition = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promotions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.admin.getPromotions()];
                    case 1:
                        promotions = _a.sent();
                        this.promotions = Object.keys(promotions)
                            .filter(function (key) { return promotions[key]; })
                            .map(function (key) { return promotions[key]; });
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminPage.prototype.getRoundingFactors = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp, client;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.admin.getAllClients()];
                    case 1:
                        resp = _a.sent();
                        if (resp.ok === true) {
                            client = resp.response.find(function (c) { return String(c.clientId) === _this.config.getMountingConfig().clientId; });
                            this.pointsRounding = client.pointsRoundingCode;
                            this.revenueRounding = client.revenueRoundingCode;
                        }
                        else {
                            this.toast.show(this.translate._(resp.error.message), "error");
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminPage.prototype.getRevenueMode = function () {
        var resp = this.admin.getClientStore();
        if (resp.ok === true) {
            this.revenueMode = resp.response.revenueModuleMode;
        }
        else {
            this.toast.show(this.translate._(resp.error.message), "error");
        }
    };
    return AdminPage;
}());
export { AdminPage };
