var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnInit } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";
import { ModalController } from "@ionic/angular";
var RevenueDatePage = /** @class */ (function () {
    function RevenueDatePage(translate, modal) {
        this.translate = translate;
        this.modal = modal;
    }
    RevenueDatePage.prototype.ngOnInit = function () {
        var date = new Date();
        this.day = date.toISOString();
        this.hour = date.toISOString();
    };
    RevenueDatePage.prototype.dismiss = function () {
        var _a = __read(new Date(this.day).toLocaleDateString("DE").split("."), 3), day = _a[0], month = _a[1], year = _a[2];
        var _b = __read(new Date(this.hour).toLocaleTimeString("DE").split(":"), 3), hour = _b[0], minutes = _b[1], seconds = _b[2];
        this.modal.dismiss(year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0") + "T" + hour.padStart(2, "0") + ":" + minutes.padStart(2, "0") + ":" + seconds.padStart(2, "0"));
    };
    RevenueDatePage.prototype.cancelation = function () {
        this.modal.dismiss();
    };
    return RevenueDatePage;
}());
export { RevenueDatePage };
