<div *ngIf="showLanguageSelect && _config?.screenSaver?.showSelectLanguage" class="ocm-header__language-selector"
  (tap)="openLanguageModal()">
  <em class="ocm-header__language-selector__flag --{{ this.translate.getSessionLanguage() }}-flag"></em>
  <span class="ocm-header__language-selector__text">{{ this.languages[this.translate.getSessionLanguage()] }}</span>
  <i-chevron-down></i-chevron-down>
</div>
<div *ngIf="showLogOut" class="ocm-header__logout" (tap)="logOut()">
  <i-power class="ocm-header__logout__icon"></i-power>
  <h1 class="ocm-header__logout__text">{{ translate._("LOG_OUT") }}</h1>
</div>
<shared-close class="ocm-header__cross" *ngIf="showCross" (tap)="back()"></shared-close>
<img class="ocm-header__logo" src="{{_config.logo||'assets/app-logo/ocm.png'}}" (tap)="goAdminPage()"
  alt="company logo" />