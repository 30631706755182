/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinkedTransaction } from './linked-transaction';
import { TenderItemData } from './tender-item-data';
import { LineItemData } from './line-item-data';


export interface TransactionData { 
    amount: number;
    currencyCode?: string;
    /**
     * External reference to uniquely identify this transaction in external systems. E.g. bon_id or webshop_transactionID
     */
    externalId?: string;
    lineItems?: Array<LineItemData>;
    linkedTransaction?: LinkedTransaction;
    reason?: string;
    taxRate?: number;
    tenderItems?: Array<TenderItemData>;
    /**
     * Date and time when the transaction is created.
     */
    transactionTime?: string;
    transactionType: TransactionData.TransactionTypeEnum;
    /**
     * Value date of the transaction. Is in future for locked transactions.
     */
    valueTime?: string;
}
export namespace TransactionData {
    export type TransactionTypeEnum = 'EARNTRANSACTION' | 'PAYWITHPOINTSTRANSACTION';
    export const TransactionTypeEnum = {
        EARNTRANSACTION: 'EARNTRANSACTION' as TransactionTypeEnum,
        PAYWITHPOINTSTRANSACTION: 'PAYWITHPOINTSTRANSACTION' as TransactionTypeEnum
    };
}


