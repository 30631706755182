var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { IBarcodeType, ICheckInResponse, ICoupon, IFile, IGetUserDetailsResponse, IGetUserHistoryResponse, IIdentifierType, IQrParams, IRevenuePoints, IRevenuePointsResponse, IReward, IUser, IVoucher, Picture, VoucherCoupon, VoucherReward, VoucherType, } from "shared/lib/common/interfaces";
import { AdminService } from "shared/lib/common/services/admin/admin.service";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import * as i0 from "@angular/core";
import * as i1 from "../sentry/sentry.service";
import * as i2 from "../p4m/p4m.service";
import * as i3 from "../admin/admin.service";
import * as i4 from "../config/config.service";
import * as i5 from "../translate/translate.service";
var UserService = /** @class */ (function () {
    function UserService(sentry, p4m, admin, config, translate) {
        this.sentry = sentry;
        this.p4m = p4m;
        this.admin = admin;
        this.config = config;
        this.translate = translate;
    }
    UserService.prototype.checkIn = function (keyCode) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.checkIn({
                            apiKey: this.admin.getApiKey(),
                            deviceKey: this.config.getMountingConfig().deviceKey,
                            language: this.translate.getSessionLanguage(),
                            keyCode: keyCode,
                            organization: this.config.getOrganization(),
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserService.prototype.setUser = function (user) {
        this.user = user;
    };
    UserService.prototype.getUser = function () {
        return this.user;
    };
    UserService.prototype.setHistory = function (history) {
        this.history = history;
    };
    UserService.prototype.getHistory = function () {
        return this.history || { transactions: [] };
    };
    UserService.prototype.updateUserCoupon = function (coupon) {
        var i = this.user.coupons.findIndex(function (c) { return c.couponId === coupon.couponId; });
        if (i >= 0)
            this.user.coupons[i] = this.p4m.parseCoupons([coupon], this.user.totalPoints)[0];
    };
    UserService.prototype.getExternalCode = function (voucher) {
        var response;
        if (this.user) {
            if (voucher.voucherType === VoucherType.coupon) {
                (this.user.coupons || []).forEach(function (c) {
                    if (c.couponId === voucher.voucherId) {
                        response = {
                            type: c.externalCodeBarcodeTypeCode,
                            code: c.externalCode,
                        };
                    }
                });
            }
            else {
                (this.user.rewards || []).forEach(function (r) {
                    if (r.rewardId === voucher.voucherId) {
                        response = {
                            type: r.externalCodeBarcodeTypeCode,
                            code: r.externalCode,
                        };
                    }
                });
            }
        }
        return response ? { ok: true, response: response } : { ok: false, error: { message: "COUPON_NOT_FOUND" } };
    };
    UserService.prototype.getDetails = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var userDetailsResponse, _a, userResponse, couponsResponse, rewardsResponse, coupons, rewards, message_1, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.p4m.getUserDetails({
                                keyCode: keyCode,
                                apiKey: this.admin.getApiKey(),
                                clientId: this.config.getMountingConfig().clientId,
                                idType: idType,
                            })];
                    case 1:
                        userDetailsResponse = _b.sent();
                        if (!(userDetailsResponse.ok === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, Promise.all([
                                this.p4m.readUser({ idType: "ID", userId: userDetailsResponse.response.userId }),
                                this.p4m.getCoupons({ apiKey: this.admin.getApiKey(), clientId: this.config.getMountingConfig().clientId }),
                                this.p4m.getRewards({ apiKey: this.admin.getApiKey(), clientId: this.config.getMountingConfig().clientId }),
                            ])];
                    case 2:
                        _a = __read.apply(void 0, [_b.sent(), 3]), userResponse = _a[0], couponsResponse = _a[1], rewardsResponse = _a[2];
                        if (userResponse.ok === true && couponsResponse.ok === true && rewardsResponse.ok === true) {
                            coupons = this.parseCoupons(couponsResponse.response.coupons);
                            rewards = this.parseRewards(rewardsResponse.response.rewards);
                            return [2 /*return*/, this.parseUser(keyCode, userDetailsResponse.response, coupons, rewards, userResponse.response, idType)];
                        }
                        else {
                            [userResponse, couponsResponse, rewardsResponse].forEach(function (response) {
                                if (response.ok === false)
                                    message_1 = response.error.message;
                            });
                            return [2 /*return*/, { ok: false, error: { message: message_1 } }];
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, userDetailsResponse];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _b.sent();
                        this.sentry.handleError({ err: error_1, method: "user.getDetails" });
                        return [2 /*return*/, { ok: false, error: { message: error_1.message || error_1 } }];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.getUserHistory = function (keyCode, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.p4m.getUserHistory({
                                keyCode: keyCode,
                                apiKey: this.admin.getApiKey(),
                                clientId: this.config.getMountingConfig().clientId,
                                idType: idType,
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_2 = _a.sent();
                        this.sentry.handleError({ err: error_2, method: "user.getUserHistory" });
                        return [2 /*return*/, { ok: false, error: { message: error_2.message || error_2 } }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.removeUser = function () {
        this.user = undefined;
        this.history = undefined;
    };
    // API1:
    // title: description
    // description: extended description (or empty)
    // API2:
    // title: title || subtitle
    // description: description
    UserService.prototype.getVouchers = function () {
        var _this = this;
        var resp = [];
        if (this.user) {
            if (this.user.coupons) {
                this.user.coupons.forEach(function (c) {
                    if (!c.storeId || (c.storeId && c.storeId === _this.config.getMountingConfig().storeId)) {
                        resp.push({
                            title: c.title || c.subtitle || c.description,
                            couponTypeCode: c.couponTypeCode,
                            voucherType: VoucherType.coupon,
                            points: c.points,
                            description: c.extendedDescription || (c.title || c.subtitle ? c.description : ""),
                            unlocked: c.unlocked,
                            voucherId: c.couponId,
                            accountVoucherId: c.accountCouponId,
                            extendedDescription: c.extendedDescription,
                            contentPictureFilePath: _this.getPicture(c.couponFiles, "content", c.pictureFilePath),
                            teaserPictureFilePath: _this.getPicture(c.couponFiles, "teaser", c.pictureFilePath),
                            userCouponKeyCodes: c.userCouponKeyCodes,
                            redeemedUserCouponKeyCodes: c.redeemedUserCouponKeyCodes,
                            validToDate: c.validToDate,
                            validFromDate: c.validFromDate,
                            redeemDependencyCode: c.redeemDependencyCode,
                            customProperties: c.customProperties,
                            activation: c.activation,
                        });
                    }
                });
            }
            if (this.user.rewards) {
                this.user.rewards.forEach(function (r) {
                    resp.push({
                        title: r.title || r.subtitle || r.description,
                        voucherType: VoucherType.reward,
                        decreasePoints: r.decreasePoints,
                        description: r.extendedDescription || (r.title || r.subtitle ? r.description : ""),
                        voucherId: r.rewardId,
                        accountVoucherId: r.accountCouponId,
                        unlocked: r.unlocked,
                        points: r.points,
                        contentPictureFilePath: _this.getPicture(r.rewardFiles, "content", r.pictureFilePath),
                        teaserPictureFilePath: _this.getPicture(r.rewardFiles, "teaser", r.pictureFilePath),
                        redeemDependencyCode: r.redeemDependencyCode,
                        customProperties: r.customProperties,
                        validFromDate: r.validFromDate,
                        validToDate: r.validToDate,
                        extendedDescription: r.extendedDescription,
                        activation: r.activation,
                    });
                });
            }
        }
        return resp;
    };
    UserService.prototype.redeemReward = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.p4m.redeemReward({
                                keyCode: this.user.keyCode,
                                rewardId: voucher.voucherId.toString(),
                                accountVoucherId: voucher.accountVoucherId,
                                apiKey: this.admin.getApiKey(),
                                clientId: this.config.getMountingConfig().clientId,
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_3 = _a.sent();
                        this.sentry.handleError({ err: error_3, method: "user.redeemReward" });
                        return [2 /*return*/, {
                                ok: false,
                                error: { message: error_3 },
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.redeemCoupon = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.p4m.redeemCoupon({
                                keyCode: this.user ? this.user.keyCode : "",
                                apiKey: this.admin.getApiKey(),
                                clientId: this.config.getMountingConfig().clientId,
                                couponId: voucher.voucherId,
                                accountVoucherId: voucher.accountVoucherId,
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_4 = _a.sent();
                        this.sentry.handleError({ err: error_4, method: "user.redeemCoupon" });
                        return [2 /*return*/, {
                                ok: false,
                                error: { message: error_4 },
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.convertToPoints = function (euros) {
        if (this.user && this.user.convertFactor) {
            return euros * this.user.convertFactor;
        }
        else {
            return euros;
        }
    };
    UserService.prototype.revenuePoints = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.revenuePoints({
                            organization: this.config.getOrganization(),
                            keyCode: params.keyCode,
                            deviceKey: params.deviceKey,
                            idType: params.idType,
                            revenueRmb: params.revenueRmb,
                            revenueRmc1: params.revenueRmc1,
                            revenueRmc2: params.revenueRmc2,
                            revenueRmc3: params.revenueRmc3,
                            revenueRmt: params.revenueRmt,
                            totalPoints: params.totalPoints,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserService.prototype.bookPoints = function (keyCode, customPoints, idType) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.p4m.bookPoints({
                            keyCode: keyCode,
                            points: customPoints,
                            idType: idType,
                            apiKey: this.admin.getApiKey(),
                            clientId: this.config.getMountingConfig().clientId,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserService.prototype.getEarnCoupons = function () {
        if (this.user && this.user.coupons) {
            return this.user.coupons.filter(function (_a) {
                var couponTypeCode = _a.couponTypeCode;
                return couponTypeCode === "EARN";
            });
        }
        else {
            return [];
        }
    };
    UserService.prototype.getEarnListFilters = function () {
        var filters = [];
        var coupons = this.getEarnCoupons();
        if (coupons) {
            coupons.forEach(function (c) {
                if (c.subtitle && !filters.includes(c.subtitle)) {
                    filters.push(c.subtitle);
                }
            });
        }
        return filters;
    };
    UserService.prototype.parseCoupons = function (coupons) {
        var resp = {};
        (coupons || []).forEach(function (coupon) {
            var storeId = null;
            if (coupon.sponsorStore && coupon.sponsorStore.storeId !== null) {
                storeId = coupon.sponsorStore.storeId;
            }
            if (coupon.store && coupon.store.storeId !== null) {
                storeId = coupon.store.storeId;
            }
            resp[coupon.couponId] = __assign({}, coupon, { storeId: storeId });
        });
        return resp;
    };
    UserService.prototype.parseRewards = function (rewards) {
        var resp = {};
        if (rewards) {
            rewards.forEach(function (reward) {
                resp[reward.rewardId] = reward;
            });
        }
        return resp;
    };
    UserService.prototype.parseUser = function (keyCode, user, infoCoupons, infoRewards, userData, idType) {
        if (user) {
            if (user.errorInfo) {
                return { ok: false, error: { message: user.errorInfo.errorCode } };
            }
            if (typeof user.totalPoints === undefined) {
                return {
                    ok: false,
                    error: { message: "EMPTY_USER_TOT" },
                };
            }
            if (typeof user.userId === undefined) {
                return {
                    ok: false,
                    error: { message: "EMPTY_USER_ID" },
                };
            }
        }
        else {
            return {
                ok: false,
                error: { message: "EMPTY_USER" },
            };
        }
        var coupons = [];
        var rewards = [];
        if (user.coupons) {
            user.coupons.forEach(function (coupon) {
                coupons.push(__assign({}, coupon, { couponId: coupon.couponId, couponTypeCode: coupon.couponTypeCode, description: coupon.description, unlocked: coupon.unlocked, subtitle: coupon.subtitle, points: coupon.points, extendedDescription: coupon.extendedDescription, pictureFilePath: coupon.pictureFilePath, couponFiles: coupon.couponFiles, userCouponKeyCodes: coupon.userCouponKeyCodes, redeemedUserCouponKeyCodes: coupon.redeemedUserCouponKeyCodes, storeId: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].storeId : null, externalCode: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].externalCode : coupon.externalCode, externalCodeBarcodeTypeCode: infoCoupons[coupon.couponId]
                        ? infoCoupons[coupon.couponId].externalCodeBarcodeTypeCode
                        : coupon.externalCodeBarcodeTypeCode, validFromDate: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].validFromDate : coupon.validFromDate, validToDate: infoCoupons[coupon.couponId] ? infoCoupons[coupon.couponId].validToDate : coupon.validToDate }));
            });
        }
        if (user.rewards) {
            user.rewards.forEach(function (reward) {
                rewards.push(__assign({}, reward, { accountCouponId: reward.accountCouponId, rewardId: reward.rewardId, points: reward.points, title: reward.title, subtitle: reward.subtitle, description: reward.description, extendedDescription: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].extendedDescription : reward.extendedDescription, decreasePoints: reward.decreasePoints, unlocked: reward.unlocked, pictureFilePath: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].pictureFilePath : null, externalCode: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].externalCode : null, externalCodeBarcodeTypeCode: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].externalCodeBarcodeTypeCode : null, validFromDate: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].validFromDate : reward.validFromDate, validToDate: infoRewards[reward.rewardId] ? infoRewards[reward.rewardId].validToDate : reward.validToDate }));
            });
        }
        return {
            ok: true,
            response: {
                userStatusCode: user.userStatusCode,
                userId: user.userId,
                userReferenceCode: user.userReferenceCode,
                totalPoints: user.totalPoints,
                coupons: coupons,
                rewards: rewards,
                keyCode: keyCode,
                convertFactor: user.convertFactor || 1,
                errorInfo: user.errorInfo,
                emailAddress: userData.emailAddress,
                firstAction: user.firstAction,
                statusDefinitions: user.statusDefinitions,
                isRegistered: user.userStatusCode === "RGU",
                idType: idType,
            },
        };
    };
    UserService.prototype.validateRedeemParams = function (qrJson) {
        if (qrJson.keyCode) {
            if (qrJson.couponCode) {
                return {
                    ok: true,
                    response: {
                        keyCode: qrJson.keyCode,
                        code: qrJson.couponCode,
                        isCoupon: true,
                    },
                };
            }
            else if (qrJson.rewardId) {
                return {
                    ok: true,
                    response: {
                        keyCode: qrJson.keyCode,
                        code: qrJson.rewardId.toString(),
                        isCoupon: false,
                    },
                };
            }
            else {
                return {
                    ok: false,
                    error: {
                        message: "VOUCHER_CODE_NOT_FOUND",
                    },
                };
            }
        }
        else {
            return {
                ok: false,
                error: { message: "KEYCODE_NOT_FOUND" },
            };
        }
    };
    UserService.prototype.getPicture = function (files, type, defaultImage) {
        var pictureFile;
        if (files) {
            files.forEach(function (file) {
                if (file.tag === type + "Image" || file.tag === type + "Picture") {
                    pictureFile = { file: file.pictureFilePath, isCorrectPicture: true };
                }
            });
            if (!pictureFile && files.length > 0)
                pictureFile = { file: files[0].pictureFilePath, isCorrectPicture: false };
        }
        return pictureFile || { file: defaultImage, isCorrectPicture: false };
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.SentryService), i0.inject(i2.P4mService), i0.inject(i3.AdminService), i0.inject(i4.ConfigService), i0.inject(i5.TranslateService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
