import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as js2xmlparser from "js2xmlparser";
import { IResponse, IReadUserParams, IUserV2 } from "shared/lib/common/interfaces";
import { LOCAL_STORAGE, P4M } from "shared/lib/common/enums";
import {
  IArtsParams,
  IArtsResponse,
  IAssignCouponParams,
  IBalance,
  IBookPointsParams,
  ICheckInParams,
  ICheckInResponse,
  IDeviceManagementAuthenticationParams,
  IDeviceManagementAuthenticationResponse,
  IDownloadDisplayAdsParams,
  IDownloadDisplayAdsResponse,
  IErrorInfo,
  IExtendedRevenuePointsManuallyParams,
  IExtendedRevenuePointsManuallyResponse,
  IGetActionDefinitions,
  IGetActionDefinitionsParams,
  IGetAllClientsParams,
  IGetAllClientsResponse,
  IGetCouponsParams,
  IGetCouponsResponse,
  IGetPermissionUserParams,
  IGetPermissionUserResponse,
  IGetRewardsParams,
  IGetRewardsResponse,
  IGetStoresOfClientParams,
  IGetStoresOfClientResponse,
  IGetUserDetailsParams,
  IGetUserDetailsResponse,
  IGetUserHistoryParams,
  IGetUserHistoryResponse,
  ILoginParams,
  ILoginToGetApiKeyParams,
  IMountDeviceParams,
  IMountDeviceResponse,
  IReadBalanceParams,
  IRedeemCouponParams,
  IRedeemRewardParams,
  IRegisterParams,
  IRegisterUserResponse,
  IResponseP4M,
  IRevenuePointsParams,
  IRevenuePointsResponse,
  IUnMountDeviceParams,
  IUnMountDeviceResponse,
  IUpdateProfileExtendedRegistrationParams,
  IUpdateProfileExtendedRegistrationResponse,
} from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EnvironmentService } from "shared/lib/common/services/environment/environment.service";

@Injectable({
  providedIn: "root",
})
export class P4mV1Service {
  constructor(
    private http: HttpClient,
    private sentry: SentryService,
    private config: ConfigService,
    private utils: UtilsService,
    private environment: EnvironmentService,
  ) {}

  public async checkIn(params: ICheckInParams): Promise<IResponse<ICheckInResponse>> {
    try {
      return this.parseResponseV1<ICheckInResponse>(
        await this.http
          .post<ICheckInResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.CHECK_IN}?ac=${params.organization}&ak=${
                params.apiKey
              }&p=${JSON.stringify({
                ...params,
                channelCode: "P",
                _transactionDateTime: new Date().getTime(),
                fromOfflineQueue: false,
                secondsSinceAction: 0,
              })}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async arts(params: IArtsParams): Promise<IResponse<IArtsResponse>> {
    try {
      return this.parseResponseV1<IArtsResponse>(
        await this.http
          .post<IArtsResponse>(`${this.environment.getVariable("API_V0")}/arts`, this.getArtsXml(params), {
            headers: new HttpHeaders({
              "Content-Type": "application/xml",
              "API-Key": params.apiKey,
            }),
          })
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getUserDetails(params: IGetUserDetailsParams): Promise<IResponse<IGetUserDetailsResponse>> {
    try {
      return this.parseResponseV1<IGetUserDetailsResponse>(
        await this.http
          .post<IGetUserDetailsResponse>(
            `${this.getApiV1Url()}/${P4M.GET_USER_DETAILS}?ak=${params.apiKey}&p={clientId:${params.clientId};keyCode:${params.keyCode}}`,
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getUserHistory(params: IGetUserHistoryParams): Promise<IResponse<IGetUserHistoryResponse>> {
    try {
      return this.parseResponseV1<IGetUserHistoryResponse>(
        await this.http
          .post<IGetUserHistoryResponse>(
            `${this.getApiV1Url()}/${P4M.GET_USER_HISTORY}?ak=${params.apiKey}&p={clientId:${params.clientId};keyCode:${params.keyCode}}`,
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async login(params: ILoginParams): Promise<IResponse<string>> {
    try {
      const response = await this.http
        .post(
          `${this.environment.getVariable("API_V1")}/api/${P4M.LOGIN}`,
          {
            email: params.email,
            organization: params.organization,
            password: params.password,
          },
          {
            responseType: "text",
            headers: { "Content-Type": "application/json; charset=utf-8" },
          },
        )
        .toPromise();
      this.setToken(response);
      return { ok: true, response };
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async redeemCoupon(params: IRedeemCouponParams): Promise<IResponse<IResponseP4M>> {
    try {
      return this.parseResponseV1<IResponseP4M>(
        await this.http
          .post<IResponseP4M>(`${this.getApiV1Url()}/${P4M.REDEEM_COUPON}?ak=${params.apiKey}&p=${JSON.stringify(params)}`, "")
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async redeemReward(params: IRedeemRewardParams): Promise<IResponse<IResponseP4M>> {
    try {
      return this.parseResponseV1<IResponseP4M>(
        await this.http
          .post<IResponseP4M>(
            `${this.getApiV1Url()}/${P4M.REDEEM_REWARD}?ak=${params.apiKey}&p={keyCode:${params.keyCode};rewardId:${
              params.rewardId
            };clientId:${params.clientId}}`,
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async bookPoints(params: IBookPointsParams): Promise<IResponse<any>> {
    try {
      const userDetailsResponse = await this.getUserDetails({ keyCode: params.keyCode, apiKey: params.apiKey, clientId: params.clientId });
      if (userDetailsResponse.ok === true) {
        return this.parseResponseV1(
          await this.http
            .post<{ errorInfo: IErrorInfo }>(
              `${this.getApiV1Url()}/${P4M.BOOK_POINTS}?ak=${params.apiKey}&p={userReferenceCode:${
                userDetailsResponse.response.userReferenceCode
              };totalRevenue:${params.points};clientId:${params.clientId}}`,
              "",
            )
            .toPromise(),
        );
      } else {
        return userDetailsResponse;
      }
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async assignCoupon(params: IAssignCouponParams): Promise<IResponse<any>> {
    try {
      return this.parseResponseV1(
        await this.http
          .post(
            `${this.environment.getVariable("API_V1")}/api/${P4M.ASSIGN_COUPONS}`,
            { couponId: params.couponId, keyCode: params.keyCode, clientId: params.clientId },
            { headers: new HttpHeaders({ Authorization: params.token }) },
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getCoupons(params: IGetCouponsParams): Promise<IResponse<IGetCouponsResponse>> {
    try {
      return this.parseResponseV1<IGetCouponsResponse>(
        await this.http
          .post<IGetCouponsResponse>(`${this.getApiV1Url()}/${P4M.GET_COUPONS}?ak=${params.apiKey}&p={clientId:${params.clientId}}`, "")
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getRewards(params: IGetRewardsParams): Promise<IResponse<IGetRewardsResponse>> {
    try {
      return this.parseResponseV1<IGetRewardsResponse>(
        await this.http
          .post<IGetRewardsResponse>(`${this.getApiV1Url()}/${P4M.GET_REWARDS}?ak=${params.apiKey}&p={clientId:${params.clientId}}`, "")
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async deviceManagementLogin(
    params: IDeviceManagementAuthenticationParams,
  ): Promise<IResponse<IDeviceManagementAuthenticationResponse>> {
    try {
      return this.parseResponseV1<IDeviceManagementAuthenticationResponse>(
        await this.http
          .post<IDeviceManagementAuthenticationResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.DEVICE_MANAGEMENT}?p=${JSON.stringify({
                userName: params.userName,
                password: params.password,
              })}&ac=${params.organization}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getAllClients(params: IGetAllClientsParams): Promise<IResponse<IGetAllClientsResponse>> {
    try {
      return this.parseResponseV1<IGetAllClientsResponse>(
        await this.http
          .post<IGetAllClientsResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_ALL_CLIENTS}?ac=${
                params.organization
              }&ak=${params.apiKey}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getStoresOfClient(params: IGetStoresOfClientParams): Promise<IResponse<IGetStoresOfClientResponse>> {
    try {
      return this.parseResponseV1<IGetStoresOfClientResponse>(
        await this.http
          .post<IGetStoresOfClientResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_STORES}?ac=${params.organization}&ak=${
                params.apiKey
              }&p=${JSON.stringify({
                clientId: params.clientId,
              })}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async downloadDisplayAds(params: IDownloadDisplayAdsParams): Promise<IResponse<IDownloadDisplayAdsResponse>> {
    try {
      return this.parseResponseV1<IDownloadDisplayAdsResponse>(
        await this.http
          .post<IDownloadDisplayAdsResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.DOWNLOAD_DISPLAY_ADS}?ac=${
                params.organization
              }&ak=${params.apiKey}&p=${JSON.stringify({
                deviceKey: params.deviceKey,
                withoutBase64: true,
                displayAdIds: [],
              })}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async mountDevice(params: IMountDeviceParams): Promise<IResponse<IMountDeviceResponse>> {
    try {
      const response = this.parseResponseV1<IMountDeviceResponse>(
        await this.http
          .post<IMountDeviceResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.MOUNT_DEVICE}?ac=${params.organization}&ak=${
                params.apiKey
              }&p=${JSON.stringify(params)}`,
            ),
            "",
          )
          .toPromise(),
      );
      if (response.ok === true) this.sentry.addTag("device-id", String(response.response.deviceId));
      return response;
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async unMountDevice(params: IUnMountDeviceParams): Promise<IResponse<IUnMountDeviceResponse>> {
    this.sentry.removeTag("device-id");
    this.sentry.removeTag("email");
    try {
      return this.parseResponseV1<IUnMountDeviceResponse>(
        await this.http
          .post<IUnMountDeviceResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.UN_MOUNT_DEVICE}?ac=${
                params.organization
              }&ak=${params.apiKey}&p=${JSON.stringify(params)}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async loginToGetApiKey(params: ILoginToGetApiKeyParams): Promise<IResponse<IResponseP4M>> {
    try {
      return this.parseResponseV1<IResponseP4M>(
        await this.http
          .post<IResponseP4M>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.LOGIN_APIKEY}?p=${JSON.stringify({
                userName: params.userName,
                password: params.password,
                credentialTypeCode: "PM",
                channelCode: "W",
              })}&ac=${params.organization}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getPermissionUser(params: IGetPermissionUserParams): Promise<IResponse<IGetPermissionUserResponse>> {
    try {
      return this.parseResponseV1<IGetPermissionUserResponse>(
        await this.http
          .post<IGetPermissionUserResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_PERMISSION_USER}?p=${JSON.stringify({
                clientId: params.clientId,
              })}&ac=${params.organization}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async extendedRevenuePointsManually(
    params: IExtendedRevenuePointsManuallyParams,
  ): Promise<IResponse<IExtendedRevenuePointsManuallyResponse>> {
    try {
      return this.parseResponseV1<IExtendedRevenuePointsManuallyResponse>(
        await this.http
          .post<IExtendedRevenuePointsManuallyResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${
                P4M.EXTENDED_REVENUE_POINTS_MANUALLY
              }?p=${JSON.stringify({
                language: params.language,
                deviceKey: params.deviceKey,
                keyCode: params.keyCode,
                extendedRevenueFactsId: params.extendedRevenueFactsId,
                channelCode: "P",
                loaderText: "Punkte buchen",
              })}&ac=${params.organization}`,
            ),
            "",
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async getActionDefinitions(params: IGetActionDefinitionsParams): Promise<IResponse<IGetActionDefinitions>> {
    try {
      return this.parseResponseV1<IGetActionDefinitions>(
        await this.http
          .post<IGetActionDefinitions>(
            encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_ACTION_DEFINITIONS}`),
            null,
            {
              params: {
                ac: params.organization,
                p: JSON.stringify({ deviceKey: params.deviceKey }),
              },
            },
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async revenuePoints(params: IRevenuePointsParams): Promise<IResponse<IRevenuePointsResponse>> {
    try {
      return this.parseResponseV1<IRevenuePointsResponse>(
        await this.http
          .post<IRevenuePointsResponse>(
            encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.REVENUE_POINTS}`),
            null,
            {
              params: {
                ac: params.organization,
                p: JSON.stringify(params),
              },
            },
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async register(params: IRegisterParams): Promise<IResponse<IRegisterUserResponse>> {
    try {
      return this.parseResponseV1<IRegisterUserResponse>(
        await this.http
          .post<IRegisterUserResponse>(
            encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.REGISTER}`),
            null,
            {
              params: {
                ac: params.organization,
                p: JSON.stringify(params).replace("+", "%2B"),
              },
            },
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async updateProfileExtendedRegistration(
    params: IUpdateProfileExtendedRegistrationParams,
  ): Promise<IResponse<IUpdateProfileExtendedRegistrationResponse>> {
    try {
      return this.parseResponseV1<IUpdateProfileExtendedRegistrationResponse>(
        await this.http
          .post<IUpdateProfileExtendedRegistrationResponse>(
            encodeURI(
              `${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.UPDATE_PROFILE_EXTENDED_REGISTRATION}`,
            ),
            null,
            {
              params: {
                ac: params.organization,
                p: JSON.stringify(params),
              },
            },
          )
          .toPromise(),
      );
    } catch (error) {
      return this.parseError(error);
    }
  }

  public async readAccountBalance(params: IReadBalanceParams): Promise<IResponse<IBalance>> {
    try {
      const resp = await this.getUserDetails({ apiKey: params.apiKey, clientId: params.clientId, keyCode: params.keyCode });
      if (resp.ok === true) return this.parseBalance(this.parseResponseV1<IGetUserDetailsResponse>(resp.response));
      else return resp;
    } catch (error) {
      return this.parseError(error);
    }
  }

  public getPassword(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.PASSWORD));
  }

  public setPassword(password: string): void {
    localStorage.setItem(LOCAL_STORAGE.PASSWORD, this.utils.encrypt(password));
  }

  public getUser(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.EMAIL));
  }

  public setUser(email: string): void {
    localStorage.setItem(LOCAL_STORAGE.EMAIL, this.utils.encrypt(email));
  }

  public getToken(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
  }

  public setToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE.TOKEN, this.utils.encrypt(token));
  }

  public getArtsXml(params: IArtsParams): string {
    try {
      return js2xmlparser.parse("DigitalReceipt", {
        Transaction: {
          "@": { MajorVersion: 6, MinorVersion: 0, FixVersion: 0, TypeCode: "SaleTransaction" },
          ReceiptDateTime: params.date,
          WorkstationID: params.workstationId,
          ReceiptNumber: `${params.amount.toFixed(2)}_${params.vatId}_${this.utils.getReceiptFormatDate(new Date(params.date))}`,
          RetailTransaction: {
            Total: {
              "@": {
                TotalType: "TransactionGrandAmount",
              },
              "#": params.amount.toFixed(2),
            },
            LoyaltyAccount: { LoyaltyCard: { PrimaryAccountNumber: params.keyCode } },
          },
        },
      });
    } catch (err) {
      this.sentry.handleError({ err, method: "getArtsXml" });
      return "";
    }
  }

  public parseError(error: HttpErrorResponse): IResponse<null> {
    if (error) {
      return {
        ok: false,
        error: {
          message: error.message || error.error || error,
          code: error.status || 0,
        },
      };
    } else {
      return {
        ok: false,
        error: {
          message: "APP_UNHANDLED_ERROR",
          code: 0,
        },
      };
    }
  }

  public parseResponseV1<T>(response: T): IResponse<T> {
    if (response["errorInfo"]) {
      return {
        ok: false,
        error: { message: response["errorInfo"].errorCode },
      };
    } else {
      return { ok: true, response: this.ensureValidTimestamp<T>(response) };
    }
  }

  public ensureValidTimestamp<T>(obj: any): T {
    Object.keys(obj).forEach(k => {
      if (obj[k] && typeof obj[k] === "object") return this.ensureValidTimestamp(obj[k]);
      if (k === "unixTimeStamp" && String(obj[k]).length === 10) obj[k] *= 1000;
    });
    return obj as T;
  }

  public parseBalance(response: IResponse<IGetUserDetailsResponse>): IResponse<IBalance> {
    if (response.ok === true) {
      return { ok: true, response: { points: response.response.totalPoints, lockedPoints: response.response.totalPointsCollected } };
    } else {
      return response;
    }
  }

  public async readUser(params: IReadUserParams): Promise<IResponse<IUserV2>> {
    return { ok: true, response: {} };
  }

  public getApiV1Url(): string {
    let resp = `${this.environment.getVariable("API_V0")}/api`;
    if (this.config.getMountingConfig().workstationId) {
      resp += `/workstationId=${this.config.getMountingConfig().workstationId}`;
    }
    return resp;
  }
}
