<div class="datetime">
  <ion-button (click)="decrementTime()" color="light" class="datetime__decrement">
    <i-minus></i-minus>
  </ion-button>
  <ion-datetime class="datetime__date" [displayFormat]="displayFormat" [value]="time"
    (ionChange)="updateTime($event.target.value)">
  </ion-datetime>
  <ion-button (click)="incrementTime()" color="light" class="datetime__increment">
    <i-plus></i-plus>
  </ion-button>
</div>