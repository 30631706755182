import { Component } from "@angular/core";
import { ConfigurationsPage as SharedConfigurationsPage } from "shared/lib/v1/pages";
import {
  P4mService,
  PosConnectionService,
  PrintService,
  ToastService,
  TranslateService,
  UtilsService,
  ConfigService,
  RoutingService,
  EnvironmentService,
} from "shared/lib/common/services";
import { ModalController, AlertController } from "@ionic/angular";
import { FormBuilder } from "@angular/forms";
import { AdminService } from "shared/lib/v2/services";
import { OverlayEventDetail } from "@ionic/core";
import { EnsureUnmountLoginModal, EnsureUnmountLoginModalOutputs } from "shared/lib/v2/pages";

@Component({
  selector: "ocm-configurations",
  templateUrl: "../../../../../../shared/lib/v1/pages/configurations/configurations.page.html",
  styleUrls: ["../../../../../../shared/lib/v1/pages/configurations/configurations.page.scss"],
})
export class ConfigurationsPage extends SharedConfigurationsPage {
  constructor(
    private _environment: EnvironmentService,
    private _modal: ModalController,
    private _utils: UtilsService,
    private _p4m: P4mService,
    private _fb: FormBuilder,
    private _toast: ToastService,
    private _routing: RoutingService,
    public _translate: TranslateService,
    public _print: PrintService,
    public _admin: AdminService,
    public _posConnectionService: PosConnectionService,
    public _alert: AlertController,
    public _config: ConfigService,
  ) {
    super(_environment, _modal, _utils, _p4m, _fb, _toast, _routing, _translate, _print, _admin, _posConnectionService, _alert, _config);
  }

  protected async presentLogin(): Promise<OverlayEventDetail<EnsureUnmountLoginModalOutputs>> {
    const modal = await this._modal.create({ component: EnsureUnmountLoginModal });
    await modal.present();
    return await modal.onDidDismiss();
  }
}
