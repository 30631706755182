import { Directive, HostListener, OnInit, EventEmitter, Output } from "@angular/core";

@Directive({ selector: "[appDoubleTap]" })
export class DoubleTapDirective implements OnInit {
  private taps: number;
  @Output()
  public appDoubleTap: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public ngOnInit(): void {
    this.taps = 0;
  }

  @HostListener("tap")
  public onClick(): void {
    this.taps++;
    if (this.taps > 1) this.appDoubleTap.emit();
    const t = setTimeout(() => {
      this.taps = 0;
      clearTimeout(t);
    }, 600);
  }
}
