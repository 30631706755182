var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { RedeemDependencyCode, VoucherType, } from "shared/lib/common/interfaces";
import { ConfirmationPage, } from "shared/lib/common/pages/confirmation/confirmation.page";
import { CouponCardPage } from "shared/lib/v1/pages/coupon-card/coupon-card.page";
import { PrintPage } from "shared/lib/v1/pages/print/print.page";
import { AdminService, PrintService, RoutingService, ToastService, TranslateService, UserService, UtilsService, } from "shared/lib/common/services";
import { RegisterPage } from "shared/lib/common/pages/register/register.page";
import { ConfigService } from "shared/lib/common/services";
import { SuccessPage } from "shared/lib/common/pages/success/success.page";
var BurnPage = /** @class */ (function () {
    function BurnPage(translate, user, utils, modal, toast, config, print, admin, routing) {
        this.translate = translate;
        this.user = user;
        this.utils = utils;
        this.modal = modal;
        this.toast = toast;
        this.config = config;
        this.print = print;
        this.admin = admin;
        this.routing = routing;
    }
    BurnPage.prototype.ngOnInit = function () {
        this.loading = true;
        this.params = this.routing.getUrlParams();
        var width = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue("--max-width-mobile")
            .split("px")[0];
        this.mobileVersion = window.innerWidth < +width;
    };
    BurnPage.prototype.ionViewDidEnter = function () {
        var _this = this;
        this.status = this.getStatus();
        this.totalPoints = this.user.getUser() ? this.user.getUser().totalPoints : 0;
        this.tickets = this.sortTickets(this.user.getVouchers());
        this.loading = false;
        this.redeemLoading = false;
        if (this.params && (this.params.couponId || this.params.rewardId)) {
            var ticket = this.findTicket(this.tickets);
            if (ticket) {
                if (ticket.unlocked) {
                    if (!this.mobileVersion)
                        this.selectTicket(ticket);
                    this.askToRedeem(ticket);
                }
                else
                    this.toast.show(this.showUnlockedReason(ticket, this.totalPoints), "info");
            }
            else {
                this.logOutUser();
                this.toast.show(this.translate._("VOUCHER_CODE_NOT_FOUND"), "error");
            }
        }
        this.isRegisterButtonRequired().then(function (isRequired) { return (_this.showRegisterButton = isRequired); });
    };
    BurnPage.prototype.isRegisterButtonRequired = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resp, client, user;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.admin.getAllClients()];
                    case 1:
                        resp = _a.sent();
                        if (resp.ok === true) {
                            client = resp.response.find(function (c) { return String(c.clientId) === _this.config.getMountingConfig().clientId; });
                            user = this.user.getUser();
                            return [2 /*return*/, client.showRegistrationButton && !!user && !user.isRegistered];
                        }
                        else {
                            this.toast.show(this.translate._(resp.error.message), "error");
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.askToRedeem = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, confirmationModal, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            voucher: voucher,
                            title: this.translate._("SHOW_THIS_COUPON_TO_A_SALES_REP"),
                            confirmText: this.translate._("REDEEM_NOW"),
                            dismissText: this.translate._("CANCEL"),
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: ConfirmationPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                            })];
                    case 1:
                        confirmationModal = _a.sent();
                        return [4 /*yield*/, confirmationModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, confirmationModal.onDidDismiss()];
                    case 3:
                        data = (_a.sent()).data;
                        if (data)
                            this.redeem(voucher);
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.goHistoryPage = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.routing.goForward([SharedRoutes.history]);
                return [2 /*return*/];
            });
        });
    };
    BurnPage.prototype.selectTicket = function (ticket) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, couponModal, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ticketSelected = ticket;
                        if (!this.mobileVersion) return [3 /*break*/, 4];
                        componentProps = {
                            ticketSelected: this.ticketSelected,
                            showRegisterButton: this.showRegisterButton,
                            registerMandatory: this.needToShowRegisterModal(this.ticketSelected),
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: CouponCardPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                            })];
                    case 1:
                        couponModal = _a.sent();
                        return [4 /*yield*/, couponModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, couponModal.onDidDismiss()];
                    case 3:
                        data = (_a.sent()).data;
                        if (data)
                            this.askToRedeem(data);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.logOutUser = function () {
        this.user.removeUser();
        this.routing.goForward([SharedRoutes.screenSaver]);
    };
    BurnPage.prototype.showPrintModal = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, printModal, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = { voucher: voucher };
                        return [4 /*yield*/, this.modal.create({
                                component: PrintPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 1:
                        printModal = _a.sent();
                        return [4 /*yield*/, printModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, printModal.onDidDismiss()];
                    case 3:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    };
    BurnPage.prototype.redeem = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var resp, ok, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.redeemLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 15, 16, 17]);
                        if (!(this.config.havePrinter() &&
                            this.print.getIp() &&
                            ((voucher.voucherType === VoucherType.coupon && voucher.couponTypeCode !== "EARN") || voucher.voucherType !== VoucherType.coupon))) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.showPrintModal(voucher)];
                    case 2:
                        ok = _a.sent();
                        if (!ok) return [3 /*break*/, 6];
                        if (!(voucher.voucherType === VoucherType.reward)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.user.redeemReward(voucher)];
                    case 3:
                        resp = _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.user.redeemCoupon(voucher)];
                    case 5:
                        resp = _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 11];
                    case 7:
                        if (!(voucher.voucherType === VoucherType.reward)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.user.redeemReward(voucher)];
                    case 8:
                        resp = _a.sent();
                        return [3 /*break*/, 11];
                    case 9: return [4 /*yield*/, this.user.redeemCoupon(voucher)];
                    case 10:
                        resp = _a.sent();
                        _a.label = 11;
                    case 11:
                        if (!(resp && resp.ok === true)) return [3 /*break*/, 13];
                        return [4 /*yield*/, this.success(voucher)];
                    case 12:
                        _a.sent();
                        return [3 /*break*/, 14];
                    case 13:
                        if (resp && resp.ok === false) {
                            this.toast.show(this.translate._(resp.error.message), "error");
                        }
                        _a.label = 14;
                    case 14: return [3 /*break*/, 17];
                    case 15:
                        e_1 = _a.sent();
                        this.toast.show(this.translate._(e_1.message), "error");
                        return [3 /*break*/, 17];
                    case 16:
                        this.redeemLoading = false;
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.goToRegister = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.registerLoading = true;
                        user = this.user.getUser();
                        return [4 /*yield*/, this.admin.getRegistrationType()];
                    case 1:
                        resp = _a.sent();
                        if (!(resp.ok === true)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.showRegisterModal(user.keyCode, user.userReferenceCode, resp.response)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.toast.show(this.translate._(resp.error.message), "error");
                        _a.label = 4;
                    case 4:
                        this.registerLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.sortTickets = function (tickets) {
        var rewards = tickets.filter(function (t) { return t.voucherType === VoucherType.reward; });
        var coupons = tickets.filter(function (t) { return t.voucherType === VoucherType.coupon; });
        return __spread(this.orderByPoints(coupons), this.orderByPoints(rewards));
    };
    BurnPage.prototype.orderByPoints = function (tickets) {
        return tickets.sort(function (t1, t2) {
            if (t1.points < t2.points)
                return -1;
            if (t1.points > t2.points)
                return 1;
            return 0;
        });
    };
    BurnPage.prototype.needToShowRegisterModal = function (voucher) {
        var user = this.user.getUser();
        if (!voucher)
            return true;
        return ((voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_EASY ||
            voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_EXTENDED ||
            voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_SIMPLE) &&
            (!!user && !user.isRegistered));
    };
    BurnPage.prototype.success = function (voucher) {
        return __awaiter(this, void 0, void 0, function () {
            var points, componentProps, successModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (voucher.voucherType === VoucherType.coupon && voucher.couponTypeCode === "EARN")
                            points = voucher.points;
                        else
                            points = voucher.points * -1;
                        componentProps = {
                            idType: this.params.idType,
                            keyCode: (this.user.getUser() || {}).keyCode,
                            points: points,
                            title: voucher.voucherType === VoucherType.reward ? this.translate._("REWARD_REDEEMED") : this.translate._("COUPON_REDEEMED"),
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: SuccessPage,
                                cssClass: "modal",
                                componentProps: componentProps,
                                backdropDismiss: false,
                            })];
                    case 1:
                        successModal = _a.sent();
                        return [4 /*yield*/, successModal.present()];
                    case 2:
                        _a.sent();
                        this.redeemLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BurnPage.prototype.showRegisterModal = function (keyCode, userReferenceCode, registrationType) {
        return __awaiter(this, void 0, void 0, function () {
            var componentProps, registerModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        componentProps = {
                            registrationType: registrationType,
                            keyCode: keyCode,
                            userReferenceCode: userReferenceCode,
                        };
                        return [4 /*yield*/, this.modal.create({
                                component: RegisterPage,
                                cssClass: "modal modal--full-screen",
                                componentProps: componentProps,
                            })];
                    case 1:
                        registerModal = _a.sent();
                        return [4 /*yield*/, registerModal.present()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, registerModal.onDidDismiss()];
                    case 3: return [2 /*return*/, (_a.sent())];
                }
            });
        });
    };
    BurnPage.prototype.getStatus = function () {
        var resp;
        var user = this.user.getUser();
        if (user && user.statusDefinitions) {
            user.statusDefinitions.forEach(function (status) {
                if (!resp || (resp.points < status.points && status.unlocked)) {
                    resp = status;
                }
            });
        }
        return resp;
    };
    BurnPage.prototype.showUnlockedReason = function (ticket, neededPoints) {
        if (ticket.points > neededPoints)
            return this.translate._("NEED_MORE_POINTS");
        var now = new Date().getTime();
        if (ticket.voucherType === VoucherType.coupon) {
            if (ticket.validFromDate && now < new Date(ticket.validFromDate.unixTimeStamp).getTime()) {
                return this.translate._("VALID_FROM_ERROR", new Date(ticket.validFromDate.unixTimeStamp).toLocaleDateString());
            }
            if (ticket.validToDate && now > new Date(ticket.validToDate.unixTimeStamp).getTime()) {
                return this.translate._("VALID_TO_ERROR", new Date(ticket.validToDate.unixTimeStamp).toLocaleDateString());
            }
        }
        return this.translate._("LOCKED_VOUCHER");
    };
    BurnPage.prototype.findTicket = function (tickets) {
        var _this = this;
        return tickets.find(function (_a) {
            var voucherId = _a.voucherId, accountVoucherId = _a.accountVoucherId;
            if (voucherId && accountVoucherId) {
                return (String(voucherId) === String(_this.params.couponId) ||
                    String(voucherId) === String(_this.params.rewardId) ||
                    accountVoucherId === String(_this.params.couponId) ||
                    accountVoucherId === String(_this.params.rewardId));
            }
            if (accountVoucherId)
                return accountVoucherId === String(_this.params.couponId) || accountVoucherId === String(_this.params.rewardId);
            if (voucherId)
                return String(voucherId) === String(_this.params.couponId) || String(voucherId) === String(_this.params.rewardId);
        });
    };
    return BurnPage;
}());
export { BurnPage };
