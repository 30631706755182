/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationConfigurationTrigger { 
    description?: string;
    type?: NotificationConfigurationTrigger.TypeEnum;
    unsubscribable?: boolean;
}
export namespace NotificationConfigurationTrigger {
    export type TypeEnum = 'REGISTRATION_DOUBLE_OPTIN' | 'REGISTRATION_WELCOME' | 'USER_PASSWORD_RESET';
    export const TypeEnum = {
        REGISTRATIONDOUBLEOPTIN: 'REGISTRATION_DOUBLE_OPTIN' as TypeEnum,
        REGISTRATIONWELCOME: 'REGISTRATION_WELCOME' as TypeEnum,
        USERPASSWORDRESET: 'USER_PASSWORD_RESET' as TypeEnum
    };
}


