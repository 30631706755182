var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as crypto from "crypto-js";
import * as i0 from "@angular/core";
var ONE_DAY_MILI_SECONDS = 1000 * 60 * 60 * 24;
var ENCRYPT_KEY = "76d44500-e9be-48d4-86be-9236308fc57c";
var UtilsService = /** @class */ (function () {
    function UtilsService() {
    }
    UtilsService.prototype.waiting = function (time) {
        if (time === void 0) { time = 2000; }
        return new Promise(function (resolve) {
            var timeout = setTimeout(function () {
                clearTimeout(timeout);
                resolve("");
            }, time);
        });
    };
    UtilsService.prototype.encrypt = function (text) {
        return crypto.AES.encrypt(JSON.stringify(text), ENCRYPT_KEY).toString();
    };
    UtilsService.prototype.decrypt = function (text) {
        if (text && text !== "") {
            try {
                return JSON.parse(crypto.AES.decrypt(text, ENCRYPT_KEY).toString(crypto.enc.Utf8));
            }
            catch (e) {
                return crypto.AES.decrypt(text, ENCRYPT_KEY).toString(crypto.enc.Utf8);
            }
        }
        else {
            return null;
        }
    };
    UtilsService.prototype.generateRandom = function () {
        return crypto.MD5(Date.now().toString()).toString();
    };
    UtilsService.prototype.parseDate = function (date) {
        return date ? Math.round(new Date(date).getTime()) : null;
    };
    UtilsService.prototype.getNumberOfDays = function (coupon) {
        var miliSecondsToUnlock = coupon.validFromDate.unixTimeStamp - new Date().getTime();
        return Math.ceil(miliSecondsToUnlock / ONE_DAY_MILI_SECONDS);
    };
    UtilsService.prototype.lockedDueToDates = function (ticket) {
        return ticket.validFromDate
            ? this.isAfterNow(ticket.validFromDate.unixTimeStamp)
            : false || ticket.validToDate
                ? this.isBeforeNow(ticket.validToDate.unixTimeStamp)
                : false;
    };
    UtilsService.prototype.isBeforeNow = function (date) {
        return new Date().getTime() >= date;
    };
    UtilsService.prototype.isAfterNow = function (date) {
        return new Date().getTime() <= date;
    };
    UtilsService.prototype.isBetweenDates = function (start, end) {
        var now = new Date().getTime();
        return now >= start && now <= end;
    };
    UtilsService.prototype.decodeHTMLEntity = function (str) {
        var element = document.createElement("p");
        element.innerHTML = str;
        return element.textContent;
    };
    UtilsService.prototype.getReceiptFormatDate = function (date) {
        var _a = __read(date.toLocaleDateString("DE").split("."), 3), day = _a[0], month = _a[1], year = _a[2];
        var _b = __read(date.toLocaleTimeString("DE").split(":"), 2), hour = _b[0], minutes = _b[1];
        return "" + year + month.padStart(2, "0") + day.padStart(2, "0") + "-" + hour.padStart(2, "0") + minutes.padStart(2, "0");
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
