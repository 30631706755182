import { Component, Input, EventEmitter, Output } from "@angular/core";

const ONE_MINUTE_IN_MILLISECONDS = 1000 * 60;

@Component({
  selector: "ocm-datetime",
  templateUrl: "./datetime.component.html",
  styleUrls: ["./datetime.component.scss"],
})
export class DatetimeComponent {
  @Input() public time: string;
  @Input() public displayFormat: string;
  @Input() public millisecondsFactor: number;
  @Output() public newDate: EventEmitter<string> = new EventEmitter();

  constructor() {}

  public incrementTime(minutes: number = this.millisecondsFactor || ONE_MINUTE_IN_MILLISECONDS): void {
    const date = new Date(this.time);
    this.newDate.emit(new Date(date.setTime(date.getTime() + minutes)).toISOString());
  }

  public decrementTime(minutes: number = this.millisecondsFactor || ONE_MINUTE_IN_MILLISECONDS): void {
    const date = new Date(this.time);
    this.newDate.emit(new Date(date.setTime(date.getTime() - minutes)).toISOString());
  }

  public updateTime(date: string): void {
    this.newDate.emit(new Date(date).toISOString());
  }
}
