/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BookingData { 
    /**
     * time when the booking is created.
     */
    bookingTime?: string;
    bookingType: BookingData.BookingTypeEnum;
    bookingTypeCode: string;
    /**
     * can be used to set an explicit partnerId, if the interactionPoint doesn\'t belong to a specific partner
     */
    partnerId?: string;
    points: number;
    reason: string;
    /**
     * technical id of the domainObject that is referenced with this booking. (e.g. couponId)
     */
    referenceId?: string;
    /**
     * value date of the booking. Is in future for locked bookings.
     */
    valueTime?: string;
}
export namespace BookingData {
    export type BookingTypeEnum = 'BURNBOOKING' | 'EARNBOOKING';
    export const BookingTypeEnum = {
        BURNBOOKING: 'BURNBOOKING' as BookingTypeEnum,
        EARNBOOKING: 'EARNBOOKING' as BookingTypeEnum
    };
}


