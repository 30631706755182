var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import * as i0 from "@angular/core";
export var EVENT_LISTENERS;
(function (EVENT_LISTENERS) {
    EVENT_LISTENERS["ON_LOAD_EPSON_SCRIPT"] = "ON_LOAD_EPSON_SCRIPT";
    EVENT_LISTENERS["ON_LOAD_EPSON_JSZUTILS"] = "ON_LOAD_EPSON_JSZUTILS";
})(EVENT_LISTENERS || (EVENT_LISTENERS = {}));
var THIRD_PARTY_SCRIPTS = [
    { path: "/assets/epos-2.12.0.js", async: true, onloadEvent: new CustomEvent(EVENT_LISTENERS.ON_LOAD_EPSON_SCRIPT) },
    { path: "/assets/jszip-utils.js", async: false, onloadEvent: new CustomEvent(EVENT_LISTENERS.ON_LOAD_EPSON_JSZUTILS) },
];
var ThirdPartyScriptsService = /** @class */ (function () {
    function ThirdPartyScriptsService() {
    }
    ThirdPartyScriptsService.prototype.addThirdPartyScripts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, THIRD_PARTY_SCRIPTS_1, THIRD_PARTY_SCRIPTS_1_1, script, e_1_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, 6, 7]);
                        THIRD_PARTY_SCRIPTS_1 = __values(THIRD_PARTY_SCRIPTS), THIRD_PARTY_SCRIPTS_1_1 = THIRD_PARTY_SCRIPTS_1.next();
                        _b.label = 1;
                    case 1:
                        if (!!THIRD_PARTY_SCRIPTS_1_1.done) return [3 /*break*/, 4];
                        script = THIRD_PARTY_SCRIPTS_1_1.value;
                        return [4 /*yield*/, this.loadScript(script)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        THIRD_PARTY_SCRIPTS_1_1 = THIRD_PARTY_SCRIPTS_1.next();
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 7];
                    case 6:
                        try {
                            if (THIRD_PARTY_SCRIPTS_1_1 && !THIRD_PARTY_SCRIPTS_1_1.done && (_a = THIRD_PARTY_SCRIPTS_1.return)) _a.call(THIRD_PARTY_SCRIPTS_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ThirdPartyScriptsService.prototype.loadScript = function (_a) {
        var path = _a.path, async = _a.async, onloadEvent = _a.onloadEvent;
        return new Promise(function (resolve) {
            var script = document.createElement("script");
            script.setAttribute("src", path);
            script.setAttribute("type", "text/javascript");
            script.onload = function () {
                document.dispatchEvent(onloadEvent);
                resolve();
            };
            document.head.appendChild(script);
            if (async)
                resolve();
        });
    };
    ThirdPartyScriptsService.ngInjectableDef = i0.defineInjectable({ factory: function ThirdPartyScriptsService_Factory() { return new ThirdPartyScriptsService(); }, token: ThirdPartyScriptsService, providedIn: "root" });
    return ThirdPartyScriptsService;
}());
export { ThirdPartyScriptsService };
