import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AdminPage,
  BurnPage,
  MountingSettingsPage,
  QrScannerPage,
  ScreenSaverPage,
  LoginPage,
  ConfigurationsPage,
} from "ocm/src/app/pages";
import { SharedRoutes } from "shared/lib/common/enums";
import { IsPinAddedGuard } from "shared/lib/v2/guards";
import { ComesFromLoginGuard, IsRegisteredAdminGuard, IsUserScannedGuard } from "shared/lib/common/guards";
import { HistoryPage, DeviceTestPage } from "shared/lib/common/pages";

const routes: Routes = [
  {
    path: SharedRoutes.login,
    component: LoginPage,
  },
  {
    path: SharedRoutes.mounting,
    component: MountingSettingsPage,
    canActivate: [ComesFromLoginGuard],
  },
  {
    path: SharedRoutes.screenSaver,
    component: ScreenSaverPage,
    canActivate: [IsRegisteredAdminGuard],
  },
  {
    path: SharedRoutes.qrScanner,
    component: QrScannerPage,
    canActivate: [IsRegisteredAdminGuard],
  },
  {
    path: SharedRoutes.configurations,
    component: ConfigurationsPage,
    canActivate: [IsRegisteredAdminGuard],
  },
  {
    path: SharedRoutes.history,
    component: HistoryPage,
    canActivate: [IsRegisteredAdminGuard, IsUserScannedGuard],
  },
  {
    path: SharedRoutes.admin,
    component: AdminPage,
    canActivate: [IsRegisteredAdminGuard, IsPinAddedGuard],
  },
  {
    path: SharedRoutes.burn,
    component: BurnPage,
    canActivate: [IsRegisteredAdminGuard, IsUserScannedGuard],
  },
  {
    path: SharedRoutes.deviceTest,
    component: DeviceTestPage,
    canActivate: [],
  },
  { path: "**", redirectTo: SharedRoutes.screenSaver },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
