import { VERSION } from "shared/lib/common/enums";
import { IConfig } from "shared/lib/common/interfaces";

export const CONFIGURATION: IConfig = {
  screen_saver: {
    showSelectLanguage: true,
    backgroundCover: true,
    message: { show: true, horizontal: "center", vertical: "bottom" },
  },
  logo_url: "/assets/app-logo/ocm.svg",
  register_form: "https://ocm.test.point4more.com/portal/service/register/",
  organization: "ocm",
  p4m_api: {
    default: VERSION.v2,
  },
  showSelfServiceButton: false,
  inactivity_time: 30,
  is_custom_app: true,
};
