import { SharedRoutes } from "shared/lib/common/enums";
import { RoutingService, TranslateService, UserService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../services/user/user.service";
import * as i2 from "../../services/translate/translate.service";
import * as i3 from "../../services/routing/routing.service";
var IsUserScannedGuard = /** @class */ (function () {
    function IsUserScannedGuard(user, translate, routing) {
        this.user = user;
        this.translate = translate;
        this.routing = routing;
    }
    IsUserScannedGuard.prototype.canActivate = function (next, state) {
        if (this.user.getUser()) {
            return true;
        }
        else {
            this.routing.goForward([SharedRoutes.screenSaver]);
            return false;
        }
    };
    IsUserScannedGuard.ngInjectableDef = i0.defineInjectable({ factory: function IsUserScannedGuard_Factory() { return new IsUserScannedGuard(i0.inject(i1.UserService), i0.inject(i2.TranslateService), i0.inject(i3.RoutingService)); }, token: IsUserScannedGuard, providedIn: "root" });
    return IsUserScannedGuard;
}());
export { IsUserScannedGuard };
