/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calculator.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "../../../v1/components/close/close.component.ngfactory";
import * as i6 from "../../../v1/components/close/close.component";
import * as i7 from "../../../common/components/keypad/keypad.component.ngfactory";
import * as i8 from "../../../common/components/keypad/keypad.component";
import * as i9 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i10 from "angular-feather";
import * as i11 from "./calculator.page";
import * as i12 from "../../services/device-mounting/device-mounting.service";
import * as i13 from "../../../common/services/translate/translate.service";
var styles_CalculatorPage = [i0.styles];
var RenderType_CalculatorPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CalculatorPage, data: {} });
export { RenderType_CalculatorPage as RenderType_CalculatorPage };
export function View_CalculatorPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 30, "ion-content", [["scroll-y", "false"]], null, null, null, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 28, "div", [["class", "page-wrapper calculator revenue-calculator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "shared-close", [["class", "calculator__desktop-close"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.modal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CloseComponent_0, i5.RenderType_CloseComponent)), i1.ɵdid(5, 114688, null, 0, i6.CloseComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "h2", [["class", "calculator__title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "calculator__revenue-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "calculator__revenue-item__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 20, "div", [["class", "calculator__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "calculator__content__top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h1", [["class", "calculator__content__top__value"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 0, "p", [["class", " calculator__content__top__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "shared-keypad", [], null, [[null, "event"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("event" === en)) {
        var pd_0 = (_co.press($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_KeypadComponent_0, i7.RenderType_KeypadComponent)), i1.ɵdid(15, 49152, null, 0, i8.KeypadComponent, [], { buttons: [0, "buttons"] }, { event: "event" }), i1.ɵpad(16, 12), (_l()(), i1.ɵeld(17, 0, null, null, 12, "div", [["class", "calculator__content__extra"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "calculator__content__extra__value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "h1", [["class", "calculator__content__extra__value__value"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(20, 2), (_l()(), i1.ɵeld(21, 0, null, null, 0, "p", [["class", " calculator__content__extra__value__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 3, "ion-button", [["class", "calculator__content__extra__delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.press("delete") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(23, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(24, 0, null, 0, 1, "i-delete", [], null, null, null, i9.View_ɵdf_0, i9.RenderType_ɵdf)), i1.ɵdid(25, 49152, null, 0, i10.ɵdf, [], null, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "ion-button", [["class", "calculator__content__extra__reset"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.press("c") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(27, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵeld(28, 0, null, null, 1, "ion-button", [["class", "calculator__content__extra__submit"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.dismiss(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(29, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 1, "div", [["class", "calculator__mobile-close"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.modal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "p", [["class", "calculator__mobile-close__text"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_4 = _ck(_v, 16, 1, ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0", "00", "plusMinus"]); _ck(_v, 15, 0, currVal_4); var currVal_9 = !_co.value; _ck(_v, 29, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("CALCULATOR_TITLE"); _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.item == null) ? null : _co.item.name); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.value, (_co.decimalRevenue ? "1.2-2" : "1.0-0"))); _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.t._("CALCULATOR_TOTAL"); _ck(_v, 13, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v, 0), _co.value, (_co.decimalRevenue ? "1.2-2" : "1.0-0"))); _ck(_v, 19, 0, currVal_5); var currVal_6 = _co.t._("CALCULATOR_TOTAL"); _ck(_v, 21, 0, currVal_6); var currVal_7 = "C"; _ck(_v, 26, 0, currVal_7); var currVal_8 = _co.t._("CALCULATOR_ADD"); _ck(_v, 28, 0, currVal_8); var currVal_10 = _co.t._("CANCEL"); _ck(_v, 31, 0, currVal_10); }); }
export function View_CalculatorPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-calculator", [], null, null, null, View_CalculatorPage_0, RenderType_CalculatorPage)), i1.ɵdid(1, 114688, null, 0, i11.CalculatorPage, [i12.DeviceMountingService, i4.ModalController, i13.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalculatorPageNgFactory = i1.ɵccf("shared-v2-calculator", i11.CalculatorPage, View_CalculatorPage_Host_0, { item: "item" }, {}, []);
export { CalculatorPageNgFactory as CalculatorPageNgFactory };
