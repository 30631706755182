import { CommonModule, registerLocaleData, DecimalPipe } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { IonicModule } from "@ionic/angular";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import {
  IconArrowDownCircle,
  IconArrowUpCircle,
  IconCheck,
  IconCheckCircle,
  IconChevronDown,
  IconChevronUp,
  IconClock,
  IconCornerDownRight,
  IconDelete,
  IconEye,
  IconEyeOff,
  IconLogOut,
  IconMap,
  IconMinus,
  IconMinusCircle,
  IconPlus,
  IconPlusCircle,
  IconPower,
  IconPrinter,
  IconRefreshCcw,
  IconSettings,
  IconUserCheck,
  IconX,
  IconMaximize,
  IconChevronRight,
  IconChevronLeft,
} from "angular-feather";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { Configuration } from "shared/lib/v2/apis/mounting-service";
import { ApiModule as MountingServiceApiModule } from "shared/lib/v2/apis/mounting-service";
import { ApiModule as ProgramApiModule } from "shared/lib/v2/apis/programs";
import { ApiModule as AccountsApiModule } from "shared/lib/v2/apis/accounts";
import { ApiModule as CouponsApiModule } from "shared/lib/v2/apis/coupons";
import { ApiModule as TransactionsApiModule } from "shared/lib/v2/apis/transactions";
import * as CDirectives from "shared/lib/common/directives";
import * as CIcons from "shared/lib/common/icons";
import * as v1Pages from "shared/lib/v1/pages";
import * as CPipes from "shared/lib/common/pipes";
import * as CServices from "shared/lib/common/services";
import * as CComponents from "shared/lib/common/components";
import * as CPages from "shared/lib/common/pages";
import * as v2Pages from "shared/lib/v2/pages";
import * as v1Components from "shared/lib/v1/components";
import * as v2Components from "shared/lib/v2/components";

registerLocaleData(localeDe, "de");
registerLocaleData(localeEn, "en");

const pipes = [CPipes.OrderByPipe];

const featherIcons = [
  IconCheck,
  IconCheckCircle,
  IconChevronDown,
  IconChevronUp,
  IconChevronRight,
  IconChevronLeft,
  IconClock,
  IconCornerDownRight,
  IconDelete,
  IconEye,
  IconEyeOff,
  IconLogOut,
  IconMap,
  IconMinus,
  IconMinusCircle,
  IconPlus,
  IconPlusCircle,
  IconPrinter,
  IconSettings,
  IconX,
  IconPower,
  IconRefreshCcw,
  IconArrowDownCircle,
  IconArrowUpCircle,
  IconUserCheck,
  IconMaximize,
];
const pages = [
  v1Pages.AdminPage,
  v1Pages.ConfigurationsPage,
  v1Pages.CouponCardPage,
  v1Pages.InformationModalPage,
  v1Pages.MountingSettingsPage,
  v1Pages.PrintPage,
  v2Pages.AdminPage,
  v2Pages.BurnPage,
  v2Pages.CalculatorPage,
  v2Pages.MountingSettingsPage,
  v2Pages.RegisterPage,
  v2Pages.RevenuePage,
  v2Pages.SummaryPage,
  v2Pages.EnsureUnmountLoginModal,
  v2Pages.CouponCardPage,
  CPages.BurnPage,
  CPages.CheckPinModalPage,
  CPages.ConfirmationPage,
  CPages.DeviceTestPage,
  CPages.EnsureUnmountLoginModal,
  CPages.HistoryPage,
  CPages.LoginPage,
  CPages.QrScannerPage,
  CPages.RegisterPage,
  CPages.ScreenSaverPage,
  CPages.SuccessPage,
  CPages.NotFoundPage,
];

const modals = [
  v1Pages.InformationModalPage,
  v1Pages.CouponCardPage,
  v1Pages.PrintPage,
  v2Pages.RegisterPage,
  v2Pages.CalculatorPage,
  v2Pages.SummaryPage,
  v2Pages.EnsureUnmountLoginModal,
  v2Pages.CouponCardPage,
  CPages.SuccessPage,
  CPages.ConfirmationPage,
  CPages.CheckPinModalPage,
  CPages.RegisterPage,
  CPages.EnsureUnmountLoginModal,
];

const components = [
  v1Components.ButtonComponent,
  v1Components.CloseComponent,
  v1Components.ConnectionBannerComponent,
  v1Components.CouponCardComponent,
  v1Components.HeaderComponent,
  v1Components.LoadingComponent,
  v1Components.PinComponent,
  v1Components.QrscannerComponent,
  v1Components.RevenueModuleComponent,
  v1Components.SelectComponent,
  v1Components.AppComponent,
  v2Components.CouponCardComponent,
  v2Components.SummaryComponent,
  v2Components.SummaryFooterComponent,
  v2Components.AppComponent,
  v2Components.RevenueItemListComponent,
  CComponents.KeypadComponent,
];

const appIcons = [CIcons.DevicesComponent, CIcons.QrCodeComponent, CIcons.ScanFrameComponent, CIcons.TapComponent];

const directives = [CDirectives.DoubleTapDirective];

export function getConfig(): Configuration {
  return new Configuration();
}

@NgModule({
  entryComponents: [modals],
  providers: [
    QRScanner,
    DecimalPipe,
    HttpClient,
    { provide: HAMMER_GESTURE_CONFIG, useClass: CServices.MyHammerGestureConfig },
    { provide: ErrorHandler, useClass: CServices.SentryService },
  ],
  imports: [
    AutocompleteLibModule,
    ZXingScannerModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
    BrowserModule,
    featherIcons,
    IonicModule.forRoot(),
    MountingServiceApiModule.forRoot(getConfig),
    ProgramApiModule.forRoot(getConfig),
    AccountsApiModule.forRoot(getConfig),
    CouponsApiModule.forRoot(getConfig),
    TransactionsApiModule.forRoot(getConfig),
  ],
  declarations: [pages, components, appIcons, pipes, directives],
  exports: [AutocompleteLibModule, featherIcons, pages, components, appIcons, pipes, directives],
})
export class SharedModule {}
