/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./burn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../coupon/coupon.component.ngfactory";
import * as i5 from "../coupon/coupon.component";
import * as i6 from "../../../../../../shared/lib/common/services/translate/translate.service";
import * as i7 from "@angular/common";
import * as i8 from "../coupon-card/coupon-card.component.ngfactory";
import * as i9 from "../coupon-card/coupon-card.component";
import * as i10 from "../../../../../../shared/lib/common/services/utils/utils.service";
import * as i11 from "./burn.component";
var styles_BurnComponent = [i0.styles];
var RenderType_BurnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BurnComponent, data: {} });
export { RenderType_BurnComponent as RenderType_BurnComponent };
function View_BurnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], null, null); }
function View_BurnComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-item", [["class", "burn-component__ticket-list__ticket"]], [[2, "--active", null], [2, "--disable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTicketHandler(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ocm-coupon", [], null, null, null, i4.View_CouponComponent_0, i4.RenderType_CouponComponent)), i1.ɵdid(3, 49152, null, 0, i5.CouponComponent, [i6.TranslateService], { ticket: [0, "ticket"] }, null)], function (_ck, _v) { var currVal_2 = !_v.context.$implicit.unlocked; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.ticketSelected == null) ? null : _co.ticketSelected.voucherId) === _v.context.$implicit.voucherId); var currVal_1 = !_v.context.$implicit.unlocked; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BurnComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-list", [["class", "burn-component__ticket-list"]], [[2, "--is-mobile", null]], null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(1, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BurnComponent_3)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tickets; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mobileVersion; _ck(_v, 0, 0, currVal_0); }); }
function View_BurnComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "burn-component__redeem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ocm-coupon-card", [], null, [[null, "RedeemEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("RedeemEvent" === en)) {
        var pd_0 = (_co.askToRedeemHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CouponCardComponent_0, i8.RenderType_CouponCardComponent)), i1.ɵdid(2, 49152, null, 0, i9.CouponCardComponent, [i10.UtilsService, i6.TranslateService], { ticketSelected: [0, "ticketSelected"] }, { RedeemEvent: "RedeemEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ticketSelected; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BurnComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "burn-component__empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("BURN_PAGE_EMPTY_MESSAGE"); _ck(_v, 2, 0, currVal_0); }); }
export function View_BurnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BurnComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BurnComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BurnComponent_4)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BurnComponent_5)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.tickets == null) ? null : _co.tickets.length) > 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((!_co.mobileVersion && (((_co.tickets == null) ? null : _co.tickets.length) > 0)) && !_co.loading); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((((_co.tickets == null) ? null : _co.tickets.length) === 0) && !_co.loading); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_BurnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ocm-burn", [], null, null, null, View_BurnComponent_0, RenderType_BurnComponent)), i1.ɵdid(1, 49152, null, 0, i11.BurnComponent, [i6.TranslateService], null, null)], null, null); }
var BurnComponentNgFactory = i1.ɵccf("ocm-burn", i11.BurnComponent, View_BurnComponent_Host_0, { mobileVersion: "mobileVersion", ticketSelected: "ticketSelected", tickets: "tickets", loading: "loading" }, { askToRedeemEvent: "askToRedeemEvent", selectTicketEvent: "selectTicketEvent" }, []);
export { BurnComponentNgFactory as BurnComponentNgFactory };
