import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { CONFIGURATION } from "shared/lib/common/constants";
import { ConfigService } from "../config/config.service";
import { Platform } from "@ionic/angular";
import { EnvironmentService } from "../environment/environment.service";

export type SentryTagKey = "app" | "api-version" | "email" | "device-id";

const SENTRY_DSN = "https://6c69381642e9499cb7cb8aa23b1d5aa9@sentry.io/1364464";

@Injectable({ providedIn: "root" })
export class SentryService extends ErrorHandler {
  constructor(private config: ConfigService, private platform: Platform, private environment: EnvironmentService) {
    super();

    Sentry.init({
      release: `${this.platform.is("capacitor") ? "ios" : "web"}-point4more-pos-app@${this.environment.getVariable("RELEASE")}`,
      dsn: SENTRY_DSN,
      environment: this.environment.getVariable("BUILD_ENV"),
      attachStacktrace: true,
    });

    Sentry.configureScope(scope => {
      scope.setTag("app", this.config.getOrganization());
      scope.setTag("api-version", CONFIGURATION.p4m_api.login ? CONFIGURATION.p4m_api.login : CONFIGURATION.p4m_api.default);
    });
  }

  public handleError(error: any): void {
    super.handleError(error);
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }

  public info(data: { status: number; message: string; method?: string }, level: Sentry.Severity = Sentry.Severity.Info): void {
    Sentry.captureMessage(JSON.stringify(data), level);
  }

  public breadcrumb(
    message: string = "",
    type: "http" | "navigation" = "navigation",
    level: Sentry.Severity = Sentry.Severity.Log,
    data: object = {},
  ): void {
    Sentry.addBreadcrumb({ message, level, type, data });
  }

  public addTag(key: SentryTagKey, value: string): void {
    Sentry.configureScope(scope => {
      scope.setTag(key, value);
    });
  }

  public removeTag(key: SentryTagKey): void {
    Sentry.configureScope(scope => {
      scope.setTag(key, null);
    });
  }
}
