import { Component, OnInit } from "@angular/core";

@Component({
  selector: "shared-close",
  templateUrl: "./close.component.html",
  styleUrls: ["./close.component.scss"],
})
export class CloseComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
