import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IVoucher } from "shared/lib/common/interfaces";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "ocm-burn",
  templateUrl: "./burn.component.html",
  styleUrls: ["./burn.component.scss"],
})
export class BurnComponent {
  @Output() private askToRedeemEvent: EventEmitter<any> = new EventEmitter(true);
  @Output() private selectTicketEvent: EventEmitter<IVoucher> = new EventEmitter(true);
  @Input() public mobileVersion: boolean;
  @Input() public ticketSelected: IVoucher;
  @Input() public tickets: IVoucher[];
  @Input() public loading: boolean;

  constructor(public translate: TranslateService) {}

  public askToRedeemHandler(voucher: IVoucher): void {
    this.askToRedeemEvent.emit(voucher);
  }

  public selectTicketHandler(ticket: IVoucher): void {
    this.selectTicketEvent.emit(ticket);
  }
}
