import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { TranslateService, UtilsService, P4mService, AdminService, RoutingService } from "shared/lib/common/services";
import { IIdentifierType } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services";

export interface ISuccessComponentParams {
  idType: IIdentifierType;
  keyCode: string;
  points: number;
  title: string;
}

@Component({
  selector: "shared-success",
  templateUrl: "./success.page.html",
  styleUrls: ["./success.page.scss"],
})
export class SuccessPage implements OnInit, OnDestroy {
  @Input() public keyCode: string;
  @Input() public idType: IIdentifierType;
  @Input() public points: number;
  @Input() public title: string;

  public loading: boolean;
  public totalPoints: number;
  public timeout: NodeJS.Timeout;

  constructor(
    private routing: RoutingService,
    private modal: ModalController,
    private p4m: P4mService,
    private admin: AdminService,
    private config: ConfigService,
    public utils: UtilsService,
    public translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.setTimeout();
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  public async ionViewDidEnter(): Promise<void> {
    const resp = await this.p4m.readAccountBalance({
      keyCode: this.keyCode,
      idType: this.idType,
      apiKey: this.admin.getApiKey(),
      clientId: this.config.getMountingConfig().clientId,
    });
    if (resp.ok === true) this.totalPoints = resp.response.points;
    this.loading = false;
  }

  public async success(): Promise<void> {
    await this.modal.dismiss();
    this.routing.goForward([SharedRoutes.screenSaver]);
  }

  private setTimeout(): void {
    this.timeout = setTimeout(() => {
      clearTimeout(this.timeout);
      this.success();
    }, 20000);
  }
}
