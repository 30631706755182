var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { HttpClient } from "@angular/common/http";
import { P4M, LOCAL_STORAGE } from "shared/lib/common/enums";
import { IAccount, IAccountBooking, IAccountDetails, IBalance, IBookPointsParams, IError, IGetCouponsResponse, IGetCouponsParams, IGetRewardsResponse, IGetRewardsParams, IGetUserDetailsResponse, IGetUserDetailsParams, IGetUserHistoryParams, IGetUserHistoryResponse, IIdentifier, IIdentifierType, ILoginParams, IMembership, IPartner, IRedeemCouponParams, IRedeemRewardParams, IResponseP4M, IRevenuePointsParams, IRevenuePointsResponse, IStoreAPI2, ITransaction, IFile, ILevel, IStatusDefinitions, IReward, ICoupon, IStatus, IUserStatus, IReadBalanceParams, ICheckInParams, ICheckInResponse, IUserV2, IReadUserParams, } from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { EnvironmentService } from "../../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../sentry/sentry.service";
import * as i3 from "../../config/config.service";
import * as i4 from "../../utils/utils.service";
import * as i5 from "../../translate/translate.service";
import * as i6 from "../../environment/environment.service";
var P4mV2Service = /** @class */ (function () {
    function P4mV2Service(http, sentry, config, utils, translate, environment) {
        this.http = http;
        this.sentry = sentry;
        this.config = config;
        this.utils = utils;
        this.translate = translate;
        this.environment = environment;
    }
    P4mV2Service.prototype.getUserDetails = function (_a) {
        var keyCode = _a.keyCode, idType = _a.idType;
        return __awaiter(this, void 0, void 0, function () {
            var _b, accountResponse, accountMembershipsResponse, accountBalanceResponse, accountCouponsResponse, accountIdentifiersResponse, accountLevelResponse, membership, message_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.readAccount(keyCode, idType),
                            this.readAccountMemberships(keyCode, idType),
                            this.readAccountBalance({ keyCode: keyCode, idType: idType }),
                            this.readAccountCoupons(keyCode, idType),
                            this.readAccountIdentifiers(keyCode, idType),
                            this.readAccountLevel(keyCode, idType),
                        ])];
                    case 1:
                        _b = __read.apply(void 0, [_c.sent(), 6]), accountResponse = _b[0], accountMembershipsResponse = _b[1], accountBalanceResponse = _b[2], accountCouponsResponse = _b[3], accountIdentifiersResponse = _b[4], accountLevelResponse = _b[5];
                        if (accountResponse.ok === true &&
                            accountMembershipsResponse.ok === true &&
                            accountBalanceResponse.ok === true &&
                            accountCouponsResponse.ok === true &&
                            accountIdentifiersResponse.ok === true &&
                            accountLevelResponse.ok === true) {
                            membership = this.getOwner(accountMembershipsResponse.response);
                            return [2 /*return*/, {
                                    ok: true,
                                    response: {
                                        userId: membership ? membership.userId : null,
                                        userStatusCode: this.getUserStatus(accountMembershipsResponse.response, accountResponse.response.status),
                                        totalPoints: accountBalanceResponse.response.points,
                                        coupons: this.parseCoupons(accountCouponsResponse.response.filter(function (c) { return c.usageType !== "REWARD"; }), accountBalanceResponse.response.points),
                                        rewards: this.parseRewards(accountCouponsResponse.response.filter(function (c) { return c.usageType === "REWARD"; }), accountBalanceResponse.response.points),
                                        isAppUser: this.isAppUser(accountIdentifiersResponse.response),
                                        statusDefinitions: accountLevelResponse.response ? this.parseLevel(accountLevelResponse.response) : null,
                                    },
                                }];
                        }
                        else {
                            [
                                accountResponse,
                                accountMembershipsResponse,
                                accountBalanceResponse,
                                accountCouponsResponse,
                                accountIdentifiersResponse,
                                accountLevelResponse,
                            ].forEach(function (response) {
                                if (response.ok === false)
                                    message_1 = response.error.message;
                            });
                            return [2 /*return*/, { ok: false, error: { message: message_1 } }];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    P4mV2Service.prototype.getUserHistory = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.readAccountBookings(params.keyCode, params.idType)];
                    case 1:
                        response = _a.sent();
                        if (response.ok === true) {
                            return [2 /*return*/, { ok: true, response: { transactions: this.parseTransactions(response.response) } }];
                        }
                        else {
                            return [2 /*return*/, response];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    P4mV2Service.prototype.bookPoints = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createAccountBookings(params.keyCode, "EARNBOOKING", params.points, this.translate._("BOOK_POINTS_REASON"), params.idType)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    P4mV2Service.prototype.revenuePoints = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createAccountBookings(params.keyCode, "EARNBOOKING", params.totalPoints.toString(), this.translate._("REVENUE_POINTS_REASON"), params.idType)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    P4mV2Service.prototype.redeemCoupon = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.redeemAccountCoupon(params.accountVoucherId)];
            });
        });
    };
    P4mV2Service.prototype.redeemReward = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.redeemAccountCoupon(params.accountVoucherId)];
            });
        });
    };
    P4mV2Service.prototype.getCoupons = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, { ok: true, response: { coupons: [] } }];
            });
        });
    };
    P4mV2Service.prototype.getRewards = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, { ok: true, response: { rewards: [] } }];
            });
        });
    };
    P4mV2Service.prototype.login = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .post(_this.environment.getVariable("API_V2") + "/" + P4M.LOGIN_V2, {
                            userName: params.email,
                            org: params.organization,
                            password: params.password,
                        }, {
                            responseType: "text",
                            headers: { "Content-Type": "application/json; charset=utf-8", "interaction-id": params.organization !== "oek" ? "posapp" : "" },
                        })
                            .subscribe(function (response) {
                            _this.setToken(response);
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.login",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readProgramPartners = function (workstationId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.PROGRAMS + "/" + _this.config.getOrganization() + "/" + P4M.PARTNERS, {
                            headers: { authorization: _this.getToken(), "interaction-id": workstationId || "pos-app" },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readProgramPartners",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readPartnerStores = function (partnerId, workstationId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.PROGRAMS + "/" + _this.config.getOrganization() + "/" + P4M.PARTNERS + "/" + partnerId + "/" + P4M.STORES, {
                            headers: { authorization: _this.getToken(), "interaction-id": workstationId || "pos-app" },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readPartnerStores",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccountBalance = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + params.keyCode + "/" + P4M.BALANCE, {
                            headers: {
                                authorization: _this.getToken(),
                                "interaction-id": _this.config.getMountingConfig().workstationId,
                                "id-type": params.idType,
                            },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccountBalance",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readUser = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        if (params.userId) {
                            _this.http
                                .get(_this.environment.getVariable("API_V2") + "/" + P4M.USERS + "/" + params.userId, {
                                headers: {
                                    authorization: _this.getToken(),
                                    "interaction-id": _this.config.getMountingConfig().workstationId,
                                    "id-type": params.idType,
                                },
                            })
                                .subscribe(function (response) {
                                resolve(_this.parseResponseV2(response));
                            }, function (error) {
                                var err = _this.parseError(error);
                                _this.sentry.info({
                                    status: err.status,
                                    message: err.message,
                                    method: "p4m.readUser",
                                });
                                resolve({ ok: false, error: { message: err.message } });
                            });
                        }
                        else {
                            resolve({ ok: true, response: { emailAddress: _this.translate._("ANONYMOUS_USER") } });
                        }
                    })];
            });
        });
    };
    P4mV2Service.prototype.parseResponseV2 = function (response) {
        if (response && response.errorType && response.message) {
            return { ok: false, error: { message: response.message } };
        }
        else {
            return { ok: true, response: response };
        }
    };
    P4mV2Service.prototype.checkIn = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.resolve({ ok: true, response: null })];
            });
        });
    };
    P4mV2Service.prototype.parseCoupons = function (coupons, totalPoints) {
        var _this = this;
        return (coupons || []).map(function (coupon) { return ({
            couponId: coupon.couponId,
            accountCouponId: coupon.accountCouponId,
            title: _this.getCouponi18nField(coupon.i18nFields, "title"),
            subtitle: _this.getCouponi18nField(coupon.i18nFields, "subTitle"),
            description: _this.getCouponi18nField(coupon.i18nFields, "description"),
            points: coupon.couponValue,
            couponFiles: _this.parseFiles(coupon.images),
            redeemDependencyCode: null,
            externalCodeBarcodeTypeCode: null,
            validToDate: { unixTimeStamp: _this.utils.parseDate(coupon.validTo) },
            unlocked: _this.isUnlocked(coupon, totalPoints),
            externalCode: coupon.externalReference,
            couponTypeCode: coupon.usageType,
            pictureFilePath: "",
            customProperties: coupon.customProperties,
            validFromDate: { unixTimeStamp: _this.utils.parseDate(coupon.validFrom) },
            activation: coupon.activation,
        }); });
    };
    P4mV2Service.prototype.getCouponi18nField = function (field, param) {
        if (field) {
            if (this.translate.getSessionLanguage() === "de") {
                return field.de ? field.de[param] : "";
            }
            else {
                return field.en ? field.en[param] : "";
            }
        }
        else {
            return "";
        }
    };
    P4mV2Service.prototype.parseFiles = function (images) {
        var c = [];
        if (images) {
            images.forEach(function (image) {
                if (image.tags) {
                    image.tags.forEach(function (tag) {
                        c.push({
                            pictureFilePath: image.path,
                            tag: tag,
                        });
                    });
                }
            });
        }
        return c;
    };
    P4mV2Service.prototype.isUnlocked = function (coupon, totalPoints) {
        return ((coupon.validTo
            ? this.utils.isBetweenDates(new Date(coupon.validFrom).getTime(), new Date(coupon.validTo).getTime())
            : this.utils.isBeforeNow(new Date(coupon.validFrom).getTime())) &&
            (coupon.usageType !== "REWARD" || coupon.couponValue <= totalPoints));
    };
    P4mV2Service.prototype.getUserStatus = function (memberShips, status) {
        if (status === "LOCKED")
            return status;
        if (memberShips && memberShips.length > 0)
            return "RGU";
        return "";
    };
    P4mV2Service.prototype.readAccountLevel = function (accountId, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + accountId + "/" + P4M.LEVEL, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            if (err.status >= 400 && err.status < 500) {
                                resolve({ ok: true, response: null });
                            }
                            else {
                                _this.sentry.info({
                                    status: err.status,
                                    message: err.message,
                                    method: "p4m.readAccountLevel",
                                });
                                resolve({ ok: false, error: { message: err.message } });
                            }
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccount = function (accountId, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + accountId, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccount",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccountMemberships = function (accountId, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + accountId + "/" + P4M.MEMBERSHIPS, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccountMemberships",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccountCoupons = function (accountId, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/v2/" + P4M.ACCOUNTS + "/" + accountId + "/" + P4M.ACCOUNT_COUPONS, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccountCoupons",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccountIdentifiers = function (accountId, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + accountId + "/" + P4M.IDENTIFIERS, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccountIdentifiers",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccountDetails = function (accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNT_DETAILS + "/" + accountId, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccountDetails",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.createAccountBookings = function (accountId, bookingType, points, reason, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .post(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + accountId + "/" + P4M.BOOKINGS, { bookingType: bookingType, bookingTypeCode: "MANUALREVENUE", points: points, reason: reason }, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.createAccountBookings",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.readAccountBookings = function (accountId, idType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .get(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNTS + "/" + accountId + "/" + P4M.BOOKINGS, {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": idType },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.readAccountBookings",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.redeemAccountCoupon = function (accountCouponId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http
                            .patch(_this.environment.getVariable("API_V2") + "/" + P4M.ACCOUNT_COUPONS + "/" + accountCouponId, "", {
                            headers: { authorization: _this.getToken(), "interaction-id": _this.config.getMountingConfig().workstationId, "id-type": "ID" },
                        })
                            .subscribe(function (response) {
                            resolve(_this.parseResponseV2(response));
                        }, function (error) {
                            var err = _this.parseError(error);
                            _this.sentry.info({
                                status: err.status,
                                message: err.message,
                                method: "p4m.redeemAccountCoupon",
                            });
                            resolve({ ok: false, error: { message: err.message } });
                        });
                    })];
            });
        });
    };
    P4mV2Service.prototype.parseRewards = function (coupons, totalPoints) {
        var _this = this;
        return (coupons || []).map(function (coupon) { return ({
            rewardId: coupon.couponId,
            accountCouponId: coupon.accountCouponId,
            title: _this.getCouponi18nField(coupon.i18nFields, "title"),
            subtitle: _this.getCouponi18nField(coupon.i18nFields, "subTitle"),
            description: _this.getCouponi18nField(coupon.i18nFields, "description"),
            points: coupon.couponValue,
            unlocked: _this.isUnlocked(coupon, totalPoints),
            rewardFiles: _this.parseFiles(coupon.images),
            redeemDependencyCode: null,
            decreasePoints: true,
            createDateTime: null,
            lastRedeemDateTime: null,
            customProperties: coupon.customProperties,
            validFromDate: { unixTimeStamp: _this.utils.parseDate(coupon.validFrom) },
            validToDate: { unixTimeStamp: _this.utils.parseDate(coupon.validTo) },
            activation: coupon.activation,
        }); });
    };
    P4mV2Service.prototype.parseTransactions = function (bookings) {
        var _this = this;
        var c = [];
        if (bookings) {
            bookings.forEach(function (booking) {
                c.push({
                    description: _this.getBookingDescription(booking),
                    points: booking.bookingType === "EARNBOOKING" ? booking.points : booking.points * -1,
                    transactionDateTime: {
                        unixTimeStamp: _this.utils.parseDate(booking.bookingTime[booking.bookingTime.length - 1] === "Z" ? booking.bookingTime : booking.bookingTime + "Z"),
                    },
                });
            });
        }
        return c;
    };
    P4mV2Service.prototype.getBookingDescription = function (booking) {
        switch (booking.bookingTypeCode) {
            case "COU":
                if (booking.points === 0) {
                    return "" + this.translate._("COUPON_REDEMPTION") + (booking.reason ? ": " + booking.reason : "");
                }
                else {
                    return "" + this.translate._("POINT_REDEMPTION") + (booking.reason ? ": " + booking.reason : "");
                }
            case "REV":
                switch (booking.bookingType) {
                    case "BURNBOOKING":
                        return "" + this.translate._("PAY_WITH_POINTS") + (booking.reason ? ": " + booking.reason : "");
                    case "EARNBOOKING":
                        return "" + this.translate._("PURCHASE") + (booking.reason ? ": " + booking.reason : "");
                }
                break;
            default:
                return booking.reason;
        }
    };
    P4mV2Service.prototype.getOwner = function (memberships) {
        return memberships.filter(function (membership) { return membership.memberRole === "OWNER"; })[0];
    };
    P4mV2Service.prototype.isAppUser = function (identifiers) {
        return identifiers.filter(function (identifier) { return identifier.type === "APPCODE"; }).length > 0;
    };
    P4mV2Service.prototype.getToken = function () {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
    };
    P4mV2Service.prototype.setToken = function (token) {
        localStorage.setItem(LOCAL_STORAGE.TOKEN, this.utils.encrypt(token));
    };
    P4mV2Service.prototype.parseError = function (error) {
        if (error.message && error.status) {
            return error;
        }
        else if (error.error instanceof ErrorEvent) {
            return {
                message: error.message,
                status: error.status,
            };
        }
        else {
            return {
                message: error.error ? error.error.message : "An error has occurred " + error.status,
                status: error.status,
            };
        }
    };
    P4mV2Service.prototype.parseLevel = function (response) {
        return [
            {
                unlocked: true,
                pictureFilePath: "",
                points: response.currentStatePoints,
                statusDefinitionId: 0,
                statusName: response.name,
                pointsDisplay: String(response.currentStatePoints),
            },
        ];
    };
    P4mV2Service.ngInjectableDef = i0.defineInjectable({ factory: function P4mV2Service_Factory() { return new P4mV2Service(i0.inject(i1.HttpClient), i0.inject(i2.SentryService), i0.inject(i3.ConfigService), i0.inject(i4.UtilsService), i0.inject(i5.TranslateService), i0.inject(i6.EnvironmentService)); }, token: P4mV2Service, providedIn: "root" });
    return P4mV2Service;
}());
export { P4mV2Service };
