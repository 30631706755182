<ion-content class="modal--inner confirm page-wrapper">
  <div class="confirm--header">
    <h1 class="modal--title confirm--title">{{ params?.title }}</h1>
  </div>
  <div class="confirm--points" *ngIf="params?.voucher">
    <p class="confirm--points__title" *ngIf="(params?.voucher)['points']">{{ params.voucher.title }}</p>
    <p class="confirm--points__number" *ngIf="(params?.voucher)['points']">
      {{ params.voucher["points"] | number: "":translate.getSessionLanguage() }}
      <span>{{ translate._("POINTS_TO_EXCHANGE") }}</span>
    </p>
    <p class="confirm--points__free" *ngIf="!(params?.voucher)['points']">
      <span>{{ params?.voucher.title }}</span>
    </p>
  </div>
  <div class="confirm--subtitle" *ngIf="!params?.voucher && params?.subtitle">
    <p class="confirm--subtitle__text">
      {{ params?.subtitle | number: "":translate.getSessionLanguage() }}
      <span>{{ translate._("POINTS_TO_EXCHANGE") }}</span>
    </p>
  </div>
  <div class="modal--footer confirm--footer">
    <ion-button (tap)="cancelation()" class="button confirm--button-transparent" color="dark" fill="clear"
      expand="block">
      {{ params?.dismissText }}
    </ion-button>
    <ion-button (tap)="confirmation()" class="button confirm--button-primary">
      {{ params?.confirmText }}
    </ion-button>
  </div>
</ion-content>