import * as JsBarcode from "jsbarcode";
import * as QRcode from "qrcode";
import * as i0 from "@angular/core";
var QrService = /** @class */ (function () {
    function QrService() {
    }
    QrService.prototype.draw = function (element, data, format) {
        if (format === "CODE128" || format === "EAN13" || format === "EAN8")
            JsBarcode(element, data, { format: format });
        if (format === "QRCODE")
            QRcode.toCanvas(element, data);
    };
    QrService.ngInjectableDef = i0.defineInjectable({ factory: function QrService_Factory() { return new QrService(); }, token: QrService, providedIn: "root" });
    return QrService;
}());
export { QrService };
