import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { CheckPinModalPage, ICheckPinModalPageInput, ICheckPinModalPageOutput } from "shared/lib/common/pages";
import { DeviceMountingService } from "../../services";

@Injectable({
  providedIn: "root",
})
export class IsPinAddedGuard implements CanActivate {
  constructor(private modal: ModalController, private deviceMounting: DeviceMountingService) {}

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.deviceMounting.profile.pinRequired) {
      return await this.showCheckPinModal(this.deviceMounting.profile.pin ? this.deviceMounting.profile.pin : "");
    } else {
      return true;
    }
  }

  private async showCheckPinModal(pin: string): Promise<boolean> {
    const componentProps: ICheckPinModalPageInput = {
      pin,
    };
    const checkPinModal = await this.modal.create({
      component: CheckPinModalPage,
      cssClass: "modal modal--full-screen",
      componentProps,
    });
    await checkPinModal.present();
    const { data } = (await checkPinModal.onDidDismiss()) as ICheckPinModalPageOutput;
    return data;
  }
}
